import { Layers, LocalCafe, ViewCarousel } from "@material-ui/icons";
import { Newspaper, Reviews as ReviewsIcon, Shop2 } from "@mui/icons-material";
import {
  Box,
  Card,
  Grid,
  Link,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TabPanel } from "../../helper";
import Banners from "./components/Banners";
import Blogs from "./components/Blogs";
import RandomBanners from "./components/RandomBanners";
import Reviews from "./components/Reviews";
import StaticPages from "./components/StaticPages";
import { useLocation, useNavigate } from "react-router-dom";
import { IntegrationIllustration } from "../../Svgs";
import { useSelector } from "react-redux";

const Frontend = () => {
  const tabs = [
    { label: "Blogs", icon: <Newspaper />, value: "blogs" },
    { label: "Banners", icon: <ViewCarousel />, value: "banners" },
    // { label: "Static Pages", icon: <Layers />, value: "static_pages" },
    { label: "Reviews", icon: <ReviewsIcon />, value: "reviews" },
    { label: "Random Banners", icon: <Shop2 />, value: "random_banners" },
  ];
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedTab, setSelectedTab] = useState("blogs");
  const frontendAccess = useSelector((state) => state.user?._access?.Frontend);
  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = useSelector((state) => state.user.isAdmin);

  useEffect(() => {
    let selected = location.hash?.replace("#", "");
    let firstTab = tabs?.[0]?.value;
    if (!selected) selected = firstTab;
    if (selected !== selectedTab) setSelectedTab(selected);
  }, []);

  useEffect(() => {
    navigate(`#${selectedTab}`);
  }, [selectedTab]);

  if (!frontendAccess && !isAdmin)
    return (
      <Box>
        <Stack direction={"row"} mt="20vh" justifyContent={"center"}>
          <IntegrationIllustration />
        </Stack>
        <Stack direction={"row"} justifyContent={"center"}>
          {/* <SettingsIllustration /> */}
          <Typography variant="h6" mt={2}>
            You don't have access to view Frontend!
          </Typography>
        </Stack>
      </Box>
    );

  return (
    <Box overflow={"visible"}>
      <Card
        sx={{
          // height: "calc(100vh - 88px)",
          overflow: "visible",
        }}
      >
        {isIpad ? (
          <Tabs
            onChange={(e, newVal) => setSelectedTab(newVal)}
            value={selectedTab}
            variant="scrollable"
            scrollButtons="auto"
            // orientation="vertical"
            // sx={{ borderRight: 1, borderColor: "divider", height: "100%" }}
          >
            {tabs.map((obj) => (
              <Tab
                icon={obj.icon}
                iconPosition="start"
                label={obj.label}
                value={obj.value}
                sx={{
                  justifyContent: "flex-start",
                  gap: "8px",
                  margin: "0px 0px",
                  minHeight: "50px",
                  fontSize: "14px",
                }}
              />
            ))}
          </Tabs>
        ) : null}
        <Grid container sx={{ height: "100%" }} overflow={"visible"}>
          <Grid
            item
            xs={2}
            sx={isIpad ? { display: "none" } : { height: "100%" }}
          >
            <Tabs
              onChange={(e, newVal) => setSelectedTab(newVal)}
              value={selectedTab}
              orientation="vertical"
              sx={{
                borderRight: 1,
                borderColor: "divider",
                height: "100%",
                mb: 2,
              }}
            >
              {tabs.map((obj) => (
                <Tab
                  icon={obj.icon}
                  iconPosition="start"
                  label={obj.label}
                  value={obj.value}
                  // component={Link}
                  // to={`/${obj.value}`}
                  sx={{
                    justifyContent: "flex-start",
                    gap: "8px",
                    margin: "0px 0px",
                    minHeight: "50px",
                    fontSize: "14px",
                  }}
                />
              ))}
            </Tabs>
          </Grid>
          {/* <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/settings" element={<Settings />} />
          </Routes> */}
          <Grid
            item
            xs={10}
            // Commented this line to show the editor hover toolbar over the grids
            // sx={{ height: "calc(100vh - 88px)", overflowY: "scroll" }}
            // sx={{ height: "calc(100vh - 88px)" }}
            overflow={"visible"}
          >
            <TabPanel value={"blogs"} index={selectedTab} overflow={"visible"}>
              <Blogs />
            </TabPanel>
            <TabPanel value={"static_pages"} index={selectedTab}>
              <StaticPages />
            </TabPanel>
            <TabPanel value={"banners"} index={selectedTab}>
              <Banners />
            </TabPanel>
            <TabPanel value={"reviews"} index={selectedTab}>
              <Reviews />
            </TabPanel>
            <TabPanel value={"random_banners"} index={selectedTab}>
              <RandomBanners />
            </TabPanel>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default Frontend;
