import {
  Autocomplete,
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SmsTemplateList } from "../../integrations/components/sms/Templates";
import {
  backendApiCall,
  backendApiEndpoints,
  dialogStyle,
  fetchIPInfo,
  formatDate,
  getAuthToken,
  getId,
  getRandomString,
  getTemplateVariableObjFromCustomer,
  getToken,
  getUserId,
  getWebsite,
  isObjWithValues,
  sendExotelSms,
  sendGupshupSms,
  sendSmsMsg91,
  xml2Json,
} from "../../../helper";
import { useDispatch, useSelector } from "react-redux";
import { Label } from "./Info";
import { Msg91Svg, PhoneSvg } from "../../../Svgs";
import { variables } from "../../components/TempleteList";
import { LoadingButton } from "@mui/lab";
import { ArrowBack } from "@mui/icons-material";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import { getIp } from "../../../App";
import { UAParser } from "ua-parser-js";
import axios from "axios";
import { EDIT_CHATS } from "../../../redux/actions/chatActions";
import { startCampaignFromSocket } from "./EmailTemplate";
import { getJweroCustomerPhoneNumber } from "../utils";
import {
  createChatUserPayloadFromCRM,
  getPhoneNumberFromChatUser,
} from "../../chat_new/utils";
import { setConnectedSMSchannels } from "../../../redux/actions/marketingActions";
import { backendEndpoints, createChatUser } from "../../chat_new/apiFunctions";
import { getChatUser } from "../../../helper/api/chat_user";
export let smsChannels = [
  {
    label: "Exotel",
    value: "exotel",
    icon: (
      <img
        src="/static/img/brands/exotelLogo.png"
        width="18px"
        height="18px"
        alt="exotel"
      />
    ),
  },
  { label: "MSG91", value: "msg91", icon: <Msg91Svg /> },
  {
    label: "Gupshup",
    value: "gupshup",
    icon: (
      <img
        src="/static/img/brands/gupshup.png"
        width="18px"
        height="18px"
        alt="gupshup"
      />
    ),
  },
];
function extractValuesMsg91(str) {
  var regex = /##(.*?)##/g;
  var matches = [];
  var match;

  while ((match = regex.exec(str))) {
    matches.push(match[1]);
  }

  return matches;
}
function extractValuesExotel(str) {
  var regex = /##(.*?)##/g;
  var matches = [];
  var match;

  while ((match = regex.exec(str))) {
    matches.push(match[1]);
  }

  return matches;
}
function extractValuesGupshup(str) {
  var regex = /##(.*?)##/g;
  var matches = [];
  var match;

  while ((match = regex.exec(str))) {
    matches.push(match[1]);
  }

  return matches;
}
const SendSms = ({
  open,
  handleClose,
  customer,
  selected,
  heading,
  sendWithCampagin: sendWithCampaign,
  onEditForm,
  returnPayload,
  CRMCustomer,
  mergedPlatforms,
  setMergedPlatforms,
}) => {
  const dispatch = useDispatch();
  const smsIntegration = useSelector((state) => state.settings.smsIntegration);

  const [selectedTemplate, setSelectedTemplate] = useState(false);
  const [sendingSms, setSendingSms] = useState(false);
  const [variableState, setVariableState] = useState({});
  const [templateString, setTemplateString] = useState("");
  const [selectedChannel, setSelectedChannel] = useState("msg91");
  const msg91Integration = useSelector(
    (state) => state?.settings?.msg91Integration
  );
  const gupshupIntegration = useSelector(
    (state) => state.settings.gupshupIntegration
  );
  const connected_sms_services = useSelector(
    (state) => state.settings?.connected_sms_services
  );

  useEffect(() => {
    if (connected_sms_services?.[0]?.name)
      setSelectedChannel(connected_sms_services?.[0]?.name);
  }, [connected_sms_services]);

  useEffect(() => {
    dispatch(setConnectedSMSchannels());
  }, [msg91Integration, gupshupIntegration]);

  const getTemplate = (template) => {
    let variablesArray =
      selectedChannel === "msg91"
        ? extractValuesMsg91(template?.template_data)
        : selectedChannel === "gupshup"
        ? extractValuesGupshup(template?.["template_content"])
        : extractValuesExotel(template?.body);
    let instalVariableObj = {};
    setTemplateString(
      template?.template_data ||
        template?.body ||
        template?.["template_content"]
    );
    console.log(variablesArray);
    for (const key of variablesArray || []) {
      instalVariableObj[key] = "";
      // setTemplateString(pre => pre.replace(/##(\w+)##/g, (match, varName) => {
      //     return <strong>{key}</strong>;
      // }))
    }
    // setTemplateString(template?.template_data);
    setVariableState(instalVariableObj);
    setSelectedTemplate({
      ...template,
      id:
        template?.id ||
        template?.dtl_template_id ||
        template?.["template_id_urn"],
    });
  };

  function replaceVar(varName, varValue) {
    setTemplateString((str) => {
      const regex = new RegExp(varName, "g");
      setTemplateString(str.replace(regex, varValue));
      return str.replace(regex, varValue);
    });
  }

  function replaceVariable({ template, key, value }) {
    return template.replace(`{${key}}`, value);
  }
  function replaceMSG91SMSVariables(vars, str) {
    // Iterate over the keys in the vars object.
    Object.keys(vars).forEach((key) => {
      // Create dynamic regular expressions to find the placeholders.
      const regexOldFormat = new RegExp(`##${key}##`, "g");
      const regexNewFormat = new RegExp(`{{${key}}}`, "g");
      const regexFormat = new RegExp(`{${key}}`, "g");

      // Replace the placeholders with the actual value from the vars object.
      str = str.replace(regexOldFormat, vars[key]);
      str = str.replace(regexNewFormat, vars[key]);
      str = str.replace(regexFormat, vars[key]);
    });
    // Return the modified string.
    return str;
  }

  const onSendMessage = async () => {
    try {
      if (
        !isObjWithValues(customer) ||
        customer?.date_created_gmt ||
        customer?.hasOwnProperty("digt_countrycode")
      ) {
        let newChatPayload = createChatUserPayloadFromCRM({
          crm_user: CRMCustomer,
          platform: "sms",
        });

        let createChatUserRes = await createChatUser({
          payload: newChatPayload,
        });
        await backendApiCall({
          endpoint: `crm/chat_crm_merge`,
          method: "POST",
          data: {
            payload: [
              {
                chat_id: createChatUserRes?.data?.id,
                crm_id: CRMCustomer?.id,
                platform: "sms",
              },
            ],
          },
        });
        // if (
        //   typeof createChatUserRes?.error === "string" &&
        //   createChatUserRes?.error?.includes("already")
        // ) {
        //   let chatUserRes = await backendApiCall({
        //     endpoint: `${backendEndpoints.chats}/${newChatPayload?.platform_id}`,
        //     params: {
        //       platform: "sms",
        //     },
        //   });
        //   console.log(chatUserRes, "<<<<<<<< chatUserRes");
        // }

        let merged = mergedPlatforms || [];
        setMergedPlatforms &&
          setMergedPlatforms(
            merged?.concat([
              { id: createChatUserRes?.data?.id, platform: "sms" },
            ])
          );
        customer = createChatUserRes?.data;
      }
      if (!isObjWithValues(customer)) throw new Error();
      let phone = getJweroCustomerPhoneNumber({ customer: customer });
      if (!phone?.success)
        phone = getPhoneNumberFromChatUser({ user: customer });
      phone = phone?.data;

      if (selectedChannel === "msg91") {
        let varState = { ...variableState };
        let variableObj = getTemplateVariableObjFromCustomer(customer);

        for (const key of Object.keys(variableState || {})) {
          if (variableObj?.[variableState?.[key]]) {
            varState[key] = variableObj?.[variableState?.[key]];
            // varState = { ...varState, [key]: variableObj?.[variableState?.key] }
          }
        }

        setSendingSms(true);

        // await sendSmsMsg91(
        //   {
        //     template_id: selectedTemplate?.template_id,
        //     sender: smsIntegration?.[selectedChannel]?.sender_id,
        //     mobiles: phone,
        //     ...varState,
        //   },
        //   smsIntegration?.[selectedChannel]?.auth_key
        // );
        let message = replaceMSG91SMSVariables(
          varState,
          selectedTemplate?.template_data
        );

        let res = await backendApiCall({
          endpoint: `social/message`,
          method: "POST",
          data: {
            payload: {
              template_id: selectedTemplate?.template_id,
              sender: smsIntegration?.[selectedChannel]?.sender_id,
              recipients: [
                {
                  // "mobiles": "919082297339",
                  // "text": "180758 is your OTP to login with our website - J Mittalal Thanga Maligai.",
                  // "OTP": "180758"
                  // "data": {
                  //     "broadcast_id": 131
                  // }
                  mobiles: phone,
                  text: message,
                  ...varState,
                },
              ],
            },
            platform: "sms",
            service: "msg91",
            meta_data: {
              sent_by: {
                id: getUserId(),
              },
            },
            user_info: customer,
          },
        });
        dispatch(
          setGlobalToast({
            show: true,
            message: `Message initiated!`,
            severity: "success",
          })
        );
        setSendingSms(false);
        handleClose();
      }

      if (selectedChannel === "exotel") {
        setSendingSms(true);
        // data array should be like  [ {To : "91XXXXXX" , Body : "Abc" }]
        let dataArray = [];

        let varState = { ...variableState };
        let variableObj = getTemplateVariableObjFromCustomer(customer);
        let Body = selectedTemplate?.body;
        for (const key of Object.keys(variableState || {})) {
          if (variableObj?.[variableState?.[key]]) {
            varState[key] = variableObj?.[variableState?.[key]];
            Body = replaceVariable({
              template: Body,
              key,
              value: variableState?.[key],
            });
            // varState = { ...varState, [key]: variableObj?.[variableState?.key] }
          }
        }
        //This is a test message powered by Exotel. Report abuse to +918088919888 -Exotel
        // for
        let response = await sendExotelSms({
          ...(smsIntegration?.exotel || {}),
          dataArray: [
            {
              To: phone,
              Body: Body,
            },
          ],
        });
        setSendingSms(false);
        let parser = new DOMParser();

        let errorString = xml2Json(
          parser.parseFromString(response?.error?.data, "text/xml")
        )?.TwilioResponse?.RestException?.Message?.["#text"];

        if (response?.error)
          dispatch(
            setGlobalToast({
              show: true,
              message: `${errorString}`,
              severity: "error",
            })
          );
        else {
          dispatch(
            setGlobalToast({
              show: true,
              message: `Message initiated!`,
              severity: "success",
            })
          );
          setSendingSms(false);
          handleClose();
        }
      }
      if (selectedChannel === "gupshup") {
        setSendingSms(true);

        let varState = { ...variableState };
        let variableObj = getTemplateVariableObjFromCustomer(customer);
        let Body = selectedTemplate?.["template_content"];
        for (const key of Object.keys(variableState || {})) {
          if (variableObj?.[variableState?.[key]]) {
            varState[key] = variableObj?.[variableState?.[key]];
            Body = replaceVariable({
              template: Body,
              key,
              value: variableState?.[key],
            });
            // varState = { ...varState, [key]: variableObj?.[variableState?.key] }
          }
        }
        let message = replaceMSG91SMSVariables(varState, Body);

        let res = await backendApiCall({
          endpoint: `social/message`,
          method: "POST",
          data: {
            payload: {
              send_to: phone,
              msg: message,
            },
            platform: "sms",
            service: "gupshup",
            meta_data: {
              sent_by: {
                id: getUserId(),
              },
            },
            user_info: customer,
          },
        });
        // let res = await sendGupshupSms(
        //   {},
        //   {
        //     msg: selectedTemplate?.["template_content"],
        //     v: "1.1",
        //     userid: "2000210424",
        //     password: "pB9liasyc",
        //     send_to: `${customer?.dialcode_mobile}${customer.billing?.phone}`,
        //     msg_type: "text",
        //     method: "sendMessage",
        //   }
        // );

        dispatch(
          setGlobalToast({
            show: true,
            message: `Message initiated!`,
            severity: "success",
          })
        );
        setSendingSms(false);
        handleClose();
      }
    } catch (error) {
      dispatch(
        setGlobalToast({
          show: true,
          message: `Enable to send message. Please try again!`,
          severity: "error",
        })
      );
    }
  };

  const sendCampaign = async () => {
    let website = getWebsite();
    let token = getToken();

    if (website && token) {
      setSendingSms(true);
      let time = Date.now();
      let id = `${time}_${getRandomString(5)}`;
      let historyObj = {
        id,
        campaign_info: {
          selected_ids: selected?.map((obj) => ({ id: obj.id })) || [],
          history_obj_id: id,
          template_id: selectedTemplate?.template_id,
          template_name: selectedTemplate?.template_name,
          channel: selectedChannel,
          // channel: msg91,
          campaign_type: "sms",
          template: {
            variables: variableState,
          },
        },
        is_campaign: false,
        start_time: time,
        ip: await getIp(),
        ua: new UAParser().getUA(),
      };

      if (!historyObj.name)
        historyObj.name = formatDate(new Date(time), "dd:mm:yyyy hh:mm am/pm");
      let location = await fetchIPInfo(historyObj.ip);
      historyObj.location = location;

      // return;
      let res = await axios({
        url: `${website}/wp-json/store/v1/settings/campaign_history`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "PUT",
        data: {
          setting: "campaign_history",
          data: {
            [id]: historyObj,
          },
        },
      });
      if (res?.data?.success) {
        dispatch({
          type: EDIT_CHATS,
          payload: {
            campaignHistory: res?.data?.data,
          },
        });
        dispatch(
          setGlobalToast({
            show: true,
            severity: "success",
            message: "Bulk SMS Started successfully!",
          })
        );
        setSendingSms(false);
        handleClose();
        startCampaignFromSocket({ id, website });
      }
    }

    setSendingSms(false);
    handleClose();
  };

  const onReturnPayload = () => {
    for (const key of Object.keys(variableState || {})) {
      replaceVar(key, variableState?.[key]);
    }

    onEditForm({
      template_id:
        selectedTemplate?.template_id || selectedTemplate?.template_id_urn,
      template_name: selectedTemplate?.template_name,
      channel: "msg91",
      campaign_type: "sms",
      template: {
        variables: variableState,
      },
      templateString,
      sender_id: smsIntegration?.[selectedChannel]?.sender_id,
      service: selectedChannel,
    });
    handleClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ ...dialogStyle }}
      fullWidth
      maxWidth="md"
    >
      <Stack
        flexDirection="row"
        alignItems="space-between"
        justifyContent="space-between"
      >
        <DialogTitle sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          {isObjWithValues(selectedTemplate) && (
            <IconButton onClick={() => setSelectedTemplate(true)}>
              <ArrowBack />
            </IconButton>
          )}
          {heading || "Send SMS"}
        </DialogTitle>
        <TextField
          select
          sx={{
            minWidth: "150px",
            ".MuiSelect-select": {
              display: "flex",
              alignItems: "center",
              gap: 1,
            },
          }}
          style={{ marginRight: 12 }}
          size="small"
          value={selectedChannel}
          onChange={(e) => setSelectedChannel(e.target.value)}
        >
          {smsChannels
            ?.filter((i) =>
              connected_sms_services?.find((j) => j?.name === i?.value)
            )
            ?.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{ display: "flex", alignItems: "center", gap: 3 }}
              >
                {option?.icon} {option.label}
              </MenuItem>
            ))}
        </TextField>
      </Stack>
      <DialogContent>
        {isObjWithValues(selectedTemplate) ? (
          <Box>
            <Grid container spacing={15}>
              <Grid item xs={12} sm={6} md={6}>
                <Grid container spacing={4}>
                  {Object.keys(variableState)?.map((val) => (
                    <Grid item xs={12} sm={6} md={6} key={val}>
                      <Label>{val}</Label>
                      <Autocomplete
                        freeSolo
                        size="small"
                        // value={headerVariable}
                        // onChange={(e, a) => onheadingchange(e, a)}
                        inputValue={variableState[val] || ""}
                        // onInputChange={(e, a) => onheadingchange(e, a)}
                        onInputChange={(e, value) => {
                          setVariableState((pre) => ({
                            ...pre,
                            [val]: value,
                          }));
                          // replaceVar(
                          //     variableState[val]
                          //         ? variableState[val]
                          //         : `##${val}##`,
                          //     value
                          // );
                        }}
                        options={variables.map((option) => option.label)}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label="freeSolo"

                            // placeholder="Enter content for {{1}}"
                            // error={headerError}
                          />
                        )}
                      />
                      {/* <TextField
                                                size="small"
                                                value={variableState[val] || ""}
                                                onChange={(e) => {
                                                    setVariableState((pre) => ({
                                                        ...pre,
                                                        [val]: e.target.value,
                                                    }));
                                                    replaceVar(
                                                        variableState[val]
                                                            ? variableState[val]
                                                            : `##${val}##`,
                                                        e.target.value
                                                    );
                                                }}
                                                fullWidth
                                            /> */}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{ position: "relative", overflow: "hidden" }}
              >
                <PhoneSvg height="60vh" />
                <Card
                  sx={{
                    position: "absolute",
                    top: "60%",
                    width: "76%",
                    right: "25px",
                    padding: "10px",
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
                    borderRadius: "18px",
                  }}
                >
                  <Stack direction={"row"} justifyContent="space-between">
                    {/* <Typography>{formData.title}</Typography> */}
                    <Typography variant="h6">
                      JW-
                      {smsIntegration?.[selectedChannel]?.sender_id}
                    </Typography>
                    <Typography
                      textAlign={"end"}
                      sx={{ justifyItems: "flex-end" }}
                    >
                      now
                    </Typography>
                  </Stack>
                  <Stack gap={1}>
                    <Typography>{templateString}</Typography>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <SmsTemplateList
            onSelectTemplate={getTemplate}
            selectedChannel={selectedChannel}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        {isObjWithValues(selectedTemplate) &&
          (returnPayload ? (
            <Button variant="contained" onClick={onReturnPayload}>
              Select
            </Button>
          ) : (
            <LoadingButton
              loading={sendingSms}
              variant="contained"
              onClick={() =>
                sendWithCampaign ? sendCampaign() : onSendMessage()
              }
              autoFocus
            >
              Send
            </LoadingButton>
          ))}
      </DialogActions>
    </Dialog>
  );
};

export default SendSms;
