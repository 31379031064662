/* eslint-disable react-hooks/exhaustive-deps */
import {
  CallMade,
  CallReceived,
  PhoneCallbackOutlined,
  PhoneMissedOutlined,
  Sync,
} from "@mui/icons-material";

import {
  Box,
  CircularProgress,
  IconButton,
  Paper,
  Popover,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  EmptyState,
  formatCallDuration,
  formatDate,
  formatServerValue,
  isArray,
  isArrayWithValues,
  isObjWithValues,
  settingsEndpoint,
  validateNumber,
} from "../../../helper";
import { EDIT_CHATS } from "../../../redux/actions/chatActions";
import { CallEmptyState } from "../../../Svgs";
import CustomChip from "../../components/CustomChip";

const Calls = ({ customerInfo, logsArray, fetchingLogsArray }) => {
  const [fetchingHistory, setFetchingHistory] = useState(false);
  const crmCallsHistory = useSelector((state) => state?.chats?.crmCallsHistory);
  const reduxTeamMembers = useSelector((state) => state.settings.teamMembers);
  const admin = useSelector((state) => state.user?.userAdminData);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const loginUser = useSelector((state) => state.user);
  const [logs, setLogs] = useState([]);
  const [callers, setCallers] = useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selectRecording, setSelectedRecording] = useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    let callerObj = {};
    for (const member of reduxTeamMembers || []) {
      callerObj[`0${member.phone}`] = `${member?.first_name || ""} ${
        member?.last_name || ""
      }`;
    }
    setCallers({
      ...callerObj,
      [`0${admin?.mobile_no}`]: `${admin?.first_name || ""} ${
        admin?.last_name || ""
      }`,
    });
  }, [admin, reduxTeamMembers]);

  useEffect(() => {
    if (isArrayWithValues(crmCallsHistory?.[customerInfo?.id]))
      setLogs(crmCallsHistory?.[customerInfo?.id] || []);
    else setLogs([]);
    return () => {
      setLogs([]);
    };
  }, [crmCallsHistory, customerInfo?.id]);
  useEffect(() => {
    if (isArrayWithValues(logsArray)) setLogs(logsArray);

    return () => {
      // setLogs([])
    };
  }, [logsArray]);

  useEffect(() => {
    setFetchingHistory(fetchingLogsArray);

    return () => {
      // setLogs([])
    };
  }, [fetchingLogsArray]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getHistory = async (id) => {
    setFetchingHistory(true);
    let res = await settingsEndpoint({
      endpoint: `call_history_${id}`,
      method: "GET",
    });
    if (isObjWithValues(res)) {
      // let res = Object.values(res)
      dispatch({
        type: EDIT_CHATS,
        payload: {
          crmCallsHistory: {
            ...(crmCallsHistory || {}),
            [id]: Object.values(res)?.sort(
              (a, b) =>
                validateNumber(b.start_time) - validateNumber(a.start_time)
            ),
          },
        },
      });
    }
    // setLogs(Object.values(res));

    setFetchingHistory(false);
  };
  useEffect(() => {
    console.log(
      customerInfo?.id,
      crmCallsHistory?.[customerInfo?.id],
      !isArrayWithValues(crmCallsHistory?.[customerInfo?.id])
    );
    if (
      !isArrayWithValues(crmCallsHistory?.[customerInfo?.id]) &&
      customerInfo?.id
    ) {
      getHistory(customerInfo?.id);
      // let res = await getHistory();
      // if (isArrayWithValues(res)) {
      //     dispatch({
      //         type: EDIT_CHATS,
      //         payload: {
      //             crmCallsHistory: { ...(crmCallsHistory || {}), [customerInfo?.id]: res }
      //         }
      //     })
      // }
    }

    return () => {};
  }, [customerInfo?.id]);

  const handleClick = (event, url) => {
    setAnchorEl(event.currentTarget);
    setSelectedRecording(url);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRecording("");
  };
  // if (!isAdmin)
  //     return (
  //         <Stack
  //             sx={{ height: "60vh" }}
  //             justifyContent={"center"}
  //             alignItems={"center"}
  //             gap={2}
  //             rowGap={3}
  //         >
  //             <EmptyState
  //                 text={"Not Access to team Member!"}
  //                 icon={<Call style={{ color: "primary.main" }} />}
  //             />
  //         </Stack>
  //     );

  return (
    <Box px={3}>
      {logsArray ? null : (
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="h5">History</Typography>
          <IconButton
            disabled={fetchingHistory}
            onClick={() => {
              getHistory();
            }}
          >
            <Sync />
          </IconButton>
          {/* <LoadingButton
                        loading={fetchingHistory}
                        onClick={() => {
                            getHistory();
                        }}
                        variant="outlined"
                        startIcon={<Sync />}
                    >
                        Refresh
                    </LoadingButton> */}
        </Stack>
      )}
      {fetchingHistory ? (
        <Stack
          sx={{ height: "60vh" }}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
          rowGap={3}
        >
          <CircularProgress />
          <Typography variant="h6">Getting logs...</Typography>
        </Stack>
      ) : null}
      {!fetchingHistory && !isArrayWithValues(logs) ? (
        <Stack
          sx={{ height: "60vh" }}
          justifyContent={"center"}
          alignItems={"center"}
          gap={2}
          rowGap={3}
        >
          <EmptyState text={"No Call History yet!"} icon={<CallEmptyState />} />
        </Stack>
      ) : null}
      <RecordingPopper
        anchorEl={anchorEl}
        id={id}
        open={open}
        handleClose={handleClose}
        selectRecording={selectRecording}
      />
      {!fetchingHistory && isArrayWithValues(logs) ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Status</TableCell>
                  {logsArray && <TableCell>Customer</TableCell>}
                  <TableCell>Agent</TableCell>

                  {/* <TableCell>Line</TableCell>
                                    {logsArray && <TableCell>To</TableCell>} */}
                  <TableCell>Duration</TableCell>
                  <TableCell>Recording</TableCell>
                  <TableCell align="right">Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logs
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  ?.map((row, index) => {
                    if (!isAdmin) {
                      if (
                        row?.to?.includes(loginUser?.phone) ||
                        row?.from?.includes(loginUser?.phone)
                      )
                        return (
                          <Row
                            key={index}
                            row={row}
                            handleClick={handleClick}
                            showCustomer={isArray(logsArray)}
                            callers={callers}
                          />
                        );
                      else return <></>;
                    }
                    return (
                      <Row
                        key={index}
                        row={row}
                        handleClick={handleClick}
                        showCustomer={isArray(logsArray)}
                        callers={callers}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={logs.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : null}
    </Box>
  );
};

export default Calls;

const Row = ({ row, handleClick, showCustomer, callers }) => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const historyArray =
    row?.meta_data?.attempts?.filter((ob) => ob?.Number) || null;
  return (
    <TableRow
      key={row.name}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        <Stack alignItems={"center"} justifyContent={"center"}>
          {row.direction === "outgoing" ? (
            <CallMade color="success" />
          ) : row.direction === "incoming" ? (
            <CallReceived color="success" />
          ) : null}
          <Typography color="text.secondary" variant="h6">
            {formatServerValue(row.direction)}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <CustomChip
          status={formatServerValue(
            row.status === "free" ? "Call Attempt" : row.status
          )}
          type={row.status}
        />

        {/* {formatServerValue(row.status)} */}
      </TableCell>
      {showCustomer ? (
        <TableCell>
          <Typography variant="subtitle2">
            {/* {row.direction === "outgoing" ? <>{callers?.[row?.from]}</> : showTo && <> {row?.first_name || ""} {row?.last_name || ""}</>} */}
            {row?.first_name || ""} {row?.last_name || ""}
          </Typography>
          <Typography>
            {row.direction === "outgoing" ? row.to : row?.from}
          </Typography>
        </TableCell>
      ) : null}
      <TableCell>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <Stack>
            <Typography variant="subtitle2">
              {/* {row.direction === "outgoing" ? <>{callers?.[row?.from]}</> : showTo && <> {row?.first_name || ""} {row?.last_name || ""}</>} */}

              {/* {row?.first_name || ""} {row?.last_name || ""} */}
              {row.direction === "outgoing" ? (
                <>{callers?.[row?.from]}</>
              ) : (
                <>{callers?.[row?.to]}</>
              )}
            </Typography>
            <Typography>
              {row.direction === "outgoing" ? row.from : row?.to}
            </Typography>
            {isArrayWithValues(historyArray) && historyArray?.length > 1 && (
              <Tooltip
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                title={
                  <Stack>
                    {historyArray?.map((obj) => {
                      return (
                        <Typography
                          sx={{ display: "flex", alignItems: "center", gap: 3 }}
                        >
                          {validateNumber(obj?.OnCallDuration) ? (
                            <PhoneCallbackOutlined
                              fontSize="small"
                              color="success"
                              style={{ fontSize: "14px", color: "#67cc6c" }}
                            />
                          ) : (
                            <PhoneMissedOutlined
                              fontSize="small"
                              color="error"
                              style={{ fontSize: "14px" }}
                            />
                          )}

                          {callers?.[obj?.Number]}
                        </Typography>
                      );
                    })}
                  </Stack>
                }
              >
                <Box sx={{ cursor: "pointer" }}>
                  <Typography sx={{ color: "primary.main" }}>
                    History
                  </Typography>
                </Box>
                {/* <IconButton onClick={onToggle}>
                                <BallotOutlined />
                            </IconButton> */}
              </Tooltip>
            )}
          </Stack>
        </Stack>
      </TableCell>

      <TableCell>{formatCallDuration(row.conversation_duration)}</TableCell>
      <TableCell>
        {row?.recording_url ? (
          <audio controls>
            <source src={row?.recording_url} type="audio/ogg" />
            {/* <source src="horse.mp3" type="audio/mpeg"> */}
            Your browser does not support the audio element.
          </audio>
        ) : null}
        {/* {row?.recording_url ? <IconButton onClick={(e) => handleClick(e, row?.recording_url)}>
                    <PlayCircleOutlineOutlined />
                </IconButton> : null} */}
      </TableCell>
      <TableCell align="right">
        <Typography variant="subtitle2">
          {formatDate(new Date(row.start_time), "dd/mm/yyyy")}
        </Typography>
        {formatDate(new Date(row.start_time), "hh:mm am/pm")}
      </TableCell>
    </TableRow>
  );
};

const RecordingPopper = ({
  id,
  open,
  anchorEl,
  handleClose,
  selectRecording,
}) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <audio controls>
        <source src={selectRecording} type="audio/ogg" />
        {/* <source src="horse.mp3" type="audio/mpeg"> */}
        Your browser does not support the audio element.
      </audio>
    </Popover>
  );
};
