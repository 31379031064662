import {
  Close,
  LocalOffer,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRandomDarkColor,
  getStoreId,
  getToken,
  getWebsite,
  isObjWithValues,
} from "../../../../helper";
import axios from "axios";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { useTheme } from "styled-components";
import Switch from "rc-switch";
import { isArrayWithValues } from "jwero-javascript-utils";

function RunoWebhooks() {
  const theme = useTheme();
  const dispatch = useDispatch();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  const [formData, setFormData] = useState({
    auto_assign_tags: [],
  });
  const [runoWebhookEndpoint, setRunoWebhookEndpoint] = useState("");
  const [loading, setLoading] = useState(false);
  const [openTagSelection, setOpenTagSelection] = useState(false);

  const runoIntegration = useSelector(
    (state) => state.settings.runoIntegration
  );
  const globalToast = useSelector((state) => state.helper.globalToast);
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const allReduxCustomerGroup = useSelector(
    (state) => state.customers.crmGrouping
  );

  useEffect(() => {
    const setupLink = () => {
      let storeId = getStoreId();
      if (storeId) {
        let link = `https://websockets.tanika.tech/runo/sync/call/${storeId}`;
        setRunoWebhookEndpoint(link);
      }
    };

    setupLink();
  }, []);

  useEffect(() => {
    if (
      isObjWithValues(runoIntegration) &&
      isArrayWithValues(runoIntegration?.auto_assign_tags)
    )
      setFormData({ auto_assign_tags: runoIntegration?.auto_assign_tags });
  }, [runoIntegration]);

  const onUpdateForm = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setLoading(true);
    let res = await axios({
      url: `${website}/wp-json/store/v1/settings/runo_integration`,
      method: "PUT",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        setting: "runo_integration",
        data: formData,
      },
    });
    if (res.data.success) {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "Runo setting saved successfully",
          severity: "success",
        })
      );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          runoIntegration: res.data.data,
        },
      });
    } else {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "RunoWebhooks Failed! Please try again.",
          severity: "error",
        })
      );
    }
    setLoading(false);
  };

  const onChangeSelectedTags = (e, group_name) => {
    console.log(group_name, e?.target?.checked);
    if (e?.target?.checked) {
      if (formData?.auto_assign_tags?.includes(group_name)) return;
      setFormData((prev) => ({
        ...prev,
        auto_assign_tags: [...prev?.auto_assign_tags, group_name],
      }));
    } else {
      setFormData({
        auto_assign_tags: formData?.auto_assign_tags?.filter(
          (i) => i !== group_name
        ),
      });
    }
  };

  return (
    <Box sx={{ paddingLeft: "12px", paddingBottom: "25px" }}>
      <Typography variant="subtitle1" sx={{ fontSize: "15px" }}>
        To automatically sync customers and call logs from Runo into Jwero,
        please copy and paste the link below into the Webhook Setup section of
        your Runo dashboard.
      </Typography>
      <Typography variant="subtitle2" sx={{ fontSize: "15px" }}>
        {runoWebhookEndpoint || "Sorry, couldn't generate the link"}
      </Typography>
      {runoWebhookEndpoint ? (
        <Stack sx={{ marginTop: 8 }}>
          <Typography variant="subtitle1" sx={{ fontSize: "15px" }}>
            Attach tags to Runo customers
          </Typography>
          <Stack
            direction={"row"}
            alignItems="center"
            sx={{ gap: "14px", mb: 2 }}
            justifyContent="space-between"
          >
            <Stack direction={"row"} alignItems="center" spacing={2}>
              {isArrayWithValues(formData?.auto_assign_tags) &&
                formData?.auto_assign_tags?.map((i) => {
                  return (
                    <Chip
                      size="small"
                      // style={{ backgroundColor: hexToRgba(i.color?.replaceAll("#", ""), 0.4), color: i.color }}
                      icon={
                        <LocalOffer
                          style={{
                            height: "11px",
                            width: "11px",
                            color: i?.color,
                          }}
                        />
                      }
                      label={
                        allReduxCustomerGroup?.find(
                          (item) => item?.group_name === i
                        )?.group_name
                      }
                      sx={{ fontSize: "12px" }}
                    />
                  );
                })}
              <Button
                startIcon={
                  <LocalOffer style={{ height: "11px", width: "11px" }} />
                }
                size="small"
                sx={{ p: 0, whiteSpace: "nowrap" }}
                onClick={() => setOpenTagSelection(true)}
              >
                Select Tags
              </Button>
            </Stack>
            <LoadingButton
              loading={loading}
              variant={"contained"}
              onClick={onUpdateForm}
              sx={{ mr: 4 }}
            >
              Save
            </LoadingButton>
          </Stack>
        </Stack>
      ) : null}
      <Dialog
        maxWidth="xs"
        fullWidth
        open={openTagSelection}
        onClose={() => setOpenTagSelection(false)}
        // {...globalPopupStyle}
      >
        <CustomerLabelView
          id={openTagSelection}
          onClose={() => setOpenTagSelection(false)}
          multiple={true}
          selectedTags={formData?.auto_assign_tags}
          setSelectedTags={onChangeSelectedTags}
        />
      </Dialog>
    </Box>
  );
}

export default RunoWebhooks;

const CustomerLabelView = ({
  id,
  onClose,
  setCustomer,
  broadcast,
  customer,
  chatDetail,
  label,
  whatsappCustomerArray,
  multiple,
  hideDelete,
  selectedTags = [],
  setSelectedTags,
}) => {
  const [allCustomerGroup, setAllCustomerGroup] = useState([]);
  const allReduxCustomerGroup = useSelector(
    (state) => state.customers.crmGrouping
  );

  useEffect(() => {
    if (isArrayWithValues(allReduxCustomerGroup))
      setAllCustomerGroup(allReduxCustomerGroup);
  }, [allReduxCustomerGroup]);

  console.log({ selectedTags });

  return (
    <React.Fragment>
      <>
        <Stack
          direction={"row"}
          alignItems="center"
          justifyContent={"space-between"}
          pr={3}
        >
          <>
            {" "}
            <DialogTitle>Select</DialogTitle>
            <IconButton onClick={() => onClose && onClose()}>
              <Close />
            </IconButton>
          </>
        </Stack>
        <DialogContent>
          {isArrayWithValues(allCustomerGroup) &&
            allCustomerGroup.map((i) => {
              return (
                <Stack
                  direction="row"
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Stack
                    direction={"row"}
                    alignItem={"center"}
                    sx={{
                      px: 3,
                      py: 2,
                      "&:hover": {
                        backgroundColor: "rgba(0,0,0,0.02)",
                      },
                      cursor: "pointer",
                    }}
                  >
                    {multiple && (
                      <Checkbox
                        checked={selectedTags?.includes(i?.group_name)}
                        onChange={(e) => setSelectedTags(e, i?.group_name)}
                      />
                    )}
                    <LocalOffer
                      style={{
                        height: "16px",
                        width: "16px",
                        color: i?.color,
                      }}
                    />
                    <Typography variant="h6" ml={3}>
                      {i?.group_name}
                    </Typography>
                  </Stack>
                </Stack>
              );
            })}
        </DialogContent>
      </>
    </React.Fragment>
  );
};
