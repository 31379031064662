import { isArrayWithValues } from "jwero-javascript-utils";
import { seperateCountryCode } from "../../helper";

const getCRMPhoneNumber = ({ customer, options = {} }) => {
  let { phone_priority } = options;
  let { meta_data, billing } = customer;
  let obj = {
    phone: billing ? billing.phone : "",
  };
  let response = { success: false };
  if (isArrayWithValues(meta_data))
    meta_data.map((i) => {
      if (
        [
          "whatsapp",
          "dialcode_whatsapp",
          "dialcode_mobile",
          "digits_phone_no",
          "digt_countrycode",
        ].includes(i.key) &&
        i.value
      )
        obj[i.key] = i.value;
    });
  if (obj.whatsapp && obj.dialcode_whatsapp && !phone_priority) {
    response = {
      success: true,
      data: `${obj.dialcode_whatsapp || ""}${obj.whatsapp || ""}`
        .replace(/\s/g, "")
        .trim(),
      dialcode: obj.dialcode_whatsapp?.replace(/\s/g, "").trim(),
      phone: obj.whatsapp?.replace(/\s/g, "").trim(),
    };
  } else if (obj.mobile && obj.dialcode_mobile) {
    response = {
      success: true,
      data: `${obj.dialcode_mobile || ""}${obj.mobile || ""}`
        .replace(/\s/g, "")
        .trim(),
      dialcode: obj.dialcode_mobile?.replace(/\s/g, "").trim(),
      phone: obj.mobile?.replace(/\s/g, "").trim(),
    };
  } else if (obj.digits_phone_no && obj.digt_countrycode) {
    response = {
      success: true,
      data: `${obj.digt_countrycode || ""}${obj.digits_phone_no || ""}`
        .replace(/\s/g, "")
        .trim(),
      dialcode: obj.digt_countrycode?.replace(/\s/g, "").trim(),
      phone: obj.digits_phone_no?.replace(/\s/g, "").trim(),
    };
  } else if (obj.phone?.includes("+")) {
    let phoneExtractRes = seperateCountryCode(obj.phone);
    if (phoneExtractRes?.dial_code && phoneExtractRes?.phone_number)
      response = {
        success: true,
        data: `${phoneExtractRes?.dial_code || ""}${
          phoneExtractRes?.phone_number || ""
        }`
          .replace(/\s/g, "")
          .trim(),
        dialcode: phoneExtractRes?.dial_code?.replace(/\s/g, "").trim(),
        phone: phoneExtractRes?.phone_number?.replace(/\s/g, "").trim(),
      };
  } else if (obj.phone && obj.dialcode_mobile) {
    response = {
      success: true,
      data: `${obj.dialcode_mobile || ""}${obj.phone || ""}`
        .replace(/\s/g, "")
        .trim(),
      dialcode: obj.dialcode_mobile?.replace(/\s/g, "").trim(),
      phone: obj.phone?.replace(/\s/g, "").trim(),
    };
  } else if (obj.whatsapp && obj.dialcode_whatsapp && phone_priority) {
    response = {
      success: true,
      data: `${obj.dialcode_whatsapp || ""}${obj.whatsapp || ""}`
        .replace(/\s/g, "")
        .trim(),
      dialcode: obj.dialcode_whatsapp?.replace(/\s/g, "").trim(),
      phone: obj.whatsapp?.replace(/\s/g, "").trim(),
    };
  } else response = { success: false };

  return response;
};

const ALL_CUSTOMER_PREDEFINED_ATTRIBUTES = [
  { label: "First Name", value: "first_name", type: "customer" },
  { label: "Last Name", value: "last_name", type: "customer" },
  { label: "Full Name", value: "full_name", type: "customer" },
  { label: "Email", value: "email", type: "customer" },
  { label: "Phone", value: "phone", type: "customer" },
  { label: "Gender", value: "gender", type: "customer" },
  { label: "User Birthday", value: "user_birthday", type: "customer" },
  { label: "User Anniversary", value: "user_anniversary", type: "customer" },
  { label: "Contact Type", value: "contact_type", type: "customer" },
  { label: "Opt-In", value: "opt-in", type: "customer" },
  { label: "City", value: "city", type: "customer" },
  { label: "State", value: "state", type: "customer" },
  { label: "Country", value: "country", type: "customer" },
  { label: "Language", value: "language", type: "customer" },
  { label: "IP", value: "ip", type: "customer" },
  { label: "Channel", value: "channel", type: "customer" },
  { label: "Referrer", value: "referrer", type: "customer" },
  { label: "Brand Name", value: "brand_name", type: "customer" },
  { label: "Brand Address", value: "brand_address", type: "customer" },
  { label: "Order Id", value: "order_id", type: "order" },
  { label: "Product Name", value: "product_name", type: "order" },
  // { label: "Order Amount", value: "order_amount", type: "order" },
  { label: "Amount", value: "amount", type: "order" },
  { label: "Payment Method", value: "payment_method", type: "order" },
  { label: "Transaction Id", value: "transaction_id", type: "order" },
  {
    label: "Order Tracking Number",
    value: "order_tracking_number",
    type: "order",
  },
  {
    label: "Shipping partner name",
    value: "shipping_partner_name",
    type: "order",
  },
  {
    label: "Digigold Plan Name",
    value: "digigold_plan_name",
    type: "digital_gold",
  },
  {
    label: "Digigold Payment Amount",
    value: "digigold_payment_amount",
    type: "digital_gold",
  },
  {
    label: "Digigold Plan Amount",
    value: "digigold_plan_amount",
    type: "digital_gold",
  },
  {
    label: "Digigold Payment Month",
    value: "digigold_payment_month",
    type: "digital_gold",
  },
  {
    label: "Digigold Next Payment Date",
    value: "digigold_next_payment_date",
    type: "digital_gold",
  },
  {
    label: "Digigold Due days remaining",
    value: "digigold_days_remaining_for_due",
    type: "digital_gold",
  },
  {
    label: "Digigold total amount remaining",
    value: "digigold_total_amount_remaining",
    type: "digital_gold",
  },
];
export { getCRMPhoneNumber, ALL_CUSTOMER_PREDEFINED_ATTRIBUTES };
