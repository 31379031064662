/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  Grid,
  LinearProgress,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import {
  backendApiCall,
  formatDate,
  getComunication,
  getIsBackendMigrated,
  getSkuFromFbId,
  isArrayWithValues,
  isDev,
  isEmailIsIntegrated,
  isObjWithValues,
  StyledTableRow,
  TabPanel,
  validateNumber,

  // website,
} from "../../../helper";

import { useNavigate, useParams } from "react-router-dom";

import {
  ChatsPrimary,
  InstagramSvg,
  Messenger,
  OneSignalSvg,
  Whatsapp,
} from "../../../Svgs";
import Chat from "../../chat/components/ChatWindow";
import NewChat from "../../chat_new/components/ChatWindow";
import { useDispatch, useSelector } from "react-redux";
import {
  addChatInRedux,
  getConversations,
} from "../../../redux/actions/chatActions";
import {
  ArrowBackIosNew,
  Call,
  EmailOutlined,
  Inventory,
  North,
  NotificationsActiveOutlined,
  ProductionQuantityLimits,
  SmsOutlined,
  South,
} from "@mui/icons-material";

import { ChannelTotalGraph } from "../../chat";
import { currencySymbols } from "../../pricing/Gold";
import { CardContent } from "@material-ui/core";
import { NoTasks, secondaryLabel } from "../../chat/ChatHelper";
import ChatProducts from "../../chat/components/ChatProducts";
import Calls from "./Calls";
import Emails from "./Emails";
import SmsHistory from "./SmsHistory";
import PushNotification from "./PushNotification";
import { getChatsUserConversation } from "../../../helper/api/chat_user";
import { getCRMPhoneNumber } from "../../../utils/crm";
import { fetchMergedPlatforms } from "../helpers";
const sectionLabel = {
  fontSize: "20px",
  fontWeight: "800",
};

export const channels = [
  {
    label: "Whatsapp",
    value: "whatsapp",
    icon: <Whatsapp />,
  },
  {
    label: "Facebook",
    value: "facebook",
    icon: <Messenger />,
  },
  {
    label: "Instagram",
    value: "instagram",
    icon: <InstagramSvg />,
  },
  {
    label: "SMS",
    value: "sms",
    icon: <SmsOutlined />,
  },
];
const metaTabs = channels?.map((o) => o?.value);

const Communication = ({ customerInfo }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState("whatsapp");

  const [gettingComunication, setGettingComunication] = useState(false);
  const [chatCustomer, setChatCustomer] = useState({});
  const [showAnalytics, setshowAnalytics] = useState(false);

  const [navArray, setNavArray] = useState(channels);
  const [mergedPlatforms, setMergedPlatforms] = useState([]);

  const dispatch = useDispatch();
  const allReduxConversations = useSelector(
    (state) => state.chats.conversations
  );
  const smtpMail = useSelector((state) => state.settings.smtpMail);
  const exotelNumbers = useSelector((state) => state.chats.exotelNumbers);
  const runoIntegration = useSelector(
    (state) => state.settings.runoIntegration
  );
  const smsIntegration = useSelector((state) => state.settings.smsIntegration);
  let facebookObj = customerInfo.meta_data.find((i) => i.key === "facebook_id");

  const reduxOnesignalIntegration = useSelector(
    (state) => state.settings.onesignalIntegration
  );

  // let fetchMergedPlatforms = async ({ id }) => {
  //   let mergedPlatforms = [];
  //   let phoneInfo = getCRMPhoneNumber({ customer: customerInfo });
  //   if (id) {
  //     let response = await backendApiCall({
  //       endpoint: `crm/merge_platforms/${id}`,
  //       method: "POST",
  //       data: {
  //         whatsapp: {
  //           dialcode_whatsapp: phoneInfo?.dialcode,
  //           whatsapp: phoneInfo?.phone,
  //         },
  //       },
  //     });
  //     if (response?.success) {
  //       mergedPlatforms = response?.data;
  //     }
  //   }
  //   setMergedPlatforms(mergedPlatforms);
  // };

  let setupMerge = async () => {
    let mergedPlatforms = await fetchMergedPlatforms({
      id: customerInfo?.id,
      customer: customerInfo,
    });
    setMergedPlatforms(mergedPlatforms);
  };
  useEffect(() => {
    setupMerge();
  }, [customerInfo?.id]);

  // const reduxMetaIntegrations = useSelector(
  //   (state) => state.settings.metaIntegration
  // );
  // const reduxFetchingAllSettings = useSelector(
  //   (state) => state.settings.fetchingAllSettings
  // );
  // const [arr, setArr] = useState([])
  useEffect(() => {
    navigate(`/crm/${id}/#Communication`);
    //es
  }, [id]);
  // const isOpted = customerInfo.whatsapp_chat
  //   ? customerInfo.whatsapp_chat.find((chat) => chat.sender)
  //   : null;

  const fetchSinglePlatform = async ({ channel }) => {
    let chatCustomer = {};
    try {
      let obj = mergedPlatforms?.find((i) => i?.platform === channel);
      console.log(obj, mergedPlatforms, "<<<<<< obj");
      if (obj) {
        let chat = allReduxConversations?.find(
          (o) => o?.["id"] + "" === obj?.id + ""
        );
        console.log(chat, "<<<<< chat");
        if (chat) {
          chatCustomer = chat;
        } else {
          let userRes = await backendApiCall({
            endpoint: `chat/users/${obj?.id}`,
          });

          if (userRes?.success) {
            chatCustomer = { ...userRes?.data, platform: obj.platform };
            // let conversationsRes = await getChatsUserConversation({ id });
            // console.log(conversationsRes, "<<<<<<<<<<<< conversationsRes");
          }
        }
      }
    } catch (error) {
      console.log(error, "<<<<<<<<<< error");
    }

    setChatCustomer(chatCustomer);
    dispatch(addChatInRedux(chatCustomer));
  };

  useEffect(() => {
    fetchSinglePlatform({ channel: selectedTab });
    console.log("run use Effect");
    if (!isArrayWithValues(allReduxConversations)) return;
    // console.log("run use Effect , allReduxConversations");
    // console.log(allReduxConversations);
    // if (selectedTab) {
    //   // let metaobj = customerInfo?.meta_data.find(
    //   //   (o) => o.key === `${selectedTab}_id`
    //   // );
    //   // let compareKey = getIsBackendMigrated("chats") ? "platform_id" : "id";
    //   // let chat = allReduxConversations?.find(
    //   //   (o) => o?.[compareKey] === metaobj?.value
    //   // );
    //   // let metaobj = customerInfo?.meta_data.find(
    //   //   (o) => o.key === `${selectedTab}_id`
    //   // );
    //   // let compareKey = getIsBackendMigrated("chats") ? "platform_id" : "id";
    //   let chat = allReduxConversations?.find(
    //     (o) => o?.["id"] + "" === chatCustomer?.id + ""
    //   );
    //   setChatCustomer(chat);
    // }
  }, [allReduxConversations, customerInfo?.id, selectedTab, mergedPlatforms]);

  // useEffect(async () => {
  //   if (!isObjWithValues(customerInfo)) return;
  //   console.log("customerInfo");
  //   if (getIsBackendMigrated("chats")) {
  //     let platforms = ["facebook_id", "whatsapp_id", "instagram_id"];
  //     console.log(customerInfo.meta_data, "<customerInfo.meta_data");
  //     let platformsArray = customerInfo.meta_data?.filter((obj) =>
  //       platforms?.includes(obj?.key)
  //     );
  //     console.log(platformsArray);
  //     dispatch(
  //       getConversations({
  //         platform_id: platformsArray?.map((obj) => obj?.value)?.join(","),
  //       })
  //     );
  //     return;
  //   } else {
  //     let facebookObj = customerInfo.meta_data.find(
  //       (i) => i.key === "facebook_id"
  //     );
  //     let whatsappObj = customerInfo.meta_data.find(
  //       (i) => i.key === "whatsapp_id"
  //     );
  //     let instagramObj = customerInfo.meta_data.find(
  //       (i) => i.key === "instagram_id"
  //     );

  //     if (facebookObj) {
  //       let arr = allReduxConversations || [];
  //       let communicationObj = arr?.find((o) => o.id === facebookObj.value);
  //       if (communicationObj) {
  //         //set chat
  //       } else {
  //         // console.log(facebook.value);
  //         setGettingComunication(true);
  //         let resObj = await getComunication(facebookObj.value, "facebook");

  //         if (resObj.success)
  //           dispatch(addChatInRedux({ ...resObj.data, platform: "facebook" }));

  //         setGettingComunication(false);
  //       }
  //     }
  //     if (whatsappObj) {
  //       setGettingComunication(true);
  //       let arr = allReduxConversations || [];
  //       let communicationObj = arr?.find((o) => o.id === whatsappObj.value);
  //       if (communicationObj) {
  //         //set chat
  //       } else {
  //         console.log(whatsappObj.value);
  //         let resObj = await getComunication(whatsappObj.value, "whatsapp");
  //         if (resObj.success)
  //           dispatch(addChatInRedux({ ...resObj.data, platform: "whatsapp" }));
  //       }
  //       setGettingComunication(false);
  //     }
  //     if (instagramObj) {
  //       setGettingComunication(true);
  //       let arr = allReduxConversations || [];
  //       let communicationObj = arr?.find((o) => o.id === instagramObj.value);
  //       if (communicationObj) {
  //         //set chat
  //       } else {
  //         console.log(instagramObj.value);
  //         let resObj = await getComunication(instagramObj.value, "instagram");
  //         if (resObj.success)
  //           dispatch(addChatInRedux({ ...resObj.data, platform: "instagram" }));
  //       }
  //       setGettingComunication(false);
  //     }
  //   }
  // }, [customerInfo]);

  // useEffect(() => {
  //   if (isObjWithValues(reduxMetaIntegrations))
  //     setMetaIntegration(reduxMetaIntegrations);
  // }, [reduxMetaIntegrations]);
  // useEffect(() => {
  //   if (!isObjWithValues(reduxMetaIntegrations)) return;
  //   let apps = [];
  //   for (const key in metaIntegration) {
  //     let connectedApps = metaIntegration[key].connected;
  //     apps = [...apps, ...connectedApps];
  //   }
  //   setIntegratedApps(apps);
  // }, [metaIntegration]);

  useEffect(() => {
    if (exotelNumbers || runoIntegration?.enabled)
      setNavArray([
        ...channels,
        {
          label: "Calls",
          value: "calls",
          icon: <Call />,
        },
      ]);
    if (isEmailIsIntegrated(smtpMail))
      setNavArray((pre) =>
        pre?.find((o) => o?.value === "email")
          ? pre
          : pre.concat([
              {
                label: "Email",
                value: "email",
                icon: <EmailOutlined />,
              },
            ])
      );
    if (
      // isDev()
      smsIntegration?.[smsIntegration?.selected_channel]
    ) {
      setNavArray((pre) =>
        pre?.find((o) => o?.value === "sms")
          ? pre
          : pre.concat([
              {
                label: "SMS",
                value: "sms",
                icon: <SmsOutlined />,
              },
            ])
      );
    }
    if (Boolean(isObjWithValues(reduxOnesignalIntegration?.selectedAccount))) {
      setNavArray((pre) =>
        pre?.find((o) => o?.value === "push_notification")
          ? pre
          : pre.concat([
              {
                label: "Push Notification",
                value: "push_notification",
                icon: <NotificationsActiveOutlined />,
              },
            ])
      );
    }
  }, [exotelNumbers, smtpMail, smsIntegration]);

  console.log(chatCustomer, "<<<<<<<<<<<<<<< chat customer");

  return (
    <Box sx={{ backgroundColor: "#fff" }}>
      {gettingComunication ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "calc(80vh - 200px)" }}
        >
          <LinearProgress sx={{ width: "60%" }} />
          <Typography variant="h6" align="center" mt={4}>
            Loading Chats...
          </Typography>
        </Stack>
      ) : null}

      {
        <>
          {!gettingComunication && (
            <>
              {showAnalytics && (
                <ChatAnalatics
                  setshowAnalytics={setshowAnalytics}
                  facebook_id={facebookObj?.value}
                  whatsapp_id={customerInfo?.whatsapp_id}
                />
              )}
              {!showAnalytics && (
                <>
                  <Stack spacing={4}>
                    <Box
                      sx={{
                        position: "sticky",
                        top: "69px",
                        zIndex: 12,
                        backgroundColor: "background.paper",
                        padding: "10px",
                        borderBottomColor: "#e8e8e8",
                        borderBottomWidth: 0.2,
                        borderBottomStyle: "solid",
                      }}
                    >
                      <Stack
                        direction="row"
                        sx={{
                          // backgroundColor: "Background",
                          padding: "10px",

                          overflow: "auto",
                          whiteSpace: "nowrap",
                        }}
                        gap={3}
                        spacing={4}
                      >
                        {navArray.map((b) => (
                          <Button
                            key={b?.value}
                            variant={"text"}
                            startIcon={b.icon}
                            // style={{ minWidth: "auto" }}
                            sx={{
                              // padding: "6px 20px",
                              backgroundColor:
                                selectedTab === b.value
                                  ? "#e6e6e6"
                                  : "transparent",
                            }}
                            onClick={() => setSelectedTab(b.value)}
                          >
                            <Typography>{b.label}</Typography>
                          </Button>
                        ))}
                      </Stack>
                      {/* <Stack> */}
                      {metaTabs?.includes(selectedTab) && (
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          justifyContent={"space-between"}
                          mt={1}
                        >
                          <Stack
                            direction={"row"}
                            alignItems="center"
                            gap={"18px"}
                          >
                            <Stack
                              direction={"row"}
                              gap={"5px"}
                              alignItems="center"
                            >
                              <ChatsPrimary
                                style={{ height: "15px", width: "15px" }}
                              />

                              <Typography variant="h6">
                                {chatCustomer?.conversation?.length || 0}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              alignItems={"center"}
                              spacing={2}
                            >
                              <North
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  fill: "blue",
                                }}
                              />
                              {chatCustomer?.conversation?.filter(
                                (obj) => obj?.is_sender
                              )?.length || 0}
                              <South
                                style={{
                                  height: "15px",
                                  width: "15px",
                                  fill: "green",
                                }}
                              />
                              {chatCustomer?.conversation?.filter(
                                (obj) => !obj?.is_sender
                              )?.length || 0}
                              {/* {customer.email || ""} */}
                            </Stack>
                          </Stack>
                          {true ? (
                            <Button
                              variant="outlined"
                              size="small"
                              onClick={() => setshowAnalytics(true)}
                            >
                              View analytics
                            </Button>
                          ) : null}
                        </Stack>
                      )}
                    </Box>
                    {metaTabs?.includes(selectedTab) && (
                      <Stack style={{ marginTop: 0 }}>
                        {/* {isObjWithValues(chatCustomer) && !gettingComunication ? ( */}
                        {!gettingComunication ? (
                          getIsBackendMigrated("chats") || true ? (
                            <NewChat
                              customer={{
                                ...chatCustomer,
                                platform: selectedTab,
                              }}
                              CRMCustomer={customerInfo}
                              customerLoading={gettingComunication}
                              showHeader={false}
                              customerInfo={chatCustomer}
                              setMergedPlatforms={setMergedPlatforms}
                              mergedPlatforms={mergedPlatforms}
                            />
                          ) : null
                        ) : // <Chat
                        //   customer={{
                        //     ...chatCustomer,
                        //     platform: selectedTab,
                        //   }}
                        //   customerLoading={gettingComunication}
                        //   showHeader={false}
                        //   customerInfo={customerInfo}
                        //   // setShowProfile={false}
                        // />
                        null}
                      </Stack>
                    )}
                    <TabPanel value={selectedTab} index={"calls"}>
                      <Calls customerInfo={customerInfo} />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={"email"}>
                      <Emails customerInfo={customerInfo} />
                    </TabPanel>
                    {/* <TabPanel value={selectedTab} index={"sms"}>
                      <SmsHistory
                        id={customerInfo?.id}
                        customerInfo={customerInfo}
                        crm={true}
                      />
                    </TabPanel> */}
                    <TabPanel value={selectedTab} index={"push_notification"}>
                      <PushNotification customerInfo={customerInfo} />
                    </TabPanel>
                  </Stack>
                </>
              )}
            </>
          )}
        </>
      }
    </Box>
  );
};

export default Communication;

const ChatAnalatics = ({ setshowAnalytics, whatsapp_id, facebook_id }) => {
  return (
    <Box p={"20px"}>
      <Button
        startIcon={<ArrowBackIosNew />}
        onClick={() => setshowAnalytics(false)}
      >
        Analytics
      </Button>
      <ChatsData whatsapp_id={whatsapp_id} facebook_id={facebook_id} />
    </Box>
  );
};
export const ChatsData = ({ whatsapp_id, facebook_id }) => {
  console.log(facebook_id);
  const allReduxConversations = useSelector(
    (state) => state.chats.conversations
  );
  const [chats, setchats] = useState({
    facebook: {},
    whatsapp: {},
  });
  const [productsId, setProductsId] = useState([]);
  const [selectedTab, setSelectedTab] = useState("products");
  const [dataObj, setDataObj] = useState({
    order: [],
    product: [],
  });
  useEffect(() => {
    if (whatsapp_id) {
      let whatsappObj = allReduxConversations?.find(
        (obj) => obj.id === whatsapp_id
      );
      if (whatsappObj) {
        setchats((pre) => ({ ...pre, whatsapp: whatsappObj }));
        let ordersList = [];
        let productList = [];
        for (let index = 0; index < whatsappObj?.conversation.length; index++) {
          const messageobj = whatsappObj?.conversation[index];
          if (messageobj?.message?.type === "order") {
            let orderObj = {};
            orderObj.time = messageobj?.time;
            orderObj.qty = messageobj?.message?.order?.product_items
              ?.map((o) => validateNumber(o.quantity))
              ?.reduce((a, b) => a + b, 0);
            orderObj.total = `${
              currencySymbols[
                messageobj?.message?.order?.product_items?.[0]?.currency
              ]
            } ${messageobj?.message?.order?.product_items
              ?.map((obj) => validateNumber(obj?.item_price))
              ?.reduce((a, b) => a + b, 0)}`;
            orderObj.sku = messageobj?.message?.order?.product_items?.map(
              (obj) => getSkuFromFbId(obj?.product_retailer_id || "")
            );
            orderObj.ids = messageobj?.message?.order?.product_items?.map(
              (obj) => obj?.product_retailer_id?.split("_")?.at(-1)
            );

            ordersList.push(orderObj);
          }
          if (messageobj?.message?.interactive?.action?.catalog_id) {
            let productObj = {};
            let items = messageobj?.message?.interactive?.action?.sections
              ?.map((o) => o.product_items)
              ?.flat()
              .map((obj) => getSkuFromFbId(obj?.product_retailer_id));
            productObj.ids = messageobj?.message?.interactive?.action?.sections
              ?.map((o) => o.product_items)
              ?.flat()
              ?.map((obj) => obj?.product_retailer_id?.split("_")?.at(-1));

            productObj.time = messageobj?.time;
            productObj.catagory =
              messageobj?.message?.interactive?.action?.sections?.map(
                (o) => o.title
              );
            productObj.sku = items;
            productObj.total = items.length;
            productList.push(productObj);
          }
        }
        setDataObj((pre) => ({
          ...pre,
          order: ordersList,
          product: productList,
        }));
      }
    }
    if (facebook_id) {
      let facebookObj = allReduxConversations?.find(
        (obj) => obj.id === facebook_id
      );
      if (facebookObj) setchats((pre) => ({ ...pre, facebook: facebookObj }));
    }
  }, [whatsapp_id, facebook_id, allReduxConversations]);

  const onTabChange = (e, newValue) => {
    setSelectedTab(newValue);
  };
  return (
    <Box>
      <Grid sx={{ my: 3 }}>
        <Card className="shadow_card">
          <ChannelTotalGraph
            channelTotal={[
              {
                name: "whatsapp",
                value: chats?.whatsapp?.conversation?.length || 0,
              },

              {
                name: "facebook",
                value: chats?.facebook?.conversation?.length || 0,
              },
              // { name: "Total", value: chats?.whatsapp?.conversation?.length || 0 + chats?.facebook?.conversation?.length || 0 },
            ]}
          />
        </Card>
      </Grid>
      <Card className="shadow_card">
        <CardContent>
          <Typography variant="h6" sx={{ my: 3, ...sectionLabel }}>
            Send Templates history
          </Typography>
          <Tabs value={selectedTab} onChange={onTabChange}>
            <Tab label="Products" value={"products"} />
            {<Tab label="Orders" value={"order"} />}
          </Tabs>

          <ChatProducts
            open={isArrayWithValues(productsId)}
            productsId={productsId}
            onClose={() => setProductsId([])}
          />
          <TabPanel value={"products"} index={selectedTab}>
            {isArrayWithValues(dataObj?.product) ? (
              <ProductsTable
                dataArry={dataObj?.product}
                setProductsId={setProductsId}
              />
            ) : (
              <Box sx={{ margin: "auto", height: "100%" }}>
                <NoTasks
                  text={
                    <Box sx={{ textAlign: "center" }}>
                      <Typography>No product Catalog send</Typography>
                      <Typography sx={{ ...secondaryLabel, fontSize: "12px" }}>
                        Let send template and start conversation
                      </Typography>
                      {/* <Button
                    variant="outlined"
                    sx={{ mt: 3 }}
                    size="small"
                  // startIcon={<Add />}
                  // onClick={() => navigate("/chats")}
                  >
                    Send
                  </Button> */}
                    </Box>
                  }
                  icon={
                    <Inventory
                      style={{
                        height: "70px",
                        width: "70px",
                        color: "#808080",
                      }}
                    />
                  }
                />
              </Box>
            )}
          </TabPanel>
          <TabPanel value={"order"} index={selectedTab}>
            {isArrayWithValues(dataObj?.order) ? (
              <OrderTable
                dataArry={dataObj?.order}
                setProductsId={setProductsId}
              />
            ) : (
              <Box sx={{ margin: "auto", height: "100%" }}>
                <NoTasks
                  text={
                    <Box sx={{ textAlign: "center" }}>
                      <Typography>No Order Reply</Typography>
                      {/* <Typography sx={{ ...secondaryLabel, fontSize: "12px" }}>
                    Let send template and start conversation
                  </Typography> */}
                      {/* <Button
                    variant="outlined"
                    sx={{ mt: 3 }}
                    size="small"
                  // startIcon={<Add />}
                  // onClick={() => navigate("/chats")}
                  >
                    Send
                  </Button> */}
                    </Box>
                  }
                  icon={
                    <ProductionQuantityLimits
                      style={{
                        height: "70px",
                        width: "70px",
                        color: "#808080",
                      }}
                    />
                  }
                />
              </Box>
            )}
          </TabPanel>
        </CardContent>
      </Card>
    </Box>
  );
};

const OrderTable = ({ dataArry, setProductsId }) => {
  return (
    <TableContainer component={Paper}>
      {" "}
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Qty.</TableCell>
            <TableCell align="right">Estimated Total</TableCell>
            <TableCell align="right">Product SKU</TableCell>
            {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataArry?.map((row) => {
            return (
              <StyledTableRow
                key={row.message?.id}
                onClick={() => setProductsId(row?.ids)}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  {row?.time
                    ? formatDate(new Date(row?.time), "relative_date")
                    : null}
                </TableCell>
                <TableCell>{row?.qty}</TableCell>
                <TableCell align="right" component="th" scope="row">
                  {row?.total}
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  {row?.sku?.map((str, index) =>
                    index > 3 ? null : <Typography>{str}</Typography>
                  )}
                  <Typography>
                    {row?.sku?.length > 4 ? "...more" : null}
                  </Typography>
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const ProductsTable = ({ dataArry, setProductsId }) => {
  return (
    <TableContainer component={Paper}>
      {" "}
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Catagory</TableCell>
            <TableCell align="right">Product sent</TableCell>

            <TableCell align="right">Product SKU</TableCell>
            {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataArry?.map((row) => {
            console.log(row.ids);
            return (
              <StyledTableRow
                onClick={() => setProductsId(row.ids)}
                key={row.message?.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  {row?.time
                    ? formatDate(new Date(row?.time), "relative_date")
                    : null}
                </TableCell>
                <TableCell>
                  {" "}
                  {row?.catagory?.map((str) => (
                    <Typography>{str}</Typography>
                  ))}
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  {row?.total}
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  {row?.sku?.map((str, index) =>
                    index > 3 ? null : <Typography>{str}</Typography>
                  )}
                  <Typography>
                    {row?.sku?.length > 4 ? "...more" : null}
                  </Typography>
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
