import React, { forwardRef } from "react";
import styled from "styled-components/macro";
import { NavLink, useLocation } from "react-router-dom";
import { rgba, darken } from "polished";

import {
  Box,
  Chip,
  Collapse,
  ListItemButton,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { useTheme } from "@mui/system";
import { Mixpanel } from "../../mixpanel";
import {
  formatServerValue,
  getAuthToken,
  getToken,
  getUserProduct,
  getWebsite,
  isDev,
  isOurWebsite,
  unformatServerValue,
} from "../../helper";
import { useTour } from "@reactour/tour";
import {
  setHelperData,
  SET_HELPER_DATA,
} from "../../redux/actions/helperActions";
import { useDispatch, useSelector } from "react-redux";
import { starterPageContent } from "../../pages/components/OnBoardingDialog";

const CustomRouterLink = forwardRef((props, ref) => {
  return (
    <div ref={ref}>
      <NavLink {...props} />
    </div>
  );
});

const Item = styled(ListItemButton)`
  padding-top: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-left: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 14 : 8)};
  padding-right: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 4 : 7)};
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  display: flex;
  ${(props) => props.theme.breakpoints.up("md")} {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }
  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }
  img {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
    color: ${(props) => props.theme.sidebar.color};
    svg {
      opacity: 1;
    }
    img {
      opacity: 1;
    }
  }
  &.${(props) => props.activeclassname} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};
    span {
      color: ${(props) => props.theme.sidebar.color};
    }
    svg {
      opacity: 1;
    }
    img {
      opacity: 1;
    }
  }
`;

const Title = styled(ListItemText)`
  text-align: center;
  margin: 0;
  span {
    color: ${(props) =>
      rgba(
        props.theme.sidebar.color,
        props.depth && props.depth > 0 ? 0.7 : 1
      )};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)};
    ${(props) => props.theme.breakpoints.down("md")} {
      text-align: left;
    }
    ${(props) => props.theme.breakpoints.up("md")} {
      font-size: 11px;
      margin-top: 5px;
    }
  }
`;

// background: ${(props) => props.theme.sidebar.badge.background};
const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 15px;
  position: absolute;
  right: 20px;
  top: 4px;
  z-index: 1;
  background: #ea2e2e;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 10px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
    margin-top: 0px;
    font-weight: 500;
  }
`;

const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const iconStyle = {
  height: "24px",
  width: "24px",
};

const SidebarNavListItem = (props) => {
  const {
    title,
    href,
    depth = 0,
    children,
    icon: Icon,
    badge,
    iconColor,
    open: openProp = false,
    onClose,
    value,
  } = props;
  const [open, setOpen] = React.useState(openProp);
  const { setCurrentStep, isOpen } = useTour();

  const handleToggle = () => {
    setOpen((state) => !state);
  };
  const theme = useTheme();
  const location = useLocation();
  const dispatch = useDispatch();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  let tour_history = useSelector((state) => state.user?.tour_history);
  const user_products = useSelector(
    (state) => state.user?.store_plan?.user_products
  );
  const frontendAccess = useSelector((state) => state.user?._access?.Frontend);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const new_backend_mirgrated = useSelector(
    (state) => state.settings.new_backend_mirgrated
  );
  let automationCRMmigrated = new_backend_mirgrated?.automation_crm;
  const onItemClick = () => {
    Mixpanel.track("page_visit", {
      title,
      href,
    });

    // if()

    if (
      user_products?.length > 1 &&
      Object.keys(starterPageContent || {})
        ?.join("")
        ?.includes(unformatServerValue(title || ""))
    ) {
      if (!tour_history?.[`${unformatServerValue(title || "")}_introduction`]) {
        dispatch({
          type: SET_HELPER_DATA,
          payload: {
            featureProduct: unformatServerValue(title || ""),
            onBoardingDialogState: true,
          },
        });
      }
    }
    if (title == "Integrations" && isOpen) setCurrentStep((state) => state + 1);

    if (isIpad) onClose();
  };
  if (children && isIpad) {
    return (
      <React.Fragment>
        <Item
          depth={depth}
          onClick={() => {
            handleToggle();
            // onClose()
          }}
          id={`nav-${unformatServerValue(title || "")}`}
        >
          {Icon && <Icon />}
          <Title depth={depth}>
            {title}
            {badge && <Badge label={badge} />}
          </Title>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Item>
        <Collapse in={open}>{children}</Collapse>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Item
        disableRipple
        depth={depth}
        component={
          (automationCRMmigrated && href === "/marketing_automation") ||
          href === "/chats" ||
          (href === "/frontend" &&
            // isOurWebsite({ websites: ["jpearls"] }) &&
            (frontendAccess || isAdmin))
            ? Box
            : CustomRouterLink
        }
        to={href === "/chats" && !isDev() ? "https://chats.jwero.ai" : href}
        activeclassname="active"
        onClick={
          href === "/frontend" &&
          // isOurWebsite({ websites: ["jpearls"] }) &&
          (frontendAccess || isAdmin)
            ? () =>
                window.open(
                  `${getWebsite()}/wp-admin/admin-ajax.php?action=sso_login&token=${getToken()}`,
                  "_blank"
                )
            : href === "/chats"
            ? () =>
                window.open(
                  `https://chats.jwero.ai?auth=${getAuthToken()}`,
                  "_blank"
                )
            : href === "/marketing_automation" && automationCRMmigrated
            ? () =>
                window.open(
                  `https://automations.jwero.ai?auth=${getAuthToken()}`,
                  "_blank"
                )
            : onItemClick
        }
        className={`sidebar_${value}`}
      >
        {Icon && (
          <Icon
            style={
              iconColor ? { fill: iconColor, ...iconStyle } : { ...iconStyle }
            }
          />
        )}
        <Title depth={depth}>
          {title}
          {badge && <Badge label={badge} />}
        </Title>
      </Item>
    </React.Fragment>
  );
};

export default SidebarNavListItem;
