import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { AccessTime, Add, Campaign } from "@mui/icons-material";
import {
  CheckCircle,
  EmailCampaignSvg,
  PushNotificationSvg,
  SMSSvg,
  WhatsappSvg,
} from "../../../../../../Svgs";
import { Box, Checkbox, Collapse, FormControlLabel } from "@mui/material";
import HorizontalStack from "../../../../../../helper/utility/HorizontalStack";
import {
  ErrorLabelExtraSmall,
  SecondaryLabelSmall,
} from "../../../../../../helper/utility/Labels";
import CustomField from "../../../../../../helper/utility/CustomField";
import { useDispatch, useSelector } from "react-redux";
import {
  isArrayWithValues,
  isObjWithValues,
  validateNumber,
} from "jwero-javascript-utils";
import {
  backendApiCall,
  backendApiEndpoints,
  formatServerValue,
  getCustomerTotal,
  isDev,
} from "../../../../../../helper";
import TempleteList from "../../../../../components/TempleteList";
import { EmailTemplateList } from "../../../../../email/EmailList";
import { default as SMSTemplates } from "../../../../../integrations/components/sms/Templates";
import { setCountOnSegment } from "../../../../../../redux/actions/chatActions";
import SendSms from "../../../../../customers/components/SendSms";
import { LoadingButton } from "@mui/lab";
import { setGlobalToast } from "../../../../../../redux/actions/helperActions";
import { fetchJweroBroadcasts } from "../../../../../../redux/actions/marketingActions";
import SendNotification from "../../../../../components/SendNotification";
import { returnOptOutParams } from "../../../../../customers/components/EmailTemplate";

const BroadcastPlatforms = [
  { label: "Whatsapp", value: "whatsapp", icon: WhatsappSvg },
  { label: "Email", value: "email", icon: EmailCampaignSvg },
  { label: "SMS", value: "sms", icon: SMSSvg },
  { label: "Push", value: "push_notification", icon: PushNotificationSvg },
];

let inititalState = {
  channel: "whatsapp",
  segment: [],
  schedule_date: new Date(),
  service: "",
};

export default function CreateBroadcast() {
  const [form, setForm] = React.useState(inititalState);
  const [open, setOpen] = React.useState(false);
  const customerSegments = useSelector((state) => state.chats.customerSegments);
  const [segments, setSegments] = React.useState([]);
  const [templates, setTemplates] = React.useState({
    whatsapp: [],
    email: [],
    sms: [],
  });
  const [showWhatsappTemplates, setShowWhatsappTemplate] =
    React.useState(false);
  const [showSMSTemplates, setShowSMSTemplates] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorText, setErrorText] = React.useState("");
  const reduxWhatsappTemplete = useSelector(
    (state) => state.chats.whatsappTempletes
  );
  const reduxEmailTemplates = useSelector(
    (state) => state.chats.emailTemplates
  );
  const smsTemplates = useSelector((state) => state.chats.smsTemplates);
  const emailTemplatesRef = React.useRef();
  const smsTemplatesRef = React.useRef();
  const pushCreateRef = React.useRef();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (isArrayWithValues(reduxWhatsappTemplete))
      setTemplates((state) => ({
        ...state,
        whatsapp: reduxWhatsappTemplete?.map((i) => ({
          label: formatServerValue(i.name),
          value: i.id,
        })),
      }));
  }, [reduxWhatsappTemplete]);

  React.useEffect(() => {
    if (isArrayWithValues(smsTemplates))
      setTemplates((state) => ({
        ...state,
        sms: smsTemplates?.map((i) => ({
          label: i.template_name,
          value: i.id,
        })),
      }));
  }, [smsTemplates]);

  React.useEffect(() => {
    if (isObjWithValues(reduxEmailTemplates))
      setTemplates((state) => ({
        ...state,
        email: Object.values(reduxEmailTemplates)?.map((i) => ({
          label: i.name,
          value: i.id,
        })),
      }));
  }, [reduxEmailTemplates]);

  React.useEffect(() => {
    if (isObjWithValues(customerSegments))
      setSegments(
        Object.values(customerSegments)?.map((i) => ({
          label: `${i.filterForm?.segment_name || ""} ${
            i?.count ? `(${i?.count} customers)` : ""
          }`,
          value: i?.id,
          count: i?.count,
        }))
      );
  }, [customerSegments]);

  const editForm = (payload) => {
    if (errorText) setErrorText("");
    setForm((state) => ({ ...state, ...payload }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onOpenTemplate = () => {
    if (form.channel === "whatsapp") setShowWhatsappTemplate(true);
    else if (form.channel === "email") emailTemplatesRef?.current?.open();
    else if (form.channel === "sms") setShowSMSTemplates(true);
    else if (form.channel === "push_notification")
      pushCreateRef?.current?.open();
    // smsTemplatesRef?.current?.open();
  };

  const onTemplateSelect = (template, service) => {
    console.log(template, "<<<< template");
    editForm({ template });
  };

  const onCloseTemplate = () => {
    setShowWhatsappTemplate(false);
  };

  const fetchSegmentsCount = async (segments) => {
    if (!isArrayWithValues(segments)) return;
    for await (let segment of segments) {
      if (!segment?.count) {
        // let seg = Object.values(customerSegments)?.find(
        //   (i) => i.id === segment.value
        // );
        dispatch(setCountOnSegment(segment));
      }
    }
  };

  // React.useEffect(() => {
  //   setTimeout(() => {
  //     fetchSegmentsCount(Object.values(customerSegments));
  //   }, 2000);
  // }, []);

  const onSubmit = async () => {
    let error = "";

    let obj = {
      type: "broadcast",
      channel: form.channel,
      template: form.template,
      meta_data: {
        status: form.schedule ? "scheduled" : "pending",
        segments: form.segment?.map((i) => ({ id: i.value })),
        scheduled: form.schedule,
        schedule_date: form.schedule_date,
        service: form?.template?.service,
      },
    };
    let templates = [];
    if (form.channel === "whatsapp") templates = reduxWhatsappTemplete;

    console.log(templates, reduxWhatsappTemplete, form?.template?.id);

    obj.meta_data.default_template = templates?.find(
      (i) => i.id === form?.template?.id || i.name === form?.template?.name
    );
    if (!isObjWithValues(form.template)) {
      error = "Please Select a Template";
    }
    if (!isArrayWithValues(form.segment)) {
      error = "Please Select at least one segment";
    }
    if (error) return setErrorText(error);
    console.log(obj);

    setLoading(true);
    let total_customers = 0;
    for await (let _i of form.segment) {
      try {
        let segment = customerSegments[_i?.value];
        let params = returnOptOutParams(segment);
        let count = await getCustomerTotal({
          params: params,
        });
        console.log(count, customerSegments, segment, params, "<<<<<<");
        if (validateNumber(count)) total_customers += validateNumber(count);
      } catch (error) {}
    }
    if (total_customers) obj.meta_data.total_customers = total_customers;
    // console.log(obj, "<<<<<<<<<");
    // if (isDev()) return;
    // if (isDev()) return;
    try {
      setLoading(true);
      let res = await backendApiCall({
        endpoint: `${backendApiEndpoints.startBroadcast}`,
        method: "POST",
        data: obj,
      });
      if (res?.success) {
        dispatch(
          setGlobalToast({
            show: true,
            message: form.schedule_date
              ? "Broadcast scheduled successfully"
              : "Broadcast started successfully",
            severity: "success",
          })
        );
        handleClose();
        dispatch(fetchJweroBroadcasts());
        setForm(inititalState);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        startIcon={<Add />}
        size="small"
        onClick={handleClickOpen}
      >
        Create New Broadcast
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="alert-dialog-title">Create New Broadcast</DialogTitle>
        <DialogContent>
          <SecondaryLabelSmall>Select Platform</SecondaryLabelSmall>
          <HorizontalStack
            sx={{ flexWrap: "wrap", gap: "10px", margin: "10px 0px" }}
          >
            {BroadcastPlatforms.map((i) => {
              let selected = i.value === form.channel;
              return (
                <PlatformCard
                  i={i}
                  selected={selected}
                  key={i.value}
                  editForm={editForm}
                />
              );
            })}
          </HorizontalStack>
          <CustomField
            label="Select Segment"
            type="checkbox"
            options={segments}
            value={form.segment}
            onChange={(e) => editForm({ segment: e })}
          />
          <SecondaryLabelSmall sx={{ mt: 3, mb: 1 }}>
            {isObjWithValues(form.template)
              ? "Selected Template"
              : `Select Template`}
          </SecondaryLabelSmall>
          {form.template ? (
            <Button
              variant="outlined"
              fullWidth
              onClick={onOpenTemplate}
            >{`Template: ${formatServerValue(
              form?.template?.name || form?.template?.template_name
            )}`}</Button>
          ) : (
            <Button
              variant="outlined"
              fullWidth
              onClick={onOpenTemplate}
            >{`Select ${formatServerValue(form.channel)} Template`}</Button>
          )}
          {/* <CustomField
            label={`Select ${formatServerValue(form.channel)} Template`}
            type="dropdown"
            options={templates[form.channel] || []}
            value={form.template}
            onChange={(e) => editForm({ template: e })}
            sx={{ mt: 3 }}
          /> */}
          <Collapse in={form.schedule}>
            <CustomField
              label="Schedule Date"
              type="datetime"
              options={segments}
              value={form.schedule_date}
              onChange={(e) => editForm({ schedule_date: e })}
              sx={{ mt: 3 }}
              textFieldProps={{
                size: "small",
              }}
            />
          </Collapse>
          <FormControlLabel
            control={<Checkbox />}
            label="Schedule Broadcast"
            value={form.schedule}
            onChange={(e) => editForm({ schedule: e.target.checked })}
          />
          {/* <Chec
            label="Schedule Broadcast"
            type="checkbox"
            // options={segments}
            // value={form.segment}
            // onChange={(e) => editForm({ segment: e })}
            // sx={{ mt: 2 }}
          /> */}
          {/* <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending
            anonymous location data to Google, even when no apps are running.
          </DialogContentText> */}
        </DialogContent>
        <Collapse in={Boolean(errorText)}>
          <ErrorLabelExtraSmall sx={{ textAlign: "center" }}>
            {errorText}
          </ErrorLabelExtraSmall>
        </Collapse>
        <DialogActions sx={{ paddingRight: "24px" }}>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            onClick={onSubmit}
            autoFocus
            variant="outlined"
            // endIcon={<AccessTime />}
            loading={loading}
            startIcon={form.schedule ? <AccessTime /> : <Campaign />}
          >
            {form.schedule ? "Schedule Broadcast" : "Start Broadcast"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <TempleteList
        open={showWhatsappTemplates}
        handleClose={onCloseTemplate}
        setParentTemplate={onTemplateSelect}
        hideRejected
        // asBox={true}
        // ref={whatsappTemplateSelectRef}
        // setShowTemplateButton={setShowTemplateButton}
        newMediaLibrary={true}
        showAllVariables
        hideTemplateActions={true}
        selectionMode={true}
      />
      <EmailTemplatesDialog
        ref={emailTemplatesRef}
        setTemplate={(e) => editForm({ template: e })}
      />
      {/* <SMSTemplatesDialog ref={smsTemplatesRef} /> */}
      <SendSms
        handleClose={() => setShowSMSTemplates(false)}
        // selected={selected}
        // sendWithCampagin={true}
        onEditForm={onTemplateSelect}
        returnPayload
        open={showSMSTemplates}
      />
      <PushTemplatesDialog setTemplate={onTemplateSelect} ref={pushCreateRef} />
    </React.Fragment>
  );
}

const PlatformCard = ({ i, selected, editForm }) => {
  let Icon = i.icon;
  return (
    <HorizontalStack
      key={i.value}
      sx={{
        padding: "7px 16px",
        borderRadius: 10,
        border:
          //  selected ?
          "1px solid grey",
        // :"1px solid lightgrey",
        gap: "6px",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        position: "relative",
        opacity: selected ? 1 : 0.5,
      }}
      onClick={() => editForm({ channel: i.value })}
    >
      <Icon height={14} width={14} />
      <SecondaryLabelSmall
        sx={{
          color:
            //  selected ? "grey" :
            "grey",
        }}
      >
        {i.label}
      </SecondaryLabelSmall>
      {selected && (
        <CheckCircle
          style={{
            position: "absolute",
            right: "-4px",
            top: "-4px",
            width: "16px",
            height: "16px",
          }}
          width={9}
          height={9}
        />
      )}
    </HorizontalStack>
  );
};

const EmailTemplatesDialog = React.forwardRef((props, ref) => {
  let { setTemplate } = props;
  const [open, setOpen] = React.useState(false);

  React.useImperativeHandle(ref, () => {
    return { open: handleClickOpen };
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <EmailTemplateList
            action={true}
            // hideHeading
            // isEdit={emailBuilderPage}
            // setIsEdit={setImageBuilderPage}
            // setSelectedPage={setDialogContentPage}
            // setCreateTemplate={setCreateTemplate}
            setOnSelectedtemplated={(t) => {
              setTemplate(t);
              handleClose();
            }}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
});

const PushTemplatesDialog = React.forwardRef(({ setTemplate }, ref) => {
  const [open, setOpen] = React.useState(false);

  React.useImperativeHandle(ref, () => {
    return { open: handleClickOpen };
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <SendNotification
            buttonLabel={"Create Notification"}
            returnPayload
            getPayload={
              // onEditForm
              (payload) => {
                setTemplate(payload);
                // onEditForm({
                //   payload: { payload, hasError: false },
                //   key: selectedNode?.id,
                // });
                // setCreateCampaignState((pre) => ({
                //     ...pre,
                //     payload: payload,
                // }));}
              }
            }
            onClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
});
