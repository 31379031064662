import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  Fab,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  InputAdornment,
  LinearProgress,
  Slide,
  Slider,
  Snackbar,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import styled, { useTheme } from "styled-components";
import { CloudUpload, FileUpload } from "../../Svgs";
import { FileUploader } from "react-drag-drop-files";
import { useDispatch, useSelector } from "react-redux";
import {
  Base64,
  blobToFile,
  dialogStyle,
  fetchProducts,
  formatDate,
  formatServerValue,
  getAxiosError,
  getToken,
  getWebsite,
  getWooCommerce,
  isArrayWithValues,
  isObjWithValues,
  removeExtension,
  removeSubstringAfterLastHyphen,
  removeSubstringAfterLastUnderscore,
  sleep,
  updateJweroBatchProducts,
} from "../../helper";
import {
  deleteMediaInRedux,
  getAllSku,
  getMediaLibrary,
  updateAllSku,
  updateMultipleProductsInRedux,
  updateProductInRedux,
} from "../../redux/actions/productActions";
import { LoadingButton } from "@mui/lab";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  ArticleOutlined,
  Check,
  Close,
  ContentCopy,
  Delete,
  Info,
  PriorityHigh,
  Rotate90DegreesCcw,
  RotateLeft,
  RotateRight,
  Search,
} from "@mui/icons-material";
import axios from "axios";
import Cropper from "react-easy-crop";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import {
  setGlobalSnackbar,
  setGlobalToast,
} from "../../redux/actions/helperActions";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { rearrangeImages, trimProductSubMediaSuffix } from "../../utils/media";
// import { LazyLoadImage } from "react-lazy-load-image-component";
let defaultParams = { per_page: 100, page: 1, search: "" };

const ProductMediaLibrary = ({
  open,
  onChange,
  setParentImages,
  selectedImages: alreadySelectedImages,
  id,
  multiple = true,
  limit = false,
  aspect,
  mediaType,
  hideUploadInfo = true,
  enable_crop = true,
}) => {
  const [value, setValue] = React.useState(0);
  const [allMedia, setAllMedia] = useState([]);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [fetchingMedia, setFetchingMedia] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [uploadingImage, setUploadingImage] = useState(false);
  const [imageToUpload, setImageToUpload] = useState("");
  const [droppedImage, setDroppedImage] = useState("");
  const [droppedImages, setDroppedImages] = useState("");
  const [droppedRowFiles, setDroppedRowFiles] = useState([]);
  const reduxMedia = useSelector((state) => state.product.media);
  const reduxAllSku = useSelector((state) => state.product.allSku);
  const reduxFetchingMedia = useSelector(
    (state) => state.product.fetchingMedia
  );
  const [deleteAlert, toggleDeleteAlert] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const inputFile = useRef(null);

  const [params, setParams] = useState(defaultParams);
  const [crop, setCrop] = useState({});
  const [zoom, setZoom] = useState({});
  const [rotate, setRotate] = useState({});
  const [croppedAreaPixels, setCroppedAreaPixels] = useState({});
  const [droppedImageObj, setDroppedImageObj] = useState("");
  const [resultToShow, setResultToShow] = useState("");
  const [selectedEditingImage, setSelectedEditingImage] = useState("0");
  const [uploadedInfo, setUploadedInfo] = useState("");
  const [toast, setToast] = useState({ show: false });
  const [scrollableheight, setScrollableHeight] = useState(0);
  let searchTimeout;
  const dispatch = useDispatch();
  const imagesRef = useRef();

  useEffect(() => {
    setScrollableHeight(imagesRef.current?.clientHeight);
  }, [allMedia]);

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels, selectedEditingImage) => {
      setCroppedAreaPixels((state) => ({
        ...state,
        [selectedEditingImage]: croppedAreaPixels,
      }));
    },
    []
  );
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onImgLoad = async ({ target: img }, selectedImage) => {
    const { naturalHeight, naturalWidth } = img;
    // let { } = (img.naturalHeight, img.naturalWidth);
    let isVertical = naturalHeight > naturalWidth;
    let size = isVertical ? naturalWidth : naturalHeight;

    let axis = isVertical
      ? { x: 0, y: (naturalHeight - naturalWidth) / 2 }
      : { y: 0, x: (naturalWidth - naturalHeight) / 2 };
    setCroppedAreaPixels((state) => ({
      ...state,
      [selectedImage]: {
        height: aspect?.height || size,
        width: aspect?.width || size,
        ...axis,
      },
    }));
  };

  useEffect(() => {
    if (Array.isArray(reduxMedia)) setAllMedia(reduxMedia);
  }, [reduxMedia]);

  useEffect(() => {
    setFetchingMedia(reduxFetchingMedia);
  }, [reduxFetchingMedia]);

  useEffect(() => {
    if (!isArrayWithValues(reduxMedia)) dispatch(getMediaLibrary(params));
    if (isArrayWithValues(alreadySelectedImages))
      setSelectedImages(alreadySelectedImages);
    // if (!isArrayWithValues(reduxAllSku)) dispatch(getAllSku());
  }, []);
  // useEffect(() => {
  //   if (mediaType) dispatch(getMediaLibrary({ ...params, media_type: mediaType }))
  // }, [mediaType])

  useEffect(() => {
    if (isArrayWithValues(alreadySelectedImages))
      setSelectedImages(alreadySelectedImages);
  }, [alreadySelectedImages]);

  const onLoadMoreMedia = () => {
    setParams({ ...params, page: params.page + 1 });
    dispatch(getMediaLibrary({ ...params, page: params.page + 1 }));
  };

  const imagePress = (obj, index) => {
    if (multiple) {
      let updatedImages = [...selectedImages];
      if (selectedImages.find((i) => i.id === obj.id))
        updatedImages = updatedImages.filter((i) => i.id !== obj.id);
      else updatedImages.push(obj);
      if (limit && updatedImages.length > limit) updatedImages.shift();
      setSelectedImages(updatedImages);
    } else setSelectedImages([obj]);
  };

  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.down("lg"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // console.log(theme);
  let backgroundColor =
    theme && theme.palette && theme.sidebar && theme.sidebar.background;

  const onSelectPress = () => {
    if (setParentImages) setParentImages(selectedImages, id);
  };

  const cropImage = async () => {
    setUploadingImage(true);
    setUploadFailed(false);
    try {
      let index = 0,
        deleteIndex = 0;
      let totalLength = droppedImages.length;
      setUploadedInfo(`${index}/${totalLength}`);
      let mapImagesData = [];
      for await (let i of Object.keys(croppedAreaPixels)) {
        try {
          let croppedImage;
          let compressedImage;
          if (droppedImages[i].type?.includes("image")) {
            if (enable_crop)
              croppedImage = await getCroppedImg(
                droppedImages[i]?.url,
                croppedAreaPixels[i],
                rotate[i]
              );

            compressedImage = enable_crop ? croppedImage : droppedRowFiles[i];
            // compressedImage = await imageCompression(
            //   enable_crop ? croppedImage : droppedRowFiles[i],
            //   {
            //     maxSizeMB: droppedImages[i].type?.includes("image") ? 0.6 : 5,
            //     // maxWidthOrHeight: 1024,
            //     alwaysKeepResolution: true,
            //   }
            // );

            // return
          } else compressedImage = droppedImages[i]?.file;
          let imageRes = await uploadImageToLibrary(
            compressedImage,
            // croppedImage,
            droppedRowFiles[i]
          );
          if (setParentImages)
            setSelectedImages((state) => [{ ...imageRes }, ...state]);
          setAllMedia((state) => [{ ...imageRes }, ...state]);
          try {
            if (imageRes?.mime_type?.includes("image"))
              mapImagesData.push({
                name: droppedRowFiles[i].name,
                data: imageRes,
              });
            if (imageRes?.mime_type?.includes("video"))
              await mapVideoURLToProduct(droppedRowFiles[i].name, imageRes);

            // let mapImageRes = await mapImageToProduct(
            //   droppedRowFiles[i].name,
            //   imageRes
            // );
          } catch (error) {
            console.log("map error");
          }
          if (Number(selectedEditingImage) + 1 >= totalLength - index)
            setSelectedEditingImage((state) =>
              Number(state) ? Number(state) - 1 : 0
            );
          if (index !== totalLength - 1) {
            setDroppedImages((state) =>
              state.filter((e, i) => i !== deleteIndex)
            );
            setDroppedRowFiles((state) =>
              state.filter((e, i) => i !== deleteIndex)
            );
          }
        } catch (error) {
          console.log(getAxiosError(error), "< upload image error");
          deleteIndex++;
          setUploadFailed(true);
          break;
        }
        index++;
        setUploadedInfo(`${index}/${totalLength}`);
        if (index !== totalLength - 1) await sleep(500);
      }
      console.log(mapImagesData, "1");

      if (isArrayWithValues(mapImagesData)) {
        setUploadedInfo(`Mapping images`);

        await mapMultipleImagesProduct(mapImagesData);
        // let obj = {};
        // setUploadedInfo(`Mapping images`);
        // for (let image of mapImagesData) {
        //   let { name, data } = image;
        //   name = name?.split(".")?.[0];
        //   // // name = name?.split("_")?.[0];
        //   // name = removeSubstringAfterLastHyphen(name);
        //   // console.log(
        //   //   name,
        //   //   // name?.split("_")?.[0],
        //   //   '<<<<< name?.split("_")?.[0]'
        //   // );
        //   if (!obj[name]) obj[name] = [];
        //   obj[name].push(data);
        // }
        // console.log(obj, "<<<<<<<<<<<<<<< obj");
        // if (isObjWithValues(obj)) {
        //   // for await (let sku of Object.keys(obj)) {
        //   //   await mapImageToProduct(sku, obj[sku]);
        //   // }
        // }
      }
      console.log("2");

      setUploadingImage(false);
      if (!deleteIndex) {
        setDroppedImages([]);
        setDroppedRowFiles([]);
        setValue(1);
        setUploadFailed(false);
      } else {
        dispatch(
          setGlobalToast({
            show: true,
            message: `Couldn't upload images, Please try again!`,
            severity: "error",
          })
        );
        // setToast({
        //   show: true,
        //   message: `Couldn't upload images, Please try again!`,
        //   severity: "error",
        // });
      }
      // setImageToUpload(croppedImage);
      // setResultToShow(URL.createObjectURL(croppedImage));
    } catch (error) {
      setUploadingImage(false);
      console.log(getAxiosError(error));
    }
    // setImageToUpload(croppedImage);
  };

  const uploadImageToLibrary = async (croppedImage, rowImage) => {
    let image = croppedImage;
    console.log(image, "<<<<blob");
    image = new File([image], rowImage.name);
    console.log(image, "<<<<file");
    setUploadingImage(true);

    const formData = new FormData();
    formData.append("file", image, image.name);
    console.log(formData);
    // const formData = new FormData();
    // formData.append("file", rowImageToUpload);
    const website = getWebsite();
    const token = getToken();
    if (website && token) {
      let res = await axios({
        url: `${website}/wp-json/wp/v2/media`,
        data: formData,
        headers: {
          accept: "application/json",
          Authorization: `Basic ${token}`,
          "Content-Type": `multipart/form-data`,
        },
        method: "POST",
      });
      console.log(res.data);
      return res.data;
    }
  };

  const mapMultipleImagesProduct = async (payload) => {
    let skus = payload?.map((i) => {
      return trimProductSubMediaSuffix(i?.name?.split(".")?.[0]);
    });
    console.log(skus, "<<< fetching skus");
    let products = await fetchProducts({
      sku: skus?.join(),
      per_page: 3000,
      _fields: "id,sku,images",
    });
    let batchUpdate = [];
    console.log(products, "<<<<<< products");
    for (let product of products) {
      let { sku, images: added_images } = product;
      let images = payload?.filter((i) => {
        return i?.name?.includes(sku);
      });

      if (isArrayWithValues(images)) {
        images = images.map((i) => ({ ...i, ...i?.data }));
        images = rearrangeImages(added_images?.concat(images), product.sku);
        images = images?.map((i) => ({ id: i?.id }));
        batchUpdate.push({
          id: product.id,
          images,
        });
      }
    }

    let batchSize = 100;
    let batchLength = Math.round(batchUpdate?.length / 100) || 1;
    for (let _i of Array.from(new Array(batchLength))) {
      let payload = batchUpdate.splice(0, batchSize);
      if (isArrayWithValues(payload)) {
        let res = await updateJweroBatchProducts({
          payload: { update: payload },
        });
        if (isArrayWithValues(res?.update))
          dispatch(updateMultipleProductsInRedux(res?.update));
      }
    }

    console.log(batchUpdate, "<<<<<< batchUpdate");
  };

  const mapImageToProduct = async (fileName, images) => {
    if (!fileName || !images) return;
    let sku = fileName?.replace(":", "/");
    // = fileName && fileName.replace(/\.[^/.]+$/, "");
    // if (sku?.includes("_")) sku = removeSubstringAfterLastUnderscore(sku);

    // if (isArrayWithValues(sku) && sku?.includes("-"))
    //   sku = removeSubstringAfterLastHyphen(sku);
    // // sku = sku?.[0]?.split("-")?.[0];
    // else if (sku?.includes("-")) sku = removeSubstringAfterLastHyphen(sku);

    console.log(images, sku, "<<<<<<<<<<<<<<< images");
    if (!sku) return;
    let obj = await fetchProduct(sku);
    const website = getWebsite();
    const token = getToken();
    if (obj && website && token) {
      let { images: addedImages } = obj || {};
      // let obj = getSkuObj(sku);
      addedImages = addedImages || [];
      try {
        let { data } = await axios({
          url: `${website}/wp-json/wc/v3/products/${obj.id}`,
          data: {
            images: addedImages.concat(
              images.map((i) => ({ id: i.id, src: i.source_url }))
            ),
          },
          method: "PUT",
          headers: {
            Authorization: `Basic ${token}`,
          },
        });
        dispatch(updateProductInRedux(data));
        // if (data.id)
        //   updateAllSku({
        //     id: data.id,
        //     images: data.images,
        //     sku: data.sku,
        //   });
        return true;
      } catch (error) {
        console.log(getAxiosError(error));
      }
    }
  };
  const mapVideoURLToProduct = async (fileName, imageRes) => {
    let sku = fileName?.replace(":", "/");
    //  && fileName.replace(/\.[^/.]+$/, "");
    if (sku.includes("_")) sku = sku?.split("_")?.[0];
    sku = removeExtension(sku);
    if (!sku) return;
    let obj = await fetchProduct(sku);
    if (!obj) return;
    // let obj = getSkuObj(sku);
    const website = getWebsite();
    const token = getToken();
    if (obj && website && token) {
      try {
        let { data } = await axios({
          url: `${website}//wp-json/wc/v3/products/${obj.id}`,
          data: {
            meta_data: [
              { key: "_woodmart_product_video", value: imageRes?.source_url },
            ],
          },
          method: "PUT",
          headers: {
            Authorization: `Basic ${token}`,
          },
        });
        // if (data.id)
        //   updateAllSku({
        //     id: data.id,
        //     images: data.images,
        //     sku: data.sku,
        //   });
        console.log("product video mapped ", data, fileName);
        return true;
      } catch (error) {
        console.log(getAxiosError(error));
      }
    }
  };

  const uploadImage = async () => {
    let image = imageToUpload;
    image = new File([image], droppedImageObj.name);
    setUploadingImage(true);

    const formData = new FormData();
    formData.append("file", image, image.name);
    const website = getWebsite();
    const token = getToken();
    const WooCommerce = await getWooCommerce();
    if (website && token)
      axios
        .post(`${website}/wp-json/wp/v2/media`, formData, {
          headers: {
            accept: "application/json",
            Authorization: `Basic ${token}`,
            "Content-Type": `multipart/form-data`,
          },
        })
        .then(async (res) => {
          let { data } = res;
          if (data) {
            let media = [...allMedia];
            media.unshift(data);
            setSelectedImages([{ ...data }].concat([...selectedImages]));
            setAllMedia(media);
            setResultToShow("");
            setImageToUpload("");
            let sku = image.name.replace(/\.[^/.]+$/, "");
            if (sku?.includes("_")) sku = sku?.split("_")?.[0];
            console.log(sku, "<<<< SKU SEARCH");
            // let obj = getSkuObj(sku);
            let obj = await fetchProduct(sku);
            if (obj && WooCommerce) {
              let images = obj.images || [];
              WooCommerce.put(`products/${obj.id}`, {
                images: images.concat({ src: data.source_url }),
              })
                .then((res) => {
                  res = res.data;
                  if (res.id)
                    dispatch(
                      updateAllSku({
                        id: res.id,
                        images: res.images,
                        sku: res.sku,
                      })
                    );
                })
                .catch((e) => console.log(getAxiosError(e)))
                .finally(() => {
                  setUploadingImage(false);
                });
            } else {
              setUploadingImage(false);
            }
            setValue(1);
          }
          // if (data.source_url) setImageURL(data.source_url);
        })
        .catch((e) => {
          console.log(getAxiosError(e));
          e = getAxiosError(e);
          if (e.message) {
            dispatch(
              setGlobalToast({
                show: true,
                severity: "error",
                message: e.message,
                title: "",
              })
            );
            // setToast({
            //   show: true,
            //   severity: "error",
            //   message: e.message,
            //   title: "",
            // });
          }
          setUploadingImage(false);
        });
    // .finally((e) => {
    //   setUploadingImage(false);
    // });
  };

  const fetchProduct = async (sku) => {
    try {
      let website = await getWebsite();
      let token = await getToken();
      let { data } = await axios({
        url: `${website}/wp-json/wc/v3/products?sku=${new URLSearchParams(sku)
          .toString()
          .replace("=", "")}`,
        headers: { Authorization: `Basic ${token}` },
      });
      console.log(data, "FETCHED PRODUCT");
      if (isArrayWithValues(data) && !data[0].error) return data?.[0];
    } catch (error) {
      console.log(getAxiosError(error));
    }
  };

  const getSkuObj = (title) => {
    if (isArrayWithValues(reduxAllSku)) {
      let length = reduxAllSku.length;
      for (let i = 0; i < length; i++) {
        let obj = reduxAllSku[i];
        if (obj.sku && title.toLowerCase() === obj.sku.toLowerCase()) {
          return obj;
        }
      }
    }
  };

  const onFileDrop = async (files) => {
    const types = ["image/jpeg", "image/png", "image/gif", "image/x-icon"];
    if (files) {
      let array = [];
      let rowFiles = [];
      let hasUnsupportedFileType = false;
      let hasLargeFiles = false;
      for (let i = 0; i < files.length; i++) {
        let { size, name, type } = files[i];
        // if (!types.includes(type)) {
        //   hasUnsupportedFileType = true;
        //   continue;
        // }
        //also change size below in message
        if (size > 5000000) {
          hasLargeFiles = true;
          continue;
        }
        array.push({
          url: URL.createObjectURL(files[i]),
          type,
          file: files[i],
        });
        rowFiles.push(files[i]);
      }
      if (
        hasLargeFiles
        // || hasUnsupportedFileType
      ) {
        dispatch(
          setGlobalToast({
            show: true,
            severity: "error",
            message: `Skipping ${
              hasUnsupportedFileType ? "Unsupported" : ""
            } files ${hasUnsupportedFileType && hasLargeFiles ? "and" : ""} ${
              hasLargeFiles ? "greater than 5 mb" : ""
            }!`,
          })
        );
        // setToast({
        //   show: true,
        //   severity: "error",
        //   message: `Skipping ${hasUnsupportedFileType ? "Unsupported" : ""
        //     } files ${hasUnsupportedFileType && hasLargeFiles ? "and" : ""} ${hasLargeFiles ? "greater than 15 mb" : ""
        //     }!`,
        // });
      }
      if (isArrayWithValues(array)) {
        // console.log(array);
        setDroppedImages(array);
        setDroppedRowFiles(rowFiles);
        setSelectedEditingImage("0");
        let cropObj = {},
          zoomObj = {},
          rotateObj = {},
          croppedAreaPixelesObj = {};

        array.map((value, i) => {
          cropObj[i.toString()] = { x: 0, y: 0 };
          zoomObj[i.toString()] = 1;
          rotateObj[i.toString()] = 0;
          croppedAreaPixelesObj[i.toString()] = {};
        });
        setCrop(cropObj);
        setZoom(zoomObj);
        setRotate(rotateObj);
        setCroppedAreaPixels(croppedAreaPixelesObj);
      }
    }

    // setDroppedImage(URL.createObjectURL(e));
  };
  const onRotate = useCallback((value, direction) => {
    if (value) return setRotate(value);
    let temp;
    if (direction === "left") {
      if (rotate <= 90) temp = 0;
      else if (rotate <= 180) temp = 90;
      else if (rotate <= 270) temp = 180;
      else if (rotate <= 360) temp = 270;
    } else if (direction === "right") {
      if (rotate < 90) temp = 90;
      else if (rotate < 180) temp = 180;
      else if (rotate < 270) temp = 270;
      else if (rotate <= 360) temp = 360;
    }
    setRotate((state) => ({ ...state, [selectedEditingImage]: temp }));
  });
  const onDelete = async () => {
    let ids = selectedImages.map((i) => i.id);
    for (const i in ids) {
      await deleteMedia({
        id: ids[i],
        setLoading: setIsDeleting,
        dispatch,
        index: i,
        length: ids.length,
        toggleDeleteAlert,
        setSelectedImages,
      });
    }
  };

  const onSearch = (value) => {
    setParams({ ...defaultParams, search: value });
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      dispatch(getMediaLibrary({ ...defaultParams, search: value }, true));
    }, 500);
  };

  const onReset = () => {
    setCrop((state) => ({ ...state, [selectedEditingImage]: { x: 0, y: 0 } }));
    setRotate((state) => ({ ...state, [selectedEditingImage]: 0 }));
    setZoom((state) => ({ ...state, [selectedEditingImage]: 1 }));
  };

  const onCancel = () => {
    setDroppedImages([]);
    setDroppedRowFiles([]);
  };

  const cropperSize = isMobile ? "300px" : "400px";
  // info image
  const [infoImage, setInfoImage] = useState(false);
  return (
    <>
      <input
        type="file"
        multiple
        id="file"
        // accept="image/*"
        onChange={(e) => onFileDrop(e.target.files)}
        ref={inputFile}
        style={{ display: "none" }}
      />
      {/* <Snackbar
        open={toast.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        TransitionComponent={Slide}
        style={{ marginRight: "2vw" }}
        onClose={() => setToast({ ...toast, show: false })}
      >
        <Alert severity={toast.severity} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar> */}
      <Dialog
        open={open}
        onClose={() => onChange(false)}
        // fullScreen={fullScreen}
        keepMounted={false}
        maxWidth="lg"
        scroll="paper"
        fullWidth={true}
        fullScreen={isMobile}
        sx={
          isMobile
            ? {}
            : {
                width: "100%",
                minHeight: "100vh",
                height: "100%",
                ".MuiDialog-paper": {
                  padding: "3px 5px",
                  borderRadius: "30px",
                },
              }
        }
      >
        <DialogTitle id="scroll-dialog-title">
          <Box
            sx={{
              margin: 0,
              // padding: 14,
              // position: "fixed",
              // top: 0,
              zIndex: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              // backgroundColor,
            }}
          >
            <Typography
              variant="h5"
              // color="background"
              style={{
                margin: "5px 0px",
                // padding: 14,
                // position: "-webkit-sticky",
                // position: "sticky",
                // top: 0,
                // zIndex: 2,
                // backgroundColor,
              }}
            >
              Select Library
            </Typography>
            <IconButton onClick={() => onChange(false)}>
              <Close />
            </IconButton>
          </Box>
          <Collapse in={selectedImages.length > 0}>
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography variant="h6">
                {selectedImages.length} Selected
              </Typography>
              <Stack direction="row" justifyContent={"flex-end"}>
                <Button
                  variant="text"
                  startIcon={<Delete />}
                  color="error"
                  onClick={() => toggleDeleteAlert(true)}
                >
                  Delete
                  {/* {isMobile ? "" : "Delete"} */}
                </Button>
                {setParentImages && (
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => onSelectPress()}
                    startIcon={<Check />}
                    color="primary"
                  >
                    Select
                  </Button>
                )}
              </Stack>
            </Stack>
          </Collapse>
        </DialogTitle>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            height: isMobile ? "auto" : "100%",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            style={{ borderWidth: 0 }}
            disableRipple
          >
            <Tab label="Upload Files" {...a11yProps(0)} />
            <Tab label="Media Library" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <DialogContent sx={{ p: { xs: 1, sm: 2 } }} dividers={"paper"}>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              flexGrow: 1,
              // overflow: "hidden",
              margin: 0,
            }}
          >
            <Box sx={{}}>
              <TabPanel
                value={value}
                style={{
                  // minHeight: "60vh",
                  minHeight: "75vh",

                  justifyContent: "center",
                  textAlign: "center",
                }}
                index={0}
              >
                {isArrayWithValues(droppedImages) ? (
                  true ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={8}>
                        <Box
                          sx={{
                            // maxWidth: "280px",
                            margin: "auto",
                            height: cropperSize,
                            width: cropperSize,
                            position: "relative",
                          }}
                        >
                          {droppedImages[selectedEditingImage]?.type?.includes(
                            "image"
                          ) &&
                            (enable_crop ? (
                              <Cropper
                                image={
                                  droppedImages[selectedEditingImage]?.url ||
                                  "https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg"
                                }
                                // video={droppedImages[selectedEditingImage]?.url ||
                                //   "https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg"}
                                // image={imageToUpload}
                                crop={crop[selectedEditingImage]}
                                zoom={zoom[selectedEditingImage]}
                                rotation={rotate[selectedEditingImage]}
                                objectFit={"contain"}
                                aspect={
                                  aspect
                                    ? aspect?.width / aspect?.height
                                    : 4 / 4
                                }
                                onCropChange={(value) =>
                                  setCrop((state) => ({
                                    ...state,
                                    [selectedEditingImage]: value,
                                  }))
                                }
                                onCropComplete={(a, b) =>
                                  onCropComplete(a, b, selectedEditingImage)
                                }
                                onZoomChange={(value) =>
                                  setZoom((state) => ({
                                    ...state,
                                    [selectedEditingImage]: value,
                                  }))
                                }
                              />
                            ) : (
                              <img
                                src={droppedImages[selectedEditingImage]?.url}
                                style={{
                                  objectFit: "contain",
                                  aspect: aspect
                                    ? aspect?.width / aspect?.height
                                    : 4 / 4,

                                  height: cropperSize,
                                  width: cropperSize,
                                }}
                              />
                            ))}
                          {droppedImages[selectedEditingImage]?.type?.includes(
                            "video"
                          ) && (
                            <>
                              <video
                                style={{
                                  height: cropperSize,
                                  width: cropperSize,
                                  borderRadius: "5px",
                                  objectFit: "contain",
                                  // border:
                                  //   selectedEditingImage == i
                                  //     ? "1px solid rgba(0,0,0,0.3)"
                                  //     : "1px solid transparent",
                                  // boxShadow:
                                  //   selectedEditingImage == i
                                  //     ? "1px 1px 2px rgba(0,0,0,0.3)"
                                  //     : "0px 0px 0px rgba(0,0,0,0.3)",
                                  // cursor: "pointer",
                                  // marginTop: "12px",
                                }}
                                controls
                              >
                                <source
                                  src={droppedImages[selectedEditingImage]?.url}
                                  type={
                                    droppedImages[selectedEditingImage]?.type
                                  }
                                />
                                {/* <source src="movie.ogg" type="video/ogg" /> */}
                                Your browser does not support the video tag.
                              </video>

                              {/* <Cropper
                            // image={
                            //   droppedImages[selectedEditingImage]?.url ||
                            //   "https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg"
                            // }
                            video={droppedImages[selectedEditingImage]?.url ||
                              "https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg"}
                            // image={imageToUpload}
                            crop={crop[selectedEditingImage]}
                            zoom={zoom[selectedEditingImage]}
                            rotation={rotate[selectedEditingImage]}
                            objectFit="horizontal-cover"
                            aspect={4 / 4}
                            onCropChange={(value) =>
                              setCrop((state) => ({
                                ...state,
                                [selectedEditingImage]: value,
                              }))
                            }
                            onCropComplete={(a, b) =>
                              onCropComplete(a, b, selectedEditingImage)
                            }
                            onZoomChange={(value) =>
                              setZoom((state) => ({
                                ...state,
                                [selectedEditingImage]: value,
                              }))
                            }
                          /> */}
                            </>
                          )}
                          {droppedImages[selectedEditingImage]?.type?.includes(
                            "application"
                          ) && (
                            <Box>
                              <Box
                                sx={{
                                  backgroundColor: "#ccc",
                                  borderRadius: "3px",
                                  display: "flex",
                                  justifyContent: "center",
                                  padding: "100px 100px",
                                }}
                              >
                                <ArticleOutlined
                                  style={{
                                    height: "50px",
                                    width: "50px",
                                    color: "#000",
                                  }}
                                />
                              </Box>
                              <Typography>
                                {droppedImages[selectedEditingImage]?.file
                                  ?.name || ""}
                              </Typography>
                              {/* <Cropper
                            image={
                              droppedImages[selectedEditingImage]?.url ||
                              "https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg"
                            }
                            // video={droppedImages[selectedEditingImage]?.url ||
                            //   "https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg"}
                            // image={imageToUpload}
                            crop={crop[selectedEditingImage]}
                            zoom={zoom[selectedEditingImage]}
                            rotation={rotate[selectedEditingImage]}
                            objectFit="horizontal-cover"
                            aspect={aspect || 4 / 4}
                            onCropChange={(value) =>
                              setCrop((state) => ({
                                ...state,
                                [selectedEditingImage]: value,
                              }))
                            }
                            onCropComplete={(a, b) =>
                              onCropComplete(a, b, selectedEditingImage)
                            }
                            onZoomChange={(value) =>
                              setZoom((state) => ({
                                ...state,
                                [selectedEditingImage]: value,
                              }))
                            }
                          /> */}
                            </Box>
                          )}
                        </Box>
                        <Stack
                          direction="row"
                          justifyContent={"center"}
                          spacing={3}
                          mt={3}
                          flexWrap="wrap"
                          px={3}
                        >
                          {droppedImages.map((value, i) => {
                            return (
                              <>
                                {droppedImages[i]?.type?.includes("image") && (
                                  <img
                                    onLoad={(e) => onImgLoad(e, i)}
                                    src={droppedImages[i]?.url}
                                    style={{
                                      height: "70px",
                                      width: "70px",
                                      borderRadius: "5px",
                                      objectFit: "contain",
                                      border:
                                        selectedEditingImage == i
                                          ? "1px solid rgba(0,0,0,0.3)"
                                          : "1px solid transparent",
                                      boxShadow:
                                        selectedEditingImage == i
                                          ? "1px 1px 2px rgba(0,0,0,0.3)"
                                          : "0px 0px 0px rgba(0,0,0,0.3)",
                                      cursor: "pointer",
                                      marginTop: "12px",
                                    }}
                                    onClick={() =>
                                      setSelectedEditingImage(`${i}`)
                                    }
                                  />
                                )}
                                {droppedImages[i]?.type?.includes("video") && (
                                  <>
                                    <video
                                      style={{
                                        height: "70px",
                                        width: "70px",
                                        borderRadius: "5px",
                                        objectFit: "contain",
                                        border:
                                          selectedEditingImage == i
                                            ? "1px solid rgba(0,0,0,0.3)"
                                            : "1px solid transparent",
                                        boxShadow:
                                          selectedEditingImage == i
                                            ? "1px 1px 2px rgba(0,0,0,0.3)"
                                            : "0px 0px 0px rgba(0,0,0,0.3)",
                                        cursor: "pointer",
                                        marginTop: "12px",
                                      }}
                                    >
                                      <source
                                        src={droppedImages[i]?.url}
                                        type={droppedImages[i]?.type}
                                      />
                                      {/* <source src="movie.ogg" type="video/ogg" /> */}
                                      Your browser does not support the video
                                      tag.
                                    </video>

                                    {/* <img
                                      onLoad={(e) => onImgLoad(e, i)}
                                      src={droppedImages[i]?.url}
                                      style={{
                                        height: "70px",
                                        width: "70px",
                                        borderRadius: "5px",
                                        objectFit: "contain",
                                        border:
                                          selectedEditingImage == i
                                            ? "1px solid rgba(0,0,0,0.3)"
                                            : "1px solid transparent",
                                        boxShadow:
                                          selectedEditingImage == i
                                            ? "1px 1px 2px rgba(0,0,0,0.3)"
                                            : "0px 0px 0px rgba(0,0,0,0.3)",
                                        cursor: "pointer",
                                        marginTop: "12px",
                                      }}
                                      onClick={() => setSelectedEditingImage(`${i}`)}
                                    />  */}
                                  </>
                                )}
                              </>
                            );
                          })}
                        </Stack>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Box sx={{ textAlign: "left" }}>
                          <Typography variant="h5">Rotate</Typography>
                          <Stack direction={"row"} alignItems="center" mt={4}>
                            <IconButton
                              sx={{
                                backgroundColor: "primary.main",
                                color: "#fff",
                                ":hover": {
                                  backgroundColor: "primary.main",
                                },
                              }}
                              size="small"
                              onClick={() => onRotate(false, "left")}
                            >
                              <RotateLeft />
                            </IconButton>
                            <IconButton
                              sx={{
                                backgroundColor: "primary.main",
                                color: "#fff",
                                ml: 2,
                                ":hover": {
                                  backgroundColor: "primary.main",
                                },
                              }}
                              disableFocusRipple
                              size="small"
                              onClick={() => onRotate(false, "right")}
                            >
                              <RotateRight />
                            </IconButton>
                            <Slider
                              sx={{ ml: 4 }}
                              value={rotate[selectedEditingImage]}
                              onChange={(e, value) =>
                                setRotate((state) => ({
                                  ...state,
                                  [selectedEditingImage]: value,
                                }))
                              }
                              max={360}
                              min={0}
                            />
                          </Stack>
                          <Typography mt={3} variant="h5">
                            Zoom
                          </Typography>
                          <Stack direction={"row"} alignItems="center" mt={2}>
                            <Slider
                              sx={{ ml: 2, mr: 2 }}
                              value={zoom[selectedEditingImage] * 100}
                              onChange={(e, value) =>
                                setZoom((state) => ({
                                  ...state,
                                  [selectedEditingImage]: value / 100,
                                }))
                              }
                              max={100}
                              min={0}
                            />
                          </Stack>
                          <Stack direction="row" mt={7}>
                            <LoadingButton
                              loading={uploadingImage}
                              loadingPosition="start"
                              // loadingIndicator={uploadedInfo}
                              variant="contained"
                              fullWidth
                              // endIcon={<FileUploadOutlinedIcon />}
                              sx={{}}
                              onClick={cropImage}
                            >
                              {uploadingImage ? uploadedInfo : "Upload"}
                            </LoadingButton>
                            <Button
                              variant="text"
                              fullWidth
                              // endIcon={<FileUploadOutlinedIcon />}
                              sx={{ ml: 3 }}
                              onClick={onReset}
                              disabled={uploadingImage}
                            >
                              Reset
                            </Button>
                          </Stack>
                          {uploadFailed && (
                            <Typography fontSize="12px" color="error" my={2}>
                              Uploading file failed. Please upload again!
                            </Typography>
                          )}
                          <Button
                            variant="outlined"
                            fullWidth
                            color="error"
                            // endIcon={<FileUploadOutlinedIcon />}
                            sx={{ mt: 4 }}
                            disabled={uploadingImage}
                            onClick={onCancel}
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  ) : (
                    <Box justifyContent={"center"}>
                      <img
                        src={resultToShow}
                        style={{
                          height: cropperSize,
                          width: cropperSize,
                          backgroundColor: "transparent",
                        }}
                      />
                      <Stack
                        direction="row"
                        mt={7}
                        margin="auto"
                        maxWidth={"500px"}
                      >
                        <LoadingButton
                          loading={uploadingImage}
                          variant="contained"
                          fullWidth
                          // endIcon={<FileUploadOutlinedIcon />}
                          sx={{}}
                          onClick={uploadImage}
                        >
                          Upload
                        </LoadingButton>
                        <Button
                          variant="outlined"
                          // color="grey.400"
                          fullWidth
                          // endIcon={<FileUploadOutlinedIcon />}
                          sx={{ ml: 3 }}
                          onClick={() => {
                            setResultToShow("");
                            setImageToUpload("");
                          }}
                        >
                          Cancel
                        </Button>
                      </Stack>
                    </Box>
                  )
                ) : (
                  // <FileUploader
                  //   handleChange={onFileDrop}
                  //   name="file"
                  //   types={["JPG", "PNG"]}
                  //   children={
                  //   }
                  // />
                  <>
                    <Stack
                      direction={"row"}
                      sx={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        height: "100%",
                        border: "2px dashed",
                        padding: "14px 6px",
                        textAlign: "left",
                        maxWidth: "400px",
                        borderRadius: "10px",
                        margin: "auto",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "rgba(0,0,0,0.1)",
                        },
                      }}
                      onClick={() => inputFile.current.click()}
                    >
                      <Box
                        sx={{
                          height: "80px",
                          width: " 80px",
                          backgroundColor: "primary.main",
                          borderRadius: "50%",

                          position: "relative",
                        }}
                      >
                        <CloudUpload
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      </Box>
                      <div style={{ marginLeft: 5 }}>
                        <h3 style={{ margin: "0px" }}>Drop or select file</h3>
                        <span style={{ margin: "0px" }}>
                          drop files here form your machine
                          {/* drop files here or drag through your machine */}
                        </span>
                      </div>
                    </Stack>
                    {hideUploadInfo ? null : (
                      <Stack
                        // direction={"row"}
                        sx={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                          // border: "2px dashed",
                          padding: "14px 6px",
                          textAlign: "left",
                          // maxWidth: "400px",
                          borderRadius: "10px",
                          margin: "auto",
                          // mt: 2,
                          // cursor: "pointer",
                          // "&:hover": {
                          //   backgroundColor: "rgba(0,0,0,0.1)",
                          // },
                        }}

                        // onClick={() => inputFile.current.click()}
                      >
                        {Object.keys(uploadInfo || {})?.map((key) => {
                          return (
                            <Stack key={key} sx={{ mt: 3 }}>
                              <Typography variant="h6">
                                {formatServerValue(key)}
                              </Typography>
                              <ol>
                                {uploadInfo?.[key]?.map((str) => {
                                  return <li>{str}</li>;
                                })}
                              </ol>
                            </Stack>
                          );
                        })}
                      </Stack>
                    )}
                  </>
                )}
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div ref={imagesRef}>
                  <Stack direction="row" sx={{ overflow: "hidden" }}>
                    <Box>
                      <Grid container spacing={3} sx={{}}>
                        <Grid item>
                          <TextField
                            placeholder="Search"
                            size="small"
                            value={params.search}
                            onChange={(e) => onSearch(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Search />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  {fetchingMedia ? (
                                    <CircularProgress
                                      style={{ height: "16px", width: "16px" }}
                                    />
                                  ) : (
                                    <Box
                                      style={{ height: "16px", width: "16px" }}
                                    />
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Grid>
                        <Grid item xs />
                        <Grid item xs sx={{}}>
                          <Stack
                            direction={"row"}
                            alignItems="center"
                            justifyContent="flex-end"
                          >
                            <Button
                              variant="text"
                              sx={{ mx: 3 }}
                              onClick={() => setSelectedImages(allMedia)}
                              disabled={
                                selectedImages.length === allMedia.length
                              }
                            >
                              Select All
                            </Button>
                            <Button
                              variant="text"
                              sx={{}}
                              onClick={() => setSelectedImages([])}
                              disabled={selectedImages.length === 0}
                            >
                              Reset Selection
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                      <Stack direction={"row"} spacing={3}>
                        <Box
                          sx={{
                            minHeight: "calc(100vh - 250px)",
                            overflowX: "scroll",
                            height: "calc(100vh - 250px)",
                            // minHeight: "80vh",
                            // overflowX: "scroll",
                            // height: "80vh",
                          }}
                        >
                          <ImageList
                            sx={{
                              // minHeight: "80vh",
                              // overflowX: "scroll",
                              // height: "80vh",
                              flex: 1,
                            }}
                            cols={
                              isMobile
                                ? 2
                                : isIpad
                                ? 3
                                : isLg
                                ? 4
                                : // : isObjWithValues(infoImage)
                                  // ? 4
                                  5
                            }
                          >
                            {allMedia.length > 0 &&
                              allMedia.map(
                                (item, index) => (
                                  <ImageThumbnail
                                    key={index}
                                    selectedImages={selectedImages}
                                    setInfoImage={setInfoImage}
                                    infoImage={infoImage}
                                    imagePress={imagePress}
                                    index={index}
                                    isMobile={isMobile}
                                    item={item}
                                    getThumbnail={getThumbnail}
                                  />
                                )

                                // {
                                //   let { date } = item;
                                //   const thumbnail = getThumbnail(item);
                                //   const url =
                                //     (thumbnail && thumbnail.source_url) ||
                                //     item.source_url;
                                //   const name = item.title && item.title.rendered;
                                //   const isSelected = selectedImages.find(
                                //     (i) => i.id === item.id
                                //   );
                                //   let mime_type = item?.mime_type || "";
                                //   const isError = false;
                                //   let size =
                                //     item.media_details &&
                                //     item.media_details.filesize;
                                //   if (!url || isError) return null;
                                //   return (
                                //     <ImageListItem
                                //       key={item.id}
                                //       sx={{
                                //         margin: "5px 2px",
                                //         // boxShadow: "1px 1px 2px 0px rgba(0,0,0,0.5)",
                                //         // borderWidth: "1px",
                                //         // border: "2px solid #E0E0D7",
                                //         borderRadius: "6px",
                                //         overflow: "hidden",
                                //         transition: "height 0.5s",
                                //       }}
                                //     >
                                //       <Box
                                //         sx={{
                                //           position: "relative",
                                //           border: `1px solid ${"secondary"}`,
                                //         }}
                                //       >
                                //         {isSelected ? (
                                //           <CheckBoxIcon
                                //             style={{
                                //               position: "absolute",
                                //               backgroundColor: "#fff",
                                //             }}
                                //           // color="secondary"
                                //           />
                                //         ) : null}

                                //         <Info
                                //           style={{
                                //             position: "absolute",
                                //             backgroundColor: "#fff",
                                //             borderRadius: "50%",
                                //             right: "0",
                                //             cursor: "pointer",
                                //             zIndex: 12,
                                //           }}
                                //           onClick={(e) => {
                                //             e.stopPropagation();
                                //             setInfoImage(item);
                                //             console.log(infoImage);
                                //           }}
                                //         // color="secondary"
                                //         />
                                //         {/* <LazyLoadImage
                                //         src={
                                //           `${url}` ||
                                //           "/static/img/woocommerce_placeholder.png"
                                //         }
                                //         style={{
                                //           height: "100%",
                                //           width: "100%",
                                //           borderRadius: "6px",
                                //           border: "0.3px solid #E0E0D7",
                                //           flex: 1,
                                //         }}
                                //         effect="opacity"
                                //       /> */}
                                //         {mime_type.includes("video") && (
                                //           <Box
                                //             sx={{
                                //               height: "100%",
                                //               width: "100%",
                                //             }}
                                //           >
                                //             <Box>
                                //               <video
                                //                 style={{
                                //                   height: "100%",
                                //                   width: "100%",
                                //                   borderRadius: "6px",
                                //                   border: "0.3px solid #E0E0D7",
                                //                 }}
                                //                 onClick={() =>
                                //                   imagePress(item, index)
                                //                 }
                                //               >
                                //                 {/* <source src="movie.mp4" type="video/mp4" /> */}
                                //                 <source
                                //                   src={
                                //                     `${url}` ||
                                //                     "/static/img/woocommerce_placeholder.png"
                                //                   }
                                //                   type={mime_type}
                                //                 />
                                //                 Your browser does not support the
                                //                 video tag.
                                //               </video>
                                //             </Box>
                                //           </Box>
                                //         )}
                                //         {mime_type.includes("application") && (
                                //           <Box
                                //             sx={{ height: "100%" }}
                                //             onClick={() =>
                                //               imagePress(item, index)
                                //             }
                                //           >
                                //             <Box>
                                //               <Box
                                //                 sx={{
                                //                   backgroundColor: "#ccc",
                                //                   borderRadius: "3px",
                                //                   display: "flex",
                                //                   justifyContent: "center",
                                //                   padding: "50px 50px",
                                //                 }}
                                //               >
                                //                 <ArticleOutlined
                                //                   style={{
                                //                     height: "50px",
                                //                     width: "50px",
                                //                   }}
                                //                 />
                                //               </Box>
                                //             </Box>
                                //           </Box>
                                //         )}

                                //         {mime_type.includes("image") && (
                                //           <LazyLoadImage
                                //             src={
                                //               `${url}` ||
                                //               "/static/img/woocommerce_placeholder.png"
                                //             }
                                //             // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                //             alt={
                                //               item.title && item.title.rendered
                                //             }
                                //             // onerror="this.style.display='none'"
                                //             loading="lazy"
                                //             style={{
                                //               height: "100%",
                                //               width: "100%",
                                //               borderRadius: "6px",
                                //               border: "0.3px solid #E0E0D7",
                                //             }}
                                //             onClick={() =>
                                //               imagePress(item, index)
                                //             }
                                //           // onError={() => (isError = true)}
                                //           />
                                //         )}
                                //       </Box>
                                //       <Typography
                                //         fontSize={isMobile ? "11px" : "12px"}
                                //         sx={{ mt: 0.5 }}
                                //         color="text.secondary"
                                //       >
                                //         {name}
                                //       </Typography>
                                //       <Stack
                                //         direction="row"
                                //         alignItems={"center"}
                                //         justifyContent="space-between"
                                //         sx={{ mt: 0.8 }}
                                //       >
                                //         <Typography
                                //           fontSize={isMobile ? "11px" : "12px"}
                                //           color="text.secondary"
                                //         >
                                //           {/* Size:{" "} */}
                                //           {Number(
                                //             parseFloat(
                                //               size / 1024 / 1024
                                //             ).toFixed(2)
                                //           )}{" "}
                                //           mb
                                //         </Typography>
                                //         <Typography
                                //           fontSize={isMobile ? "11px" : "12px"}
                                //           color="text.secondary"
                                //         >
                                //           {formatDate(
                                //             new Date(date),
                                //             "dd:mm:yyyy hh:mm"
                                //           )}
                                //         </Typography>
                                //       </Stack>
                                //     </ImageListItem>
                                //   );
                                // }
                              )}
                          </ImageList>
                          <div
                            style={{
                              justifyContent: "center",
                              alignSelf: "center",
                              flex: 0,
                              textAlign: "center",
                            }}
                          >
                            <LoadingButton
                              variant="contained"
                              size="large"
                              sx={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                flex: 0,
                              }}
                              onClick={() => onLoadMoreMedia()}
                              loading={fetchingMedia}
                            >
                              Load more images
                            </LoadingButton>
                          </div>
                        </Box>
                      </Stack>
                    </Box>
                    {/* <Collapse
                    in={isObjWithValues(infoImage)}
                    orientation="horizontal"
                  >
                  </Collapse> */}
                    {!isIpad && (
                      <ImageInfo
                        isIpad={isIpad}
                        infoImage={infoImage}
                        setInfoImage={setInfoImage}
                        getThumbnail={getThumbnail}
                        navigator={navigator}
                        dispatch={dispatch}
                      />
                    )}
                    {isIpad && (
                      <Drawer
                        sx={{
                          zIndex: 120000,
                          "	.MuiDrawer-paperAnchorBottom": {
                            borderTopLeftRadius: 9,
                            borderTopRightRadius: 9,
                          },
                          // "	.MuiDrawer-paperAnchorRight": {
                          //   height: "calc(100vh - 20px)",
                          //   margin: "10px",
                          //   borderRadius: 4,
                          // },
                        }}
                        anchor={"bottom"}
                        open={isObjWithValues(infoImage)}
                        onClose={() => {
                          setInfoImage(false);
                        }}
                      >
                        <ImageInfo
                          isIpad={isIpad}
                          // isIpad={isIpad}
                          infoImage={infoImage}
                          setInfoImage={setInfoImage}
                          getThumbnail={getThumbnail}
                          navigator={navigator}
                          dispatch={dispatch}
                        />
                      </Drawer>
                    )}
                  </Stack>
                </div>
              </TabPanel>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={deleteAlert}
        onClose={() => toggleDeleteAlert(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          Delete ({selectedImages.length}) selected media permanently?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You won't be able to recover them.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => toggleDeleteAlert(false)}>Close</Button>
          <LoadingButton
            loading={isDeleting}
            color="error"
            variant="contained"
            onClick={onDelete}
            autoFocus
          >
            delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ImageInfo = ({
  infoImage,
  setInfoImage,
  getThumbnail,
  navigator,
  dispatch,
  isIpad,
}) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    alt_text: "",
    title: "",
    caption: "",
    description: "",
  });
  const editForm = (payload) => {
    setForm((state) => ({ ...state, ...payload }));
  };

  useEffect(() => {
    editForm({
      alt_text: infoImage?.alt_text,
      title: infoImage?.title?.rendered,
      caption: infoImage?.caption?.rendered,
      description: infoImage?.description?.rendered,
    });
  }, [infoImage]);

  const onUpdate = async () => {
    setLoading(true);
    try {
      let website = await getWebsite();
      let token = await getToken();
      let res = await axios({
        url: `${website}/wp-json/wp/v2/media/${infoImage?.id}`,
        headers: {
          Authorization: "Basic " + token,
        },
        method: "PUT",
        data: {
          ...form,
        },
      });
      // console.log(res);
      if (res?.data?.id) {
        dispatch(
          setGlobalSnackbar({
            show: true,
            message: "Image details updated successfully",
            severity: "success",
          })
        );
      }
    } catch (error) {
      console.log(error);
      dispatch(
        setGlobalSnackbar({
          show: true,
          message: "Unable to update Image details!",
          severity: "error",
        })
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        width: isObjWithValues(infoImage) ? "100%" : "0%",
        maxWidth: isIpad ? null : "350px",
        padding: "15px",
        paddingRight: 0,
        transition: `all ${isObjWithValues(infoImage) ? "0.5s" : "0.2s"}`,
        // overflow: "hidden",
        position: "sticky",
        // px: "24px",
        minHeight: "calc(100vh - 235px)",
        overflowX: "scroll",
        height: "calc(100vh - 235px)",
        md: {
          maxWidth: null,
          padding: "20px",
        },
      }}
    >
      <Box sx={{ px: 1 }}></Box>
      <Stack
        justifyContent={"space-between"}
        direction="row"
        alignItems="center"
      >
        <Typography variant="h5">Attachment Details</Typography>
        <IconButton
          onClick={() => {
            setInfoImage(false);
          }}
        >
          <Close />
        </IconButton>
      </Stack>
      {isObjWithValues(infoImage) && (
        <Stack justifyContent={"center"} alignItems="center">
          {infoImage?.mime_type?.includes("image") && (
            <img
              src={
                getThumbnail(infoImage)?.source_url ||
                "/static/img/woocommerce_placeholder.png"
              }
              style={{
                width: "100%",
                maxWidth: "350px",
                objectFit: "contain",
                borderRadius: "14px",
                marginBottom: "26px",
              }}
              // style={{ height: "170px", width: "170px" }}
            />
          )}
          {infoImage?.mime_type.includes("video") && (
            <Box
              sx={{
                height: "100%",
                maxWidth: "350px",
              }}
            >
              <Box>
                <video
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "6px",
                    border: "0.3px solid #E0E0D7",
                  }}
                  controls
                  // onClick={() => imagePress(item, index)}
                >
                  {/* <source src="movie.mp4" type="video/mp4" /> */}
                  <source
                    src={
                      `${infoImage?.source_url}` ||
                      "/static/img/woocommerce_placeholder.png"
                    }
                    type={infoImage?.mime_type}
                  />
                  Your browser does not support the video tag.
                </video>
              </Box>
            </Box>
          )}
          {infoImage?.mime_type.includes("application") && (
            <Box
              sx={{ height: "100%" }}
              // onClick={() => imagePress(item, index)}
            >
              <Box>
                <Box
                  sx={{
                    backgroundColor: "#ccc",
                    borderRadius: "3px",
                    display: "flex",
                    justifyContent: "center",
                    padding: "50px 50px",
                  }}
                >
                  <ArticleOutlined style={{ height: "50px", width: "50px" }} />
                </Box>
              </Box>
            </Box>
          )}

          {/* {mime_type.includes("image") && <LazyLoadImage
                          onClick={() => imagePress(item, index)}
                          src={
                            `${url}` ||
                            "/static/img/woocommerce_placeholder.png"
                          }
                          style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "6px",
                            border: "0.3px solid #E0E0D7",
                            flex: 1,
                          }}
                          effect="opacity"
                        />} */}
        </Stack>
      )}
      <Stack spacing={3}>
        <Typography variant="h6">{infoImage.title?.rendered || ""}</Typography>
        <Typography>
          {formatDate(new Date(infoImage.date), "relative_date")}
        </Typography>
        <Typography>
          {" "}
          {Number(
            parseFloat(
              infoImage.media_details &&
                infoImage.media_details.filesize / 1024 / 1024
            ).toFixed(2)
          )}{" "}
          mb
        </Typography>
      </Stack>
      <Box sx={{ height: "10px" }} />
      <Divider />
      <Box sx={{ height: "10px" }} />
      <Stack spacing={3}>
        <TextField
          value={form.alt_text}
          onChange={(e) => editForm({ alt_text: e.target.value })}
          label="ALT TEXT"
          helperText={
            "Learn how to describe the purpose of the image(opens in a new tab). Leave empty if the image is purely decorative."
          }
        />
        <TextField
          value={form.title}
          onChange={(e) => editForm({ title: e.target.value })}
          label="TITLE"
        />
        <TextField
          value={form.caption}
          onChange={(e) => editForm({ caption: e.target.value })}
          rows={3}
          multiline
          label="CAPTION"
        />
        <TextField
          value={form.description}
          onChange={(e) => editForm({ description: e.target.value })}
          rows={3}
          multiline
          label="DESCRIPTION"
        />
        <Label>File URL</Label>
        <TextField
          // disabled
          value={infoImage.source_url}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  size="small"
                  onClick={() => {
                    navigator.clipboard.writeText(infoImage.source_url);
                    dispatch(
                      setGlobalToast({
                        show: true,
                        message: "Link copied on clipboard",
                        severity: "success",
                      })
                    );
                  }}
                >
                  <ContentCopy />
                </IconButton>
              </InputAdornment>
            ),
          }}
          // label="FILE URL"
        />
        <LoadingButton loading={loading} variant="contained" onClick={onUpdate}>
          Update Image Details
        </LoadingButton>
      </Stack>
    </Box>
  );
};

const ImageThumbnail = memo(
  ({
    item,
    getThumbnail,
    selectedImages,
    setInfoImage,
    infoImage,
    imagePress,
    index,
    isMobile,
    // getThumbnail
  }) => {
    let { date } = item;
    const thumbnail = getThumbnail(item);
    const url = (thumbnail && thumbnail.source_url) || item.source_url;
    const name = item.title && item.title.rendered;
    // const isSelected = selectedImages.find((i) => i.id === item.id);
    let mime_type = item?.mime_type || "";
    const isError = false;
    let size = item.media_details && item.media_details.filesize;
    const isSelected = useMemo(
      () => (
        <CheckBoxIcon
          style={{
            position: "absolute",
            backgroundColor: "#fff",
            display: selectedImages.find((i) => i.id === item.id)
              ? "block"
              : "none",
          }}
          // color="secondary"
        />
      ),
      [selectedImages]
    );
    if (!url || isError) return null;
    return (
      <ImageListItem
        key={item.id}
        sx={{
          margin: "5px 2px",
          // boxShadow: "1px 1px 2px 0px rgba(0,0,0,0.5)",
          // borderWidth: "1px",
          // border: "2px solid #E0E0D7",
          borderRadius: "6px",
          overflow: "hidden",
          transition: "height 0.5s",
        }}
      >
        <Box
          sx={{
            position: "relative",
            border: `1px solid ${"secondary"}`,
          }}
        >
          {/* {isSelected ? (
            <CheckBoxIcon
              style={{
                position: "absolute",
                backgroundColor: "#fff",
              }}
            // color="secondary"
            />
          ) : null} */}
          {isSelected}

          <Info
            style={{
              position: "absolute",
              backgroundColor: "#fff",
              borderRadius: "50%",
              right: "0",
              cursor: "pointer",
              zIndex: 12,
            }}
            onClick={(e) => {
              e.stopPropagation();
              setInfoImage(item);
              console.log(infoImage);
            }}
            // color="secondary"
          />
          {/* <LazyLoadImage
                                      src={
                                        `${url}` ||
                                        "/static/img/woocommerce_placeholder.png"
                                      }
                                      style={{
                                        height: "100%",
                                        width: "100%",
                                        borderRadius: "6px",
                                        border: "0.3px solid #E0E0D7",
                                        flex: 1,
                                      }}
                                      effect="opacity"
                                    /> */}
          {mime_type.includes("video") && (
            <Box
              sx={{
                height: "100%",
                width: "100%",
              }}
            >
              <Box>
                <video
                  style={{
                    height: "100%",
                    width: "100%",
                    borderRadius: "6px",
                    border: "0.3px solid #E0E0D7",
                  }}
                  onClick={() => imagePress(item, index)}
                >
                  {/* <source src="movie.mp4" type="video/mp4" /> */}
                  <source
                    src={`${url}` || "/static/img/woocommerce_placeholder.png"}
                    type={mime_type}
                  />
                  Your browser does not support the video tag.
                </video>
              </Box>
            </Box>
          )}
          {mime_type.includes("application") && (
            <Box
              sx={{ height: "100%" }}
              onClick={() => imagePress(item, index)}
            >
              <Box>
                <Box
                  sx={{
                    backgroundColor: "#ccc",
                    borderRadius: "3px",
                    display: "flex",
                    justifyContent: "center",
                    padding: "50px 50px",
                  }}
                >
                  <ArticleOutlined
                    style={{
                      height: "50px",
                      width: "50px",
                    }}
                  />
                </Box>
              </Box>
            </Box>
          )}

          {mime_type.includes("image") && (
            <LazyLoadImage
              src={`${url}` || "/static/img/woocommerce_placeholder.png"}
              // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title && item.title.rendered}
              // onerror="this.style.display='none'"
              loading="lazy"
              style={{
                height: "100%",
                width: "100%",
                borderRadius: "6px",
                border: "0.3px solid #E0E0D7",
              }}
              onClick={() => imagePress(item, index)}
              // onError={() => (isError = true)}
            />
          )}
        </Box>
        <Typography
          fontSize={isMobile ? "11px" : "12px"}
          sx={{ mt: 0.5 }}
          color="text.secondary"
        >
          {name}
        </Typography>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
          sx={{ mt: 0.8 }}
        >
          <Typography
            fontSize={isMobile ? "11px" : "12px"}
            color="text.secondary"
          >
            {/* Size:{" "} */}
            {Number(parseFloat(size / 1024 / 1024).toFixed(2))} mb
          </Typography>
          <Typography
            fontSize={isMobile ? "11px" : "12px"}
            color="text.secondary"
          >
            {formatDate(new Date(date), "dd:mm:yyyy hh:mm")}
          </Typography>
        </Stack>
      </ImageListItem>
    );
  }
);
const Label = styled(Typography)`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 3px;
  margin-top: 3px;
`;

export const deleteMedia = async (obj) => {
  let {
    id,
    setLoading,
    dispatch,
    index,
    length,
    toggleDeleteAlert,
    setSelectedImages,
  } = obj;
  try {
    setLoading(true);

    let website = getWebsite();
    let token = getToken();
    if (website && token)
      axios({
        url: `${website}/wp-json/wp/v2/media/${id}`,
        method: "DELETE",
        headers: {
          Authorization: `Basic ${token}`,
        },
        params: { force: true },
      })
        .then((res) => {
          if (Number(index) + 1 === length) {
            toggleDeleteAlert(false);
            setSelectedImages([]);
          }
          dispatch(deleteMediaInRedux(id));
        })
        .catch((e) => console.log(getAxiosError(e)))
        .finally(() => {
          setLoading(false);
        });
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

const ImageView = () => {
  return (
    <Grid item xs={2}>
      <img />
    </Grid>
  );
};

const getThumbnail = (item) => {
  return (
    item &&
    item.media_details &&
    item.media_details.sizes &&
    item.media_details.sizes.thumbnail
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default ProductMediaLibrary;

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  );

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL("image/png", 1);

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (file) => {
        resolve(file);
        // resolve(URL.createObjectURL(file));
      },
      "image/jpeg",
      1
    );
  });
}

const getImageDimensions = (url, rejectTimeout) =>
  new Promise((resolve, reject) => {
    let timer = null;

    const img = new Image();

    img.addEventListener("load", () => {
      if (timer) {
        clearTimeout(timer);
      }

      resolve({ width: img.width, height: img.height });
    });

    img.addEventListener("error", (event) => {
      if (timer) {
        clearTimeout(timer);
      }

      reject(`${event.type}: ${event.message}`);
    });

    img.src = url;

    if (rejectTimeout) {
      timer = setTimeout(() => reject("Timeout exception"), rejectTimeout);
    }
  });

const uploadInfo = {
  images: [
    "Dimensions: 800px * 800px or 1000px * 1000px",
    "Size :  Under 1MB",
    "Naming : Images Name Saved by their SKU. Ex.SKU.jpeg",
    "Images must be in jpeg(mandatory)",
  ],
  video: [
    "Dimensions: 800px * 800px or 1000px * 1000px",
    "Size :  Under 5MB",
    "Naming : Images Name Saved by their SKU. Ex.SKU.mp4",
  ],
};
