/* eslint-disable react-hooks/exhaustive-deps */
import {
  Add,
  AlternateEmail,
  CancelOutlined,
  CheckCircleOutline,
  Close,
  Delete,
  DoDisturb,
  Download,
  Email,
  ErrorTwoTone,
  InfoOutlined,
  Person,
} from "@mui/icons-material";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  a11yProps,
  backendApiCall,
  dialCodesObj,
  dialogStyle,
  formatDate,
  formatServerValue,
  getAuthToken,
  getAxiosError,
  getCustomer,
  getId,
  getInitials,
  getIsBackendMigrated,
  getRandomString,
  getRelativeTimeDifference,
  getRemainingTime,
  getStoreId,
  getTenantId,
  getToken,
  getWebsite,
  isArrayWithValues,
  isDev,
  isEmailValid,
  isObjWithValues,
  replaceTemplateVariable,
  secondaryLabel,
  sectionLabel,
  sleep,
  StyledTableRow,
  TabPanel,
  unformatServerValue,
  // token,
  validateNumber,
  websocketApiCall,
  workingCrm,
  formatDateDynamic,
  WEBSOCKET_NGROK_URL,
  verifyOTPMsg91,
  // website,
} from "../../../helper";

// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDigiPlans,
  paymentMethods,
} from "../../../redux/actions/productActions";
import { currencySymbols } from "../../pricing/Gold";
import axios from "axios";
import {
  DesktopDatePicker,
  DesktopDateTimePicker,
  LoadingButton,
  LocalizationProvider,
} from "@mui/lab";
import { commonDialCodes, dialCodes } from "../../crm/Add";
import { getDropdownData } from "../../../redux/actions/persistActions";
import {
  SET_CRM_DATA,
  updateCustomerInRedux,
} from "../../../redux/actions/crmActions";
import { isValidDate } from "../../../utils/dateFunctions";
import { getTempletes } from "../../../redux/actions/chatActions";
import { setGlobalToast } from "../../../redux/actions/helperActions";
import TempleteList from "../../components/TempleteList";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  addDigigoldGift,
  addDigigoldPlanCancel,
} from "../../../redux/actions/settingActions";
import { TotalCard } from "../../catalogs/CatalogHelper";
import { DigigoldIllustration, GoldCoin } from "../../../Svgs";
import { customerAnalyticCardsStyle } from "../Customers";
import SelectCustomer from "../../components/SelectCustomer";
import { fetchCustomers } from "../../products/AllProducts";
import { LoadingCustomerData } from "./Orders";
import { addUserActivity } from "../../../redux/actions/hubActions";
import { sendMessage } from "../../chat_new/apiFunctions";
import {
  PrimaryLabel,
  PrimaryLabelSmall,
  SecondaryLabelSmall,
} from "../../../helper/utility/Labels";
import HorizontalStack from "../../../helper/utility/HorizontalStack";
import { onSendSMS } from "../helpers/sms";
import {
  LEDGER_TYPES,
  formatLedgerForPDF,
  mergeRewardsIntoLedger,
} from "../../digital_gold/utils/ledger";
import { getJweroCustomerPhoneNumber } from "../utils";
import { removeFalsyValuesFromObject } from "../../../utils/js";
import { handleDownloadFromURL } from "../../../utils/file";
import { getBonusInfoOnPlanRedeem } from "../../digital_gold/utils/plan";
import { getTimeInMilliSeconds, isArray } from "jwero-javascript-utils";
import CustomChip from "../../components/CustomChip";
import { getCRMPhoneNumber } from "../../../utils/crm";

const goldPurities = [
  { label: "24KT (999.99)", value: "999.99" },
  { label: "24KT (999)", value: "999" },
  { label: "24KT (995)", value: "995" },
  { label: "22KT (916)", value: "916" },
  { label: "18KT (750)", value: "750" },
  { label: "14KT (583)", value: "583" },
  { label: "9KT (375)", value: "375" },
];
const doigiGoldTabs = [
  { title: "Plans subscribed" },
  { title: "Payments recieved" },
  { title: "Members" },
  // { title: "Missed payments" },
  // { title: "Cashbaack" },
];
const DigitalGold = ({
  customerInfo,
  setCustomerInfo,
  redirect = false,
  fromDigigold = false,
}) => {
  let [digiInfo, setDigiInfo] = useState({});
  let [analytics, setAnalytics] = useState({
    payment: 0,
    amount: 0,
    total_reward: 0,
    total_penalty: 0,
  });
  let [currency, setCurrency] = useState("");
  const [fetchingDigigold, setFetchingDigigold] = useState(false);
  let [currencyName, setCurrencyName] = useState("");
  const [ornateIntegrated, setOrnateIntegrated] = useState(false);
  const [goldPricing, setGoldPricing] = useState([]);
  const [allPlans, setAllPlans] = useState([]);
  const [manualPaymentOpen, setMenualPayment] = React.useState(false);
  const [cancelPlanOpen, setCancelPlanOpen] = React.useState(false);
  const [showSelectCustomer, setShowSelectCustomer] = useState(false);
  const [todayGoldRate, setTodayGoldRate] = useState(0);
  const paymentsMethods = useSelector((state) => state.product.PaymentMethod);
  const masterPricing = useSelector((state) => state.settings.masterPricing);
  const productSettings = useSelector(
    (state) => state.settings.productSettings
  );
  const allDigiPlans = useSelector((state) => state.product.digigold_plans);
  const reduxBranches = useSelector((state) => state.settings.branch);
  const fetching_digigold_plans = useSelector(
    (state) => state.product.fetching_digigold_plans
  );
  const ornateIntegration = useSelector(
    (state) => state.settings.ornateIntegration
  );
  const digigoldCancelledPlans = useSelector(
    (state) => state.settings.digigoldCancelledPlans
  );
  const [addMemberDialog, setAddMemberDialog] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [allMembers, setAllMembers] = useState([]);
  const [allMembersIds, setAllMembersIds] = useState([]);
  const [branches, setBranches] = useState([]);
  useEffect(() => {
    if (isObjWithValues(reduxBranches))
      setBranches(Object.values(reduxBranches));
  }, [reduxBranches]);

  useEffect(() => {
    if (!isObjWithValues(masterPricing) || !currencyName) return;
    let { gold_pricing } = masterPricing;
    let { type } = gold_pricing;
    let pricing = gold_pricing?.[currencyName]?.[type];
    let defaultPurity = gold_pricing?.[currencyName]?.default;
    if (isObjWithValues(pricing)) {
      let priceObj = {};
      // let sortedPricing = Math.max(
      //   ...Object.keys(pricing).map((i) => validateNumber(i))
      // );
      let sortedPricing = Object.keys(pricing)
        .map((i) => validateNumber(i))
        .sort((a, b) => b - a);
      for (let purity of sortedPricing)
        if (pricing[purity]?.rate) {
          priceObj.rate = pricing[purity].rate;
          priceObj.purity = purity;
          break;
        }
      if (pricing?.[defaultPurity]?.rate)
        setTodayGoldRate(pricing?.[defaultPurity]?.rate);
      else if (isObjWithValues(priceObj)) {
        setTodayGoldRate(
          Math.round(
            Number(999 / Number(priceObj.purity)) * Number(priceObj.rate)
          )
        );
      }
    }
  }, [masterPricing, currencyName]);

  const closeManualDialog = () => {
    setMenualPayment(false);
  };

  const openManualDialog = (text) => {
    setMenualPayment(text);
  };
  const closeCancelPlan = (props = {}) => {
    setCancelPlanOpen(false);
    let { cancelled_plan } = props;
    let digigold_details = customerInfo.digigold_details;

    if (isObjWithValues(digigold_details?.plans)) {
      let plans = digigold_details?.plans;
      let updatedPlans = {};
      let viewLedger;
      for (let plan_id in plans) {
        let plan = plans[plan_id];
        if (cancelled_plan?.plan_id + "" === plan?.product + "") {
          viewLedger = { ...plan, is_cancelled: cancelled_plan?.id };
          updatedPlans[plan_id] = viewLedger;
        } else updatedPlans[plan_id] = plan;
      }
      console.log(viewLedger, updatedPlans, customerInfo, {
        ...customerInfo,
        digigold_details: {
          ...customerInfo.digigold_details,
          plans: updatedPlans,
        },
      });
      setCustomerInfo({
        ...customerInfo,
        digigold_details: {
          ...customerInfo.digigold_details,
          plans: updatedPlans,
        },
      });
      if (viewLedger) setViewLedger(viewLedger);
    }
  };

  const openCancelPlan = (text) => {
    setCancelPlanOpen(text);
  };

  const tabChange = (a, e) => setSelectedTab(e);

  const openAddMemberDialog = () => setAddMemberDialog(true);
  const closeAddMemberDialog = () => setAddMemberDialog(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const goldPurities = [
    { label: "24KT (999.99)", value: "999.99" },
    { label: "24KT (999)", value: "999" },
    { label: "24KT (995)", value: "995" },
    { label: "22KT (916)", value: "916" },
    { label: "18KT (750)", value: "750" },
    { label: "14KT (583)", value: "583" },
    { label: "9KT (375)", value: "375" },
  ];

  useEffect(() => {
    if (ornateIntegration?.ornate_auth?.ip_port) setOrnateIntegrated(true);
    else setOrnateIntegrated(false);
  }, [ornateIntegration]);

  useEffect(() => {
    if (!isArrayWithValues(allDigiPlans)) dispatch(fetchDigiPlans());
  }, []);

  useEffect(() => {
    if (isArrayWithValues(allDigiPlans)) {
      let arr = [];
      for (let product of allDigiPlans) {
        let { meta_data, name, id, price, sku } = product;
        let plan = {
          plan_name: name,
          product: id,
          label: name,
          value: name,
          amount: price,
          sku,
        };
        if (!isArrayWithValues(meta_data)) continue;
        let metaKeys = [
          "digi_plan_type",
          // "jeweller_contribution",
          "digi_plan_duration",
          "plan_total_grams",
          "change_gold_rate",

          "allow_auto_increment",
          "unique_id_prefix",
          "unique_id_suffix",
        ];
        let temp = {
          digi_plan_type: "type",
          // "jeweller_contribution",
          digi_plan_duration: "duration",
          plan_total_grams: "weight",
          change_gold_rate: "change_gold_rate",

          allow_auto_increment: "allow_auto_increment",
          unique_id_prefix: "unique_id_prefix",
          unique_id_suffix: "unique_id_suffix",
        };
        for (let obj of meta_data)
          if (metaKeys.includes(obj.key)) plan[temp[obj.key]] = obj.value;
        arr.push(plan);
      }
      setAllPlans(arr);
    }
  }, [allDigiPlans]);

  useEffect(() => {
    let currency = "";
    if (isObjWithValues(productSettings))
      currency = productSettings.default_currency;
    if (isObjWithValues(masterPricing)) {
      let { gold_pricing } = masterPricing;
      if (gold_pricing[currency]) {
        let { type } = gold_pricing;
        let { enabled_purities } = gold_pricing[currency];
        if (type) {
          let purityObjects = gold_pricing[currency][type];
          let array = [];
          for (let purity in enabled_purities) {
            if (enabled_purities[purity]) {
              let obj = goldPurities.find((i) => i.value == purity);
              if (!obj) continue;
              array.push({ ...obj, rate: purityObjects[purity].rate });
            }
          }
          setGoldPricing(array);
        }
      }
    }
  }, [masterPricing, productSettings]);

  useEffect(() => {
    if (!paymentsMethods) dispatch(paymentMethods());
    // if (!isObjWithValues(masterPricing)) dispatch(getMasterPricings());
    // if (!isObjWithValues(productSettings)) dispatch(getProductSettings());
  }, []);

  useEffect(() => {
    if (isObjWithValues(productSettings)) {
      setCurrency(currencySymbols[productSettings.default_currency]);
      setCurrencyName(productSettings.default_currency);
    }
  }, [productSettings]);

  useEffect(() => {
    if (customerInfo && isObjWithValues(customerInfo.digigold_details)) {
      setDigiInfo(customerInfo.digigold_details);
      let { plans } = customerInfo.digigold_details;
      let totalPayment = 0;
      let totalAmount = 0;
      if (isObjWithValues(plans)) {
        for (let id in plans) {
          let { ledger } = plans[id];
          if (isArrayWithValues(ledger)) {
            totalPayment += ledger.length;
            for (let obj of ledger) totalAmount += validateNumber(obj.total);
          }
        }
      }
      setAnalytics({
        payment: totalPayment,
        amount: totalAmount,
      });
      let { members } = customerInfo;
      if (isArrayWithValues(members)) {
        setAllMembers(members);
        setAllMembersIds(members.map((i) => i.id));
      } else {
        fetchAllMembers(customerInfo.id);
        setAllMembers([]);
        setAllMembersIds([]);
      }
    } else {
      setAnalytics({
        payment: 0,
        amount: 0,
      });
      setDigiInfo({});
    }
  }, [customerInfo]);

  useEffect(() => {
    fetchDigiGold();
  }, [customerInfo?.id]);

  useEffect(() => {
    if (redirect) navigate(`/crm/${id}/#orders`);
  }, [id]);
  function addPlanUniqueId(plans) {
    // Iterate through the keys of the input object
    for (const key in plans) {
      if (plans.hasOwnProperty(key)) {
        // Add the plan_unique_id key with the value of the current key
        plans[key]["plan_unique_id"] = key;
      }
    }

    return plans;
  }
  const fetchDigiGold = async () => {
    let website = getWebsite();
    let token = getToken();
    if ((!website && !token) || !customerInfo?.id) return false;
    // if (!id || id === undefined) return;
    setFetchingDigigold(true);
    try {
      const { data: digigold } = await axios({
        url: `${website}/wp-json/store/v1/customer/${customerInfo?.id}/digigold`,
        // params: { include: customerInfo.orders.join(",") },
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      if (isObjWithValues(digigold.data)) {
        let digigoldDetails = { ...digigold.data };
        let { plans } = digigoldDetails;
        let updatedPlans = { ...plans };
        if (isObjWithValues(plans) && isArrayWithValues(plans?.ledger)) {
          updatedPlans.ledger = plans.ledger?.filter(
            (i) => i.status !== "pending"
          );
        }
        console.log(updatedPlans, "<<<<<<<<<<<<<<<<<< updatedPlans");
        if (isObjWithValues(updatedPlans))
          updatedPlans = addPlanUniqueId(updatedPlans);
        digigoldDetails.plans = updatedPlans;

        setCustomerInfo({
          ...customerInfo,
          digigold_details: digigoldDetails,
        });

        dispatch(
          updateCustomerInRedux({
            ...customerInfo,
            digigold_details: digigoldDetails,
          })
        );
      }
    } catch (error) {}

    setFetchingDigigold(false);
  };

  const fetchAllMembers = async (id) => {
    let website = await getWebsite();
    const token = await getToken();
    if (website && token) {
      let { data } = await axios({
        url: `${website}/wp-json/store/v1/settings/digimembers_${id}?lasdkjf`,
        headers: {
          Authorization: `Basic ${token}`,
        },
      });
      if (data.type === "success" && isArrayWithValues(data.data)) {
        setAllMembersIds(data.data);
        try {
          let customerRes = await axios({
            url: `${website}/wp-json/wc/v3/customers/`,
            headers: {
              Authorization: `Basic ${token}`,
            },
            params: { include: data.data.join() },
          });
          if (isArrayWithValues(customerRes.data)) {
            setAllMembers(customerRes.data);
            dispatch(
              updateCustomerInRedux({
                ...customerInfo,
                members: customerRes.data,
              })
            );
          }
        } catch (error) {
          console.log(getAxiosError(error));
        }
      }
      // setAllMembers([]);
      // setAllMembersIds([]);
    }
  };

  const [viewLedger, setViewLedger] = useState(false);
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  // if (isObjWithValues(customerInfo?.digigold_details))
  //   return;
  return (
    <Box
      sx={{ backgroundColor: "#fff", padding: 4, marginTop: 3, height: "100%" }}
    >
      {fetchingDigigold ? (
        <LoadingCustomerData loadingText={"Loading digigold"} />
      ) : (
        <NewCrmDigiGold
          customerInfo={customerInfo}
          openManualDialog={openManualDialog}
          viewLedger={viewLedger}
          setViewLedger={setViewLedger}
          digiInfo={digiInfo}
          analytics={analytics}
          allMembers={allMembers}
          isIpad={isIpad}
          digigoldCancelledPlans={digigoldCancelledPlans}
          openAddMemberDialog={openAddMemberDialog}
          setShowSelectCustomer={setShowSelectCustomer}
        />
      )}

      {false && (
        <>
          <Box sx={{ borderBottom: 1, mb: 2, borderColor: "divider" }}>
            <Tabs
              value={selectedTab}
              onChange={tabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Plans" {...a11yProps(0)} />
              <Tab label="Family Members" {...a11yProps(1)} />
            </Tabs>
          </Box>

          <TabPanel value={selectedTab} index={0}>
            <Grid my={2} container spacing={3}>
              <Grid item xs={6} md={3} lg={2.4}>
                <AnalyticsCard
                  title="Plans"
                  value={
                    isObjWithValues(digiInfo.plans)
                      ? Object.values(digiInfo.plans).length
                      : "0"
                  }
                />
              </Grid>
              <Grid item xs={6} md={3} lg={2.4}>
                <AnalyticsCard title="Payments" value={analytics.payment} />
              </Grid>
              <Grid item xs={6} md={3} lg={2.4}>
                <AnalyticsCard
                  title="Amount"
                  value={`${currency} ${analytics.amount}`}
                />
              </Grid>
              <Grid item xs={6} md={3} lg={2.4}>
                <AnalyticsCard title="Cashback" value={`${currency} 0`} />
              </Grid>
              <Grid item xs={6} md={3} lg={2.4}>
                <AnalyticsCard title="Cashback Gold" value={`0`} />
              </Grid>
            </Grid>
            <Stack
              direction="row"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                my: 1.5,
                // mt: 5,
                px: 2,
              }}
            >
              <Typography variant="h5" fontSize="16px">
                User Plans
              </Typography>
              <Button
                variant="contained"
                size="small"
                startIcon={<Add style={{ height: "15px", width: "15px" }} />}
                onClick={() => openManualDialog("Assign New Plan")}
                // sx={{ paddingTop: "2px", paddingBottom: "2px" }}
              >
                Assign New Plan
              </Button>
            </Stack>
            {digiInfo && isObjWithValues(digiInfo.plans) && (
              <TableContainer
                sx={{ mt: 2, border: "0px solid #e8e8e8" }}
                component={Paper}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    {/* {isIpad && (
                <TableRow>
                  <TableCell>Digital Gold</TableCell>
                </TableRow>
              )} */}
                    {!isIpad && (
                      <TableRow>
                        <TableCell>Plan Name</TableCell>
                        <TableCell align="left">Type</TableCell>
                        <TableCell>Joining</TableCell>
                        <TableCell>Payments</TableCell>
                        <TableCell>Amount Paid</TableCell>
                        <TableCell>Gold Credited</TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                  <TableBody>
                    {customerInfo &&
                      customerInfo.digigold_details &&
                      isObjWithValues(customerInfo.digigold_details.plans) &&
                      Object.values(customerInfo.digigold_details.plans).map(
                        (digi) => (
                          <>
                            {isIpad && (
                              <TableRow sx={{ cursor: "pointer" }}>
                                <TableCell>
                                  <Card>
                                    <Typography variant="h6">
                                      {digi.plan_name}
                                    </Typography>

                                    {/* <Chip label="Amount" color="primary" variant="outlined" /> */}
                                    <Typography
                                      variant="caption"
                                      sx={{ color: "#808080" }}
                                    >
                                      Joining :
                                      {/* {
                                  getRelativeTimeDifference(digi.joining_date)
                                  // 6 months
                                } */}
                                    </Typography>
                                    <Typography>
                                      Status :{" "}
                                      {digi.paid_count ||
                                        digi?.ledger?.length ||
                                        ""}
                                      /{digi.duration}
                                    </Typography>
                                    <Stack
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Typography variant="caption">
                                        {digi.paid}
                                      </Typography>
                                      <Typography
                                        variant="caption"
                                        sx={{ marginLeft: 4 }}
                                      >
                                        Credited : {digi.weight} grams
                                      </Typography>
                                      {isObjWithValues(
                                        digigoldCancelledPlans
                                      ) &&
                                      digigoldCancelledPlans[
                                        `${digi.is_cancelled}`
                                      ] ? (
                                        <Tooltip title="Plan cancelled">
                                          <DoDisturb
                                            style={{
                                              height: "15px",
                                              width: "15px",
                                            }}
                                            sx={{ color: "error.main" }}
                                          />
                                        </Tooltip>
                                      ) : null}
                                    </Stack>
                                  </Card>
                                </TableCell>
                              </TableRow>
                            )}
                            {!isIpad && (
                              <TableRow
                                onClick={() => {
                                  setViewLedger(digi);
                                }}
                                sx={{ cursor: "pointer" }}
                              >
                                <TableCell>
                                  <Stack
                                    direction="row"
                                    sx={{ alignItems: "center" }}
                                  >
                                    <Typography
                                      variant="h5"
                                      fontSize="14px"
                                      sx={{ mr: 1 }}
                                    >
                                      {digi.plan_name}
                                    </Typography>
                                    {isObjWithValues(digigoldCancelledPlans) &&
                                    digigoldCancelledPlans[
                                      `${digi.is_cancelled}`
                                    ] ? (
                                      <Tooltip title="Plan cancelled">
                                        <DoDisturb
                                          style={{
                                            height: "15px",
                                            width: "15px",
                                          }}
                                          sx={{ color: "error.main" }}
                                        />
                                      </Tooltip>
                                    ) : null}
                                  </Stack>
                                  <Typography
                                    variant="caption"
                                    sx={{ color: "#808080" }}
                                  >
                                    {digi.duration} months
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Chip
                                    label={digi.type}
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                  />
                                  {/* <Stack alignItems="center">
                              </Stack> */}
                                </TableCell>
                                <TableCell>
                                  <Typography sx={{ color: "#808080" }}>
                                    {formatDate(
                                      new Date(digi.joining_date),
                                      "relative_date"
                                    )}
                                  </Typography>
                                  {/* <Typography
                                variant="caption"
                                sx={{ color: "#808080" }}
                              >
                                Plan Due In - 6 months
                              </Typography> */}
                                </TableCell>
                                <TableCell>
                                  <Typography>
                                    {digi.paid_count ||
                                      digi?.ledger?.length ||
                                      ""}
                                    {digi.type !== "flexi"
                                      ? `/${digi.duration}`
                                      : ""}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{ fontWeight: "600" }}>
                                  {currency} {digi.paid}
                                </TableCell>
                                <TableCell sx={{ fontWeight: "600" }}>
                                  {validateNumber(digi.weight).toFixed(3)} grams
                                  {/* {allDigiPlans
                                    ?.find((i) => i?.id + "" === digi?.id + "")
                                    ?.meta_data?.find(
                                      (i) => i?.key === "grams_on_amount"
                                    )?.value
                                    ? `${validateNumber(digi.weight).toFixed(
                                        3
                                      )} gramssss`
                                    : "N/A"} */}
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        )
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </TabPanel>

          <TabPanel value={selectedTab} index={1}>
            <Stack
              direction="row"
              sx={{ justifyContent: "flex-end", alignItems: "center" }}
            >
              <Button
                variant="contained"
                size="small"
                startIcon={<Add style={{ height: "15px", width: "15px" }} />}
                onClick={openAddMemberDialog}
                // onClick={() => setShowSelectCustomer(true)}
                // sx={{ paddingTop: "2px", paddingBottom: "2px" }}
              >
                Add new Member
              </Button>
            </Stack>
            <AllMembers allMembers={allMembers} currency={currency} />
          </TabPanel>
        </>
      )}

      {/* {!customerInfo.digigold_details ||
        !isObjWithValues(customerInfo.digigold_details.plans) && (
          <Stack>No Digital Gold</Stack>
        )} */}
      <Ledger
        customerInfo={customerInfo}
        setCustomerInfo={setCustomerInfo}
        currency={currency}
        viewLedger={viewLedger}
        setViewLedger={setViewLedger}
        email={customerInfo.email}
        openManualDialog={openManualDialog}
        openCancelPlan={openCancelPlan}
        digigoldCancelledPlans={digigoldCancelledPlans}
      />
      {/* <Dialog
        open={showSelectCustomer}
        onClose={() => setShowSelectCustomer(false)}
        maxWidth="sm"
        fullWidth
        sx={isIpad ? {} : dialogStyle}
        fullScreen={isIpad}
      >
        <DialogContent>
          <SelectCustomer
            onClose={() => setShowSelectCustomer(false)}
            // onSelect={setSelectedCustomer}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowSelectCustomer(false)}>Cancel</Button>
          <LoadingButton
            variant="contained"
            // disabled={!isObjWithValues(selectedCustomer)}
            // loading={mergingCustomer}
            // onClick={onMeargeCustomer}
          >
            Merge Customer
          </LoadingButton>
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={Boolean(manualPaymentOpen)}
        onClose={closeManualDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
        sx={isIpad ? {} : dialogStyle}
        {...globalPopupStyle}
        // sx={(theme) => (theme.breakpoints.down("md") ? {} : dialogStyle)}
        // PaperProps={{
        //   sx: {
        //     height: "80vh"
        //   }
        // }}
      >
        <AddManualPayment
          closeManualForm={closeManualDialog}
          currency={currency}
          paymentMethods={
            isArrayWithValues(paymentsMethods)
              ? paymentsMethods.concat(
                  ornateIntegrated ? [{ label: "Ornate", value: "ornate" }] : []
                )
              : []
          }
          defaultFormProp={viewLedger}
          userView={customerInfo}
          goldPricing={goldPricing}
          digiInfo={digiInfo}
          allPlans={allPlans}
          fetchingAllPlans={fetching_digigold_plans}
          setViewLedger={setViewLedger}
          manualPaymentOpen={manualPaymentOpen}
          setUserView={setCustomerInfo}
          disableAllPlans={manualPaymentOpen === "Add Manual Payment"}
          todayGoldRate={todayGoldRate}
          selectedPlanId={viewLedger.product}
          branches={branches}
          dialogTitle={manualPaymentOpen}
          // setCustomerInfo={setCustomerInfo}
          setCustomerInfo={setCustomerInfo}
          refreshPlans={() => fetchDigiGold()}
        />
      </Dialog>
      <Dialog
        open={Boolean(cancelPlanOpen)}
        onClose={closeCancelPlan}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
        sx={isIpad ? {} : dialogStyle}
        {...globalPopupStyle}
        // sx={(theme) => (theme.breakpoints.down("md") ? {} : dialogStyle)}
        // PaperProps={{
        //   sx: {
        //     height: "80vh"
        //   }
        // }}
      >
        <CancelForm
          closeCancelPlan={closeCancelPlan}
          ledger={viewLedger}
          currency={currency}
          digigoldCancelledPlans={digigoldCancelledPlans}
          customerInfo={customerInfo}
        />
      </Dialog>
      <Dialog
        open={addMemberDialog}
        onClose={closeAddMemberDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
        sx={isIpad ? {} : dialogStyle}
        {...globalPopupStyle}
        // sx={(theme) => (theme.breakpoints.down("md") ? {} : dialogStyle)}
        // PaperProps={{
        //   sx: {
        //     height: "80vh"
        //   }
        // }}
      >
        <AddMember
          closeAddMemberDialog={closeAddMemberDialog}
          customerInfo={customerInfo}
          allMembersIds={allMembersIds}
        />
      </Dialog>
    </Box>
  );
};

export default DigitalGold;
const NewCrmDigiGold = ({
  customerInfo,
  openManualDialog,
  isPlanCancelled,
  viewLedger,
  setViewLedger,
  digiInfo,
  analytics,
  isIpad,
  digigoldCancelledPlans,
  allMembers,
  openAddMemberDialog,
  setShowSelectCustomer,
}) => {
  const master_pricing = useSelector((state) => state.settings.masterPricing);
  const reduxProductSettings = useSelector(
    (state) => state.settings.productSettings
  );
  const allDigiPlans = useSelector((state) => state.product.digigold_plans);

  const [currency, setCurrency] = useState("");
  const [digigoldData, setDigigoldData] = useState({});
  const [todayGoldRate, setTodayGoldRate] = useState("");
  const [selectedTab, setSelectedTab] = useState("Plans subscribed");
  let store_id = getStoreId();

  useEffect(() => {
    let dataObj = {
      amountPlan: 0,
      gramPlan: 0,
      total: 0,
      received: {
        inGram: 0,
        total: 0,
      },
      expected: {
        inGram: 0,
        total: 0,
      },
    };
    dataObj.total = customerInfo?.digigold_details?.total;
    dataObj.received.total = customerInfo?.digigold_details?.total;

    if (isObjWithValues(customerInfo?.digigold_details?.plans)) {
      let plans = customerInfo?.digigold_details?.plans;
      for (let index = 0; index < Object.keys(plans).length; index++) {
        const key = Object.keys(plans)[index];
        let plan = plans[key];
        if (plan.type === "amount") dataObj.amountPlan = dataObj.amountPlan + 1;
        else dataObj.gramPlan = dataObj.amountPlan + 1;
        // dataObj.received.inGram = Number(
        //   dataObj.received.total / todayGoldRate
        // ).toFixed(2);
        dataObj.received.inGram = validateNumber(
          customerInfo?.digigold_details?.grams
        );
        dataObj.expected.total =
          validateNumber(plan?.ledger?.[0]?.total) *
            validateNumber(plan?.duration) +
          dataObj.expected.total;
        dataObj.expected.inGram = Number(
          dataObj.expected.total / todayGoldRate
        ).toFixed(3);
      }
      setDigigoldData(dataObj);
    }
  }, [customerInfo?.id, todayGoldRate]);
  useEffect(() => {
    if (!isObjWithValues(master_pricing) || !currency) return;
    let { gold_pricing } = master_pricing;
    let { type } = gold_pricing;
    let pricing = gold_pricing?.[currency]?.[type];
    let defaultPurity = gold_pricing?.[currency]?.default;
    if (isObjWithValues(pricing)) {
      let priceObj = {};

      let sortedPricing = Object.keys(pricing)
        .map((i) => validateNumber(i))
        .sort((a, b) => b - a);

      for (let purity of sortedPricing)
        if (pricing[purity]?.rate) {
          priceObj.rate = pricing[purity].rate;
          priceObj.purity = purity;
          break;
        }
      if (pricing?.[defaultPurity]?.rate)
        setTodayGoldRate(pricing?.[defaultPurity]?.rate);
      else if (isObjWithValues(priceObj)) {
        setTodayGoldRate(
          Math.round(
            Number(999 / Number(priceObj.purity)) * Number(priceObj.rate)
          )
        );
      }
    }
  }, [master_pricing, currency]);
  useEffect(() => {
    if (reduxProductSettings) {
      let { default_currency } = reduxProductSettings;
      setCurrency(default_currency);
    }
  }, [reduxProductSettings]);

  return (
    <Box>
      {isObjWithValues(customerInfo.digigold_details) && (
        <>
          <Card
            sx={{
              border: "0.5px solid #e5eef8",
              ...customerAnalyticCardsStyle,
            }}
          >
            <CardContent>
              <Stack
                direction={"row"}
                alignItems="center"
                justifyContent={"space-between"}
                flexWrap={"wrap"}
                rowGap={3}
              >
                <Stack sx={{ rowGap: "10px" }}>
                  <Typography
                    variant="h6"
                    color={"text.secondary"}
                    sx={secondaryLabel}
                  >
                    Customer id
                  </Typography>
                  <Typography variant="h6">{customerInfo?.id}</Typography>
                </Stack>
                {/* <Stack>
              <Typography variant="h6" color={"text.secondary"}>
                Digigold id
              </Typography>
              <Typography variant="h6">0987654</Typography>
            </Stack> */}
                <Stack sx={{ rowGap: "10px" }}>
                  <Typography
                    variant="h6"
                    color={"text.secondary"}
                    sx={secondaryLabel}
                  >
                    Family/Account name
                  </Typography>
                  <Typography variant="h6">{`${
                    customerInfo?.first_name || ""
                  } ${customerInfo?.last_name || ""}`}</Typography>
                </Stack>
                <Stack sx={{ rowGap: "10px" }}>
                  <Typography
                    variant="h6"
                    color={"text.secondary"}
                    sx={secondaryLabel}
                  >
                    Joining Date
                  </Typography>
                  <Typography variant="h6">
                    {formatDate(
                      new Date(
                        Object.values(
                          customerInfo?.digigold_details?.plans || {}
                        )?.[0]?.joining_date
                      ),
                      "relative_date"
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
          <Box mt={4} mb={4}>
            <Grid container spacing={5} rowSpacing={4}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TotalCard
                  title="Total plan subscribed"
                  value={
                    Object.keys(customerInfo?.digigold_details?.plans || {})
                      .length
                  }
                  hideGraph
                  hideNavigate
                  containerStyle={{
                    ...customerAnalyticCardsStyle,
                  }}
                  values={[
                    {
                      title: "Amount plans",
                      value: (
                        <Stack rowGap={"8px"} justifyContent="flex-end">
                          <Typography variant="h4">
                            {digigoldData?.amountPlan || ""}
                          </Typography>
                          {/* <Typography
                        variant="h6"
                        fontWeight={"400"}
                        sx={{ ...secondaryLabel }}
                      >
                        {(digigoldData?.amountPlan /
                          validateNumber(
                            Object.keys(
                              customerInfo?.digigold_details?.plans || {}
                            ).length
                          )) *
                          100}{" "}
                        %
                      </Typography> */}
                        </Stack>
                      ),
                      // prefix: currencySymbols[currency],
                    },
                    {
                      title: "in gms",
                      value: (
                        <Stack rowGap={"8px"} justifyContent="flex-end">
                          <Typography variant="h4">
                            {digigoldData?.gramPlan || ""}
                          </Typography>
                          {/* <Typography
                        variant="h6"
                        fontWeight={"400"}
                        sx={{ color: "text.secondary" }}
                      >
                        {(digigoldData?.gramPlan /
                          validateNumber(
                            Object.keys(
                              customerInfo?.digigold_details?.plans || {}
                            ).length
                          )) *
                          100}{" "}
                        %
                      </Typography> */}
                        </Stack>
                      ),
                      // suffix: "g",
                    },
                  ]}
                  // graphData={topGraphs?.plans}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TotalCard
                  title="Total collection expected"
                  value={`${currencySymbols[currency] || ""} ${
                    digigoldData?.expected?.total || ""
                  } `}
                  hideGraph
                  hideNavigate
                  containerStyle={{
                    ...customerAnalyticCardsStyle,
                  }}
                  values={[
                    {
                      title: "In Rupees",
                      value: (
                        <Stack rowGap={"8px"} justifyContent="flex-end">
                          <Typography variant="h4">
                            {`${currencySymbols[currency] || ""} ${
                              digigoldData?.expected?.total || ""
                            } `}
                          </Typography>
                        </Stack>
                      ),
                      // prefix: currencySymbols[currency],
                    },
                    {
                      title: "in gms",
                      value: (
                        <Stack rowGap={"8px"} justifyContent="flex-end">
                          <Typography variant="h4">
                            {digigoldData?.expected?.inGram || ""}gms
                          </Typography>
                          {/* <Typography variant="h6" sx={{ color: "text.secondary" }}>
                        @ {`${currencySymbols[currency]} ${todayGoldRate} `}
                      </Typography> */}
                        </Stack>
                      ),
                      // suffix: "g",
                    },
                  ]}
                  // graphData={topGraphs?.plans}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={6} lg={6}>
                <TotalCard
                  title="Total collection recieved"
                  value={`${currencySymbols[currency] || ""} ${
                    digigoldData?.received?.total || ""
                  } `}
                  hideGraph
                  hideNavigate
                  containerStyle={{
                    ...customerAnalyticCardsStyle,
                  }}
                  values={[
                    {
                      title: "in Rupees",
                      value: (
                        <Stack rowGap={"8px"} justifyContent="flex-end">
                          <Typography variant="h4">
                            {`${currencySymbols[currency] || ""} ${
                              digigoldData?.received?.total || ""
                            } `}
                          </Typography>
                        </Stack>
                      ),
                      // prefix: currencySymbols[currency],
                    },
                    {
                      title: "in gms",
                      value: (
                        <Stack rowGap={"8px"} justifyContent="flex-end">
                          <Typography variant="h4">
                            {digigoldData?.received?.inGram || ""} gms
                          </Typography>
                          {/* <Typography variant="h6" sx={{ color: "text.secondary" }}>
                        @ {`${currencySymbols[currency]} ${todayGoldRate} `}
                      </Typography> */}
                        </Stack>
                      ),
                      // suffix: "g",
                    },
                  ]}
                  // graphData={topGraphs?.plans}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} md={6}>
            <TotalCard
              title="Missed payments"
              value={"2"}
              hideGraph
              hideNavigate
              values={[
                {
                  title: "In Rupees",
                  value: (
                    <Stack rowGap={"8px"} justifyContent="flex-end">
                      <Typography variant="h4">$6798</Typography>
                      <Typography variant="h4" fontWeight={"400"}>
                        62%
                      </Typography>
                    </Stack>
                  ),
                  // prefix: currencySymbols[currency],
                },
                // {
                //   title: "in gms(999)",
                //   value: (
                //     <Stack rowGap={"8px"} justifyContent="flex-end" >
                //       <Typography variant="h4">1</Typography>
                //       <Typography variant="h4" sx={{ color: "text.secondary" }}>
                //         25%
                //       </Typography>
                //     </Stack>
                //   ),
                //   // suffix: "g",
                // },
              ]}
            // graphData={topGraphs?.plans}
            />
          </Grid> */}
            </Grid>
          </Box>
        </>
      )}
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
        sx={{ borderBottom: "divider" }}
        rowGap={4}
        flexWrap={"wrap"}
      >
        <Tabs
          value={selectedTab}
          onChange={(e, newValue) => setSelectedTab(newValue)}
        >
          {doigiGoldTabs.map((obj) => (
            <Tab label={obj.title} value={obj?.title} />
          ))}
        </Tabs>
      </Stack>
      <TabPanel value={selectedTab} index={"Plans subscribed"}>
        {isObjWithValues(customerInfo.digigold_details) ? (
          <>
            <Stack
              direction="row"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                my: 1.5,
                // mt: 5,
                px: 2,
              }}
            >
              <Typography variant="h5" fontSize="16px">
                User Plans
              </Typography>
              <Button
                variant="contained"
                size="small"
                startIcon={<Add style={{ height: "15px", width: "15px" }} />}
                onClick={() => openManualDialog("Assign New Plan")}
                // sx={{ paddingTop: "2px", paddingBottom: "2px" }}
              >
                Assign New Plan
              </Button>
            </Stack>
            {digiInfo && isObjWithValues(digiInfo.plans) && (
              <TableContainer
                sx={{ mt: 2, border: "0px solid #e8e8e8" }}
                component={Paper}
              >
                <Table aria-label="simple table">
                  <TableHead>
                    {/* {isIpad && (
               <TableRow>
                 <TableCell>Digital Gold</TableCell>
               </TableRow>
             )} */}
                    {!isIpad && (
                      <TableRow>
                        <TableCell>Plan Name</TableCell>
                        <TableCell align="left">Type</TableCell>
                        <TableCell>Joining</TableCell>
                        <TableCell>Payments</TableCell>
                        <TableCell>Amount Paid</TableCell>
                        <TableCell>Gold Credited</TableCell>
                      </TableRow>
                    )}
                  </TableHead>
                  <TableBody>
                    {customerInfo &&
                      customerInfo.digigold_details &&
                      isObjWithValues(customerInfo.digigold_details.plans) &&
                      Object.values(customerInfo.digigold_details.plans).map(
                        (digi) => (
                          <>
                            {isIpad && (
                              <TableRow
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  setViewLedger(digi);
                                }}
                              >
                                <TableCell>
                                  <Card>
                                    <Typography variant="h6">
                                      {digi.plan_name}
                                    </Typography>
                                    <Typography
                                      // variant="caption"
                                      sx={{
                                        color: "#808080",
                                        fontWeight: "600",
                                        fontSize: "14px",
                                        letterSpacing: "0.6px",
                                      }}
                                    >
                                      ID:{" "}
                                      {customerInfo?.[
                                        `digigold_uid_${store_id}`
                                      ]?.[digi?.plan_unique_id] ||
                                        customerInfo?.[
                                          `digigold_uid_${store_id}`
                                        ]?.[digi?.product]}
                                    </Typography>
                                    {/* <Chip label="Amount" color="primary" variant="outlined" /> */}
                                    <Typography
                                      variant="caption"
                                      sx={{ color: "#808080" }}
                                    >
                                      Joining :
                                      {formatDate(
                                        new Date(digi.joining_date),
                                        "relative_date"
                                      )}
                                      {/* {
                                 getRelativeTimeDifference(digi.joining_date)
                                 // 6 months
                               } */}
                                    </Typography>
                                    <Typography>
                                      Status :{" "}
                                      {digi.paid_count ||
                                        digi?.ledger?.length ||
                                        ""}
                                      /{digi.duration}
                                    </Typography>
                                    <Stack
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Typography>{digi.paid}</Typography>
                                      <Typography
                                        // variant="caption"
                                        sx={{ marginLeft: 4 }}
                                      >
                                        Credited : {digi.weight} grams
                                        {/* {allDigiPlans
                                          ?.find(
                                            (i) => i?.id + "" === digi?.id + ""
                                          )
                                          ?.meta_data?.find(
                                            (i) => i?.key === "grams_on_amount"
                                          )?.value
                                          ? `${validateNumber(
                                              digi.weight
                                            ).toFixed(3)} gramssss`
                                          : "N/A"} */}
                                      </Typography>
                                    </Stack>
                                  </Card>
                                </TableCell>
                              </TableRow>
                            )}
                            {!isIpad && (
                              <TableRow
                                onClick={() => {
                                  setViewLedger(digi);
                                }}
                                sx={{ cursor: "pointer" }}
                              >
                                <TableCell>
                                  <Stack
                                    direction="row"
                                    sx={{ alignItems: "center" }}
                                  >
                                    <Typography
                                      variant="h5"
                                      fontSize="14px"
                                      sx={{ mr: 1 }}
                                    >
                                      {digi.plan_name}
                                    </Typography>
                                    {isObjWithValues(digigoldCancelledPlans) &&
                                    digigoldCancelledPlans[
                                      `${digi.is_cancelled}`
                                    ] ? (
                                      <Tooltip title="Plan cancelled">
                                        <DoDisturb
                                          style={{
                                            height: "15px",
                                            width: "15px",
                                          }}
                                          sx={{ color: "error.main" }}
                                        />
                                      </Tooltip>
                                    ) : null}
                                  </Stack>
                                  <Typography
                                    // variant="caption"
                                    sx={{
                                      color: "#808080",
                                      fontWeight: "600",
                                      fontSize: "14px",
                                      letterSpacing: "0.6px",
                                    }}
                                  >
                                    ID:{" "}
                                    {customerInfo?.[
                                      `digigold_uid_${store_id}`
                                    ]?.[digi?.plan_unique_id] ||
                                      customerInfo?.[
                                        `digigold_uid_${store_id}`
                                      ]?.[digi?.product]}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Chip
                                    label={digi.type}
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                  />
                                  <Typography
                                    // variant="caption"
                                    sx={{ color: "#808080" }}
                                  >
                                    {digi.duration} months
                                  </Typography>
                                  {/* <Stack alignItems="center">
                             </Stack> */}
                                </TableCell>
                                <TableCell>
                                  <Typography sx={{ color: "#808080" }}>
                                    {formatDate(
                                      new Date(digi.joining_date),
                                      "relative_date"
                                    )}
                                  </Typography>
                                  {/* <Typography
                               variant="caption"
                               sx={{ color: "#808080" }}
                             >
                               Plan Due In - 6 months
                             </Typography> */}
                                </TableCell>
                                <TableCell>
                                  <Typography>
                                    {digi.paid_count ||
                                      digi?.ledger?.length ||
                                      ""}
                                    {digi.type !== "flexi"
                                      ? `/${digi.duration}`
                                      : ""}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{ fontWeight: "600" }}>
                                  {currency} {digi.paid}
                                </TableCell>
                                <TableCell sx={{ fontWeight: "600" }}>
                                  {validateNumber(digi.weight).toFixed(3)} grams
                                  {/* {allDigiPlans
                                    ?.find((i) => i?.id + "" === digi?.id + "")
                                    ?.meta_data?.find(
                                      (i) => i?.key === "grams_on_amount"
                                    )?.value
                                    ? `${validateNumber(digi.weight).toFixed(
                                        3
                                      )} gramss`
                                    : "N/A"} */}
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        )
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            {false && (
              <PlanSubscribed
                customerInfo={customerInfo}
                currency={currency}
                setViewLedger={setViewLedger}
              />
            )}
          </>
        ) : (
          <>
            <NoPlans
              customerInfo={customerInfo}
              openManualDialog={openManualDialog}
            />

            {/* <Stack
              direction="row"
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              {!fromDigigold && false ? (
                <Typography variant="h5">
                  {customerInfo.first_name + " " + customerInfo.last_name}'s
                  Digital Gold
                </Typography>
              ) : null}
            </Stack> */}
          </>
        )}
      </TabPanel>
      <TabPanel value={selectedTab} index={"Payments recieved"}>
        {isObjWithValues(customerInfo.digigold_details) ? (
          <PaymentRecived
            customerInfo={customerInfo}
            currency={currency}
            viewLedger={viewLedger}
            store_id={store_id}
          />
        ) : (
          <NoPlans
            customerInfo={customerInfo}
            openManualDialog={openManualDialog}
          />
        )}
      </TabPanel>
      <TabPanel value={selectedTab} index={"Members"}>
        <Stack
          direction="row"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
        >
          <Typography variant="h5" fontSize="16px">
            Members
          </Typography>
          <Button
            variant="contained"
            size="small"
            startIcon={<Add style={{ height: "15px", width: "15px" }} />}
            onClick={openAddMemberDialog}
            // onClick={() => setShowSelectCustomer(true)}

            // sx={{ paddingTop: "2px", paddingBottom: "2px" }}
          >
            Add new Member
          </Button>
        </Stack>
        <AllMembers allMembers={allMembers} currency={currency} />
      </TabPanel>
      <TabPanel value={selectedTab} index={"Missed payments"}>
        {/* <PlanSubscribed customerInfo={customerInfo} currency={currency} /> */}
      </TabPanel>
      <TabPanel value={selectedTab} index={"Cashbaack"}>
        {/* <PlanSubscribed customerInfo={customerInfo} currency={currency} /> */}
      </TabPanel>
    </Box>
  );
};

const NoPlans = ({ customerInfo, openManualDialog }) => {
  return (
    <Stack
      // direction="row"
      sx={{ justifyContent: "center", alignItems: "center" }}
      rowGap={"10px"}
    >
      <DigigoldIllustration />
      <Typography sx={{ ...secondaryLabel }}>
        Looks like no plans have been created {customerInfo?.first_name}{" "}
        {customerInfo?.last_name}
      </Typography>
      <Button
        variant="contained"
        size="small"
        startIcon={<Add style={{ height: "15px", width: "15px" }} />}
        onClick={() => openManualDialog("Assign New Plan")}
        // sx={{ paddingTop: "2px", paddingBottom: "2px" }}
      >
        Assign New Plan
      </Button>
    </Stack>
  );
};

const PaymentRecived = ({ customerInfo, currency, viewLedger, store_id }) => {
  const [ledger, setLedger] = useState([]);
  // const digigold_user_rewards = useSelector(
  //   (state) => state.settings.digigold_user_rewards
  // );
  useEffect(() => {
    setLedger(
      Object.values(customerInfo.digigold_details?.plans || {})
        ?.map((obj) => [
          ...(obj?.ledger || []).map((i) => ({ ...i, product: obj.product })),
        ])
        ?.flat() || []
    );
  }, [customerInfo.digigold_details, currency]);

  return (
    <TableContainer component={Paper}>
      {" "}
      <Typography variant="h5" sx={{ my: 1.5 }} fontSize={"16px"}>
        Payment History
      </Typography>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Date of Payment</TableCell>
            <TableCell>Payment Ref.</TableCell>
            <TableCell>Mode</TableCell>
            <TableCell>Amount Paid</TableCell>
            <TableCell>Gold Credited</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ledger.map((detail) => (
            <StyledTableRow
              key={detail.url}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Typography
                  // variant="caption"
                  sx={{
                    color: "#808080",
                    fontWeight: "600",
                    fontSize: "14px",
                    letterSpacing: "0.6px",
                  }}
                >
                  {customerInfo?.[`digigold_uid_${store_id}`]?.[detail.product]}
                </Typography>
              </TableCell>
              <TableCell component="th" scope="row">
                {detail.date_paid}
              </TableCell>
              <TableCell>{detail.ref}</TableCell>
              <TableCell>{detail.mode}</TableCell>
              <TableCell>
                <Typography>
                  {currency} {detail.total}
                </Typography>
                <Typography sx={{ ...secondaryLabel, fontSize: "11px" }}>
                  {detail.manual ? "Manual" : "Automatic"}
                </Typography>
              </TableCell>
              <TableCell>{detail.gold}</TableCell>
            </StyledTableRow>
          ))}
          {/* <StyledTableRow>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell sx={{ fontWeight: "600" }}>Total</TableCell>
        <TableCell sx={{ fontWeight: "600" }}>
          {currency} {viewLedger.paid}
        </TableCell>
        <TableCell sx={{ fontWeight: "600" }}>
          {viewLedger?.weight?.toFixed(3)} grams
        </TableCell>
      </StyledTableRow> */}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const PlanSubscribed = ({ customerInfo, currency, setViewLedger }) => {
  return (
    <Box>
      <Grid container spacing={3} rowSpacing={4}>
        {Object.values(customerInfo?.digigold_details?.plans || {}).map(
          (obj) => (
            <Grid item xs={12} md={4}>
              <PlanCard
                plan={obj}
                currency={currency}
                setViewLedger={setViewLedger}
              />
            </Grid>
          )
        )}
      </Grid>
    </Box>
  );
};
const PlanCard = ({ plan, currency, setViewLedger }) => {
  let { images } = plan;
  return (
    <Card
      sx={{
        borderRadius: "8px",
        // background:
        //   "linear-gradient(10deg, rgba(242,232,199,1) 0%, rgba(250,247,240,1) 100%)",
        // cursor: "pointer",
        height: "100%",
        position: "relative",
        my: 2,
      }}
      className="shadow_card"
      // onClick={() => onCardClick(info)}
    >
      <CardContent>
        {/* <IconButton sx={{ position: "absolute", top: 10, right: 10 }}>
        <Switch
          disabled={statusChanging}
          checked={product.status === "publish"}
          onChange={(e) =>
            onStatusChange(
              info.id,
              product.status === "publish" ? "draft" : "publish"
            )
          }
          style={{
            border: `1px solid ${product.status === "publish" ? backgroundColor : "#e8e8e8"
              }`,
            backgroundColor:
              product.status === "publish" ? backgroundColor : "#e8e8e8",
          }}
        />
      </IconButton> */}
        {isArrayWithValues(images) ? (
          <Box
            sx={{
              position: "relative",
              maxWidth: "70%",
              margin: "20px auto",
              // backgroundColor: "blue",
              borderRadius: "50%",
              overflow: "hidden",
              // boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
              // p: 4
            }}
          >
            <img
              src={images[0].src}
              style={{
                position: "relative",
                maxWidth: "100%",
                // margin: "0px auto",
                // backgroundColor: "blue",
                borderRadius: "50%",
                overflow: "hidden",
                // boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
                // p: 4
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              position: "relative",
              maxWidth: "70%",
              margin: "20px auto",
              padding: "5px",
              // backgroundColor: "blue",
              borderRadius: "50%",
              // boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.75)",
              // p: 4
            }}
          >
            <GoldCoin />
            <Box
              sx={{
                position: "absolute",
                top: plan?.type === "grams" ? "43%" : "49%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                alignItems: "center",
                opacity: 0.6,
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontFamily: "Georgia, serif",
                  textShadow: "2px 2px #daaf17",
                  fontSize: plan?.type === "grams" ? "70px" : "27px",
                  fontWeight: "500",
                  flexWrap: "nowrap",
                  whiteSpace: "nowrap",
                }}
              >
                {plan?.type === "grams"
                  ? plan?.grams
                  : `${currencySymbols[currency]} ${
                      plan?.ledger?.[0]?.total || ""
                    }`}
              </Typography>
              <Typography
                variant="h5"
                textAlign={"center"}
                mt={0.5}
                sx={{
                  fontFamily: "Georgia, serif",
                  textShadow: "2px 2px #daaf17",
                  fontSize: "14px",
                  fontWeight: "500",
                }}
              >
                {plan.type === "grams"
                  ? "Grams"
                  : plan.type === "flexi"
                  ? ""
                  : `Per Month`}
              </Typography>
            </Box>
          </Box>
        )}
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            mt: 1.5,
            textAlign: "center",
          }}
        >
          {plan?.plan_name}
        </Typography>

        {/* <Typography
        sx={{
          fontSize: "12px",
          mt: 1.5,
          textAlign: "center",
          color: "text.secondary",
        }}
      >
        {info.totalUsers} Customers
      </Typography> */}
        {/* <Typography
          sx={{
            fontSize: "12px",
            mt: 1.5,
            textAlign: "center",
            color: "text.secondary",
          }}
        >
          {formatDate(new Date(info.date_created), "dd:mm:yyyy hh:mm am/pm")}
        </Typography> */}
        <Stack
          mt={1}
          direction="row"
          sx={{ justifyContent: "center", alignItems: "center" }}
        >
          <Typography variant="h6" sx={{ fontSize: "13px" }}>
            Duration:
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontSize: "13px", fontWeight: "500", ml: 1 }}
          >
            {plan.duration || "0"} Months
          </Typography>
        </Stack>
        <Stack sx={{ my: 4 }} rowGap={3}>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography>Contribution</Typography>
            <Typography>{plan?.ledger?.[0]?.total}/month</Typography>
          </Stack>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography>Created on</Typography>
            <Typography>
              {plan?.joining_date
                ? formatDate(new Date(plan?.joining_date), "relative_date")
                : null}
            </Typography>
          </Stack>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography>Min. Customer</Typography>
            <Typography>0</Typography>
          </Stack>
          <Stack
            direction={"row"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <Typography>Grifts</Typography>
            <Typography>
              {/* <IconButton size="small"> */}
              <ErrorTwoTone fontSize="small" />
              {/* </IconButton> */}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          mt={2}
          // sx={{ display: "none" }}
          justifyContent={"center"}
        >
          {/* <ButtonGroup
            variant="text"
            size="small"
            aria-label="text button group"
          >
          </ButtonGroup> */}
          {/* <Tooltip
            title={
              plan?.totalUsers
                ? "Cannot delete plans which contains user payments"
                : ""
            }
          >
            <div>
              <Button
                disabled={plan?.totalUsers}
                color="error"
              // onClick={() => setShowDelete(plan?)}
              >
                Delete
              </Button>
            </div>
          </Tooltip> */}
          {/* <Button
          // onClick={() => onCardClick(plan)}
          >
            Edit
          </Button> */}
          <Button
            onClick={() => {
              setViewLedger(plan);
            }}
            // onClick={() => setPlanView(plan?)}
          >
            View
          </Button>
        </Stack>
        <Dialog
          // open={isObjWithValues(showDelete)}
          // onClose={closeDelete}
          maxWidth="sm"
          fullWidth
          sx={{ ...dialogStyle }}
        >
          <DialogTitle>
            {/* Delete <strong>{showDelete.name}</strong> plan? */}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>This action cannot be undone.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
            // onClick={closeDelete}
            >
              Cancel
            </Button>
            <LoadingButton
              // loading={deleting}
              color="error"
              // onClick={() => onDeletePlan()}
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

const allRelationShips = [
  { label: "Son", value: "son" },
  { label: "Daughter", value: "daughter" },
  { label: "Father", value: "father" },
  { label: "Mother", value: "mother" },
  { label: "Brother", value: "brother" },
  { label: "Sister", value: "sister" },
  { label: "Grandfather", value: "grandfather" },
  { label: "Grandmother", value: "grandmother" },
  { label: "Uncle", value: "uncle" },
  { label: "Aunt", value: "aunt" },
  { label: "Nephew", value: "nephew" },
  { label: "Niece", value: "niece" },
  { label: "Cousin", value: "cousin" },
  { label: "Husband", value: "husband" },
  { label: "Wife", value: "wife" },
  { label: "Father-in-law", value: "father-in-law" },
  { label: "Mother-in-law", value: "mother-in-law" },
  { label: "Brother-in-law", value: "brother-in-law" },
  { label: "Sister-in-law", value: "sister-in-law" },
  { label: "Son-in-law", value: "son-in-law" },
  { label: "Daughter-in-law", value: "daughter-in-law" },
  { label: "Stepfather", value: "stepfather" },
  { label: "Stepmother", value: "stepmother" },
  { label: "Stepson", value: "stepson" },
  { label: "Stepdaughter", value: "stepdaughter" },
  { label: "Guardian", value: "guardian" },
  { label: "External", value: "external" },
];

const defaultExistingCustomer = {
  id: "",
  relationship: "",
};

const AddMember = ({ closeAddMemberDialog, customerInfo, allMembersIds }) => {
  const [existingCustomers, setExistingCustomers] = useState([
    defaultExistingCustomer,
  ]);
  let [addForm, setAddForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    dialcode_mobile: { label: "India 🇮🇳 (+91)", value: "91" },
    relationship: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    country: "",
    postcode: "",
  });
  const [states, setStates] = useState([]);
  const [fetchingPostcode, setFetchingPostcode] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [allSearchedCustomers, setAllSearchedCustomers] = useState([]);

  const allCountries = useSelector((state) => state.persist.countries);
  const allStates = useSelector((state) => state.persist.states);
  let customerSearchTimeout;
  const [customerSearching, setCustomerSearching] = useState(false);

  const onCustomerSearch = async (value, setState, setLoading) => {
    if (!value || (value && value.length < 2)) return;
    if (customerSearchTimeout) clearTimeout(customerSearchTimeout);

    customerSearchTimeout = setTimeout(async () => {
      setCustomerSearching(true);
      await fetchCustomers(value, setState);
      setCustomerSearching(false);
    }, 500);
  };
  const setUpdatedCustomers = (customers) => {
    if (isArrayWithValues(customers)) {
      let arr = [];
      for (let i = 0; i < customers.length; i++) {
        arr.push({
          label: `${customers[i].first_name} ${customers[i].last_name}`,
          value: customers[i].id,
        });
      }
      setAllSearchedCustomers(arr);
    }
  };
  const dispatch = useDispatch();

  const handleTabChange = (a, e) => {
    setSelectedTab(e);
  };

  useEffect(() => {
    if (!isArrayWithValues(allCountries)) dispatch(getDropdownData());
  }, []);

  const editForm = (payload) => {
    if (error) setError("");
    setAddForm((state) => ({ ...state, ...payload }));
  };

  const postcodeChange = (e) => {
    editForm({ postcode: e.target.value });
    setTimeout(() => {
      onPostcodeChange(e.target.value, editForm, setFetchingPostcode);
    }, 300);
  };

  const onPostcodeChange = (value, setState, setLoading) => {
    if (value.length >= 6) {
      setLoading(true);
      axios({
        method: "GET",
        url: `https://api.postalpincode.in/pincode/${value}`,
      })
        .then((res) => {
          let { data } = res;
          if (Array.isArray(data)) {
            let obj = data[0];
            if (obj.Status === "Success") {
              let { PostOffice } = obj;
              if (
                PostOffice &&
                Array.isArray(PostOffice) &&
                PostOffice.length > 0
              ) {
                let info = PostOffice[0];
                let countryObj, stateObj;
                if (isArrayWithValues(allCountries)) {
                  countryObj = allCountries.find(
                    (i) => i.label == info.Country
                  );
                  if (isObjWithValues(allStates) && countryObj) {
                    let states = allStates[countryObj.code];
                    if (states) {
                      setStates(states);
                      stateObj = states.find((i) => i.label == info.State);
                    }
                  }
                }
                setState({
                  city: info.Name ? info.Name : "",
                  // state: info.State ? info.State : "",
                  state: stateObj ? stateObj.code : "",
                  // country: info.Country ? info.Country : "",
                  country: countryObj ? countryObj.code : "",
                });
              }
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  function removeEmpty(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v));
  }

  const onSubmit = async () => {
    let store_id = getStoreId();
    if (selectedTab === 0) {
      let index = 0;
      for (let customer of existingCustomers) {
        let { id, relationship } = customer;
        if (!id) return setError(`Please select customer ${index + 1}`);
        if (!relationship)
          return setError(`Please select customer ${index + 1} relationship`);
        index++;
      }
      setError("");
      setSubmitting(true);
      let memberIds = [];
      let members_added = [];
      for await (let customer of existingCustomers) {
        let { id, relationship } = customer;
        let updateObj = {
          id: id?.value,
          meta_data: [
            { key: "relationship", value: relationship },
            { key: "digiReferredFrom", value: customerInfo.id },
            { key: "digigold", value: true },
            { key: `lead_source_${store_id}`, value: true },
          ],
        };
        console.log(updateObj, allMembersIds);
        memberIds.push(updateObj.id);
        let updatedCustomer = await updateCustomer(updateObj);
        if (updatedCustomer) members_added.push(updatedCustomer);
      }
      await updateSettings(customerInfo.id, memberIds, true);
      if (isArrayWithValues(members_added)) {
        let { members } = customerInfo;
        let array = isArrayWithValues(members) ? members : [];
        dispatch(
          updateCustomerInRedux({
            ...customerInfo,
            members: [...array, ...members_added],
          })
        );
      }
      setSubmitting(false);
      closeAddMemberDialog();
    } else {
      let {
        first_name,
        last_name,
        email,
        address_1,
        address_2,
        postcode,
        state,
        country,
        relationship,
        phone,
        dialcode_mobile,
      } = addForm;
      let customerObj = {
        billing: {
          first_name,
          last_name,
          email,
          address_1,
          address_2,
          postcode,
          state,
          country,
          phone,
        },
        first_name,
        last_name,
        email,
        meta_data: [
          { key: "relationship", value: relationship },
          { key: "digiReferredFrom", value: customerInfo.id },
          { key: "digigold", value: true },
          { key: `lead_source_${store_id}`, value: true },
          { key: `dialcode_mobile`, value: dialcode_mobile.value },
        ],
      };
      // if (!email) return setError("Email address is required");
      if (email && !isEmailValid(email))
        return setError("Please enter valid Email address");
      if (!relationship) return setError("Please enter your relationship");
      customerObj.billing = removeEmpty(customerObj.billing);
      customerObj = removeEmpty(customerObj);
      setSubmitting(true);
      let customer = await createCustomerByUnique({
        value:
          addForm.email ||
          addForm.phone ||
          unformatServerValue(
            `${addForm.first_name?.trim()} ${addForm.last_name?.trim()}`.trim()
          ),
      });
      if (customer?.already_exists) {
        setSubmitting(false);
        return setError("Customer is already created");
      }
      await createCustomer({ ...customerObj, id: customer.data }, true);
      setSubmitting(false);
      closeAddMemberDialog();
    }
  };

  const updateCustomer = async (customerObj) => {
    let website = await getWebsite();
    const token = await getToken();
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/wc/v3/customers/${customerObj.id}`,
          headers: { Authorization: `basic ${token}` },
          method: "PUT",
          data: customerObj,
        });
        return data;
      } catch (error) {
        console.log(getAxiosError(error));
      }
    }
  };

  const createCustomer = async (
    customerObj,
    isUpdate,
    updateSetting = true
  ) => {
    let website = await getWebsite();
    const token = await getToken();
    if (!isUpdate) customerObj.password = "password";
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/wc/v3/customers/${
            isUpdate ? customerObj.id : ""
          }`,
          headers: { Authorization: `basic ${token}` },
          method: isUpdate ? "PUT" : "POST",
          data: customerObj,
        });
        if (isObjWithValues(data)) {
          if (updateSetting) await updateSettings(customerInfo.id, data.id);
          let { members } = customerInfo;
          let array = isArrayWithValues(members) ? members : [];
          dispatch(
            updateCustomerInRedux({
              ...customerInfo,
              members: [...array, data],
            })
          );
        }
      } catch (error) {
        console.log(getAxiosError(error));
        error = getAxiosError(error);
        if (error.code === "registration-error-email-exists") {
          let { data } = await axios({
            url: `${website}/wp-json/wc/v3/customers`,
            headers: { Authorization: `basic ${token}` },
            params: { search: customerObj.email },
          });
          if (isArrayWithValues(data) && !isUpdate) {
            let { id } = data[0];
            createCustomer({ ...customerObj, id }, true);
          }
        }
      }
    }
  };

  const createCustomerByUnique = async (data) => {
    let website = getWebsite();
    let token = getToken();
    let headers = {
      Authorization: `Basic ${token}`,
    };
    let reqOptions = {
      url: `${website}/wp-json/store/v1/customer/create`,
      method: "POST",
      headers,
      data,
    };
    try {
      const response = await axios.request(reqOptions);
      return response.data;
    } catch (error) {
      error = getAxiosError(error);
      return error;
    }
  };
  const updateSettings = async (id, memberId, multiple) => {
    let website = await getWebsite();
    const token = await getToken();
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/settings/digimembers_${id}`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "POST",
          data: {
            setting_type: `digimembers_${id}`,
            data: multiple
              ? [...allMembersIds, ...memberId]
              : [...allMembersIds, memberId],
          },
        });
        console.log(data, "update settigns settings");
        return data;
      } catch (error) {
        console.log(getAxiosError(error));
      }
    }
  };
  const editExistingForm = (index, payload) => {
    setExistingCustomers((state) =>
      state.map((i, _index) => (index == _index ? { ...i, ...payload } : i))
    );
  };
  const onAddMoreExisting = () => {
    setExistingCustomers((state) => [...state, defaultExistingCustomer]);
  };
  const removeExistingCustomer = (index) => {
    setExistingCustomers((state) =>
      state.map((a, i) => (i == index ? false : a)).filter(Boolean)
    );
  };

  return (
    <>
      <DialogContent>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
        >
          <Typography variant="h6" id="alert-dialog-title">
            Add Family Member
          </Typography>
          <IconButton onClick={closeAddMemberDialog}>
            <Close />
          </IconButton>
        </Stack>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab label="Existing Customer" {...a11yProps(0)} />
              <Tab label="New Customer" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={selectedTab} index={0}>
            {existingCustomers.map((i, index) => (
              <ExistingCustomer
                info={i}
                index={index}
                editExistingForm={editExistingForm}
                onCustomerSearch={onCustomerSearch}
                setUpdatedCustomers={setUpdatedCustomers}
                allCustomers={allSearchedCustomers}
                customerSearching={customerSearching}
                removeExistingCustomer={removeExistingCustomer}
              />
            ))}
            <Button
              variant="outlined"
              startIcon={<Add />}
              sx={{
                mt: 2,
                display: "flex",
                alignSelf: "flex-end",
                textAlign: "center",
                float: "right",
              }}
              onClick={onAddMoreExisting}
              size="small"
            >
              Add More
            </Button>
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <Grid container mt={1} spacing={3}>
              <Grid item xs={6}>
                <Label>First Name</Label>
                <TextField
                  size="small"
                  // disabled
                  // label="Duration"
                  value={addForm.first_name}
                  onChange={(e) => editForm({ first_name: e.target.value })}
                  fullWidth
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">Months</InputAdornment>
                  //   ),
                  // }}
                />
              </Grid>
              <Grid item xs={6}>
                <Label>Last Name</Label>
                <TextField
                  size="small"
                  // disabled
                  // label="Duration"
                  value={addForm.last_name}
                  onChange={(e) => editForm({ last_name: e.target.value })}
                  fullWidth
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">Months</InputAdornment>
                  //   ),
                  // }}
                />
              </Grid>
              <Grid item xs={6}>
                <Label>Email</Label>
                <TextField
                  size="small"
                  // disabled
                  // label="Duration"
                  value={addForm.email}
                  onChange={(e) => editForm({ email: e.target.value })}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email fontSize="10px" />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Label>Relationship</Label>
                <FormControl size="small" fullWidth>
                  {/* <InputLabel id="demo-simple-select-label">
                  Mode of Payment
                </InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={addForm.relationship}
                    // label="relationship of Payment"
                    onChange={(e) => editForm({ relationship: e.target.value })}
                  >
                    {isArrayWithValues(allRelationShips) &&
                      allRelationShips.map((i) => {
                        return <MenuItem value={i.value}>{i.label}</MenuItem>;
                      })}
                    {/* <MenuItem value={"cheque"}>Cheque</MenuItem>
                  <MenuItem value={"demand_draft"}>Demand Draft</MenuItem>
                  <MenuItem value={"online_payment"}>Online Payment</MenuItem>
                  <MenuItem value={"ornate_nx"}>Ornate NX</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Label>Phone</Label>
                <Stack direction="row" alignItems={"center"} spacing={2}>
                  <FormControl size="small" sx={{ flex: 1, minWidth: "92px" }}>
                    {/* <InputLabel id="demo-simple-select-label">Dial</InputLabel> */}
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={addForm.dialcode_mobile}
                      // label="Dial"
                      // renderValue={}
                      onChange={(e) =>
                        editForm({ dialcode_mobile: e.target.value })
                      }
                      renderValue={(value) => `+${value.value}`}
                    >
                      <ListSubheader>Common Dial Codes</ListSubheader>
                      {commonDialCodes.map((i) => {
                        return (
                          <MenuItem key={i.value} value={i}>
                            {i.label}
                          </MenuItem>
                        );
                      })}
                      <ListSubheader>Dial Codes</ListSubheader>
                      {dialCodes.map((i) => {
                        return (
                          <MenuItem key={i.value} value={i}>
                            {i.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <TextField
                    // label="Phone"
                    fullWidth
                    onChange={(e) => editForm({ phone: e.target.value })}
                    value={addForm.phone}
                    type="number"
                    sx={{ flex: 4 }}
                    size="small"
                  />
                </Stack>
                <Divider sx={{ mt: 4, mb: 0 }} />
              </Grid>

              <Grid item xs={6}>
                <Label>Address 1</Label>
                <TextField
                  size="small"
                  // disabled
                  // label="Duration"
                  value={addForm.address_1}
                  onChange={(e) => editForm({ address_1: e.target.value })}
                  fullWidth
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">Months</InputAdornment>
                  //   ),
                  // }}
                />
              </Grid>
              <Grid item xs={6}>
                <Label>Address 2</Label>
                <TextField
                  size="small"
                  // disabled
                  // label="Duration"
                  value={addForm.address_2}
                  onChange={(e) => editForm({ address_2: e.target.value })}
                  fullWidth
                  // InputProps={{
                  //   endAdornment: (
                  //     <InputAdornment position="end">Months</InputAdornment>
                  //   ),
                  // }}
                />
              </Grid>
              <Grid item xs={6}>
                <Label>Pin Code</Label>
                <TextField
                  size="small"
                  // disabled
                  // label="Duration"
                  value={addForm.postcode}
                  onChange={postcodeChange}
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {fetchingPostcode && (
                          <CircularProgress
                            style={{ height: "15px", width: "15px" }}
                          />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <Label>City</Label>
                <TextField
                  size="small"
                  // disabled
                  // label="Duration"
                  value={addForm.city}
                  onChange={(e) => editForm({ city: e.target.value })}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <Label>Country</Label>
                <FormControl size="small" fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={addForm.country}
                    onChange={(e) => editForm({ country: e.target.value })}
                    // renderValue={(value) => `+${value.value}`}
                  >
                    {allCountries.map((i) => {
                      return <MenuItem value={i.code}>{i.label}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Label>States</Label>
                <FormControl size="small" fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={addForm.state}
                    onChange={(e) => editForm({ state: e.target.value })}
                    // renderValue={(value) => `+${value.value}`}
                  >
                    {states.map((i) => {
                      return <MenuItem value={i.code}>{i.label}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </TabPanel>
        </Box>
      </DialogContent>
      <Typography
        sx={{
          fontSize: "13px",
          fontWeight: "500",
          mt: 2,
          color: "error.main",
          textAlign: "center",
        }}
      >
        {error}
      </Typography>
      <DialogActions>
        <Button onClick={closeAddMemberDialog}>Cancel</Button>
        <LoadingButton loading={submitting} onClick={onSubmit} autoFocus>
          Add
        </LoadingButton>
      </DialogActions>
    </>
  );
};

const ExistingCustomer = ({
  info,
  editExistingForm,
  index,
  allCustomers,
  onCustomerSearch,
  customerSearching,
  setUpdatedCustomers,
  removeExistingCustomer,
}) => {
  return (
    <Box sx={{ marginTop: "12px", gap: "7px" }}>
      <Stack
        direction={"row"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Label>{index + 1}. Select Customer</Label>
        <Button
          sx={{ mb: 1 }}
          onClick={() => removeExistingCustomer(index)}
          size="small"
          color="error"
        >
          Remove
        </Button>
      </Stack>
      <Autocomplete
        // multiple
        disablePortal
        id="combo-box-demo"
        options={allCustomers}
        size="small"
        // sx={{ width: 300 }}
        value={info?.id}
        loading={customerSearching}
        noOptionsText="No Customers"
        openOnFocus
        renderInput={(params) => (
          <TextField
            {...params}
            // label="UP SELL"
            onChange={(e) =>
              onCustomerSearch(e.target.value, setUpdatedCustomers)
            }
          />
        )}
        onChange={(e, value) => editExistingForm(index, { id: value })}
      />
      <Label sx={{ mt: 1 }}>Relationship</Label>
      <FormControl size="small" fullWidth>
        {/* <InputLabel id="demo-simple-select-label">
                  Mode of Payment
                </InputLabel> */}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={info.relationship}
          // label="relationship of Payment"
          onChange={(e) =>
            editExistingForm(index, { relationship: e.target.value })
          }
        >
          {isArrayWithValues(allRelationShips) &&
            allRelationShips.map((i) => {
              return <MenuItem value={i.value}>{i.label}</MenuItem>;
            })}
          {/* <MenuItem value={"cheque"}>Cheque</MenuItem>
                  <MenuItem value={"demand_draft"}>Demand Draft</MenuItem>
                  <MenuItem value={"online_payment"}>Online Payment</MenuItem>
                  <MenuItem value={"ornate_nx"}>Ornate NX</MenuItem> */}
        </Select>
      </FormControl>
      <Divider sx={{ my: 3 }} />
    </Box>
  );
};

const AllMembers = ({ allMembers, currency }) => {
  let [rows, setRows] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (isArrayWithValues(allMembers)) {
      let rows = [];
      for (let customer of allMembers) {
        let {
          id,
          first_name,
          last_name,
          email,
          avatar_url,
          meta_data,
          digigold_details,
        } = customer;

        let obj = {
          name: `${first_name || ""} ${last_name || ""}`,
          email,
          avatar_url,
          id,
        };
        if (isObjWithValues(digigold_details)) {
          let { grams, total, plans } = digigold_details;
          obj.grams = grams || "0";
          obj.total = total || "0";
          obj.plans = isObjWithValues(plans) ? Object.keys(plans).length : 0;
        }
        let metaKeys = ["relationship"];
        if (isArrayWithValues(meta_data)) {
          for (let metaObj of meta_data) {
            if (metaKeys.includes(metaObj.key))
              obj[metaObj.key] = metaObj.value;
          }
        }
        rows.push(obj);
      }
      setRows(rows);
    } else setRows([]);
  }, [allMembers]);

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Member</TableCell>
              <TableCell align="right">Relationship</TableCell>
              <TableCell align="right">Plans</TableCell>
              <TableCell align="right">Grams</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                hover
                key={row.name}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  cursor: "pointer",
                }}
                onClick={() => navigate(`/crm/${row.id}/#info`)}
              >
                <TableCell component="th" scope="row">
                  <Stack direction="row" alignItems="center">
                    <Avatar
                      src={row.avatar_url}
                      sx={{
                        width: 40,
                        height: 40,
                        fontSize: 13,
                        backgroundColor: "GrayText",
                      }}
                      // srcSet={`${small} 100w, ${medium} 68w, ${large} 1280w, ${xlarge} 3200w`}
                    />
                    <Box sx={{ ml: 2 }}>
                      <Typography variant="h5" sx={{ fontSize: "14px" }}>
                        {row.name}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: "13px",
                          color: "text.secondary",
                          mt: 1,
                        }}
                      >
                        {row.email}
                      </Typography>
                    </Box>
                  </Stack>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontSize: "13px", fontWeight: "500" }}
                >
                  {row.relationship}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontSize: "13px", fontWeight: "500" }}
                >
                  {row.plans}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontSize: "13px", fontWeight: "500" }}
                >
                  {row.grams}
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontSize: "13px", fontWeight: "500" }}
                >
                  {currency || ""} {row.total}
                </TableCell>
                {/* <TableCell align="right">{row.calories}</TableCell>
                <TableCell align="right">{row.carbs}</TableCell>
                <TableCell align="right">{row.protein}</TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const CancelForm = ({ closeCancelPlan, ledger, currency, customerInfo }) => {
  const [addForm, setAddForm] = useState({
    tax: "",
    penalty: "",
    bank_charges: "",
    additional_charges: "",
    final_paid: "",
    close_request_by: "",
    close_by: "",
    additional_benefit: "",
  });
  const [phoneNumber, setPhoneNumber] = useState({
    number: "",
    dialcode: "",
  });
  const dispatch = useDispatch();
  const addingDigigoldCancel = useSelector(
    (state) => state.settings.addingDigigoldCancel
  );
  const [subusers, setSubusers] = useState([]);
  const [rewardInfo, setRewardsInfo] = useState({});

  const reduxTeamMembers = useSelector((state) => state.settings.teamMembers);
  const editForm = (e) => setAddForm((state) => ({ ...state, ...e }));
  const smsIntegration = useSelector((state) => state.settings.smsIntegration);
  const allDigiPlans = useSelector((state) => state.product.digigold_plans);

  useEffect(() => {
    if (rewardInfo?.total_reward)
      editForm({
        additional_benefit: rewardInfo.total_reward,
      });
  }, [rewardInfo]);

  useEffect(() => {
    setRewardsInfo(
      getBonusInfoOnPlanRedeem({ plans: allDigiPlans, user_plan_info: ledger })
    );
  }, [allDigiPlans, ledger]);

  // console.log(allDigiPlans, closeCancelPlan, ledger, currency, customerInfo);

  useEffect(() => {
    let { dialcode_mobile, dialcode_whatsapp, whatsapp, billing } =
      customerInfo;
    let dialcode, number;
    if (dialcode_mobile && billing?.phone) {
      dialcode = dialcode_mobile;
      number = billing?.phone;
    } else if (dialcode_whatsapp && whatsapp) {
      dialcode = dialcode_whatsapp;
      number = whatsapp;
    }
    setPhoneNumber({ number, dialcode });
  }, [customerInfo]);

  useEffect(() => {
    if (isArrayWithValues(reduxTeamMembers)) {
      setSubusers(
        [...reduxTeamMembers].map((i) => {
          return {
            label: `${i.first_name} ${i.last_name}`,
            value: `${i.first_name} ${i.last_name}`,
          };
        })
      );
    }
  }, [reduxTeamMembers]);

  let { paid_count, duration, paid, joining_date } = ledger;

  const onSubmit = () => {
    let obj = { ...addForm, plan_id: ledger.product };
    if (!obj?.final_paid) {
      obj.final_paid = Math.round(
        validateNumber(paid) -
          (validateNumber(addForm.tax) +
            validateNumber(addForm.additional_charges) +
            validateNumber(addForm.bank_charges) +
            validateNumber(addForm.penalty)) +
          validateNumber(addForm.additional_benefit)
      );
    }
    obj = {
      id: `${ledger.product}_${customerInfo.id}_${getTimeInMilliSeconds()}`,
      time: new Date().getTime(),
      ...obj,
    };
    dispatch(
      addDigigoldPlanCancel(ledger.product, customerInfo.id, obj, {
        onSuccess: () => closeCancelPlan({ cancelled_plan: obj }),
        onError: (e) => {
          dispatch(
            setGlobalToast({
              show: true,
              message: e?.message || "Unable to close Plan",
              severity: "error",
            })
          );
          closeCancelPlan({ cancelled_plan: obj });
        },
      })
    );
  };

  const onSendOTP = async () => {
    try {
      editForm({
        sending_otp: true,
      });
      // let res = await backendApiCall({
      //   endpoint: "sms/otp/generate",
      //   method: "POST",
      //   data: {
      //     to: `${phoneNumber.dialcode || ""}${phoneNumber?.number || ""}`,
      //   },
      // });
      let res = await onSendSMS({
        smsIntegration: smsIntegration,
        customer: customerInfo,
        type: "otp_template",
        sms_type: "otp",
      });
      if (res?.success) {
        editForm({ is_otp_sent: true, otp_sent: res?.otp_sent });
        dispatch(
          setGlobalToast({
            show: true,
            message: "OTP has been sent successfully",
            severity: "success",
          })
        );
      }
      editForm({
        sending_otp: false,
      });
    } catch (error) {
      console.log(error);
      editForm({
        otp_error: "Unable to send OTP",
      });
    }
  };
  const onVerifyOTP = async () => {
    try {
      editForm({
        verifying_otp: true,
      });
      // await sleep(2000);
      let otpRes = {};
      let phoneNumberRes = getCRMPhoneNumber({
        customer: customerInfo,
        options: { phone_priority: true },
      });
      let res;
      if (smsIntegration?.selected_channel === "msg91") {
        res = await verifyOTPMsg91(
          addForm.otp,
          phoneNumberRes?.data,
          smsIntegration?.[smsIntegration?.selected_channel]?.auth_key
        );
        console.log(res, "<<<< res");
      }
      console.log(addForm.otp, addForm.otp_sent, addForm);
      if (res?.data?.data?.type === "success") {
        otpRes.success = true;
      } else otpRes.not_match = true;
      // if (addForm.otp + "" === addForm.otp_sent + "") {
      //   otpRes.success = true;
      // } else otpRes.not_match = true;

      // let otpRes = await backendApiCall({
      //   endpoint: "sms/otp/verify",
      //   method: "POST",
      //   data: {
      //     to: `${phoneNumber.dialcode || ""}${phoneNumber?.number || ""}`,
      //     otp: addForm.otp,
      //   },
      // });

      if (!otpRes?.success)
        if (
          otpRes?.message?.toLowerCase()?.includes("match") ||
          otpRes.not_match
        )
          editForm({
            otp_error: "Entered OTP is incorrect",
          });
        else
          editForm({
            otp_error: "Unable to verify OTP",
          });
      if (otpRes?.success) {
        editForm({ is_otp_verified: true });
        editForm({
          otp_error: "",
        });
      }
    } catch (error) {
      console.log(error);
      editForm({
        otp_error: "Unable to verify OTP",
      });
    } finally {
      editForm({
        verifying_otp: false,
      });
    }
  };
  return (
    <>
      <DialogContent>
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="h6" id="alert-dialog-title">
            Close Plan
          </Typography>
          <IconButton onClick={closeCancelPlan}>
            <Close />
          </IconButton>
        </Stack>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent="space-between"
            >
              <Label>Plan Date - </Label>
              <Label sx={{ ml: 1 }}>
                {isValidDate(new Date(joining_date))
                  ? formatDate(new Date(joining_date), "relative_date")
                  : ""}
                {" - "}
                {isValidDate(new Date(joining_date))
                  ? formatDate(
                      new Date(
                        new Date(joining_date).setMonth(
                          new Date(joining_date).getMonth() + Number(duration)
                        )
                      ),
                      "relative_date"
                    )
                  : ""}
              </Label>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent="flex-start"
            >
              <Label>Paid Installments - </Label>
              <Label sx={{ ml: 1 }}>
                {paid_count}/{duration}
              </Label>
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent="space-between"
            >
              <Label>Total Paid Amount - </Label>
              <Label sx={{ ml: 1 }}>
                {currency} {paid}
              </Label>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent="space-between"
            >
              <Label sx={{ color: "success.main" }}>Amount Payable - </Label>
              <Label
                sx={{
                  ml: 1,
                  fontSize: "15px",
                  fontWeight: "bold",
                  color: "success.main",
                }}
              >
                {currency} {paid}
              </Label>
            </Stack>
          </Grid>
          {/* <Grid item xs={6}>
          <Box>
            <Label>Additional Benefit</Label>
            <TextField
              // variant="outlined"
              size="small"
              // disabled
              // label="Duration"
              // value={addForm.postcode}
              // onChange={postcodeChange}
              // fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{currency}</InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid> */}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Label sx={{ fontSize: "15px" }}>Deduction: </Label>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Label sx={{ color: "error.main" }}>Tax -</Label>
              <TextField
                // variant="outlined"
                size="small"
                // disabled
                // label="Duration"
                value={addForm.tax}
                onChange={(e) => editForm({ tax: e.target.value })}
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Label sx={{ color: "error.main" }}>Penalty -</Label>
              <TextField
                // variant="outlined"
                size="small"
                // disabled
                // label="Duration"
                value={addForm.penalty}
                onChange={(e) => editForm({ penalty: e.target.value })}
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Label sx={{ color: "error.main" }}>Bank Charges -</Label>
              <TextField
                // variant="outlined"
                size="small"
                // disabled
                // label="Duration"

                value={addForm.bank_charges}
                onChange={(e) => editForm({ bank_charges: e.target.value })}
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Label sx={{ color: "error.main" }}>Additional Charges -</Label>
              <TextField
                // variant="outlined"
                size="small"
                // disabled
                // label="Duration"
                value={addForm.additional_charges}
                onChange={(e) =>
                  editForm({ additional_charges: e.target.value })
                }
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Label sx={{ color: "success.main" }}>Additional Benefit +</Label>
              <TextField
                // variant="outlined"
                size="small"
                // disabled
                // label="Duration"

                value={addForm.additional_benefit}
                onChange={(e) =>
                  editForm({ additional_benefit: e.target.value })
                }
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      {isArrayWithValues(rewardInfo?.reward_breakup) && (
                        <Tooltip
                          title={
                            <div>
                              {rewardInfo?.reward_breakup?.map((i, index) => (
                                <div key={index}>
                                  <div>
                                    {currency} {i.amount} for {i.month} months
                                  </div>
                                </div>
                              ))}
                            </div>
                          }
                        >
                          <InfoOutlined
                            style={{ height: "18px", width: "18px" }}
                          />
                        </Tooltip>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ alignSelf: "center" }}>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"flex-end"}
            >
              <Label>Closing Balance: </Label>
              <Label sx={{ ml: 2 }}>
                {currency}{" "}
                {Math.round(
                  validateNumber(paid) -
                    (validateNumber(addForm.tax) +
                      validateNumber(addForm.additional_charges) +
                      validateNumber(addForm.bank_charges) +
                      validateNumber(addForm.penalty)) +
                    validateNumber(addForm.additional_benefit)
                )}
              </Label>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
              mt={1}
            >
              <Label>Final Paid: </Label>
              <TextField
                // variant="outlined"
                size="small"
                // disabled
                // label="Duration"

                value={addForm.final_paid}
                onChange={(e) => editForm({ final_paid: e.target.value })}
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">{currency}</InputAdornment>
                  ),
                }}
              />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={6}>
            <Box>
              <Label>Close Request By</Label>
              <TextField
                // variant="outlined"
                size="small"
                placeholder="Enter name"
                // disabled
                // label="Duration"

                value={addForm.close_request_by}
                onChange={(e) => editForm({ close_request_by: e.target.value })}
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person style={{ height: "20px", width: "20px" }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Label>Close By</Label>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                options={subusers.map((option) => option.label)}
                inputValue={addForm.close_by}
                onInputChange={(a, e) => editForm({ close_by: e })}
                renderInput={(params) => <TextField {...params} />}
                size="small"
                openOnFocus
              />
              {/* <TextField
                // variant="outlined"
                size="small"
                placeholder="Enter name"
                // disabled
                // label="Duration"
                value={addForm.close_by}
                onChange={(e) => editForm({ close_by: e.target.value })}
                // fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person style={{ height: "20px", width: "20px" }} />
                    </InputAdornment>
                  ),
                }}
              /> */}
            </Box>
          </Grid>
        </Grid>
        {!addForm.is_otp_verified && (
          <FormControlLabel
            control={
              <Checkbox
                checked={addForm.otp_verified}
                onChange={(e) => editForm({ otp_verified: e.target.checked })}
              />
            }
            label="Verify with OTP"
          />
        )}
        {addForm.otp_verified &&
          !addForm.is_otp_verified &&
          addForm?.is_otp_sent && (
            <Box>
              <Label sx={{ color: "success.main" }}>
                Enter OTP (Phone:{" "}
                {`${phoneNumber.dialcode || ""} ${phoneNumber?.number || ""}`}):{" "}
              </Label>
              <TextField
                // variant="outlined"
                size="small"
                // disabled
                // label="Duration"

                value={addForm.otp}
                onChange={(e) => editForm({ otp: e.target.value })}
                // fullWidth
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start">{currency}</InputAdornment>
                //   ),
                // }}
              />
            </Box>
          )}
      </DialogContent>
      {addForm.is_otp_verified && (
        <SecondaryLabelSmall
          sx={{ color: "success.main", textAlign: "center" }}
        >
          OTP has been verified
        </SecondaryLabelSmall>
      )}
      {addForm.otp_error && (
        <SecondaryLabelSmall sx={{ color: "error.main", textAlign: "center" }}>
          {addForm?.otp_error}
        </SecondaryLabelSmall>
      )}
      <DialogActions>
        <Button onClick={closeCancelPlan}>Close</Button>
        {addForm?.otp_verified && !addForm.is_otp_verified ? (
          !addForm?.is_otp_sent ? (
            <LoadingButton
              variant="contained"
              // color="error"
              size="small"
              onClick={onSendOTP}
              loading={addForm.sending_otp}
              autoFocus
            >
              Send OTP
            </LoadingButton>
          ) : (
            <LoadingButton
              variant="contained"
              // color="error"
              size="small"
              onClick={onVerifyOTP}
              loading={addForm.verifying_otp}
            >
              Verify OTP
            </LoadingButton>
          )
        ) : (
          <LoadingButton
            variant="contained"
            color="error"
            size="small"
            onClick={onSubmit}
            loading={addingDigigoldCancel}
            autoFocus
          >
            Cancel Plan
          </LoadingButton>
        )}
      </DialogActions>
    </>
  );
};

export const AddManualPayment = ({
  closeManualForm,
  paymentMethods,
  allPlans,
  currency,
  disableAllPlans,
  dialogTitle,
  branches,
  teamMembers,
  userView,
  setUserView,
  todayGoldRate,
  selectedPlanId,
  setViewLedger,
  fetchingAllPlans,
  setCustomerInfo,
  refreshPlans,
}) => {
  let [addForm, setAddForm] = useState({
    id: "",
    plan: "",
    duration: "",
    amount: "",
    weight: "",
    payment_ref: "",
    remarks: "",
    mode: "",
    rate: "",
    date_paid: new Date(),
    type: "",
    branch: "",
    collected_by: "",
    change_gold_rate: false,
    digigold_uid: "",
  });
  const [goldPricing, setGoldPricing] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const user = useSelector((state) => state.user);
  const [isPlanAssign, setIsPlanAssign] = useState(false);

  const dispatch = useDispatch();
  const editForm = (payload) => {
    setAddForm((state) => ({ ...state, ...payload }));
  };

  const [plans, setPlans] = useState([]);
  const masterPricing = useSelector((state) => state.settings.masterPricing);

  const productSettings = useSelector(
    (state) => state.settings.productSettings
  );

  useEffect(() => {
    if (dialogTitle && typeof dialogTitle === "string")
      setIsPlanAssign(dialogTitle?.includes("Assign"));
  }, [dialogTitle]);

  useEffect(() => {
    if (!isArrayWithValues(allPlans)) return;
    if (selectedPlanId) return setPlans(allPlans);
    let filteredPlans = [...allPlans];
    if (isObjWithValues(userView)) {
      let { plans: _plans, digigold_details } = userView;
      let planIds = [];
      if (digigold_details && isObjWithValues(digigold_details?.plans))
        planIds = Object.keys(digigold_details?.plans);
      if (isObjWithValues(_plans)) planIds = Object.keys(_plans);

      if (isObjWithValues(planIds))
        filteredPlans = allPlans.filter(
          (i) => !planIds.includes(`${i.product}`)
        );
    }
    setPlans(filteredPlans);
  }, [userView, allPlans]);

  useEffect(() => {
    let currency = "";
    if (isObjWithValues(productSettings))
      currency = productSettings.default_currency;
    if (isObjWithValues(masterPricing)) {
      let { gold_pricing } = masterPricing;
      if (gold_pricing[currency]) {
        let { type } = gold_pricing;
        let { enabled_purities } = gold_pricing[currency];
        if (type) {
          let purityObjects = gold_pricing[currency][type];
          let array = [];
          for (let purity in enabled_purities) {
            if (enabled_purities[purity]) {
              let obj = goldPurities.find((i) => i.value == purity);
              if (!obj) continue;
              array.push({ ...obj, rate: purityObjects[purity].rate });
            }
          }
          setGoldPricing(array);
        }
      }
    }
  }, [masterPricing, productSettings]);

  useEffect(() => {
    if (todayGoldRate) editForm({ rate: todayGoldRate });
  }, [todayGoldRate]);
  // console.log(todayGoldRate, addForm?.rate, "<<<<<<<<<");

  useEffect(() => {
    editForm({ plan: selectedPlanId });
  }, [selectedPlanId]);

  useEffect(() => {
    let updatedAddForm = {};
    let { plan } = addForm;
    if (!plan) return;
    let selectedPlan = allPlans.find((i) => i.product == plan);
    console.log(selectedPlan, "<<<<");
    if (!isObjWithValues(selectedPlan)) return;
    let { meta_data } = selectedPlan;
    if (isArrayWithValues(meta_data))
      for (let i of meta_data) selectedPlan[i.key] = i.value;
    console.log(selectedPlan, "<<<<");
    let {
      type: digi_plan_type,
      duration: digi_plan_duration,
      amount: price,
      weight: plan_total_grams,
      change_gold_rate,
    } = selectedPlan;
    if (digi_plan_type === "amount") {
      updatedAddForm.amount = price;
      updatedAddForm.weight = 0;
      if (addForm.rate)
        updatedAddForm.weight = Number(
          updatedAddForm.amount / Number(addForm.rate)
        ).toFixed(3);
      updatedAddForm.type = "amount";
    }
    if (digi_plan_type === "flexi") {
      updatedAddForm.amount = Math.round(
        Math.round(price / Number(digi_plan_duration))
      );
      updatedAddForm.weight = 0;
      if (addForm.rate)
        updatedAddForm.weight = Number(
          updatedAddForm.amount / Number(addForm.rate)
        ).toFixed(3);
      updatedAddForm.type = "flexi";
    }
    if (digi_plan_type === "grams") {
      let gramsPerMonth = Number(plan_total_grams) / Number(digi_plan_duration);
      if (gramsPerMonth && addForm.rate)
        updatedAddForm.amount = Math.round(
          Number(addForm.rate) * gramsPerMonth
        );
      updatedAddForm.weight = Number(gramsPerMonth).toFixed(3);
      updatedAddForm.type = "grams";
    }
    updatedAddForm.change_gold_rate = Boolean(change_gold_rate);
    updatedAddForm.duration = digi_plan_duration;
    editForm(updatedAddForm);
  }, [addForm.plan, addForm.rate]);

  console.log(addForm);

  // useEffect(() => {
  //   let { plan, type, amount, weight } = addForm;
  //   if(type === 'flexi'){

  //   }

  // }, [addForm.amount, addForm.weight]);

  const onAmountChange = (value) => {
    let obj = { amount: value };
    if (addForm.type === "flexi") {
      let { rate } = addForm;
      if (rate)
        obj.weight = validateNumber(
          (validateNumber(value) / validateNumber(rate)).toFixed(3)
        );
    }
    editForm({ ...obj });
  };
  const onGramChange = (value) => {
    let obj = { weight: value };
    if (addForm.type === "flexi") {
      let { rate } = addForm;
      if (rate)
        obj.amount = validateNumber(
          Math.round(validateNumber(rate) * validateNumber(value))
        );
    }
    editForm({ ...obj });
  };

  // console.log(userView?.digigold_details?.plans, "<<<<<<<<< currentPlans");
  const onSubmit = async () => {
    let { billing, shipping } = userView;
    console.log(userView, "<<<<<<<< userView");
    let planObj =
      isArrayWithValues(allPlans) &&
      allPlans.find((i) => i.product == addForm.plan);
    let currentPlans = Object.values(userView?.digigold_details?.plans || {});
    let samePlanPurchaseCount = 0;
    currentPlans.forEach((i) => {
      if (i?.product + "" === planObj?.product + "") samePlanPurchaseCount++;
    });
    let added_by = "";
    let added_by_id = "";
    if (isAdmin) {
      added_by = "admin";
    } else {
      added_by = `${user?.first_name} ${user?.last_name}`;
    }
    added_by_id = getId();
    let obj = {
      customer_id: userView.id,
      billing,
      shipping,
      payment_method: addForm.mode,
      payment_method_title: "",
      // date_paid: getDate(addForm.date_paid),
      status: "completed",
      line_items: [
        {
          product_id: planObj?.product,
          quantity: 1,
          total: `${addForm.amount}`,
          tax_class: "zero-rate",
        },
      ],
    };
    if (!obj.billing.email) delete obj.billing.email;
    if (isArrayWithValues(paymentMethods)) {
      let paymentObj = paymentMethods.find((i) => i.value == addForm.mode);
      if (paymentObj) obj.payment_method_title = paymentObj.label;
    }
    let metaKeys = ["payment_ref", "remarks", "branch"];
    let meta_data = [
      {
        key: "virtual_order",
        value: "digigold",
      },
      {
        key: "manual_payment",
        value: "yes",
      },
      {
        key: "gold_gross",
        value: addForm.weight,
      },
      {
        key: "added_by",
        value: added_by,
      },
      {
        key: "added_by_id",
        value: added_by_id,
      },
      {
        key: "collected_by",
        value: addForm.collected_by,
      },
      {
        key: "manual_order_date",
        value: new Date(addForm.date_paid).getTime() / 1000,
      },
      { key: "tax_status", value: "none" },
    ];
    for (let key of metaKeys)
      if (addForm[key]) meta_data.push({ key, value: addForm[key] });
    meta_data.push({
      key: "branch_name",
      value: addForm.branch,
    });
    obj.meta_data = meta_data;
    setSubmitting(true);
    // let totalUniqueIdsAdded = await getTotalUniqueIdAdded(addForm.plan);

    if (isPlanAssign)
      await updateCustomerDigiUniqueId(
        userView,
        addForm.plan,
        `${planObj.sku || getInitials(planObj.plan_name)}${
          samePlanPurchaseCount ? "-" + samePlanPurchaseCount : ""
        }-${userView.id}`,
        samePlanPurchaseCount
      );

    let res = await createOrder(obj);

    if (res) {
      await backendApiCall({
        endpoint: "webhooks/trigger",
        data: {
          event_name: "DIGIGOLD_PAYMENT_DONE",
          tenant_id: await getTenantId(),
          users: [
            {
              id: userView?.id,
              type: "CRM",
            },
          ],
        },
        method: "POST",
      });

      let ledgerItem = {
        id: res?.id,
        // url: "https://tiarabytj.com/checkout/order-pay/70369/?pay_for_order=true&key=wc_order_r4gQTzb7DqSYE",
        status: "completed",
        date_paid: formatDateDynamic(
          new Date(addForm.date_paid),
          "MMMM DD, YYYY, HH:mm:ss"
        ),
        mode: addForm.mode,
        transaction_id: "",
        total: addForm.amount,
        ref: addForm.payment_ref,
        gold: `${addForm.weight} grams`,
        manual: true,
        branch: addForm.branch,
      };
      let digigold_details = userView.digigold_details;

      if (isObjWithValues(digigold_details?.plans)) {
        let plans = digigold_details?.plans;
        let updatedPlans = {};
        let viewLedger;
        for (let plan_id in plans) {
          let plan = plans[plan_id];
          if (
            planObj?.product + "" === plan?.product + "" &&
            isArray(plan?.ledger)
          ) {
            plan.ledger = [...plan?.ledger, ledgerItem];
            let totalPaid = 0;
            plan.ledger?.forEach(
              (i) => (totalPaid += validateNumber(i?.total))
            );
            viewLedger = {
              ...plan,
              paid_count: plan?.ledger?.length,
              paid: totalPaid,
            };
            updatedPlans[plan_id] = viewLedger;
          } else updatedPlans[plan_id] = plan;
        }
        setCustomerInfo({
          ...userView,
          digigold_details: {
            ...userView.digigold_details,
            plans: updatedPlans,
          },
        });
        if (viewLedger) setViewLedger(viewLedger);
      }
      // let customerRes = await getCustomer(userView.id);
      // console.log(customerRes);
      // if (customerRes && customerRes.data) {
      //   console.log(customerRes.data, res);
      //   setUserView(customerRes.data);
      //   let plans = customerRes.data?.digigold_details?.plans;
      //   if (
      //     isArrayWithValues(res?.line_items) &&
      //     plans?.[res?.line_items?.[0]?.product_id]
      //   )
      //     setViewLedger && setViewLedger(plans[res.line_items[0].product_id]);
      // }
    }
    // if (res) {
    //   let customers = await getDigiCustomers();
    //   console.log(customers, "digi customers");
    //   if (isObjWithValues(customers)) {
    //     dispatch({
    //       type: SET_CRM_DATA,
    //       payload: {
    //         allDigiCustomers: customers,
    //       },
    //     });
    //     setUserView(customers[userView.id]);
    //   }
    //   dispatch(
    //     setGlobalToast({
    //       show: true,
    //       message: "Manual Payment added successfully!",
    //       severity: "success",
    //     })
    //   );
    // }
    setSubmitting(false);
    refreshPlans && refreshPlans();
    closeManualForm();
    // closeManualDialog();
  };

  const createOrder = async (obj) => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/wc/v3/orders`,
          method: "POST",
          data: obj,
          headers: { Authorization: `Basic ${token}` },
        });
        return data;
      } catch (error) {
        console.log(getAxiosError(error));
        return false;
      }
    }
  };
  const getDigiCustomers = async () => {
    let website = getWebsite();
    let token = getToken();
    if (website && token) {
      try {
        let { data } = await axios({
          url: `${website}/wp-json/store/v1/digigold`,
          // url: `${url}/customers`,
          // params,
          headers: {
            Authorization: `Basic ${token}`,
          },
        });
        return data;
      } catch (error) {
        console.log(getAxiosError(error));
      }
    }
  };

  return (
    <Box className="scrollbar-hidden">
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent className="scrollbar-hidden">
        <Grid container mt={0.5} spacing={4}>
          <Grid item xs={8}>
            <Label>Plan Name</Label>
            <FormControl disabled={disableAllPlans} size="small" fullWidth>
              {/* <InputLabel id="demo-simple-select-label">Plan Name</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={addForm.plan}
                placeholder="Plan Name"
                onChange={(e) => editForm({ plan: e.target.value })}
                // disabled
              >
                {fetchingAllPlans ? (
                  <MenuItem disabled>Loading...</MenuItem>
                ) : (
                  isArrayWithValues(plans) &&
                  plans.map((i) => {
                    return <MenuItem value={i.product}>{i.plan_name}</MenuItem>;
                  })
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <Label>Duration</Label>
            <TextField
              size="small"
              disabled
              // label="Duration"
              value={addForm.duration}
              onChange={(e) => editForm({ duration: e.target.value })}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Months</InputAdornment>
                ),
              }}
              type="number"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: addForm.change_gold_rate ? "block" : "none" }}
          >
            <Label>Gold Rates</Label>
            <TextField
              size="small"
              // disabled
              // label="Amount"
              value={addForm.rate}
              onChange={(e) => editForm({ rate: e.target.value })}
              // onChange={(e) => onRateChange(e.target.value)}
              fullWidth
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{currency}</InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment>
                    <FormControl
                      variant="standard"
                      sx={{
                        "&:hover": {
                          border: "0px solid transparent",
                          backgroundColor: "transparent",
                          borderRadius: "10px",
                        },
                        "&:focus": {
                          backgroundColor: "transparent",
                        },
                        // minWidth: minWidth || "120px",
                      }}
                      size="small"
                    >
                      {/* <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel> */}
                      <Select
                        disableUnderline
                        id="demo-multiple-checkbox"
                        // multiple
                        // open={open}
                        // input={<OutlinedInput  label={""} />}
                        // renderValue={(selected) => selected}
                        value={addForm.rate}
                        onChange={(e) => {
                          // handleClose();
                          // onChange(e);
                          // onRateChange(e.target.value);
                          editForm({ rate: e.target.value });
                        }}
                        // onClose={handleClose}
                        // onOpen={handleOpen}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        style={{ backgroundColor: "transparent" }}
                        SelectDisplayProps={{
                          style: { backgroundColor: "transparent" },
                        }}
                        sx={{
                          // margin: "4px 6px",
                          "&:focus": {
                            backgroundColor: "transparent",
                          },
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                        }}
                        size="small"
                      >
                        {isArrayWithValues(goldPricing) &&
                          goldPricing.map((obj) => {
                            return (
                              <MenuItem key={obj.value} value={obj.rate}>
                                {obj.label}
                                {/* <ListItemText primary={obj.label} /> */}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <Label>Amount</Label>
            <TextField
              size="small"
              disabled={
                // true
                addForm.type !== "flexi"
              }
              // label="Amount"
              value={addForm.amount}
              // onChange={(e) => editForm({ amount: e.target.value })}
              onChange={(e) => onAmountChange(e.target.value)}
              fullWidth
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">{currency}</InputAdornment>
                ),
              }}
            />
            {addForm.rate ? (
              <Typography
                sx={{
                  fontSize: "11px",
                  color: "text.secondary",
                  mt: 0.2,
                  ml: 0.2,
                }}
              >
                As per todays gold rate
                <strong style={{ marginLeft: "3px" }}>
                  {currency} {addForm.rate}
                </strong>
              </Typography>
            ) : null}
          </Grid>
          <Grid item xs={6}>
            <Label>Gold Credit</Label>
            <TextField
              size="small"
              disabled={addForm.type !== "flexi"}
              // disabled={true}
              // label="Gold Credit"
              value={addForm.weight}
              // onChange={(e) => editForm({ weight: e.target.value })}
              onChange={(e) => onGramChange(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Grams</InputAdornment>
                ),
              }}
              type="number"
            />
          </Grid>
          {isPlanAssign && false && (
            <Grid item xs={6}>
              <Label>Unique id</Label>
              <TextField
                size="small"
                // label="Duration"
                value={addForm.digigold_uid}
                onChange={(e) =>
                  editForm({ digigold_uid: e.target.value?.toUpperCase() })
                }
                fullWidth
              />
              <Typography
                sx={{
                  fontSize: "11px",
                  color: "text.secondary",
                  mt: 0.2,
                  ml: 0.2,
                }}
              >
                For e.g. Plan1-01
              </Typography>
            </Grid>
          )}
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Label>Payment made on</Label>
              <DatePicker
                // label="Payment made on"
                style={{ width: "100%" }}
                value={addForm.date_paid}
                toolbarFormat="ddd DD MMMM"
                onChange={(newValue) => {
                  editForm({ date_paid: newValue });
                }}
                renderInput={(params) => (
                  <TextField size="small" fullWidth {...params} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <Label>Mode of payment</Label>
            <FormControl size="small" fullWidth>
              {/* <InputLabel id="demo-simple-select-label">
                  Mode of Payment
                </InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={addForm.mode}
                // label="Mode of Payment"
                onChange={(e) => editForm({ mode: e.target.value })}
              >
                {isArrayWithValues(paymentMethods) &&
                  paymentMethods.map((i) => {
                    return (
                      <MenuItem value={i.value}>
                        {i.label === "Cash on delivery" ? "Cash" : i.label}
                      </MenuItem>
                    );
                  })}
                {/* <MenuItem value={"cheque"}>Cheque</MenuItem>
                  <MenuItem value={"demand_draft"}>Demand Draft</MenuItem>
                  <MenuItem value={"online_payment"}>Online Payment</MenuItem>
                  <MenuItem value={"ornate_nx"}>Ornate NX</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <Label>Payment Reference</Label>
            <TextField
              size="small"
              // label="Payment Reference"
              value={addForm.payment_ref}
              onChange={(e) => editForm({ payment_ref: e.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <Label>Branch</Label>
            <FormControl size="small" fullWidth>
              {/* <InputLabel id="demo-simple-select-label">
                  Mode of Payment
                </InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={addForm.branch}
                // label="branch of Payment"
                onChange={(e) => editForm({ branch: e.target.value })}
              >
                {isArrayWithValues(branches) &&
                  branches.map((i) => {
                    return (
                      <MenuItem value={i.branchName}>{i.branchName}</MenuItem>
                    );
                  })}
                {/* <MenuItem value={"cheque"}>Cheque</MenuItem>
                  <MenuItem value={"demand_draft"}>Demand Draft</MenuItem>
                  <MenuItem value={"online_payment"}>Online Payment</MenuItem>
                  <MenuItem value={"ornate_nx"}>Ornate NX</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={isPlanAssign ? 6 : 12}>
            <Label>Collected by</Label>
            <TextField
              size="small"
              // label="collected By"
              value={addForm.collected_by}
              // rows={2}
              // multiline
              helperText="Name of the person collected"
              onChange={(e) => editForm({ collected_by: e.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Label>Remarks</Label>
            <TextField
              size="small"
              // label="Remarks"
              value={addForm.remarks}
              rows={2}
              multiline
              onChange={(e) => editForm({ remarks: e.target.value })}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeManualForm}>Cancel</Button>
        <LoadingButton loading={submitting} onClick={onSubmit} autoFocus>
          Add
        </LoadingButton>
      </DialogActions>
    </Box>
  );
};

// const AddManualPayment = ({
//   closeManualDialog,
//   currency,
//   defaultFormProp,
//   paymentMethods,
//   customerInfo,
//   goldPricing,
//   digiInfo,
//   allPlans,
//   manualPaymentOpen,
//   disableAllPlans,
//   setViewLedger,
//   setCustomerInfo,
// }) => {
//   let [addForm, setAddForm] = useState({
//     id: "",
//     plan_name: "",
//     duration: "",
//     amount: "",
//     weight: "",
//     payment_ref: "",
//     remarks: "",
//     mode: "",
//     rate: "",
//     date_paid: new Date(),
//     type: "",
//   });
//   // const [allPlans, setAllPlans] = useState([]);
//   const [defaultForm, setDefaultForm] = useState({});
//   const [submitting, setSubmitting] = useState(false);

//   const dispatch = useDispatch();

//   useEffect(() => {}, [customerInfo]);

//   const editForm = (payload) => {
//     // console.log(payload);
//     // console.log(
//     //   Object.keys(payload).includes("rate") && defaultFormProp.type === "grams"
//     // );
//     // console.log(
//     //   Object.keys(payload).includes("amount"),
//     //   Object.keys(payload).includes("rate"),
//     //   addForm.type
//     // );
//     if (
//       // false
//       Object.keys(payload).includes("weight")
//       // ||
//       // (Object.keys(payload).includes("rate") && addForm.type === "grams")
//     )
//       calculateAmount({ ...addForm, ...payload }, "edit");
//     if (
//       Object.keys(payload).includes("amount") ||
//       (Object.keys(payload).includes("rate") && addForm.type === "amount")
//     )
//       calculateWeight({ ...addForm, ...payload });
//     setAddForm((state) => ({ ...state, ...payload }));
//   };

//   // useEffect(() => {
//   // }, [addForm.rate]);

//   // const onRateChange = (e) => {
//   //   let obj = { rate: e };
//   //   let { rate, type, weight } = addForm;
//   //   console.log(rate, type);
//   //   if (rate && weight && type !== "amount") {
//   //     let amount = Number(rate) * Number(weight);
//   //     amount = Math.round(amount);
//   //     // console.log(amount, "<<<<");
//   //     // editForm({ amount });
//   //     obj.amount = amount;
//   //     // setAddForm((state) => ({ ...state, amount }));
//   //   }
//   //   if (type === "amount") {
//   //     let amount = obj.amount || addForm.amount;
//   //     if (amount && rate && type !== "grams") {
//   //       let weight = Number(amount) / Number(rate);
//   //       weight = Number(weight).toFixed(3);
//   //       // console.log(weight);
//   //       obj.weight = weight;
//   //     }
//   //   }
//   //   setAddForm((state) => ({ ...state, ...obj }));
//   //   // if (rate && type === "grams")
//   //   //   calculateAmount({ ...addForm, rate: e }, "rate");
//   // };

//   useEffect(() => {}, [addForm.amount, addForm.rate]);
//   useEffect(() => {}, [addForm.weight, addForm.rate]);

//   useEffect(() => {
//     if (isArrayWithValues(goldPricing)) {
//       let { weight, amount } = addForm;
//       let rate = goldPricing[0].rate;
//       if (!weight && amount) {
//         weight = Number(amount) / Number(rate);
//         weight = Number(weight).toFixed(3);
//       }
//       editForm({ rate, weight });
//     }
//   }, [goldPricing, addForm.amount]);

//   useEffect(() => {
//     if (isArrayWithValues(allPlans)) {
//       let obj = allPlans.find((i) => i.value == addForm.plan_name);
//       if (obj) setupDefaultForm(obj);
//     }
//   }, [allPlans, addForm.plan_name]);

//   useEffect(() => {
//     if (isObjWithValues(defaultFormProp)) {
//       setupDefaultForm(defaultFormProp);
//     }
//   }, [defaultFormProp]);

//   const calculateWeight = (obj) => {
//     let { amount, rate } = obj;
//     if (amount && rate && addForm.type !== "grams") {
//       let weight = Number(amount) / Number(rate);
//       weight = Number(weight).toFixed(3);
//       // console.log(weight);
//       setAddForm((state) => ({ ...state, weight }));
//     }
//   };

//   const calculateAmount = (obj, con) => {
//     let { rate, weight } = obj;
//     console.log(weight, rate, addForm.type, con);
//     if (rate && weight && addForm.type !== "amount") {
//       let amount = Number(rate) * Number(weight);
//       amount = Math.round(amount);
//       // console.log(amount, "<<<<");
//       editForm({ amount });
//       // setAddForm((state) => ({ ...state, amount }));
//     }
//   };

//   const setupDefaultForm = (defaultFormProp) => {
//     // console.log(defaultFormProp);
//     let { weight, duration, plan_name, type, id, amount } = {
//       ...defaultFormProp,
//     };
//     let obj = {
//       type,
//       weight,
//       duration,
//       plan_name,
//       id,
//     };
//     // let amount = "";
//     // if (type == "grams")
//     // weight = Number(Number(weight) / Number(duration)).toFixed(3);
//     // if (type == "amount" && defaultFormProp.type !== "amount")
//     if (type == "amount" && validateNumber(amount))
//       obj.amount = Math.round(Number(amount));
//     // if (type == "amount") amount = Number(digiInfo.total);
//     // console.log(amount);
//     // if (disableAllPlans) setAllPlans([{ label: plan_name, value: plan_name }]);

//     // console.log(defaultFormProp);

//     editForm({ ...obj });
//   };

//   const getDate = (date) => {
//     var sun = new Date();
//     sun.setDate(sun.getDate() + (7 - sun.getDay()));
//     sun.setHours(9);
//     sun.setMinutes(0);
//     sun.setSeconds(0);
//     return sun.toISOString().split(".")[0];
//   };

//   const onSubmit = async () => {
//     let { billing, shipping } = customerInfo;
//     let planObj =
//       isArrayWithValues(allPlans) &&
//       allPlans.find((i) => i.plan_name === addForm.plan_name);
//     let obj = {
//       customer_id: customerInfo.id,
//       billing,
//       shipping,
//       payment_method: addForm.mode,
//       payment_method_title: "",
//       total: `${addForm.amount}`,
//       date_paid: getDate(addForm.date_paid),
//       status: "completed",
//       line_items: [
//         {
//           product_id: planObj && planObj.product,
//           quantity: 1,
//         },
//       ],
//     };
//     if (!obj.billing.email) delete obj.billing.email;
//     if (isArrayWithValues(paymentMethods)) {
//       let paymentObj = paymentMethods.find((i) => i.value == addForm.mode);
//       if (paymentObj) obj.payment_method_title = paymentObj.label;
//     }
//     let metaKeys = ["payment_ref", "remarks"];
//     let meta_data = [
//       {
//         key: "virtual_order",
//         value: "digigold",
//       },
//       {
//         key: "manual_payment",
//         value: "yes",
//       },
//       {
//         key: "gold_gross",
//         value: addForm.weight,
//       },
//     ];
//     for (let key of metaKeys)
//       if (addForm[key]) meta_data.push({ key, value: addForm[key] });
//     obj.meta_data = meta_data;
//     console.log(obj, "Payload");
//     // return;
//     let res = await createOrder(obj);
//     if (res) {
//       let customerRes = await getCustomer(customerInfo.id);
//       console.log(customerRes);
//       if (customerRes && customerRes.data) {
//         console.log(customerRes.data, res);
//         setCustomerInfo(customerRes.data);
//         let plans = customerRes.data?.digigold_details?.plans;
//         if (
//           isArrayWithValues(res?.line_items) &&
//           plans?.[res?.line_items?.[0]?.product_id]
//         )
//           setViewLedger(plans[res.line_items[0].product_id]);
//       }
//     }

//     setSubmitting(false);
//     closeManualDialog();
//   };

//   const createOrder = async (obj) => {
//     let website = await getWebsite();
//     let token = await getToken();
//     if (website && token) {
//       try {
//         setSubmitting(true);
//         let { data } = await axios({
//           url: `${website}/wp-json/wc/v3/orders`,
//           method: "POST",
//           data: obj,
//           headers: { Authorization: `Basic ${token}` },
//         });
//         console.log(data);
//         return data;
//       } catch (error) {
//         console.log(getAxiosError(error));
//         return false;
//       }
//     }
//   };

//   return (
//     <>
//       <DialogContent className="scrollbar-hidden">
//         <Stack
//           direction="row"
//           alignItems={"center"}
//           justifyContent="space-between"
//         >
//           <Typography variant="h6" id="alert-dialog-title">
//             {manualPaymentOpen}
//           </Typography>
//           <IconButton onClick={closeManualDialog}>
//             <Close />
//           </IconButton>
//         </Stack>
//         <DialogContentText id="alert-dialog-description">
//           <Grid container mt={0.5} spacing={4}>
//             <Grid item xs={8}>
//               <Label>Plan Name</Label>
//               <FormControl disabled={disableAllPlans} size="small" fullWidth>
//                 {/* <InputLabel id="demo-simple-select-label">Plan Name</InputLabel> */}
//                 <Select
//                   labelId="demo-simple-select-label"
//                   id="demo-simple-select"
//                   value={addForm.plan_name}
//                   placeholder="Plan Name"
//                   onChange={(e) => editForm({ plan_name: e.target.value })}
//                   // disabled
//                 >
//                   {isArrayWithValues(allPlans) &&
//                     allPlans.map((i) => {
//                       return <MenuItem value={i.value}>{i.label}</MenuItem>;
//                     })}
//                 </Select>
//               </FormControl>
//             </Grid>
//             <Grid item xs={4}>
//               <Label>Duration</Label>
//               <TextField
//                 size="small"
//                 disabled
//                 // label="Duration"
//                 value={addForm.duration}
//                 onChange={(e) => editForm({ duration: e.target.value })}
//                 fullWidth
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position="end">Months</InputAdornment>
//                   ),
//                 }}
//                 type="number"
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <Label>Gold Rates</Label>
//               <TextField
//                 size="small"
//                 // disabled
//                 // label="Amount"
//                 value={addForm.rate}
//                 onChange={(e) => editForm({ rate: e.target.value })}
//                 // onChange={(e) => onRateChange(e.target.value)}
//                 fullWidth
//                 type="number"
//                 InputProps={{
//                   startAdornment: (
//                     <InputAdornment position="start">{currency}</InputAdornment>
//                   ),
//                   endAdornment: (
//                     <InputAdornment>
//                       <FormControl
//                         variant="standard"
//                         sx={{
//                           "&:hover": {
//                             border: "0px solid transparent",
//                             backgroundColor: "transparent",
//                             borderRadius: "10px",
//                           },
//                           "&:focus": {
//                             backgroundColor: "transparent",
//                           },
//                           // minWidth: minWidth || "120px",
//                         }}
//                         size="small"
//                       >
//                         {/* <InputLabel id="demo-multiple-checkbox-label">{label}</InputLabel> */}
//                         <Select
//                           disableUnderline
//                           id="demo-multiple-checkbox"
//                           // multiple
//                           // open={open}
//                           // input={<OutlinedInput  label={""} />}
//                           // renderValue={(selected) => selected}
//                           value={addForm.rate}
//                           onChange={(e) => {
//                             // handleClose();
//                             // onChange(e);
//                             // onRateChange(e.target.value);
//                             editForm({ rate: e.target.value });
//                           }}
//                           // onClose={handleClose}
//                           // onOpen={handleOpen}
//                           displayEmpty
//                           inputProps={{ "aria-label": "Without label" }}
//                           style={{ backgroundColor: "transparent" }}
//                           SelectDisplayProps={{
//                             style: { backgroundColor: "transparent" },
//                           }}
//                           sx={{
//                             // margin: "4px 6px",
//                             "&:focus": {
//                               backgroundColor: "transparent",
//                             },
//                             "&:hover": {
//                               backgroundColor: "transparent",
//                             },
//                           }}
//                           size="small"
//                         >
//                           {isArrayWithValues(goldPricing) &&
//                             goldPricing.map((obj) => {
//                               return (
//                                 <MenuItem key={obj.value} value={obj.rate}>
//                                   {obj.label}
//                                   {/* <ListItemText primary={obj.label} /> */}
//                                 </MenuItem>
//                               );
//                             })}
//                         </Select>
//                       </FormControl>
//                     </InputAdornment>
//                   ),
//                 }}
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <Label>Amount</Label>
//               <TextField
//                 size="small"
//                 disabled={
//                   true
//                   // defaultFormProp.type === "amount" || addForm.type === "amount"
//                 }
//                 // label="Amount"
//                 value={addForm.amount}
//                 onChange={(e) => editForm({ amount: e.target.value })}
//                 fullWidth
//                 type="number"
//                 InputProps={{
//                   startAdornment: (
//                     <InputAdornment position="start">{currency}</InputAdornment>
//                   ),
//                 }}
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <Label>Gold Credit</Label>
//               <TextField
//                 size="small"
//                 // disabled={defaultFormProp.type === "grams"}
//                 disabled={true}
//                 // label="Gold Credit"
//                 value={addForm.weight}
//                 onChange={(e) => editForm({ weight: e.target.value })}
//                 fullWidth
//                 InputProps={{
//                   endAdornment: (
//                     <InputAdornment position="end">Grams</InputAdornment>
//                   ),
//                 }}
//                 type="number"
//               />
//             </Grid>
//             <Grid item xs={6}>
//               <LocalizationProvider dateAdapter={AdapterDateFns}>
//                 <Label>Payment made on</Label>
//                 <DatePicker
//                   // label="Payment made on"
//                   style={{ width: "100%" }}
//                   value={addForm.date_paid}
//                   toolbarFormat="ddd DD MMMM"
//                   onChange={(newValue) => {
//                     editForm({ date_paid: newValue });
//                   }}
//                   renderInput={(params) => (
//                     <TextField size="small" fullWidth {...params} />
//                   )}
//                 />
//               </LocalizationProvider>
//             </Grid>
//             <Grid item xs={6}>
//               <Label>Mode of payment</Label>
//               <FormControl size="small" fullWidth>
//                 {/* <InputLabel id="demo-simple-select-label">
//                   Mode of Payment
//                 </InputLabel> */}
//                 <Select
//                   labelId="demo-simple-select-label"
//                   id="demo-simple-select"
//                   value={addForm.mode}
//                   // label="Mode of Payment"
//                   onChange={(e) => editForm({ mode: e.target.value })}
//                 >
//                   {isArrayWithValues(paymentMethods) &&
//                     paymentMethods.map((i) => {
//                       return (
//                         <MenuItem value={i.value}>
//                           {i.label === "Cash on delivery" ? "Cash" : i.label}
//                         </MenuItem>
//                       );
//                     })}
//                   {/* <MenuItem value={"cheque"}>Cheque</MenuItem>
//                   <MenuItem value={"demand_draft"}>Demand Draft</MenuItem>
//                   <MenuItem value={"online_payment"}>Online Payment</MenuItem>
//                   <MenuItem value={"ornate_nx"}>Ornate NX</MenuItem> */}
//                 </Select>
//               </FormControl>
//             </Grid>
//             <Grid item xs={12}>
//               <Label>Payment Reference</Label>
//               <TextField
//                 size="small"
//                 // label="Payment Reference"
//                 value={addForm.payment_ref}
//                 onChange={(e) => editForm({ payment_ref: e.target.value })}
//                 fullWidth
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <Label>Remarks</Label>
//               <TextField
//                 size="small"
//                 // label="Remarks"
//                 value={addForm.remarks}
//                 rows={2}
//                 multiline
//                 onChange={(e) => editForm({ remarks: e.target.value })}
//                 fullWidth
//               />
//             </Grid>
//           </Grid>
//         </DialogContentText>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={closeManualDialog}>Cancel</Button>
//         <LoadingButton loading={submitting} onClick={onSubmit} autoFocus>
//           Add
//         </LoadingButton>
//       </DialogActions>
//     </>
//   );
// };

const Ledger = ({
  customerInfo,
  viewLedger,
  setViewLedger,
  email,
  currency,
  openManualDialog,
  openCancelPlan,
  digigoldCancelledPlans,
  setCustomerInfo,
}) => {
  let { duration, plan_name, type, joining_date, gifts } = viewLedger;
  console.log(viewLedger, "<<<<<<<<<<<<<<<<< viewLedger");
  // let gifts = {
  //   1: { title: "Diwali Gift", gift: "Bag", id: "LKASFJ", month: "1" },
  //   2: { title: "Navratri Gift", gift: "Watch", id: "KDSJFL", month: "2" },
  // };
  const [allLedgers, setAllLedgers] = useState([]);
  const [analytics, setAnalytics] = useState({});
  const dispatch = useDispatch();
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  const [sendingTemplate, setSendingTemplate] = useState(false);
  const [selectedTempleteRemainder, setSelectedTemplateRemainder] = useState(
    {}
  );
  const [templateSendTo, setTemplateSendTo] = useState("");
  const [selectedTempleteLedger, setSelectedTemplateLedger] = useState({});
  const [showWhatsappTemplete, setShowWhatsappTemplate] = useState(false);
  const [giftForm, setGiftForm] = useState({
    date: new Date(),
    received_by: "",
    given_by: "",
    title: "",
    gift: "",
  });
  const digigoldGifts = useSelector((state) => state.settings.digigoldGifts);
  const [giftFormOpen, setGiftFormOpen] = useState(false);
  const editGiftForm = (payload) =>
    setGiftForm((state) => ({ ...state, ...payload }));
  const [addedGifts, setAddedGifts] = useState({});
  const [isPlanCancelled, setIsPlanCancelled] = useState(false);
  const [cancelPlanObject, setCancelPlanObject] = useState({});
  const [planUniqueId, setPlanUniqueId] = useState("");
  const [uniqueIdUpdating, setUniqueIdUpdating] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [allPaymentsMade, setIsAllPaymentsMade] = useState(false);
  const [loading, setLoading] = useState("");
  const [currentPlan, setCurrentPlan] = useState({});
  const assignTemplate = useSelector(
    (state) => state?.settings?.whatsappAssignedTemplates
  );
  const reduxWhatsappTemplete = useSelector(
    (state) => state.chats.whatsappTempletes
  );
  const digigold_user_rewards = useSelector(
    (state) => state.settings.digigold_user_rewards
  );
  const isDarkTheme = theme.palette && theme.palette.mode === "dark";

  const delete_ledger_entry = useSelector(
    (state) => state.user.delete_ledger_entry
  );
  const isAdmin = useSelector((state) => state.user.isAdmin);
  let store_details = useSelector((state) => state.user.store_details);
  const allDigiPlans = useSelector((state) => state.product.digigold_plans);

  const default_currency = useSelector(
    (state) => state.settings.productSettings?.default_currency
  );
  const delete_digigold_entries = useSelector(
    (state) => state.user.delete_digigold_entries
  );
  let store_id = getStoreId();

  const closeDelete = () => setShowDelete(false);
  const openDelete = () => setShowDelete(true);

  useEffect(() => {
    let currentPlan = allDigiPlans?.find(
      (i) => i?.id + "" === viewLedger?.product + ""
    );
    if (isArrayWithValues(currentPlan?.meta_data)) {
      currentPlan.meta_data.forEach((i) => {
        currentPlan[i?.key] = i?.value;
      });
    }
    setCurrentPlan(currentPlan);
  }, [allDigiPlans, viewLedger]);

  console.log(currentPlan, "<<<< currentPlan");
  useEffect(() => {
    let ledger = [];
    let total_penalty = 0,
      total_reward = 0;
    if (isArrayWithValues(viewLedger.ledger)) {
      ledger = [...viewLedger.ledger];
      let rewards =
        digigold_user_rewards?.[`${customerInfo?.id}_${viewLedger?.product}`];

      if (isArrayWithValues(rewards)) {
        ledger = mergeRewardsIntoLedger(
          digigold_user_rewards?.[`${customerInfo?.id}_${viewLedger?.product}`],
          ledger
        );
        rewards.forEach((reward) => {
          if (reward?.type === "reward")
            total_reward += validateNumber(reward?.reward_or_penalty_to_pay);
          if (reward?.type === "penalty")
            total_penalty += validateNumber(reward?.reward_or_penalty_to_pay);
        });
      }
      ledger.sort((a, b) => {
        const dateA = new Date(a.date_paid).getTime();
        const dateB = new Date(b.date_paid).getTime();
        return dateA - dateB;
      });
    }
    setAnalytics((state) => ({ ...state, total_penalty, total_reward }));
    setAllLedgers(ledger);
  }, [viewLedger.ledger, digigold_user_rewards]);

  useEffect(() => {
    if (!isArrayWithValues(reduxWhatsappTemplete)) dispatch(getTempletes());
  }, []);
  useEffect(() => {
    if (isObjWithValues(digigoldCancelledPlans)) {
      let obj =
        viewLedger?.is_cancelled &&
        digigoldCancelledPlans[`${viewLedger?.is_cancelled}`];
      setIsPlanCancelled(Boolean(obj));
      if (obj) setCancelPlanObject(obj);
      else setCancelPlanObject({});
    }
    if (
      (customerInfo[`digigold_uid_${store_id}`] &&
        customerInfo[`digigold_uid_${store_id}`]?.[
          viewLedger?.plan_unique_id
        ]) ||
      customerInfo[`digigold_uid_${store_id}`]?.[viewLedger?.product]
    )
      setPlanUniqueId(
        customerInfo[`digigold_uid_${store_id}`]?.[
          viewLedger?.plan_unique_id
        ] || customerInfo[`digigold_uid_${store_id}`]?.[viewLedger?.product]
      );
    else setPlanUniqueId("");

    setIsAllPaymentsMade(
      validateNumber(viewLedger?.duration) <=
        validateNumber(viewLedger?.ledger?.length) && viewLedger?.duration
    );
  }, [digigoldCancelledPlans, viewLedger, customerInfo]);

  useEffect(() => {
    if (
      digigoldGifts &&
      digigoldGifts[`${viewLedger.product}_${customerInfo.id}`]
    )
      setAddedGifts(digigoldGifts[`${viewLedger.product}_${customerInfo.id}`]);
    else setAddedGifts({});
  }, [digigoldGifts, viewLedger, customerInfo]);

  useEffect(() => {
    if (!isArrayWithValues(reduxWhatsappTemplete)) return;
    let remainder = reduxWhatsappTemplete.find(
      (obj) => obj?.name === assignTemplate?.digital_gold_reminder
    );
    let ledger = reduxWhatsappTemplete.find(
      (obj) => obj?.name === assignTemplate?.digital_monthly_ledger
    );
    if (isObjWithValues(remainder)) setSelectedTemplateRemainder(remainder);
    if (isObjWithValues(ledger)) setSelectedTemplateLedger(ledger);
  }, [reduxWhatsappTemplete]);

  const onSendReminder = () => {
    let hasPhoneNumber =
      customerInfo?.dialcode_whatsapp && customerInfo?.whatsapp;

    let hasTemplateAssinged = assignTemplate?.digital_gold_remainder;

    if (!hasPhoneNumber)
      return dispatch(
        setGlobalToast({
          show: true,
          message: "Couldn't find user whatsapp number",
          severity: "error",
        })
      );
    if (!hasTemplateAssinged)
      return dispatch(
        setGlobalToast({
          show: true,
          message: "Couldn't find assigned template for reminder",
          severity: "error",
        })
      );

    setTemplateSendTo("reminder");
    setShowWhatsappTemplate(selectedTempleteRemainder);
  };

  const remainderButton = (
    <LoadingButton
      size="small"
      loading={sendingTemplate && templateSendTo === "reminder"}
      variant="outlined"
      disabled={!isObjWithValues(selectedTempleteRemainder)}
      onClick={onSendReminder}
    >
      Send Reminder
    </LoadingButton>
  );

  const monthlyLedgerButton =
    (customerInfo?.dialcode_whatsapp ||
      (customerInfo?.dialcode_whatsapp && customerInfo?.whatsapp) ||
      (customerInfo?.dialcode_whatsapp && customerInfo?.whatsapp)) &&
    assignTemplate?.digital_monthly_ledger ? (
      <LoadingButton
        size="small"
        loading={sendingTemplate && templateSendTo === "ledger"}
        variant="outlined"
        disabled={!isObjWithValues(selectedTempleteLedger)}
        onClick={async () => {
          setTemplateSendTo("ledger");
          // setSendingLedger(true);
          setShowWhatsappTemplate(selectedTempleteLedger);
          // setSendingLedger(false);
        }}
      >
        Send Monthly Ledger
      </LoadingButton>
    ) : null;

  const onSendTemplate = async (obj) => {
    let template = obj;
    template = replaceTemplateVariable(obj, customerInfo); //customerInfo.billing.phone
    let number = customerInfo?.whatsapp_id
      ? customerInfo?.whatsapp_id
      : `${customerInfo?.dialcode_whatsapp}${customerInfo?.whatsapp}`
      ? `${customerInfo?.dialcode_whatsapp}${customerInfo?.whatsapp}`
      : `${customerInfo?.dialcode_mobile}${customerInfo.billing.phone}`;
    setShowWhatsappTemplate(false);
    setSendingTemplate(true);
    let website = getWebsite();
    let token = getToken();
    console.log(template);
    // if (isDev()) return;
    // if (!website && !token) return false
    let res = {};
    try {
      if (getIsBackendMigrated("chats")) {
        res.data = await sendMessage({
          payload: {
            messaging_product: "whatsapp",
            recipient_type: "individual",
            to: number,
            type: "template",
            template,
          },
          platform: "whatsapp",
        });
      } else {
        res = await axios({
          url: `${website}/wp-json/store/v1/social/message`,
          headers: {
            Authorization: `Basic ${token}`,
          },
          method: "POST",
          data: {
            platform: "whatsapp",
            payload: {
              messaging_product: "whatsapp",
              recipient_type: "individual",
              to: number,
              type: "template",
              name: `${
                customerInfo?.first_name ||
                customerInfo?.billing?.first_name ||
                ""
              } ${
                customerInfo?.last_name ||
                customerInfo?.billing?.last_name ||
                ""
              }`,
              template,
            },
          },
        });
      }

      if (res.data.success) {
        // dispatch(addResponseInChat(res.data.data, customer.id));
        dispatch(
          setGlobalToast({
            show: true,
            message: "Sent Successfully",
            severity: "success",
          })
        );
      }
      if (isObjWithValues(res.data?.data?.error)) {
        dispatch(
          setGlobalToast({
            show: true,
            message:
              res.data?.data?.error?.error_data?.details ||
              res.data?.data?.error?.message,
            severity: "error",
          })
        );
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
    setTemplateSendTo("");
    setSendingTemplate(false);
  };
  const onAddDigigoldGift = (obj) => {
    let giftObj = isObjWithValues(gifts)
      ? Object.values(gifts).find((i) => i.id === obj.id)
      : false;
    if (giftObj) obj = { ...obj, ...giftObj };
    dispatch(
      addDigigoldGift(
        viewLedger.product,
        customerInfo.id,
        {
          ...digigoldGifts[`${viewLedger.product}_${customerInfo.id}`],
          [obj.id]: { ...obj, date: new Date(obj.date).getTime() },
        },
        {
          onSuccess: () => {
            setGiftFormOpen(false);
            dispatch(
              setGlobalToast({
                show: true,
                severity: "success",
                message: "Gift Entry added",
              })
            );
          },
        }
      )
    );
  };

  const onUniqueIdUpdate = async () => {
    setUniqueIdUpdating(true);
    let customer = await updateCustomerDigiUniqueId(
      customerInfo,
      viewLedger.product,
      planUniqueId
    );
    setUniqueIdUpdating(false);
    dispatch(
      setGlobalToast({
        message: "Unique ID updated!",
        show: true,
        severity: "success",
      })
    );
  };

  const onOrderDelete = async () => {
    setDeleting(true);
    await deleteOrder(deleteId?.id);
    dispatch(
      setGlobalToast({
        message: "Payment history deleted!",
        show: true,
        severity: "success",
      })
    );
    dispatch(
      addUserActivity({
        event: "delete_ledger_digigold",
        event_info: {
          customer_id: customerInfo?.id,
          order_id: deleteId?.id,
          ledger_info: deleteId,
        },
      })
    );
    closeDelete();
    if (viewLedger?.ledger?.length == 1) setViewLedger(false);
    setDeleting(false);
  };

  const deleteOrder = async (id) => {
    let website = await getWebsite();
    let token = await getToken();
    if (website && token) {
      try {
        await axios({
          url: `${website}/wp-json/wc/v3/orders/${id}`,
          headers: { Authorization: `Basic ${token}` },
          method: "DELETE",
          params: { force: true },
        });
        let { data: customerData } = await axios({
          url: `${website}/wp-json/wc/v3/customers/${customerInfo.id}?asd`,
          headers: { Authorization: `Basic ${token}` },
        });
        if (customerData) {
          dispatch(updateCustomerInRedux(customerData));
          setCustomerInfo(customerData);
        }
      } catch (error) {
        console.log(error, getAxiosError(error));
      }
    }
  };

  const onOrderDeletePress = (details) => {
    setDeleteId(details);
    openDelete();
  };

  const downloadCustomerLedgerAsPDF = async () => {
    // let currentPlan = allDigiPlans?.find(
    //   (i) => i?.id + "" === viewLedger?.product + ""
    // );
    // console.log(currentPlan, viewLedger, "<<<<<<");
    // return;

    let show_gold = currentPlan?.grams_on_amount;
    let phone = getJweroCustomerPhoneNumber({ customer: customerInfo });
    let table_aggregation = [
      {
        "Customer Name": `${customerInfo?.first_name} ${customerInfo?.last_name}`,
        Contact: phone?.success ? `+${phone.dialcode} ${phone.phone}` : "",
        "Joining Date": viewLedger?.joining_date,
      },
      {
        "Installments Paid": `${validateNumber(
          viewLedger?.ledger?.length
        )}/${validateNumber(viewLedger?.duration)}`,
        "Plan closed on": `${formatDate(
          new Date(cancelPlanObject?.time),
          "DD MMM YYYY, hh:mm A"
        )}`,
        Status: !viewLedger?.is_cancelled ? "Ongoing" : "Closed",
      },
      {
        ...(viewLedger?.is_cancelled
          ? {
              "OTP verified on Close Plan": cancelPlanObject?.is_otp_verified
                ? "Yes"
                : "No",
            }
          : {}),
        // "Unique ID": `${
        //   customerInfo[`digigold_uid_${store_id}`] &&
        //   customerInfo[`digigold_uid_${store_id}`]?.[viewLedger?.product]
        // }`,
        "Close By": cancelPlanObject?.close_by,
      },
    ];
    table_aggregation = table_aggregation
      ?.filter((i) => {
        let obj = removeFalsyValuesFromObject(i);
        if (isObjWithValues(obj)) return obj;
        return false;
      })
      ?.filter(Boolean);

    //   {
    //     "id": "Mw8LGX",
    //     "time": 1701716703668,
    //     "tax": "100",
    //     "penalty": "140",
    //     "bank_charges": "100",
    //     "additional_charges": "120",
    //     "final_paid": "25800",
    //     "close_request_by": "Kajol",
    //     "close_by": "akshay catalog memeber",
    //     "additional_benefit": "3600",
    //     "otp_verified": false
    // }
    let table_bottom_aggregation = [
      ...(currentPlan?.grams_on_amount
        ? [
            {
              key: "Total Gold accumulated",
              value: `${validateNumber(
                Number(viewLedger.weight).toFixed(3)
              )} grams`,
              prefix_value: "",
              surplus: false,
            },
          ]
        : []),
      {
        key: "Total Paid (In Installments)",
        value: `${validateNumber(viewLedger?.paid)}`,
        prefix_value: default_currency,
        surplus: false,
      },
      {
        key: "Tax (-)",
        value: cancelPlanObject?.tax,
        prefix_value: default_currency,
        surplus: false,
      },
      {
        key: "Penalty (-)",
        value: cancelPlanObject?.penalty,
        prefix_value: default_currency,
        surplus: false,
      },
      {
        key: "Bank Charges (-)",
        value: cancelPlanObject?.bank_charges,
        prefix_value: default_currency,
        surplus: false,
      },
      {
        key: "Additional Charges (-)",
        value: cancelPlanObject?.additional_charges,
        prefix_value: default_currency,
        surplus: true,
      },
      {
        key: "Additional Benefits/Bonus (+)",
        value: cancelPlanObject?.additional_benefit,
        prefix_value: default_currency,
        surplus: true,
      },
      {
        key: "Total Rewards (+)",
        value: analytics.total_reward,
        prefix_value: default_currency,
        surplus: true,
      },
      {
        key: "Total Penalty (-)",
        value: analytics.total_penalty,
        prefix_value: default_currency,
        surplus: false,
      },
      {
        key: "Final paid",
        value: cancelPlanObject?.final_paid,
        prefix_value: default_currency,
        surplus: true,
      },
    ];
    table_bottom_aggregation = table_bottom_aggregation?.filter((i) => i.value);
    let array = formatLedgerForPDF(allLedgers, { show_gold });
    let link;
    try {
      let res = await axios({
        url: WEBSOCKET_NGROK_URL,
      });
      link = res?.data?.data;
    } catch (error) {}
    try {
      setLoading("customer_ledger_pdf");
      let res = await websocketApiCall({
        url: link,
        endpoint: `digital_gold/pdf_table`,
        token: await getAuthToken(),
        tenant_id: await getTenantId(),
        data: {
          table_heading: {
            title: "Customer Ledger",
            subtitle: `${
              customerInfo[`digigold_uid_${store_id}`] &&
              customerInfo[`digigold_uid_${store_id}`]?.[viewLedger?.product]
                ? `
                ${
                  customerInfo[`digigold_uid_${store_id}`] &&
                  customerInfo[`digigold_uid_${store_id}`]?.[
                    viewLedger?.product
                  ]
                } - `
                : ""
            }${viewLedger?.plan_name}`,
            // subtitle: duration_range,
            // subtitle: "23 Dec, 2023 - 25 Dec 2023",
          },
          store_logo: store_details?.store_logo,
          store_name: store_details?.store_name,
          table_data: array,
          table_aggregation,
          table_bottom_aggregation,
          file_path: `digigold/ledger/${formatDateDynamic(
            new Date(),
            "YYYY"
          )}/${formatDateDynamic(new Date(), "MMM")}`,
          file_name: unformatServerValue(
            `${customerInfo?.first_name} ${
              customerInfo?.last_name
            } ${formatDateDynamic(new Date(), "YYYY-MM-DD_hh-mm-ss")}`
          ),
          customer: customerInfo,
          show_signature: true,
        },
        method: "POST",
      });

      if (res?.success) {
        window.open(res?.data?.link, "_blank");
        // let openRes = await openPDF({ link: res?.data?.link });
        // if (!openRes) throw new Error();
      } else throw new Error();
    } catch (error) {
      console.log(error);
      dispatch(
        setGlobalToast({
          show: true,
          message: "Unable to download PDF, please try again later.",
          severity: "error",
        })
      );
    } finally {
      setLoading("");
    }
  };

  return (
    <>
      <TempleteList
        open={showWhatsappTemplete}
        handleClose={() => setShowWhatsappTemplate(false)}
        setParentTemplate={onSendTemplate}
        // hideRejected
        // viewOnly
      />
      <Drawer
        anchor={isIpad ? "bottom" : "right"}
        open={Boolean(viewLedger)}
        onClose={() => {
          setViewLedger(false);
        }}
        transitionDuration={200}
        sx={{
          "	.MuiDrawer-paperAnchorRight": {
            height: "calc(100vh - 20px)",
            // margin: "10px",
            borderRadius: "10px",
            padding: 4,
            margin: "10px",
            width: "55vw",
          },
        }}
      >
        <Box>
          <Box sx={{ px: 1, py: 2 }}>
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent="space-between"
            >
              <Typography variant="h4" sx={{ fontSize: "15px" }}>
                {plan_name}'s history
              </Typography>
              <Stack direction={"row"} sx={{ gap: "7px" }} alignItems="center">
                {remainderButton} {monthlyLedgerButton}
                <IconButton onClick={() => setViewLedger(false)}>
                  <Close />
                </IconButton>
              </Stack>
            </Stack>
            <Stack
              direction={"row"}
              alignItems="center"
              justifyContent={"space-between"}
            >
              <Typography
                mt={1}
                variant="h6"
                mb={2}
                sx={{ fontSize: "12px", color: "text.secondary" }}
              >
                {email}
              </Typography>
            </Stack>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4} lg={3.5}>
                <LabelValue label="Duration" value={`${duration} months`} />
              </Grid>
              <Grid item xs={6} md={4} lg={3.5}>
                <LabelValue
                  label="Type"
                  value={`${type ? formatServerValue(type) : ""}`}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={5}>
                <LabelValue
                  label="Joining Date"
                  value={`${formatDate(
                    new Date(joining_date),
                    "dd:mm:yyyy hh:mm am/pm"
                  )}`}
                />
              </Grid>
            </Grid>
          </Box>
          <Stack
            direction={"row"}
            alignItems="flex-start"
            justifyContent="flex-end"
            spacing={2}
            mt={1}
          >
            <TextField
              value={planUniqueId}
              onChange={(e) => setPlanUniqueId(e.target.value.toUpperCase())}
              // fullWidth
              // placeholder="Enter name"
              label="Unique ID"
              size="small"
              // disabled
              helperText={
                planUniqueId?.includes(" ")
                  ? "Spaces not allowed in unique IDs"
                  : ""
              }
              error={planUniqueId?.includes(" ")}
            />
            <LoadingButton
              loading={uniqueIdUpdating}
              variant="outlined"
              size="small"
              onClick={onUniqueIdUpdate}
              disabled={planUniqueId?.includes(" ")}
            >
              Update
            </LoadingButton>
          </Stack>
          {/* <Card sx={{ border: "0.5px solid #e8e8e8", borderRadius: "8px", mt: 3 }}>
        <CardContent>
        </CardContent>
      </Card> */}
          {isObjWithValues(cancelPlanObject) && (
            <Box>
              <Typography
                sx={{
                  fontSize: "13px",
                  fontWeight: "600",
                  color: "error.main",
                }}
              >
                This plan has been closed on{" "}
                {cancelPlanObject?.time
                  ? formatDate(
                      new Date(cancelPlanObject.time),
                      "dd:mm:yyyy hh:mm am/pm"
                    )
                  : ""}
                , By {cancelPlanObject?.close_by} with settlement of {currency}{" "}
                {cancelPlanObject?.final_paid}.
              </Typography>
              {cancelPlanObject?.is_otp_verified ? (
                <HorizontalStack spacing={1} sx={{ mt: 1 }}>
                  <CheckCircleOutline
                    style={{ height: 20, width: 20 }}
                    sx={{ color: "success.main" }}
                  />
                  <PrimaryLabelSmall sx={{ color: "success.main" }}>
                    Verfied with OTP
                  </PrimaryLabelSmall>
                </HorizontalStack>
              ) : (
                <HorizontalStack spacing={1} sx={{ mt: 1 }}>
                  <CancelOutlined
                    style={{ height: 20, width: 20 }}
                    sx={{ color: "error.main" }}
                  />
                  <PrimaryLabelSmall sx={{ color: "error.main" }}>
                    Not Verfied with OTP
                  </PrimaryLabelSmall>
                </HorizontalStack>
              )}
            </Box>
          )}

          <Grid my={2} container spacing={3}>
            <Grid item xs={6} md={3} lg={2.4}>
              <AnalyticsCard
                title="Amount Received"
                value={viewLedger.paid ? `${currency} ${viewLedger.paid}` : "0"}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={2.4}>
              <AnalyticsCard
                title="Total Payments"
                value={viewLedger.paid_count || "0"}
              />
            </Grid>

            {currentPlan?.grams_on_amount && (
              <Grid item xs={6} md={3} lg={2.4}>
                <AnalyticsCard
                  title="Equivalent Gold"
                  value={`${validateNumber(
                    Number(viewLedger.weight).toFixed(3)
                  )}`}
                  suffix={"g"}
                />
              </Grid>
            )}
            <Grid item xs={6} md={3} lg={2.4}>
              <AnalyticsCard
                title="Total Rewards"
                value={`${currency} ${analytics.total_reward}`}
              />
            </Grid>
            <Grid item xs={6} md={3} lg={2.4}>
              <AnalyticsCard
                title="Total Penalty"
                value={`${currency} ${analytics.total_penalty}`}
              />
            </Grid>
          </Grid>
          {/* <Box sx={{padding}}> */}
          <Stack
            direction="row"
            mx={1}
            alignItems={"center"}
            mt={3}
            justifyContent={"space-between"}
          >
            <Typography variant="h6">Gifts Received</Typography>
          </Stack>
          <Stack
            direction="row"
            alignItems={"center"}
            flexWrap="wrap"
            spacing={3}
            mt={3}
          >
            {isObjWithValues(gifts) &&
              Object.values(gifts).map((i) => {
                return (
                  <Chip
                    size="small"
                    mr={1}
                    mb={1}
                    label={`${i.title}: ${i.gift}`}
                    // color="warning"
                    onClick={() => setGiftFormOpen(true)}
                    sx={{
                      color: addedGifts[i.id] ? "#1aae6f" : "#910905",
                      border: isDarkTheme ? "0.7px solid" : "0px solid",
                      // borderColor: statusFontColor(row.status),
                      cursor: "pointer",
                      backgroundColor: isDarkTheme
                        ? "transparent"
                        : addedGifts[i.id]
                        ? "#e0f6ed"
                        : "#f2e5e8",
                    }}
                    variant="outlined"
                  />
                );
                // return <Chip label={`${i.title}: ${i.gift}`} size="small" color="success" />;
              })}
          </Stack>

          <Stack
            direction="row"
            mx={1}
            alignItems={"center"}
            mt={3}
            justifyContent={"space-between"}
          >
            <Stack direction="row" alignItems={"center"}>
              <Typography variant="h6">Payment history</Typography>

              {/* <Button
            // variant="contained"
            size="small"
            startIcon={<Add style={{ width: "15px", height: "15px" }} />}
            onClick={() => openManualDialog("Add Manual Payment")}
            sx={{ ml: 1 }}
            color="error"
          >
            Cancel Plan
          </Button> */}
            </Stack>
            <Stack direction="row" spacing={3} alignItems={"center"}>
              <LoadingButton
                variant="outlined"
                size="small"
                startIcon={
                  <Download style={{ width: "15px", height: "15px" }} />
                }
                onClick={() => downloadCustomerLedgerAsPDF()}
                loading={loading === "customer_ledger_pdf"}
              >
                Ledger
              </LoadingButton>
              {!isPlanCancelled && (
                <Button
                  variant="outlined"
                  onClick={openCancelPlan}
                  size="small"
                  color="error"
                >
                  Close Plan
                </Button>
              )}
              {/* <IconButton
              size="small"
              color="error"
              sx={{ mr: 1 }}
              onClick={openCancelPlan}
            >
              <Delete style={{ height: "20px", width: "20px" }} />
            </IconButton> */}
              <Tooltip
                title={
                  allPaymentsMade
                    ? "All payments has been made"
                    : isPlanCancelled
                    ? "Plan has been closed"
                    : ""
                }
              >
                <div>
                  <Button
                    variant="outlined"
                    size="small"
                    startIcon={
                      <Add style={{ width: "15px", height: "15px" }} />
                    }
                    onClick={() => openManualDialog("Add Manual Payment")}
                    disabled={isPlanCancelled || allPaymentsMade}
                  >
                    Add Manual Payment
                  </Button>
                </div>
              </Tooltip>
            </Stack>
          </Stack>
          {isArrayWithValues(viewLedger.ledger) && (
            // <Box
            //   sx={{
            //     border: "0.5px solid #e8e8e8",
            //     borderRadius: "8px",
            //     mt: 3,
            //     overflowX: "visible !important",
            //   }}
            //   // component={Paper}
            // >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date of Payment</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Payment Ref.</TableCell>
                  <TableCell>Mode</TableCell>
                  <TableCell>Amount Paid</TableCell>
                  <TableCell>Penalty</TableCell>
                  {currentPlan?.grams_on_amount && (
                    <TableCell>Gold Credited</TableCell>
                  )}
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {allLedgers?.map((detail) => (
                  <TableRow
                    key={detail.url}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {formatDateDynamic(
                        new Date(detail.date_paid),
                        "DD MMM, YYYY hh:ss A"
                      )}
                    </TableCell>
                    <TableCell>
                      <CustomChip
                        label={
                          detail.status === "reward"
                            ? "Reward"
                            : detail.status === "penalty"
                            ? "Penalty"
                            : "Payment"
                        }
                        type={LEDGER_TYPES[detail.status] || "processing"}
                      />
                    </TableCell>
                    <TableCell>{detail.ref}</TableCell>
                    <TableCell>{detail.mode}</TableCell>
                    <TableCell>
                      {detail?.status !== "penalty" && (
                        <>
                          <Typography>
                            {currency} {detail.total}
                          </Typography>
                          <Typography
                            sx={{ ...secondaryLabel, fontSize: "11px" }}
                          >
                            {detail.manual ? "Manual" : "Automatic"}
                          </Typography>
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      {detail?.status === "penalty" && (
                        <>
                          <Typography>
                            {currency} {detail.total}
                          </Typography>
                          <Typography
                            sx={{ ...secondaryLabel, fontSize: "11px" }}
                          >
                            {detail.manual ? "Manual" : "Automatic"}
                          </Typography>
                        </>
                      )}
                    </TableCell>
                    {currentPlan?.grams_on_amount && (
                      <TableCell>{detail.gold}</TableCell>
                    )}
                    <TableCell>
                      <Tooltip
                        title={
                          !detail.manual
                            ? "Cannot delete automatic payment"
                            : !delete_ledger_entry && !isAdmin
                            ? "You don't have access to delete payment entries"
                            : ""
                        }
                      >
                        <div>
                          <IconButton
                            size="small"
                            color="error"
                            disabled={
                              !detail.manual ||
                              (!delete_ledger_entry && !isAdmin)
                            }
                            onClick={() => onOrderDeletePress(detail)}
                          >
                            <Delete />
                          </IconButton>
                        </div>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell sx={{ fontWeight: "600" }}>Total</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell sx={{ fontWeight: "600" }}>
                    {currency}{" "}
                    {validateNumber(viewLedger.paid) +
                      validateNumber(analytics.total_reward)}
                  </TableCell>
                  <TableCell>
                    {currency} {validateNumber(analytics.total_penalty)}
                  </TableCell>
                  {currentPlan?.grams_on_amount && (
                    <TableCell sx={{ fontWeight: "600" }}>
                      {viewLedger?.weight?.toFixed(3)} grams
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
            // </Box>
          )}
          {/* </Box> */}
          <GiftForm
            form={giftForm}
            setForm={editGiftForm}
            open={giftFormOpen}
            onClose={() => setGiftFormOpen(false)}
            gifts={gifts}
            onAddDigigoldGift={onAddDigigoldGift}
          />
        </Box>
        <Dialog
          open={showDelete}
          onClose={closeDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
          fullWidth
          sx={{ ...dialogStyle }}
        >
          <DialogTitle id="alert-dialog-title">
            Delete this payment history?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              The order will also be deleted
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeDelete}>Cancel</Button>
            <LoadingButton
              loading={deleting}
              variant="contained"
              size="small"
              color="error"
              onClick={onOrderDelete}
              autoFocus
            >
              Delete
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Drawer>
    </>
  );
};

const GiftForm = ({
  form,
  setForm,
  open,
  onClose,
  gifts,
  onAddDigigoldGift,
}) => {
  const addingDigigoldGift = useSelector(
    (state) => state.settings.addingDigigoldGift
  );
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};
  return (
    <Box>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
        sx={{ ...dialogStyle }}
        {...globalPopupStyle}
      >
        <DialogTitle id="alert-dialog-title">{"Add Gift Entry"}</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Label>Received Date</Label>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDateTimePicker
                  // label="Birthday"
                  // name="birthdate"
                  renderInput={(params) => (
                    <TextField fullWidth label="Enter Date" {...params} />
                  )}
                  fullWidth
                  onChange={(newValue) => {
                    setForm({ date: formatDate(newValue) });
                  }}
                  value={form.date ? form.date : null}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label>Gift</Label>
              <Select
                fullWidth
                value={form.id}
                onChange={(e) => setForm({ id: e.target.value })}
              >
                {isObjWithValues(gifts) &&
                  Object.values(gifts).map((i) => {
                    return (
                      <MenuItem
                        value={i.id}
                      >{`${i.title}: ${i.gift}`}</MenuItem>
                    );
                    // return <Chip label={`${i.title}: ${i.gift}`} size="small" color="success" />;
                  })}
              </Select>
            </Grid>
            <Grid item xs={12} md={6}>
              <Label>Received By</Label>
              <TextField
                value={form.received_by}
                onChange={(e) => setForm({ received_by: e.target.value })}
                fullWidth
                placeholder="Enter name"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Label>Given By</Label>
              <TextField
                value={form.given_by}
                onChange={(e) => setForm({ given_by: e.target.value })}
                fullWidth
                placeholder="Enter name"
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <LoadingButton
              loading={addingDigigoldGift}
              onClick={() => onAddDigigoldGift(form)}
              autoFocus
            >
              Add
            </LoadingButton>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

const LabelValue = ({ label, value }) => {
  return (
    <Stack direction="row">
      <Typography variant="body1">{label}: </Typography>
      <Typography variant="body1" ml={1} fontWeight={"500"}>
        {value}
      </Typography>
    </Stack>
  );
};

const AnalyticsCard = ({ title, value, suffix }) => {
  return (
    <Card
      sx={{
        border: "0.5px solid #e8e8e8",
        height: "100%",
        backgroundColor: "#ecedf1",
      }}
    >
      <CardContent sx={{ "&:last-child": { paddingBottom: "16px" } }}>
        <Typography variant="h5" fontSize="12px" fontWeight={"500"}>
          {title}
        </Typography>
        <Stack mt={2} alignItems="flex-end" direction={"row"} flexWrap="wrap">
          <Typography variant="h5" fontSize="22px" fontWeight={"500"}>
            {value}
          </Typography>
          <Typography
            ml={1}
            variant="body2"
            fontSize="16px"
            color="text.secondary"
          >
            {suffix}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

const Label = styled(Typography)`
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 4px;
`;

export const updateCustomerDigiUniqueId = async (
  customer = {},
  planId,
  uniqueId,
  samePlanPurchaseCount
) => {
  let store_id = getStoreId();
  let { meta_data } = customer;
  // if (!isArrayWithValues(meta_data)) return;
  let digiUniqueObj = meta_data?.find(
    (i) => i.key == `digigold_uid_${store_id}`
  )?.value;
  if (!digiUniqueObj) digiUniqueObj = customer?.[`digigold_uid_${store_id}`];
  if (!digiUniqueObj) digiUniqueObj = {};
  digiUniqueObj[
    `${planId}` + (samePlanPurchaseCount ? "_" + samePlanPurchaseCount : "")
  ] = uniqueId;
  let metaData = [
    { key: `digigold`, value: "1" },
    { key: `digigold_uid_${store_id}`, value: { ...digiUniqueObj } },
  ];

  let website = await getWebsite();
  const token = await getToken();
  if (website && token) {
    try {
      let { data } = await axios({
        url: `${website}/wp-json/wc/v3/customers/${customer.id}`,
        headers: { Authorization: `basic ${token}` },
        method: "PUT",
        data: { meta_data: metaData },
      });
      return data;
    } catch (error) {
      console.log(getAxiosError(error));
    }
  }
};
export const getTotalUniqueIdAdded = async (id) => {
  let store_id = getStoreId();
  let website = await getWebsite();
  const token = await getToken();
  if (website && token) {
    try {
      let { data } = await axios({
        url: `${website}/wp-json/wc/v3/products/${id}`,
        headers: { Authorization: `basic ${token}` },
      });
      if (data) {
        let { meta_data } = data;
        if (isArrayWithValues(meta_data)) {
          let uniqueIdAdded =
            meta_data?.find((i) => i.key === "unique_id_added")?.value || 0;
          return uniqueIdAdded;
        }
        return 0;
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
  }
};
