import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  FormHelperText,
  FormLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken, getWebsite, isObjWithValues } from "../../../../helper";
import axios from "axios";
import { setGlobalToast } from "../../../../redux/actions/helperActions";
import { EDIT_SETTINGS } from "../../../../redux/actions/settingActions";
import { useTheme } from "styled-components";
import Switch from "rc-switch";

function Integration() {
  const theme = useTheme();
  const dispatch = useDispatch();
  let backgroundColor = theme.palette.primary && theme.palette.primary.main;

  const [formData, setFormData] = useState({
    enabled: false,
    auth_key: "",
  });

  const [visibility, setVisibility] = useState({
    auth_key: false,
  });
  const [loading, setLoading] = useState(false);

  const runoIntegration = useSelector(
    (state) => state.settings.runoIntegration
  );
  const globalToast = useSelector((state) => state.helper.globalToast);
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (isObjWithValues(runoIntegration)) setFormData({ ...runoIntegration });
  }, [runoIntegration]);

  const onFormEdit = (payload) => {
    setFormData((pre) => ({ ...pre, ...payload }));
  };

  const onUpdateForm = async () => {
    let website = getWebsite();
    let token = getToken();
    if (!website && !token) return false;
    setLoading(true);
    let res = await axios({
      url: `${website}/wp-json/store/v1/settings/runo_integration`,
      method: "PUT",
      headers: {
        Authorization: `Basic ${token}`,
      },
      data: {
        setting: "runo_integration",
        data: formData,
      },
    });
    if (res.data.success) {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "Runo setting saved successfully",
          severity: "success",
        })
      );
      dispatch({
        type: EDIT_SETTINGS,
        payload: {
          runoIntegration: res.data.data,
        },
      });
    } else {
      dispatch(
        setGlobalToast({
          ...globalToast,
          show: true,
          message: "Integration Failed! Please try again.",
          severity: "error",
        })
      );
    }
    setLoading(false);
  };

  return (
    <Box sx={{ paddingLeft: "12px", paddingBottom: "25px" }}>
      <Stack
        direction={"row"}
        alignItems="center"
        sx={{ gap: "14px", mb: 2 }}
        justifyContent="space-between"
      >
        <Stack direction={"row"} alignItems="center" sx={{ gap: "14px" }}>
          <FormLabel variant="h6">Enable /Disabled</FormLabel>
          <Switch
            onChange={(e) => {
              onFormEdit({ enabled: e });
            }}
            checked={formData.enabled}
            style={
              formData.enabled
                ? {
                    border: `1px solid ${backgroundColor}`,
                    backgroundColor,
                  }
                : {}
            }
          />
        </Stack>
        <LoadingButton
          loading={loading}
          variant={"contained"}
          onClick={onUpdateForm}
          sx={{ mr: 4 }}
        >
          Update
        </LoadingButton>
      </Stack>
      <Stack spacing={7} sx={{ width: isPhone ? "100%" : "70%" }}>
        {/* <Stack>
          <FormLabel variant="h6">Title</FormLabel>
          <TextField
            value={formData.title}
            onChange={(e) => onFormEdit({ title: e.target.value })}
            size="small"
            placeholder="Title"
            helperText=""
          />
          <FormHelperText>This controls the title which the user sees during checkout</FormHelperText>
        </Stack> */}
        <Stack>
          <FormLabel variant="h6">API Key</FormLabel>
          <TextField
            type={visibility.auth_key ? "text" : "password"}
            value={formData.auth_key}
            onChange={(e) => onFormEdit({ auth_key: e.target.value })}
            size="small"
            placeholder="API Key"
            helperText=""
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    // aria-label="toggle password visibility"
                    onClick={() =>
                      setVisibility((pre) => ({
                        ...pre,
                        auth_key: !visibility.auth_key,
                      }))
                    }
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {visibility.auth_key ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <FormHelperText>
            Generate API Key from Runo Admin Web Application and paste it here
          </FormHelperText>
        </Stack>
      </Stack>
    </Box>
  );
}

export default Integration;
