/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components/macro";

import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Badge,
  Box,
  Grid,
  Card,
  TextField as MuiTextField,
  Typography,
  ListItemIcon,
  ListItemText,
  Avatar,
  Fab,
  Divider as MuiDivider,
  Link,
  CardContent,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  DialogActions,
  Stack,
  Chip,
  CircularProgress,
  Tooltip,
  Menu,
  MenuItem,
  Popover,
  Button,
  Alert,
  AvatarGroup,
} from "@mui/material";
import { spacing, useTheme } from "@mui/system";
import SendIcon from "@mui/icons-material/Send";
import {
  Attachment,
  InstagramSvg,
  LeadIcon,
  Messenger,
  Whatsapp,
} from "../../../Svgs";
import {
  ConfimPopup,
  backendDoamin,
  containsEmoji,
  dialogStyle,
  formatAMPM,
  formatDate,
  formatServerValue,
  formatWhatsappMessage,
  getAuthToken,
  getAxiosError,
  getConversationById,
  getIsBackendMigrated,
  getNameFromPlatform,
  getRelativeTimeDifference,
  getStoreName,
  getTemplateVariableObjFromCustomer,
  getToken,
  getWebsite,
  isArrayWithValues,
  isDev,
  isNumber,
  isObjWithValues,
  secondaryLabel,
  secondaryLabelSmall,
  validateNumber,
  wrapLinksWithTag,
} from "../../../helper";
import "../../../App.css";

import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";

import {
  ArrowBack,
  ArticleOutlined,
  CameraAlt,
  Close,
  ContentCopy,
  DeleteOutlineOutlined,
  Done,
  DoneAll,
  DoneAllRounded,
  FormatListBulleted,
  HeadsetMic,
  InsertDriveFileOutlined,
  Inventory,
  KeyboardArrowDownRounded,
  MoreVert,
  North,
  People,
  PeopleAltOutlined,
  PermMedia,
  Person,
  PersonOutlineOutlined,
  PlayArrow,
  PostAdd,
  ReceiptOutlined,
  Reply,
  ReplyAll,
  SentimentSatisfiedOutlined,
  South,
  Star,
  TaskOutlined,
  TrendingFlatRounded,
} from "@mui/icons-material";

import { CustomerLabelView } from "../../crm/CRM";

import { LoadingButton } from "@mui/lab";
import SelectProducts from "../../components/SelectProducts";

import { useDispatch, useSelector } from "react-redux";
import { updateCustomer } from "../../crm/View";
import { CRMfilters, filterColorObj } from "../ChatsSocial";

import ProductMediaLibrary from "../../components/ProductMediaLibrary";
import {
  addResponseInChat,
  assignedFilter,
  deleteConversation,
  getTempletes,
  readMessage,
  setUpConversations,
  updateChatObject,
  updateCrmIdinSetting,
} from "../../../redux/actions/chatActions";
import TempleteList from "../../components/TempleteList";
import { Add, MailOutline } from "@material-ui/icons";

import { setGlobalToast } from "../../../redux/actions/helperActions";
import { updateCustomerInRedux } from "../../../redux/actions/crmActions";
import UserTasks, { CreateUserTask } from "../UserTasks";
import AddCannedMessages from "./AddCannedMessages";
import { primaryLabel } from "../ChatHelper";

import { currencySymbols } from "../../pricing/Gold";
import ChatProducts from "./ChatProducts";
import { useMemo } from "react";
import { memo } from "react";
import { channels } from "../../customers/components/Communication";
import { StoryReply } from "./helper";
import BroadcastList from "./BroadcastList";
// import MappingChats from "../chat/components/MappingChats";
import asyncComponent from "../../../components/Async";
import Loader from "../../../components/Loader";

const MappingChats = asyncComponent(() => import("./MappingChats"));
const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

// height: 100%;

const ChatInput = styled(Grid)`
  background-color: ${(props) => props.theme.palette.background.paper};

  // min-height: 74px;
`;
// align-centent: center;
// margin: ${(props) => (props.isIpad ? "15px" : "30px")};
export const ChatMessage = styled(Box)`
  // margin: ${(props) => "22px"};
  text-align: ${(props) => props.position};
  display: "flex";
  align-items: self-start;
`;

// background-color: ${(props) =>
//   props.isSender
//     ? props.theme.palette.primary.main
//     : props.theme.palette.background.paper};
export const ChatMessageInner = styled.div`
  display: inline-block;
  overflow: hidden;
  border-radius: 8px;
  padding: 0px 6px;
`;

export const ChatMessageTime = styled(Typography)`
  text-align: right;
`;

export const ChatMessageBubble = styled.div`
  margin-right: auto;
`;

// const labels = [
//   { label: "New Customer", value: "New Customer", color: "#8FA940" },
//   { label: "New Order", value: "New Order", color: "#C1A13F" },
//   { label: "Pending payment", value: "Pending payment", color: "#A42B70" },
//   { label: "Paid", value: "Paid", color: "#732438" },
//   { label: "Order Complete", value: "Order Complete", color: "#EEB130" },
// ];

export const check24HoursWindowClose = (chats) => {
  if (isArrayWithValues(chats)) {
    for (let i = chats.length - 1; i >= 0; i--) {
      if (chats[i] && !chats[i]?.is_sender) {
        let currentTime = Date.now() / 1000;
        let { time } = chats[i];
        if (currentTime - validateNumber(time) / 1000 > 86400) return true;
        // setIsWindowClosed(true);
        else return false;
        // setIsWindowClosed(false);
      } else return true;
    }
  }
};
const MapContainer = ({ latitude, longitude }) => {
  const apiKey = "YOUR_API_KEY";
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=15&size=400x300`;

  return (
    <div>
      <img src={mapUrl} alt="Map" width="400" height="300" />
    </div>
  );
};

const WhatsappChatMessageComponent = memo((props) => {
  let {
    message,
    obj,
    isFirstItem,
    prevObj,
    scrollToElement,
    template,
    setReplyMessage,
    setMessageImageView,
    setFormOpen,
    setProductsId,
    customer,
    allchats,
  } = props;
  const [datePill, setDatePill] = useState(false);
  const [showDatePill, setShowDatePill] = useState(false);
  const [replyView, setReplyView] = useState(false);
  const [openContacts, setOpenContacts] = useState(false);
  // const [template, setTemplate] = useState({})
  const theme = useTheme();
  const dispatch = useDispatch();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // let interactive = obj?.message?.type === "interactive" ? true : false;
  let interactiveHeader = obj?.message?.interactive?.header?.text || "";
  let interactiveBody = obj?.message?.interactive?.body?.text || "";
  let interactivefooter = obj?.message?.interactive?.footer?.text || "";
  let interactiveType = obj?.message?.interactive?.type || "";
  let interactiveButton = obj?.message?.interactive?.action?.buttons || [];
  let productLen = obj?.message?.interactive?.action?.sections || [];
  let orderLen = obj?.message?.order?.product_items?.length;
  let order = obj?.message?.order;
  let estimateTotal = obj?.message?.order?.product_items

    ?.map((obj) => validateNumber(obj?.item_price))
    ?.reduce((a, b) => a + b, 0);
  let contacts = obj?.message?.contacts;
  let currency = obj?.message?.order?.product_items?.[0]?.currency;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    let prevDate, currentDate;
    if (
      // prevObj.type === "message" ||
      prevObj?.message ||
      prevObj?.msg_text ||
      prevObj?.img_url
    ) {
      if (prevObj.time || prevObj.timestamp) {
        if (prevObj.time) {
          prevDate = formatDate(
            prevObj.time.toString().length < 13
              ? new Date(prevObj.time * 1000)
              : new Date(prevObj.time),
            "relative_date"
          );
        }
        if (prevObj.timestamp) {
          prevDate = formatDate(new Date(prevObj.timestamp), "relative_date");
        }
      }
      if (
        obj?.type === "message" ||
        obj?.message ||
        obj?.msg_text ||
        obj?.img_url
      )
        if (obj.time || obj.timestamp) {
          // console.log(JSON.stringify(obj, null, 2));
          if (obj.time) {
            currentDate = formatDate(
              obj.time.toString().length < 13
                ? new Date(obj.time * 1000)
                : new Date(obj.time),
              "relative_date"
            );
          }
          if (obj.timestamp) {
            currentDate = formatDate(new Date(obj.timestamp), "relative_date");
          }
        }
      if (prevDate !== currentDate && currentDate && !isFirstItem) {
        setShowDatePill(true);
        setDatePill(currentDate);
        // this.setState({ showDatePill: true, datePill: currentDate });
      } else if (isFirstItem && currentDate) {
        setShowDatePill(true);
        setDatePill(currentDate);
        // this.setState({ showDatePill: true, datePill: prevDate });
      } else {
        setShowDatePill(false);
        setDatePill(false);
      }
    }
  }, [obj]);
  let time = obj?.time
    ? obj?.time?.toString()?.length < 13
      ? formatAMPM(obj?.time * 1000)
      : formatAMPM(obj?.time)
    : obj?.timestamp
    ? formatAMPM(obj?.timestamp)
    : "";

  const [header, setHeader] = useState("");
  const [body, setBody] = useState("");
  const [footer, setFooter] = useState("");
  const [buttons, setButton] = useState([]);
  const [image, setImage] = useState("");
  let video = obj?.message?.video?.link;
  let location = obj?.message?.location;
  let videoCaption = obj?.message?.video?.caption;
  let imageCaption = obj?.message?.image?.caption;
  let document = obj?.message?.document?.link;
  let audio = obj?.message?.audio?.link;
  let filename = obj?.message?.document?.filename;
  let type = obj?.message?.type;
  video = video
    ? video
    : obj?.message?.template?.components?.find((o) => o.type === "HEADER")
        ?.parameters?.[0]?.video?.link;
  // videoCaption = videoCaption ? videoCaption
  let itemIds =
    obj?.message?.interactive?.action?.sections
      ?.map((o) => o.product_items)
      ?.flat()
      ?.map((obj) => obj?.product_retailer_id?.split("_")?.at(-1)) ||
    obj?.message?.order?.product_items?.map((obj) =>
      obj?.product_retailer_id?.split("_")?.at(-1)
    );
  if (obj?.message?.type === "text") {
    // console.log(obj.message?.text?.body);
    message = obj?.message.text.body || "";
  }
  if (obj?.message?.type === "button") message = obj.message.button.text || "";
  let isSender = obj?.is_sender;
  // let image = ""
  useEffect(() => {
    if (obj?.message?.type === "image") setImage(obj?.message?.image?.link);
    else setImage("");
  }, [obj, message]);
  // video

  // useEffect(() => {
  //   if (obj.message.type === "template") {
  //     let templateName = obj.message.template.name
  //     setTemplate(allWhataappTemplates.find(t => t.name === obj.message.template.name))
  //   }
  // }, [obj, allWhataappTemplates])

  useEffect(() => {
    let headerVariable = "";
    let bodyVariable = [];
    if (obj?.message?.type === "template") {
      let headerthings = obj?.message?.template?.components?.find(
        (i) => i.type === "HEADER"
      );

      if (headerthings) {
        let headerImageArray = headerthings?.parameters || [];
        let headerImageObj = headerImageArray.find(
          (obj) => obj.type === "image"
        );
        let headerImage = headerImageObj?.image?.link;
        if (headerImage) setImage(headerImage);
        else setImage("");
      }
      if (headerthings)
        headerVariable =
          headerthings.parameters[0].type === "text"
            ? headerthings.parameters[0].text
            : "";
      let bodythings = obj?.message?.template?.components?.find(
        (i) => i.type === "BODY"
      );
      if (bodythings?.parameters)
        bodyVariable = bodythings?.parameters?.map((b) => b.text);
    }
    if (isObjWithValues(template)) {
      let headerObj = template?.components?.find((o) => o.type === "HEADER");
      let bodyObj = template?.components?.find((o) => o.type === "BODY");
      let footerObj = template?.components?.find((o) => o.type === "FOOTER");
      let buttonObj = template?.components?.find((o) => o.type === "BUTTONS");
      if (footerObj) setFooter(footerObj.text);
      if (buttonObj) setButton(buttonObj.buttons.map((b) => b.text));
      setBody(bodyObj.text);

      if (headerObj && headerObj?.text)
        setHeader(headerObj.text.replace("{{1}}", headerVariable));
      else setHeader("");
      for (let index = 0; index < bodyVariable.length; index++) {
        setBody((pre) => pre.replace(`{{${index + 1}}}`, bodyVariable[index]));
      }
    } else {
      setBody("");
      setHeader("");
      setFooter("");
    }
  }, [obj, template]);
  const sendReply = () => {
    let replyObj = {
      document,
      audio,
      image,
      message,
      header,
      body,
      footer,
      obj,
    };
    setReplyMessage(replyObj);
  };
  let isReactedMessage = allchats?.find(
    (o) => o?.message?.reaction?.message_id === obj?.message?.id
  );
  const onCopy = (text, label) => {
    navigator.clipboard.writeText(text);
    dispatch(
      setGlobalToast({
        show: true,
        message: `${label} copied on clipboard`,
        severity: "success",
      })
    );
  };
  let replyObject = allchats?.find((o) => o?.id === obj?.message?.context?.id);
  // temp solution
  if (isObjWithValues(obj?.message?.reaction)) return <></>;
  // useEffect(() => {
  //   if (isIpad) setReplyView(true)
  // }, [isIpad])

  // console.log(obj, interactiveType);
  return (
    <Stack sx={{ margin: 2, maxWidth: "100%" }}>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={sendReply}>
          <ListItemIcon>
            <Reply fontSize="small" />
          </ListItemIcon>
          <ListItemText>Reply</ListItemText>
        </MenuItem>
        <MenuItem onClick={() => setFormOpen(true)}>
          <ListItemIcon>
            <TaskOutlined fontSize="small" />
          </ListItemIcon>
          <ListItemText>Create Task</ListItemText>
        </MenuItem>
      </Menu>
      {showDatePill || isFirstItem ? (
        <Typography
          variant="overline"
          sx={{
            backgroundColor: "#FEFEFE",
            width: "max-content",
            padding: "0 9px",
            borderRadius: "4px",
            boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            alignSelf: "center",
            marginTop: "5px",
          }}
        >
          {datePill}
        </Typography>
      ) : null}
      {
        // !interactive &&

        <Box
          onMouseEnter={() => !isIpad && setReplyView(true)}
          onMouseLeave={() => !isIpad && setReplyView(false)}
        >
          <Stack>
            {/* {obj?.from && (
              <Card
                sx={{
                  padding: 1,
                  px: 2,
                  backgroundColor: isSender ? "#D9FDD3" : "#fff",
                  color: "#000000",
                  // width: "100%",
                  position: "relative",
                  borderRadius: 2,
                  wordBreak: "break-word",
                  mb: 1,
                  alignSelf: isSender ? "flex-end" : "flex-start",
                }}
              >
                <Stack direction={"row"} alignItems="center" gap={2}>
                  <Typography> {obj?.from}</Typography> <TrendingFlatRounded />{" "}
                  <Typography> {obj?.to}</Typography>
                </Stack>
              </Card>
            )} */}

            <Stack
              sx={{
                padding: 2,
                mt: 4,
                // width: "max-content",
                // backgroundColor: isSender ? "#D9FDD3" : "#fff",
                color: "#000000",

                maxWidth: audio
                  ? "fit-content"
                  : isMobile
                  ? "70vw"
                  : isIpad
                  ? "30vw"
                  : image
                  ? "15vw"
                  : "30vw",
                alignSelf: isSender ? "flex-end" : "flex-start",
                // position: "relative",
                borderRadius: 2,
              }}
            >
              <Box
                // className={isSender ? "speech-bubble from-receiver" : "speech-bubble from-receiver"}
                sx={{
                  padding: 2,
                  backgroundColor: isSender ? "#D9FDD3" : "#fff",
                  color: "#000000",
                  width: "100%",
                  position: "relative",
                  borderRadius: 2,
                  wordBreak: "break-word",
                }}
              >
                {obj?.message?.context?.forwarded ? (
                  <Box
                    onClick={() => scrollToElement(obj?.message?.context?.id)}
                    sx={{
                      // backgroundColor: isSender ? "#D9FDD3" : "#8080801f",
                      borderRadius: "12px",
                      p: 1,
                    }}
                  >
                    <Stack
                      direction={"row"}
                      alignContent={"center"}
                      gap={1}
                      sx={{ color: "text.secondary", opacity: "0.7" }}
                    >
                      <ReplyAll fontSize="small" color={"action"} />{" "}
                      <Typography
                        sx={{ fontStyle: "italic" }}
                        fontWeight={"900"}
                        color={"text.secondary"}
                      >
                        Forwarded
                      </Typography>
                    </Stack>
                  </Box>
                ) : null}
                {location && (
                  <>
                    {/* 
                  <MapContainer latitude={location?.latitude} longitude={location?.longitude} /> */}
                    <Button
                      onClick={() => {
                        const url = `https://www.google.com/maps/search/?api=1&query=${location?.latitude},${location?.longitude}`;
                        window.open(url, "_blank");
                      }}
                    >
                      Navigate
                    </Button>
                  </>
                )}
                {/* {obj?.message?.context?.id && (
                  <Box
                    onClick={() => scrollToElement(obj?.message?.context?.id)}
                    sx={{
                      backgroundColor: isSender ? "#D9FDD3" : "#80808047",
                      borderRadius: "12px",
                      p: 2,

                    }}
                  >
                    <Stack direction={"row"} alignContent={"center"} gap={1} sx={{ color: "text.secondary" }}>
                      <Reply fontSize="small" color={"action"} />{" "}
                      <Typography>Replied</Typography>
                    </Stack>
                  </Box>
                )} */}
                {isArrayWithValues(contacts) && (
                  <>
                    {isArrayWithValues(openContacts) && (
                      <ConfimPopup
                        open={isArrayWithValues(openContacts)}
                        content={
                          <Stack gap={1.5}>
                            {openContacts?.map((obj) => {
                              return (
                                <>
                                  <Stack
                                    direction={"row"}
                                    gap={2}
                                    key={obj?.name?.formatted_name}
                                  >
                                    <Avatar />
                                    <Stack gap={2}>
                                      <Typography variant="h6">
                                        {obj?.name?.formatted_name}
                                      </Typography>
                                      {obj?.phones?.map((phone) => (
                                        <Typography
                                          variant="subtitle2"
                                          key={phone?.wa_id}
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          {phone?.phone}
                                          <IconButton
                                            size="small"
                                            onClick={() =>
                                              onCopy(
                                                phone?.phone,
                                                `${obj?.name?.formatted_name} Number`
                                              )
                                            }
                                          >
                                            <ContentCopy fontSize="small" />
                                          </IconButton>
                                        </Typography>
                                      ))}
                                    </Stack>
                                  </Stack>
                                  <Divider />
                                </>
                              );
                            })}
                          </Stack>
                        }
                        handleClose={() => setOpenContacts(false)}
                      />
                    )}
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      gap={2}
                      sx={{ width: "300px" }}
                    >
                      <AvatarGroup max={4} spacing={30} sx={{ ml: "30px" }}>
                        {contacts?.map((o) => (
                          <Avatar
                            alt={o?.name?.formatted_name}
                            key={o?.name?.formatted_name}
                          />
                        ))}
                      </AvatarGroup>
                      <Typography variant="subtitle2">
                        {generateContactString(contacts)}
                      </Typography>
                    </Stack>
                    <Divider sx={{ mt: 1 }} />
                    <Stack>
                      <Button onClick={() => setOpenContacts(contacts)}>
                        View
                      </Button>
                    </Stack>
                  </>
                )}

                {isReactedMessage?.message?.reaction?.emoji && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "-9px",
                      right: "-5px",
                      backgroundColor: isSender ? "#D9FDD3" : "#fff",
                      borderRadius: "8px",
                      padding: "1px 3px",
                      fontSize: "0.9rem",
                    }}
                  >
                    {isReactedMessage?.message?.reaction?.emoji}
                  </Box>
                )}
                {!isSender && isArrayWithValues(customer?.ids) && (
                  <Stack direction={"row"} alignItems="center" gap={"10px"}>
                    <Typography color={obj?.color || "#F3B604"}>
                      {" "}
                      {obj?.from}
                    </Typography>{" "}
                    {obj?.number && (
                      <Typography color> ~{obj?.number}</Typography>
                    )}
                  </Stack>
                )}
                {isSender && obj?.from && isArrayWithValues(customer?.ids) && (
                  <Stack direction={"row"} alignItems="center" gap={"10px"}>
                    <Typography>{getStoreName()}</Typography>{" "}
                    <TrendingFlatRounded />{" "}
                    <Typography color={obj?.color || "#F3B604"}>
                      {" "}
                      {obj?.from}
                    </Typography>{" "}
                    {obj?.number && (
                      <></>
                      // <Typography color> ~{obj?.number}</Typography>
                    )}
                  </Stack>
                )}
                {isIpad && (
                  <IconButton
                    id="basic-button"
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      left: isSender ? "-40px" : null,
                      right: isSender ? null : "-40px",
                    }}
                  >
                    <KeyboardArrowDownRounded />
                  </IconButton>
                )}
                {replyView && (
                  <IconButton
                    onClick={sendReply}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      left: isSender ? "-40px" : null,
                      right: isSender ? null : "-40px",
                    }}
                  >
                    <Reply />
                  </IconButton>
                )}
                {replyView && (
                  <IconButton
                    onClick={() => setFormOpen(true)}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      transform: "translateY(-50%)",
                      left: isSender ? "-70px" : null,
                      right: isSender ? null : "-70px",
                    }}
                  >
                    <TaskOutlined />
                  </IconButton>
                )}
                {image && (
                  <>
                    <img
                      src={image}
                      alt={imageCaption}
                      // onError={e => }
                      style={{ borderRadius: 2 }}
                      onClick={() =>
                        setMessageImageView((pre) => ({
                          ...pre,
                          url: image,
                          show: true,
                        }))
                      }
                      height="100%"
                      width="100%"
                    />
                    <Typography>{imageCaption}</Typography>
                  </>
                )}
                {audio ? (
                  <audio controls>
                    <source src={audio} type="audio/ogg" />
                    {/* <source src="horse.mp3" type="audio/mpeg"> */}
                    Your browser does not support the audio element.
                  </audio>
                ) : null}
                {video && (
                  <Box
                    onClick={() =>
                      setMessageImageView((pre) => ({
                        ...pre,
                        url: video,
                        show: true,
                        type: "video",
                      }))
                    }
                    sx={
                      {
                        // backgroundColor: "#CCD0D5",
                        // borderRadius: "3px",
                        // display: "flex",
                        // justifyContent: "center",
                      }
                    }
                  >
                    <Box
                      sx={{
                        backgroundColor: "#000",
                        borderRadius: "3px",
                        display: "flex",
                        justifyContent: "center",
                        padding: "50px 100px",
                      }}
                    >
                      <PlayArrow
                        style={{
                          height: "50px",
                          width: "50px",
                          color: "#ccc",
                        }}
                      />
                    </Box>
                    <Typography>{videoCaption}</Typography>
                  </Box>
                )}
                {document && (
                  <Box
                    sx={{
                      // backgroundColor: "#CCD0D5",
                      borderRadius: "3px",
                      // display: "flex",
                      // justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={
                        {
                          // backgroundColor: "#000",
                          // borderRadius: "3px",
                          // display: "flex",
                          // justifyContent: "center",
                        }
                      }
                    >
                      <Box
                        sx={{
                          backgroundColor: "#000",
                          borderRadius: "3px",
                          display: "flex",
                          justifyContent: "center",
                          padding: "50px 100px",
                        }}
                      >
                        <ArticleOutlined
                          style={{
                            height: "50px",
                            width: "50px",
                            color: "#ccc",
                          }}
                        />
                      </Box>
                    </Box>
                    <Typography>{filename}</Typography>
                    <Button
                      onClick={() => {
                        window.open(document);
                      }}
                      sx={{ width: "100%" }}
                    >
                      Download
                    </Button>
                    {/* <Link  >Download</Link> */}
                  </Box>
                )}

                <div
                  sx={{
                    wordWrap: "break-word",
                    // wordBreak: "break-all",
                    whiteSpace: "normal",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: wrapLinksWithTag(formatWhatsappMessage(message)),
                  }}
                />
                {type === "unsupported" ? (
                  <Typography
                    sx={{
                      ...secondaryLabel,
                      fontStyle: "italic",
                      opacity: 0.7,
                      mb: 0.5,
                    }}
                  >
                    Unsupported message type.
                  </Typography>
                ) : null}

                {template && (
                  <Stack>
                    {header && (
                      <Typography
                        variant="h6"
                        dangerouslySetInnerHTML={{
                          __html: wrapLinksWithTag(
                            formatWhatsappMessage(header)
                          ),
                        }}
                      ></Typography>
                    )}
                    <Typography
                      sx={{
                        wordWrap: "break-word",
                        // wordBreak: "break-all",
                        whiteSpace: "normal",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: wrapLinksWithTag(formatWhatsappMessage(body)),
                      }}
                    />

                    {footer && (
                      <Typography variant="caption" sx={{ color: "#A9A9A9" }}>
                        {footer}
                      </Typography>
                    )}
                  </Stack>
                )}
                {interactiveType === "product_list" || order ? (
                  <>
                    <Stack
                    // sx={{
                    //   padding: 2,
                    //   mt: 4,
                    //   // width: "max-content",
                    //   borderRadius: 2,
                    //   borderBottomLeftRadius: isSender ? 2 : 0,
                    //   backgroundColor: isSender ? "#D9FDD3" : "#fff",
                    //   color: "#000000",
                    //   // maxWidth: isIpad ? "80%" : image ? "30%" : template ? "30%" : "50%",
                    //   maxWidth: isMobile ? "52vw" : isIpad ? "30vw" : "30vw",
                    //   alignSelf: isSender ? "flex-end" : "flex-start",
                    //   position: "relative",
                    // }}
                    >
                      <Stack
                        direction={"row"}
                        alignItems="center"
                        sx={{
                          gap: "7px",
                          backgroundColor: isSender ? "#c9e2c5" : "#D3D3D3",
                        }}
                      >
                        <img
                          src={"/static/img/woocommerce_placeholder.png"}
                          height="80px"
                          style={{ borderRadius: "3px" }}
                          alt={"/static/img/woocommerce_placeholder.png"}
                        />

                        <Stack
                          justifyContent={"center"}
                          sx={{ overflow: "hidden", padding: "10px" }}
                        >
                          <Typography variant="subtitle2">
                            {interactiveHeader}
                          </Typography>
                          <Typography variant="subtitle2">
                            {estimateTotal
                              ? `${
                                  currencySymbols[currency] || ""
                                } ${estimateTotal} estimated total`
                              : null}
                          </Typography>

                          <Typography variant="subtitle1">
                            {productLen.length || orderLen} items
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack sx={{ mt: 0.5, mb: 0.5 }}>
                        <Typography sx={{ fontSize: "16px" }}>
                          {interactiveBody}
                        </Typography>
                        <Typography sx={{ color: "text.primary" }}>
                          {interactivefooter}
                        </Typography>
                      </Stack>
                      <Divider />
                      <Stack
                        justifyContent={"center"}
                        sx={{ textAlign: "center" }}
                      >
                        <Link
                          underline="none"
                          sx={{ cursor: "pointer" }}
                          onClick={() => setProductsId(itemIds)}
                        >
                          View Items{" "}
                        </Link>
                      </Stack>
                    </Stack>
                  </>
                ) : null}
                {interactiveType !== "product_list" &&
                isObjWithValues(obj?.message?.interactive) ? (
                  <Stack>
                    {interactiveHeader && (
                      <Typography variant="h6">
                        {interactiveHeader || ""}
                      </Typography>
                    )}
                    <Typography
                      sx={{
                        whiteSpace: "-moz-pre-wrap !important",

                        // wordWrap: "break-word",
                        // wordBreak: "break-all",
                        whiteSpace: "normal",
                      }}
                    >
                      {interactiveBody || ""}
                    </Typography>
                    {interactivefooter && (
                      <Typography variant="caption" sx={{ color: "#A9A9A9" }}>
                        {interactivefooter}
                      </Typography>
                    )}
                    {interactiveType === "list_reply" ||
                    interactiveType === "button_reply" ? (
                      <>
                        <Typography
                          sx={{
                            // whiteSpace: "-moz-pre-wrap !important",
                            // whiteSpace: "-webkit-pre-wrap",
                            // whiteSpace: "-pre-wrap",
                            // whiteSpace: "-o-pre-wrap",
                            // whiteSpace: "pre-wrap",
                            // wordWrap: "break-word",
                            // wordBreak: "break-all",
                            whiteSpace: "normal",
                          }}
                        >
                          {obj?.message?.interactive?.list_reply?.title || ""}
                          {obj?.message?.interactive?.button_reply?.title || ""}
                        </Typography>
                        <Typography
                          sx={{
                            // whiteSpace: "-moz-pre-wrap !important",
                            // whiteSpace: "-webkit-pre-wrap",
                            // whiteSpace: "-pre-wrap",
                            // whiteSpace: "-o-pre-wrap",
                            // whiteSpace: "pre-wrap",
                            wordWrap: "break-word",
                            // wordBreak: "break-all",
                            whiteSpace: "normal",
                          }}
                        >
                          {obj?.message?.interactive?.list_reply?.description ||
                            ""}
                        </Typography>
                      </>
                    ) : null}
                    {/* <Divider /> */}
                  </Stack>
                ) : null}
                <Stack
                  direction={"row"}
                  alignItems="center"
                  justifyContent={"end"}
                  sx={{ gap: "5px" }}
                >
                  <Typography
                    sx={{
                      color: "#808080",
                      display: "block",
                      textAlign: "end",
                      fontSize: "11px",
                    }}
                  >
                    {time}
                  </Typography>
                  {isSender && (
                    <DoneAll
                      style={{
                        height: "17px",
                        width: "17px",
                        color: obj.is_read === 1 ? "#0000FF" : "#808080",
                      }}
                    />
                  )}
                </Stack>
                {interactiveType === "list" ? (
                  <Button fullWidth startIcon={<FormatListBulleted />}>
                    Select Option
                  </Button>
                ) : null}
                {interactiveType === "button" ? (
                  <Grid
                    container
                    sx={{
                      // width: isMobile
                      //   ? buttons.length === 1
                      //     ? "50vw"
                      //     : "52vw"
                      //   : isIpad
                      //     ? "30vw"
                      //     : image
                      //       ? "15vw"
                      //       : "30vw",
                      width: "100%",
                      mt: 1,
                      // minWidth: "14vw",
                    }}
                    spacing={interactiveButton.length === 1 ? 0 : 2}
                  >
                    {interactiveButton.map((b, index) => (
                      <Grid
                        item
                        xs={
                          interactiveButton.length === 1
                            ? 12
                            : interactiveButton.length === 2
                            ? 6
                            : interactiveButton.length === 3
                            ? b.index !== 3
                              ? 6
                              : 12
                            : null
                        }
                      >
                        <Card
                          sx={{
                            padding: 1,
                            backgroundColor: isSender ? "#D9FDD3" : "#fff",
                            color: "#000000",
                            cursor: "pointer",
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography sx={{ textAlign: "center" }}>
                            {b?.reply?.title}
                          </Typography>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                ) : null}
              </Box>

              {template && isArrayWithValues(buttons) && (
                <Stack
                  sx={{
                    // width: "max-content",
                    // borderRadius: template ? 4 : 2,
                    // borderBottomLeftRadius: isSender ? 2 : 0,
                    // backgroundColor: isSender ? "#D9FDD3" : "#fff",
                    color: "#000000",
                    // maxWidth: "30vw",
                    // maxWidth: isMobile
                    //   ? "52vw"
                    //   : isIpad
                    //     ? "30vw"
                    //     : image
                    //       ? "15vw"
                    //       : "30vw",
                    width: "100%",
                    // alignSelf: isSender ? "flex-end" : "flex-start",
                  }}
                >
                  {isArrayWithValues(buttons) && (
                    <Grid
                      container
                      sx={{
                        // width: isMobile
                        //   ? buttons.length === 1
                        //     ? "50vw"
                        //     : "52vw"
                        //   : isIpad
                        //     ? "30vw"
                        //     : image
                        //       ? "15vw"
                        //       : "30vw",
                        width: "100%",
                        mt: 1,
                        // minWidth: "14vw",
                      }}
                      spacing={buttons.length === 1 ? 0 : 2}
                    >
                      <Divider />
                      {buttons.map((b, index) => (
                        <Grid
                          item
                          xs={
                            buttons.length === 1
                              ? 12
                              : buttons.length === 2
                              ? 6
                              : buttons.length === 3
                              ? b.index !== 3
                                ? 6
                                : 12
                              : null
                          }
                        >
                          <Card
                            sx={{
                              padding: 1,
                              backgroundColor: isSender ? "#D9FDD3" : "#fff",
                              color: "#000000",
                              cursor: "pointer",
                              height: "100%",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography sx={{ textAlign: "center" }}>
                              {b}
                            </Typography>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </Stack>
              )}
            </Stack>
          </Stack>
          {/* {true && <IconButton onClick={() => { setReplyMessage(obj) }}> */}

          {/* </IconButton>} */}
        </Box>
      }
    </Stack>
  );
});

const ChatMessageComponent = memo((props) => {
  let {
    position,
    obj,
    // nextObj,
    prevObj,
    // isLastItem,
    // message,
    isFirstItem,
    platform,
    setMessageImageView,
    // setReplyMessage,
  } = props;
  const [datePill, setDatePill] = useState(false);
  const [showDatePill, setShowDatePill] = useState(false);
  // const [replyView, setReplyView] = useState(false);

  useEffect(() => {
    let prevDate, currentDate;
    if (
      prevObj.type === "message" ||
      prevObj.message ||
      prevObj.msg_text ||
      prevObj.img_url
    ) {
      if (prevObj.time || prevObj.timestamp) {
        if (prevObj.time) {
          prevDate = formatDate(
            prevObj.time.toString().length < 13
              ? new Date(prevObj.time * 1000)
              : new Date(prevObj.time),
            "relative_date"
          );
        }
        if (prevObj.timestamp) {
          prevDate = formatDate(new Date(prevObj.timestamp), "relative_date");
        }
      }
      if (obj.type === "message" || obj.message || obj.msg_text || obj.img_url)
        if (obj.time || obj.timestamp) {
          // console.log(JSON.stringify(obj, null, 2));
          if (obj.time) {
            currentDate = formatDate(
              obj.time.toString().length < 13
                ? new Date(obj.time * 1000)
                : new Date(obj.time),
              "relative_date"
            );
          }
          if (obj.timestamp) {
            currentDate = formatDate(new Date(obj.timestamp), "relative_date");
          }
        }
      if (prevDate !== currentDate && currentDate && !isFirstItem) {
        setShowDatePill(true);
        setDatePill(currentDate);
        // this.setState({ showDatePill: true, datePill: currentDate });
      } else if (isFirstItem && currentDate) {
        setShowDatePill(true);
        setDatePill(currentDate);
        // this.setState({ showDatePill: true, datePill: prevDate });
      } else {
        setShowDatePill(false);
        setDatePill(false);
      }
    }
  }, [obj]);

  let {
    // sender, img_url,payload,
    is_sender,
  } = obj;
  // let time = "";

  let isSender = !is_sender;

  // let isSender = obj.sender;
  let time = obj?.time
    ? obj?.time?.toString()?.length < 13
      ? formatAMPM(obj?.time * 1000)
      : formatAMPM(obj?.time)
    : obj?.timestamp
    ? formatAMPM(obj?.timestamp)
    : "";

  // let senderImagePayload = payload && payload.payload;
  let image = "";
  // console.log(image, obj?.message?.attachments[0]?.payload?.url, obj);
  if (isArrayWithValues(obj?.message?.attachments))
    image = obj?.message?.attachments[0]?.payload?.url;
  // console.log(image);
  // img_url ||
  // (senderImagePayload && senderImagePayload.url) ||
  // (message && message.url);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  // console.log(message);
  // let is_sender = obj.is_sender;
  let message = "";
  if (platform === "whatsapp") {
    if (obj.message.type === "text") {
      // console.log(obj.message?.text?.body);
      message = obj.message.text.body || "";
    }
  }
  if (platform !== "whatsapp") message = obj.message.text;
  if (message?.body) message = message.body;
  let storyReply = obj?.message?.reply_to?.story?.url;
  const story_mention =
    obj?.message?.attachments?.[0]?.type === "story_mention";
  const isDeleted = obj?.is_deleted;
  const isEmojiReply = containsEmoji(message);

  return (
    <Stack
      sx={
        {
          // backgroundImage: platform === "whatsapp" ? "static/img/whatsappBackground.png" : null,
          // backgroundAttachment: "fixed",
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "content",
        }
      }
    >
      <div
        style={{
          justifyContent: "center",
          textAlign: "center",
          marginTop: "10px",
          whiteSpace: "pre-wrap",

          // maxWidth: isIpad ? "60vw" : "50vw",
        }}
      >
        {showDatePill || isFirstItem ? (
          <span
            style={{
              alignSelf: "center",
              backgroundColor: "#fff",
              borderRadius: "18px",
              padding: "3px 7px",
              textAlign: "center",
              margin: "10px auto",
              border: "0.5px solid #ababab",
              // boxShadow: "2px 2px 3px 0px rgba(0,0,0,0.36)",
              // position: "sticky",
            }}
          >
            <span style={{ fontSize: 13, color: "#616161" }}>{datePill}</span>
          </span>
        ) : null}
      </div>
      <ChatMessage
        // onMouseEnter={() => { setReplyView(true) }}
        // onMouseLeave={() => setReplyView(false)}
        isIpad={isIpad}
        position={isSender ? "left" : "right"}
        sx={{
          margin: "22px",
          maxWidth: "70%",
          marginLeft: !isSender && "auto",
          marginRight: isSender && "auto",
          position: "relative",
        }}
      >
        {isDeleted ? (
          <>
            <Box
              sx={{
                backgroundColor:
                  platform === "whatsapp"
                    ? isSender
                      ? "#efefef"
                      : "#E3F7D7"
                    : isSender
                    ? "#efefef"
                    : "#0A7CFF",
                padding: "10px 8px",
                borderRadius: "30px",
                color:
                  platform === "whatsapp"
                    ? isSender
                      ? "#000"
                      : "#000"
                    : isSender
                    ? "#000"
                    : "#fff",
                fontSize: "15px",
                fontWeight: "500",
                px: 3,
                textAlign: "left",
                marginRight: "12px",
                marginLeft: "12px",
              }}
              highlighted={position === "right"}
            >
              <Typography
                sx={{
                  ...secondaryLabel,
                  fontStyle: "italic",
                  opacity: 0.7,
                  mb: 0.5,
                }}
              >
                This message has been unsent
              </Typography>
            </Box>
            <ChatMessageTime
              isSender={isSender}
              variant="body2"
              fontSize={"11px"}
              color="text.secondary"
            >
              {time}
            </ChatMessageTime>
          </>
        ) : (
          // <Typography sx={{ ...secondaryLabel, mx: 4 }}>This is unsend by sender</Typography>
          <>
            {/* {replyView && (
              <IconButton
                sx={{
                  position: "absolute",
                  top: "50%",
                  transform: "translateY(-50%)",
                  left: !isSender ? "-40px" : null,
                  right: !isSender ? null : "-40px",
                }}
                // onClick={() => { setReplyMessage(obj) }}
              >
                <Reply />
              </IconButton>
            )} */}
            <ChatMessageInner
              isSender={isSender}
              style={
                // { maxWidth: isIpad ? "60vw" : "60vw" },
                image ? { maxWidth: "min-content" } : {}
              }
            >
              {/* <ChatMessageAvatar alt="Lucy Lavender" src={avatar} /> */}
              <ChatMessageBubble
                sx={{
                  alignSelf: isSender ? "flex-start" : "flex-end",
                  padding: 2,
                  // position: "relative"
                  // width: "200px"
                }}
                highlighted={position === "right"}
              >
                {/* <Typography
              variant="body1"
              sx={{ fontSize: "13px", fontWeight: "500" }}
            >
              {isSender ? sender.name : "You"}
            </Typography> */}

                {storyReply && (
                  <Box sx={{ mx: 3 }}>
                    <Typography sx={{ ...secondaryLabel, mb: 1, mx: 3 }}>
                      Replied to your story
                    </Typography>
                    <Badge
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      badgeContent={
                        isEmojiReply ? (
                          <Box
                            sx={{
                              // width: 30,
                              // height: 30,
                              border: `2px solid ${theme.palette.background.paper}`,
                              padding: "4px  3px ",
                              backgroundColor: `#efefef`,
                              borderRadius: "50%",
                              fontSize: "20px",
                            }}
                          >
                            {message}
                          </Box>
                        ) : (
                          ""
                        )
                      }
                      // badgeContent={}
                      // overlap="circular"
                    >
                      <StoryReply
                        url={storyReply}
                        isIpad={isIpad}
                        isMobile={isMobile}
                      />
                    </Badge>
                  </Box>
                )}
                {message && !isEmojiReply ? (
                  <Typography
                    variant="subtitle1"
                    // component="span"
                    sx={{
                      backgroundColor:
                        platform === "whatsapp"
                          ? isSender
                            ? "#efefef"
                            : "#E3F7D7"
                          : isSender
                          ? "#efefef"
                          : "#0A7CFF",
                      padding: "10px 8px",
                      borderRadius: "30px",
                      color:
                        platform === "whatsapp"
                          ? isSender
                            ? "#000"
                            : "#000"
                          : isSender
                          ? "#000"
                          : "#fff",
                      fontSize: "15px",
                      fontWeight: "500",
                      px: 3,
                      textAlign: "left",
                      // wordWrap: "break-word",
                      // display: "inline-block",
                      // wordBreak: "break-all",
                      // whiteSpace: "-moz-pre-wrap !important",
                      // whiteSpace: "-webkit-pre-wrap",
                      // whiteSpace: "-pre-wrap",
                      // whiteSpace: "-o-pre-wrap",
                      // whiteSpace: "pre-wrap",
                      // wordWrap: "break-word",
                      // wordBreak: "break-all",
                      whiteSpace: "normal",
                      marginRight: "12px",
                      marginLeft: "12px",
                      // mt: 4,
                    }}
                  >
                    {message || ""}
                    {/* {(obj.message && obj.message.text) || obj.msg_text} */}
                  </Typography>
                ) : null}
                {image ? (
                  <>
                    {story_mention ? (
                      <Typography sx={{ ...secondaryLabel, mb: 1, mx: 3 }}>
                        Mentioned you in story
                      </Typography>
                    ) : null}
                    <Box
                      sx={{
                        mt: 1,
                        maxWidth: "calc(10vw-44px)",
                        width: "calc(10vw-44px)",
                        border: story_mention ? "" : "0.5px solid",
                        borderColor: "text.primary",
                        borderRadius: "20px",
                        overflow: "hidden",
                        mx: 3,
                      }}
                      onClick={() =>
                        !story_mention &&
                        setMessageImageView((pre) => ({
                          ...pre,
                          url: image,
                          show: true,
                        }))
                      }
                    >
                      <StoryReply
                        url={image}
                        isIpad={isIpad}
                        isMobile={isMobile}
                      />

                      {/* <img
                    src={image}
                    style={{
                      width: isMobile ? "50vw" : isIpad ? "40vw" : "15vw",
                      // width: "30vw",
                      // maxWidth: "calc(10vw-44px)",
                      // width: "calc(10vw-44px)",
                      // height: "100px",
                      // height: "400px",
                      objectFit: "contain",
                      // objectPosition: "50% 100%",
                    }}
                  /> */}
                    </Box>
                  </>
                ) : null}
                {/* {image ? (
              <ChatImageContainer onClick={() => setMessageImageView(image)}>
                <ChatImage src={image} />
                <div
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    padding: "0px 10px",
                  }}
                >
                  <PlayArrowRoundedIcon />
                  <Typography variant="body1" fontWeight="bold" ml={1}>
                    Photo
                  </Typography>
                </div>
              </ChatImageContainer>
            ) : null} */}
              </ChatMessageBubble>
              <Stack
                direction={"row"}
                m={1}
                mt={0.2}
                alignItems="center"
                justifyContent={"flex-end"}
                sx={{ backgroundColor: "transparent" }}
                style={{
                  backgroundColor: "transparent",
                }}
              >
                <ChatMessageTime
                  isSender={isSender}
                  variant="body2"
                  fontSize={"11px"}
                  color="text.secondary"
                  sx={isEmojiReply && { mt: "25px" }}
                >
                  {time}
                </ChatMessageTime>
                {!isSender && (
                  <DoneAllRounded
                    fill="#808080"
                    style={{
                      marginLeft: "5px",
                      color: "#808080",
                      height: "18px",
                      width: "18px",
                    }}
                  />
                )}
              </Stack>
            </ChatMessageInner>
          </>
        )}
      </ChatMessage>
    </Stack>
  );
});

const ChatWindow = memo((props) => {
  let {
    customerLoading,
    customerName,
    customerProfile,
    chatsTabSelected,
    customerLabel,
    customer,
    customerInfo,
    setCustomerView,
  } = props;
  let { platform } = customer;
  const chatsParent = useRef(null);
  const location = useLocation();
  // const [message, setMessage] = useState("");
  const [chats, setChats] = useState([]);
  // const [customerId, setCustomerId] = useState(null);
  // const [fetchingChats, setFetchingChats] = useState(false);
  // const [chatsRendered, setChatsRendered] = useState(false);

  // const [sendingImage, setSendingImage] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [caption, setCaption] = useState("");
  const [imageToUpload, setImageToUpload] = useState(null);
  const [rowImageToUpload, setRowImageToUpload] = useState(null);
  // const [showProductDialog, toggleProductDialog] = useState(false);
  // const [productSearch, setProductSearch] = useState("");
  // const [fetchingProducts, setFetchingProducts] = useState(false);
  // const [fetchingCatalogProducts, setFetchingCatalogProducts] = useState(false);
  // const [productsSearched, setProductsSearched] = useState([]);
  // const [selectedProducts, setSelectedProducts] = useState([]);
  // const [catalogDialog, setCatalogDialog] = useState(false);
  // const [catalogProducts, setCatalogProducts] = useState([]);
  // const [catalogError, setCatalogError] = useState("");
  // const [creatingCatalog, setCreatingCatalog] = useState(false);

  const [sessionEnded, setSessionEnded] = useState(false);
  const [notOptedIn, setNotOptedIn] = useState(false);
  const [isWindowClosed, setIsWindowClosed] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  // const [selectedCatalogProducts, setSelectedCatalogProducts] = useState([]);
  const [propsChatsLength, setPropChatsLength] = useState(0);
  const [messageImageView, setMessageImageView] = useState({
    url: "",
    show: false,
  });
  // const [isFullScreen, setIsFullScreen] = useState(false);
  // const [quotationDialog, setQuotaionDialog] = useState(false);
  // const [customerSearching, setCustomerSearching] = useState(false);
  // const [showNotesDialog, setShowNotesDialog] = useState(false);
  // const [noteForm, setNoteForm] = useState({
  //   title: "",
  //   note: "",
  // });
  // const [allQuotationCustomers, setQuotationCustomers] = useState([]);
  // const [catalogForm, setCatalogForm] = useState({
  //   content: "",
  //   private: "off",
  //   expiry: "",
  //   title: "",
  //   customers: [],
  //   products: [],
  //   public: false,
  // });
  const [selectProductOpen, setSelectProductOpen] = useState(false);

  // const [quotationForm, setQuotationForm] = useState({
  //   customers: {},
  //   title: "",
  //   otp_enabled: false,
  //   products: [],
  // });
  // const [speedDialOpen, setSpeedDialOpen] = useState(false);
  // const [quotationError, setQuotationError] = useState("");
  const [customerLabelId, setCustomerLabelId] = useState(false);
  // const [noteLoading, setNoteLoading] = useState(false);
  // const [scheduleDate, setScheduleDate] = useState(new Date());
  const [integratedSocials, setIntegratedSocials] = useState([]);
  // const [customer, setCustomer] = useState(false);
  const [productsId, setProductsId] = useState([]);
  ///////////////
  const [openCreateTemplete, setopenCreateTemplete] = useState(false);
  const [openTempleteList, setOpenTempleteList] = useState(false);
  const [messageInput, setMessageInput] = useState("");
  const [mediaLibraryOpen, setMediaLibraryOpen] = useState(false);
  const [mediaLibraryImage, setMediaLibraryImage] = useState([]);
  const [templateObj, setTemplateObj] = useState({});
  // const [sendingMessage, setSendingMessage] = useState(false)
  const [metaIntegration, setMetaIntegration] = useState({});
  const [integratedApps, setIntegratedApps] = useState([]);
  const [replyMessage, setReplyMessage] = useState(null);
  const [allWhatsappTemplates, setAllWhatsAppTemplates] = useState([]);
  const [showHeader, setShowHeader] = useState(true);
  const [selectedCatalog, setSelectedCatalog] = useState("");
  const [lastIntreact, setLastIntreact] = useState("");
  const [toggleDeleteDialog, setToggleDeleteDialog] = useState(false);
  const [deletingConversation, setdeletingConversation] = useState(false);
  const [anchorElCanned, setAnchorElCanned] = React.useState(null);
  const [showChats, setShowChats] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  let fetchingInterval;
  // const fullscreenHandle = useFullScreenHandle();
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reduxMetaIntegrations = useSelector(
    (state) => state.settings.metaIntegration
  );
  let allCannedMessages = useSelector((state) => state.chats.cannedMessages);
  const allUserTasks = useSelector((state) => state.chats.allUserTasks);
  const allReduxConversations = useSelector(
    (state) => state.chats.conversations
  );
  const [userIncompleteTasks, setUserInCompleteTasks] = useState([]);

  useEffect(() => {
    if (!isArrayWithValues(allUserTasks)) return setUserInCompleteTasks([]);
    setUserInCompleteTasks(
      allUserTasks.filter(
        (i) => i?.user_id == customer?.id && i?.status !== "complete"
      )
    );
  }, [allUserTasks, customer]);

  const checkOptedIn = (chats) => {
    if (isArrayWithValues(chats))
      setNotOptedIn(Boolean(!chats.find((i) => !i.is_sender)));
    else setNotOptedIn(true);
    // if (isArrayWithValues(chats)) {
    //   let notOptedIn;
    //   let length = chats.length > 175 ? 176 : chats.length;
    //   // let length = chats.length > 5 ? 6 : chats.length;
    //   let temp = 0;
    //   for (let i = 0; i < length; i++) {
    //     let obj = chats[i];
    //     if (
    //       !obj.destination &&
    //       !obj.msg_text &&
    //       !obj.source &&
    //       !obj['src.name"'] &&
    //       !obj.sender
    //     ) {
    //       temp++;
    //     }
    //   }
    //   if (temp === length) {
    //     setNotOptedIn(true);
    //     return;
    //   } else setNotOptedIn(false);
    // }
  };
  const check24HoursWindow = (chats) => {
    if (isArrayWithValues(chats)) {
      let length = chats.length;
      for (let i = length - 1; i >= 0; i--) {
        if (chats[i] && !chats[i]?.is_sender) {
          let currentTime = Date.now() / 1000;
          let { time } = chats[i];
          if (currentTime - validateNumber(time) / 1000 > 86400)
            setIsWindowClosed(true);
          else setIsWindowClosed(false);
          break;
        }
      }
    }
  };

  useMemo(() => {
    setChats(customer.conversation || []);

    setSelectedTab(customer?.platform);
  }, [customer.conversation]);
  // useEffect(() => {
  //   if (isArray(customer.conversation)) {

  //     // setChats(customer.conversation);
  //   }
  //   // setTimeout(() => {

  //   // }, 1000);

  //   else setChats([]);
  // }, [customer.conversation, customer]);

  // useEffect(() => {
  //   setCustomer(props.customer);
  // }, [props.customer]);
  // useMemo(() => {
  //   setTimeout(() => {
  //     scrollToBottom();
  //   }, 2000);
  // }, [chats]);
  useEffect(() => {
    scrollToBottom();
  }, [chats]);

  const actions = [
    {
      icon: <ImageOutlinedIcon />,
      name: "Image",
      onClick: () => {
        inputFile.current.click();
      },
    },
    {
      icon: <Inventory2OutlinedIcon />,
      name: "Products",
      onClick: () => setSelectProductOpen("select_product"),
    },
    {
      icon: <AutoStoriesOutlinedIcon />,
      name: "Catalog",
      onClick: () => setSelectProductOpen(true),
    },
    {
      icon: <ReceiptOutlined />,
      name: "Quotation",
      onClick: () => setSelectProductOpen("quotation"),
    },
  ];
  useEffect(() => {
    return () => clearInterval(fetchingInterval);
  }, []);

  // useEffect(() => {
  //   onProductSearch(productSearch);
  // }, [productSearch]);

  // useEffect(() => {
  //   if (showProductDialog && !isArrayWithValues(productsSearched))
  //     fetchProducts(productSearch, setProductsSearched, setFetchingProducts);
  // }, [showProductDialog]);

  const scrollToBottom = () => {
    const domNode = chatsParent.current;
    if (domNode && domNode.scrollHeight) {
      domNode.scrollTop = domNode.scrollHeight;
    }
  };

  useMemo(() => {
    const domNode = chatsParent.current;
    if (isArrayWithValues(chats) && isArrayWithValues(props.chats)) {
      if (propsChatsLength !== chats.length) {
        if (domNode.scrollHeight - domNode.scrollTop < 1000) {
          scrollToBottom();
          setPropChatsLength(chats.length);
          if (sessionEnded) setSessionEnded(false);
        }
      }
    }
    checkOptedIn(chats);
    check24HoursWindow(chats);
    scrollToBottom();
    // if (isArrayWithValues(props.chats)) {
    //   setChatsRendered(true);
    //   if (domNode.scrollHeight - domNode.scrollTop < 1000) {
    //     setTimeout(() => scrollToBottom(), 0);
    //   }
    //   if (isArrayWithValues(chats)) {
    //     let length = chats.length;
    //     for (let i = 0; i < length; i++) {
    //       let obj = props.chats[i];
    //       if (obj.sender) {
    //         let currentTime = Date.now() / 1000;
    //         let daysDiff = numDaysBetween(
    //           new Date(currentTime),
    //           new Date(obj.time)
    //         );
    //         console.log(Math.floor(daysDiff) > 0);
    //         if (Math.floor(daysDiff) > 0) setSessionEnded(true);
    //         break;
    //       }
    //     }
    //   }
    // }
    // if (isNumber(domNode.scrollHeight) && isNumber(domNode.scrollTop)) {
    //   let scrolled = domNode.scrollTop - domNode.scrollHeight;
    //   scrolled = Math.abs(scrolled);
    //   if (scrolled < 1000) domNode.scrollTop = domNode.scrollHeight;
    // }
  }, [chats]);

  // useEffect(() => {
  //   if (isArrayWithValues(props.chats)) {
  //     checkOptedIn(props.chats);
  //     setPropChatsLength(props.chats.length);
  //     // if (shouldReverse(props.chats)) setChats([...props.chats].reverse());
  //     // else setChats(props.chats);
  //     let length = [...props.chats].length;
  //     let noSenderMessages = true;
  //     for (let i = 0; i < length; i++) {
  //       let obj = props.chats[i];
  //       if (obj.sender) {
  //         noSenderMessages = false;
  //         let currentTime = Date.now() / 1000;
  //         let daysDiff = numDaysBetween(
  //           new Date(currentTime),
  //           new Date(obj.time)
  //         );
  //         if (Math.floor(daysDiff) > 0) setSessionEnded(true);
  //         else setSessionEnded(false);
  //         break;
  //       }
  //     }
  //     if (noSenderMessages) {
  //       setNotOptedIn(true);
  //     }
  //   } else {
  //     setNotOptedIn(true);
  //     // setChats([]);
  //   }
  // }, [props.chats]);

  // useEffect(() => {
  //   if (!sessionEnded && !notOptedIn) setFetchingChats(true);
  //   else setFetchingChats(false);
  // }, [sessionEnded, notOptedIn]);

  // var numDaysBetween = function (d1, d2) {
  //   var today = d2.getTime() / 1000;
  //   var diff = Math.abs(d1 - today);
  //   return diff / (60 * 60 * 24);
  // };

  useMemo(() => {
    let pieces = location.pathname.split("/");
    setShowHeader(!pieces.includes("crm"));
    // if (pieces[pieces.length - 1] !== "chats")

    //   setCustomerId(pieces[pieces.length - 1]);

    // return () => clearInterval(fetchingInterval);
  }, []);

  useEffect(() => {
    setIsLoading(true);

    // Simulating an asynchronous operation
    setTimeout(() => {
      // Update the state here...
      setIsLoading(false);
    }, 2000); // Simulating a 2-second delay
  }, [location]);
  // useEffect(() => {
  //   if (fetchingChats) {
  //     clearInterval(fetchingInterval);
  //     if (customer.id)
  //       fetchingInterval = setInterval(() => {
  //         fetchChats(customer.id, setChats);
  //         // if (props.id)
  //         // else {
  //         //   let pieces = location.pathname.split("/");
  //         //   fetchChats(pieces[pieces.length - 1], setChats);
  //         // }
  //       }, 10000);
  //   } else clearInterval(fetchingInterval);
  //   return () => {
  //     clearInterval(fetchingInterval);
  //   };
  // }, [fetchingChats]);

  // const onMessageSend = () => {
  //   if (
  //     new Date(scheduleDate).toISOString().substring(0, 10) !==
  //     new Date().toISOString().substring(0, 10)
  //   ) {
  //     setSendingMessage(true);
  //     setTimeout(() => {
  //       setToast({
  //         show: true,
  //         message: `Message scheduled for ${formatDate(
  //           new Date(scheduleDate),
  //           "dd:mm:yyyy hh:mm am/pm"
  //         )}`,
  //         severity: "success",
  //       });
  //       setSendingMessage(false);
  //       setScheduleDate(new Date());
  //     }, 3000);
  //     return;
  //   }
  //   setFetchingChats(false);
  //   setSendingMessage(true);
  //   setMessage("");
  //   let messageObj = {
  //     whatsapp_chat: {
  //       type: "text",
  //       message,
  //     },
  //   };

  //   const website = getWebsite();
  //   const token = getToken();
  //   if (website && token)
  //     axios({
  //       url: `${website}/wp-json/wc/v3/customers/${customerId}`,
  //       data: { ...messageObj },
  //       headers: {
  //         Authorization: `Basic ${token}`,
  //       },
  //       method: "POST",
  //     })
  //       .then((res) => {
  //         res = res.data;
  //         if (res && isArrayWithValues(res.whatsapp_chat)) {
  //           if (shouldReverse(res.whatsapp_chat))
  //             setChats(res.whatsapp_chat.reverse());
  //           else setChats(res.whatsapp_chat);
  //           // scrollToBottom();
  //         }
  //       })
  //       .catch((e) => console.log(getAxiosError(e)))
  //       .finally(() => setSendingMessage(false));
  // };

  // const onImageSend = () => {
  //   setFetchingChats(false);
  //   setSendingImage(true);

  //   const formData = new FormData();
  //   formData.append(`whatsapp_chat[type]`, "image");
  //   formData.append(`whatsapp_chat[message]`, caption);
  //   // formData.append("file", {
  //   //   name: `test`,
  //   //   uri: image,
  //   //   type: "image/jpg",
  //   // });
  //   // formData.append("image", image, image.name);
  //   formData.append("file", rowImageToUpload);

  //   setImageToUpload("");
  //   setRowImageToUpload("");
  //   // return;
  //   const website = getWebsite();
  //   const token = getToken();
  //   const headers = {
  //     Accept: "image/*",
  //     Authorization: `Basic ${token}`,
  //     // "Content-Type": "multipart/form-data",
  //   };
  //   let url = `${website}/wp-json/wc/v3/customers/${customerId}`;
  //   if (website && token)
  //     // axios({
  //     //   url: `${website}/wp-json/wc/v3/customers/${customerId}`,
  //     //   data: formData,
  //     //   // data: { ...messageObj },
  //     //   headers: {
  //     //     Accept: "image/*",
  //     //     Authorization: `Basic ${token}`,
  //     //     // "Content-Type": "multipart/form-data",
  //     //   },
  //     //   method: "POST",
  //     // })
  //     axios
  //       .post(url, formData, { headers })
  //       .then((res) => {
  //         res = res.data;
  //         if (res && isArrayWithValues(res.whatsapp_chat)) {
  //           setChats(res.whatsapp_chat.reverse());
  //           scrollToBottom();
  //         }
  //       })
  //       .catch((e) => console.log(getAxiosError(e)))
  //       .finally(() => {
  //         setSendingImage(false);
  //       });
  // };

  // const onProductSelect = (product) => {
  //   toggleProductDialog(false);
  //   let { name, price, categories, permalink, sku, images, status } = product;
  //   let category = categories && categories[0] && categories[0].slug;
  //   name = name.charAt(0).toUpperCase() + name.slice(1);
  //   if (category)
  //     category = category.charAt(0).toUpperCase() + category.slice(1);
  //   let image =
  //     images && Array.isArray(images) && images.length > 0 && images[0].src;
  //   let message = `Product: ${name}\nPrice: ₹${price} \n${category ? `Category: ${category}\n` : ""
  //     }${permalink}`;
  //   setMessage(message);
  //   // let products = [...selectedProducts];
  //   // if (products.find((i) => i.id === product.id))
  //   //   products = products.filter((i) => i.id !== product.id);
  //   // else products.push(product);
  //   // setSelectedProducts(products);
  // };

  // const onFileDrop = (value) => {
  //   const types = ["image/jpeg", "image/png", "image/gif", "image/x-icon"];
  //   let size = Math.round(validateNumber(value.size / 1000));
  //   let type = value.type;
  //   if (!types.includes(type))
  //     return setToast({
  //       show: true,
  //       message: "Only JPG, PNG and GIF formats are allowed",
  //       severity: "error",
  //     });
  //   if (size > 512)
  //     return setToast({
  //       show: true,
  //       message: "File size should not exceed 500kb.",
  //       severity: "error",
  //     });
  //   if (value) {
  //     setRowImageToUpload(value);
  //     setImageToUpload(URL.createObjectURL(value));
  //   }
  // };

  // const changeCatalogForm = (payload) => {
  //   setCatalogForm({ ...catalogForm, ...payload });
  // };

  // const onProductSearch = async (value) => {
  //   if (!value || (value && value.length < 2)) return;
  //   if (customerSearchTimeout) clearTimeout(customerSearchTimeout);

  //   customerSearchTimeout = setTimeout(async () => {
  //     fetchProducts(value, setUpdatedProducts, setFetchingCatalogProducts);
  //   }, 500);
  // };

  // const setUpdatedProducts = (products) => {
  //   if (isArrayWithValues(products)) {
  //     let arr = [];
  //     for (let i = 0; i < products.length; i++) {
  //       arr.push({
  //         label: `${products[i].name}`,
  //         value: products[i].id,
  //       });
  //     }
  //     setCatalogProducts(arr);
  //   }
  // };

  // const onCreateCatalog = () => {
  //   try {
  //     let { title, expiry, content, products } = catalogForm;

  //     if (!title) return setCatalogError("Please enter title");
  //     if (!catalogForm.public) {
  //       if (!expiry) return setCatalogError("Please enter expiry");
  //       if (expiry > 48 || expiry < 1)
  //         return setCatalogError("Expiry should be miniumn 1 to maximum 48");
  //     }
  //     setCatalogError("");
  //     let obj = {
  //       title,
  //       content,
  //       expiry,
  //       isPublic: catalogForm.public,
  //       // products: selectedProducts,
  //       customers: [customerId],
  //     };
  //     if (isArrayWithValues(products) && !catalogForm.public) {
  //       obj.products = products.map((i) => i.value && i.value);
  //       obj.products = obj.products.toString();
  //     }
  //     setCreatingCatalog(true);
  //     // return;
  //     // axios
  //     //   .post(
  //     //     "https://www.tiarabytj.com/wp-json/store/v1/catalogs?type=add_catalogue",
  //     //     JSON.stringify({ obj }),
  //     //     {
  //     //       headers: {
  //     //         Authorization:
  //     //           "Basic dGlhcmFieXRqQGdtYWlsLmNvbTpUaWFyYWJ5dGoxMiMk=",
  //     //         "Content-Type": "application/json",
  //     //       },
  //     //     }
  //     //   )
  //     // axios({
  //     //   url: "https://www.tiarabytj.com/wp-json/store/v1/catalogs",
  //     //   headers: {
  //     //     Authorization: "Basic dGlhcmFieXRqQGdtYWlsLmNvbTpUaWFyYWJ5dGoxMiMk=",
  //     //     "Content-Type": "application/json",
  //     //     "Access-Control-Allow-Origin": "*",
  //     //   },
  //     //   params: { type: "add_catalogue" },
  //     //   method: "POST",
  //     //   data: JSON.stringify({ data: { ...obj } }),
  //     // })
  //     // axios
  //     //   .post(
  //     //     "https://www.tiarabytj.com/wp-json/store/v1/catalogs?type=add_catalogue",
  //     //     JSON.stringify({ data: { ...obj } }),
  //     //     {
  //     //       headers: {
  //     //         Authorization:
  //     //           "Basic dGlhcmFieXRqQGdtYWlsLmNvbTpUaWFyYWJ5dGoxMiMk=",
  //     //         "Access-Control-Allow-Origin": "*",
  //     //       },
  //     //     }
  //     //   )

  //     const website = getWebsite();
  //     const token = getToken();
  //     if (website && token)
  //       axios({
  //         url: `${website}/wp-json/store/v1/catalogs?type=add_catalogue`,
  //         data: JSON.stringify({ data: { ...obj } }),
  //         headers: {
  //           Authorization: `Basic ${token}`,
  //           "Content-Type": "application/json",
  //         },
  //         method: "POST",
  //       })
  //         .then((res) => {
  //           setCatalogDialog(false);
  //           let { data } = res;
  //           if (isArrayWithValues(data.data))
  //             setMessage(
  //               `${message ? `${message} \n ` : ""}${data.data[0].link}`
  //             );
  //           // setToast({ isError: false, message: "Catalog added successfully!" });
  //           setToast({
  //             show: true,
  //             severity: "success",
  //             message: "Catalog added successfully!",
  //           });
  //         })
  //         .catch((e) => {
  //           console.log(getAxiosError(e));
  //           setToast({
  //             show: true,
  //             severity: "error",
  //             message: "Couldn't add Catalog, try again!",
  //           });
  //         })
  //         .finally(() => {
  //           setCreatingCatalog(false);
  //         });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const onQuotationCustomerSearch = async (value, setState) => {
  //   if (!value || (value && value.length < 2)) return;
  //   if (customerSearchTimeout) clearTimeout(customerSearchTimeout);

  //   customerSearchTimeout = setTimeout(async () => {
  //     setCustomerSearching(true);
  //     await fetchCustomers(value, setState);
  //     setCustomerSearching(false);
  //   }, 500);
  // };
  // const setUpdatedQuotationCustomers = (customers) => {
  //   if (isArrayWithValues(customers)) {
  //     let arr = [];
  //     for (let i = 0; i < customers.length; i++) {
  //       arr.push({
  //         label: `${customers[i].first_name} ${customers[i].last_name}`,
  //         value: customers[i].id,
  //       });
  //     }
  //     setQuotationCustomers(arr);
  //   }
  // };

  // const onQuotationCreate = () => {
  //   let { customers } = quotationForm;
  //   let obj = { ...quotationForm, customer: `${customers.value}` };
  //   dispatch(
  //     createQuotation(
  //       obj,
  //       (quotation) => {
  //         setToast({
  //           show: true,
  //           message: "Quotation created successfully!",
  //           severity: "success",
  //         });
  //         if (quotation.link) setMessage(quotation.link);
  //         closeQuotationDialog();
  //       },
  //       () => {
  //         setToast({
  //           show: true,
  //           message: "Couldn't create quotation, Please try again later",
  //           severity: "error",
  //         });
  //       }
  //     )
  //   );
  //   console.log(quotationForm);
  // };

  // const fullscreenChange = useCallback(
  //   (state, handle) => {
  //     if (handle === fullscreenHandle) {
  //       setIsFullScreen(state);
  //     }
  //   },
  //   [fullscreenHandle]
  // );

  // const changeQuotationForm = (payload) => {
  //   if (quotationError) setQuotationError("");
  //   setQuotationForm({ ...quotationForm, ...payload });
  // };
  // const closeQuotationDialog = () => setQuotaionDialog(false);
  function formatArrayStrings(array) {
    if (array.length === 0) {
      return "";
    } else if (array.length === 1) {
      return array[0];
    } else if (array.length === 2) {
      return array.join(" and ");
    } else {
      const lastElement = array[array.length - 1];
      const remainingElements = array.slice(0, array.length - 1);
      return `${remainingElements.join(", ")}, and ${lastElement}`;
    }
  }

  const onProductsSelected = (products) => {
    if (!isArrayWithValues(products)) return;

    let action = {
      catalog_id: selectedCatalog,
    };
    let productData = products.map((obj) => {
      if (isArrayWithValues(obj?.categories)) {
        return {
          catagory: obj?.categories[0]?.name || "Miscellaneous",
          product_retailer_id: `${obj.sku.replaceAll(" ", "_")}_${obj.id}`,
        };
      }
    });

    let response = [];
    let obj = [];
    let catagory = [];
    for (let temp of productData) {
      catagory.push(temp.catagory);
      if (!Array.isArray(obj[temp.catagory])) obj[temp.catagory] = [];
      if (obj[temp.catagory]) {
        obj[temp.catagory].push(temp.product_retailer_id);
      } else {
        obj[temp.catagory] = [temp.product_retailer_id];
      }
      // obj[temp.catagory] ? obj[temp.catagory].push(temp.product_retailer_id) :
    }
    console.log(catagory);

    for (let key of Object.keys(obj)) {
      let object = {
        title: key,
        product_items: obj[key].map((i) => ({
          product_retailer_id: i,
        })),
      };
      response.push(object);
    }

    action.sections = response;
    let payload = {
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: customer.number,
      name:
        `${
          customerInfo?.first_name || customerInfo?.billing?.first_name || ""
        } ${
          customerInfo?.last_name || customerInfo?.billing?.last_name || ""
        }`?.trim() || customerName,
      type: "interactive",
      interactive: {
        type: "product_list",
        header: {
          type: "TEXT",
          text: "Kindly find the products as requested",
        },
        body: {
          // text: `Earrings and Ladies Rings Collection`,
          text: `${formatArrayStrings([...new Set(catagory)])} Collection`,
        },
        footer: {
          text: `powered by ${getWebsite().replace(/(^\w+:|^)\/\//, "")}`,
        },
        action: action,
      },
    };

    sendMessage(payload);
    setSelectProductOpen(false);
    // if (selectProductOpen === "quotation") {
    //   setQuotationForm((state) => ({
    //     ...state,
    //     products: products.map((i) => i.id).join(),
    //   }));
    //   setSelectProductOpen(false);
    //   setQuotaionDialog(true);
    // } else if (selectProductOpen === "select_product") {
    //   let i = 1;
    //   let string = "";
    //   for (let product of products) {
    //     let { name, permalink } = product;
    //     string += `  ${i}. ${name}: ${permalink}\n`;
    //     i++;
    //   }
    //   setMessage((state) => (state ? `\n${string}` : string));
    //   setSelectProductOpen(false);
    // } else {
    //   setCatalogForm((state) => ({
    //     ...state,
    //     products: products.map((i) => ({ label: i.name, value: i.id })),
    //   }));
    //   setSelectProductOpen(false);
    //   setCatalogDialog(true);
    // }
  };

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));

  // const changeNotesForm = (p) => {
  //   return setNoteForm((state) => ({ ...state, ...p }));
  // };

  // const onAddNote = async () => {
  //   setNoteLoading(true);
  //   const obj = {
  //     additional_custom_data: {
  //       notes: {
  //         request: "add",
  //         data: noteForm,
  //       },
  //     },
  //   };
  //   try {
  //     await updateCustomer(customer.id, obj);
  //     setShowNotesDialog(false);
  //     setToast({
  //       show: true,
  //       message: "Note added successfully!",
  //       severity: "success",
  //     });
  //   } catch (error) {
  //     console.log(getAxiosError(error));
  //     setToast({
  //       show: true,
  //       message: "Couldn't add Note! Please try again later",
  //       severity: "error",
  //     });
  //   }
  //   setNoteLoading(false);
  // };
  /////

  ////tast

  const [formOpen, setFormOpen] = useState(false);
  const [openBroadCast, setOpenBroadCast] = useState(false);
  // const openForm = () => setFormOpen(true);
  const closeForm = () => setFormOpen(false);
  ///
  const totalConversationsCount = useSelector(
    (state) => state.chats.totalConversationsCount
  );
  const reduxTemplates = useSelector((state) => state.chats.whatsappTempletes);

  const addCannedMessageRef = useRef();

  const openCannedPopover = (event) => {
    setAnchorElCanned(event.currentTarget);
  };

  const closeCannedPopover = () => {
    setAnchorElCanned(null);
  };

  const cannedOpen = Boolean(anchorElCanned);
  const id = cannedOpen ? "simple-popover" : undefined;
  // useEffect(() => {
  //   let conversation = customer.conversation || [];
  //   let lastObj = conversation.reverse().find((obj) => !obj.is_sender);
  //   setLastIntreact(lastObj?.time || "");
  // }, [customer]);

  // const reduxMetaIntegrations = useSelector(
  //   (state) => state.settings.metaIntegration
  // );

  useMemo(() => {
    if (!isArrayWithValues(reduxTemplates)) dispatch(getTempletes());
  }, []);
  useMemo(() => {
    if (isArrayWithValues(reduxTemplates))
      setAllWhatsAppTemplates(reduxTemplates);
  }, [reduxTemplates]);
  const ProductPopup = useMemo(
    () => (
      <>
        <ChatProducts
          open={isArrayWithValues(productsId)}
          productsId={productsId}
          onClose={() => setProductsId([])}
        />
      </>
    ),
    [productsId]
  );

  // useEffect(() => {
  //   if (!isObjWithValues(reduxMetaIntegrations)) dispatch(getMetaIntegration())
  // }, [])
  useMemo(() => {
    if (isObjWithValues(reduxMetaIntegrations)) {
      setMetaIntegration(reduxMetaIntegrations);
      let connected = [];

      Object.values(reduxMetaIntegrations).map((i) => {
        connected = connected.concat(i.connected);
      });

      setIntegratedSocials(connected);
    }
  }, [reduxMetaIntegrations]);
  // useEffect(() => {
  //   if (isObjWithValues(reduxMetaIntegrations)) {
  //     setMetaIntegration(reduxMetaIntegrations);
  //     let connected = [];

  //     Object.values(reduxMetaIntegrations).map((i) => {
  //       connected = connected.concat(i.connected);
  //     });

  //     setIntegratedSocials(connected);
  //   }
  // }, [reduxMetaIntegrations]);
  const globalToast = useSelector((state) => state.helper.globalToast);
  useMemo(() => {
    if (!isObjWithValues(reduxMetaIntegrations)) return;
    let apps = [];
    for (const key in metaIntegration) {
      let connectedApps = metaIntegration[key].connected || [];
      apps = [...apps, ...connectedApps];
      if (connectedApps.includes("commerce")) {
        let selected_id = metaIntegration[key].select_commerce_catalogs || "";
        setSelectedCatalog(selected_id);
      }
    }
    setIntegratedApps(apps);
  }, [metaIntegration]);

  const setTemplate = (obj) => {
    let variableObj = getTemplateVariableObjFromCustomer(
      customerInfo || customer
    );

    let template = { ...obj };

    // console.log(payload);
    let errorFound = false;
    if (isArrayWithValues(template.components)) {
      let components = [];
      for (let component of template.components) {
        if (isArrayWithValues(component.parameters)) {
          let parameters = [];
          for (let parameter of component.parameters) {
            let { text } = parameter || {};
            let variableText = text
              ? text?.includes("{") && text?.includes("}")
                ? variableObj?.[text] || " "
                : text
              : text;
            if (!variableText) variableText = " ";
            parameters.push({ ...parameter, text: variableText });
          }
          components.push({ ...component, parameters });
        }
      }
      if (isArrayWithValues(components)) template = { ...template, components };

      // payload.template.components = components;
    }
    console.log(template, "<<<<2");
    // if (isArrayWithValues(obj.components)) {
    //   let components = [];
    //   for (let component of obj.components) {
    //     if (isArrayWithValues(component.parameters)) {
    //       let parameters = [];
    //       for (let parameter of component.parameters) {
    //         let { text } = parameter;
    //         // if (!variableObj[text]) {
    //         //   errorFound = true;
    //         //   break;
    //         // }
    //         let variableText = text
    //           ? text.includes("{") && text.includes("}")
    //             ? variableObj[text]
    //             : ""
    //           : text;
    //         parameters.push({ ...parameter, text: variableText });
    //       }
    //       components.push({ ...component, parameters });
    //     }
    //   }
    //   if (isArrayWithValues(components)) obj = { ...obj, components };
    //   payload = {
    //     ...obj,
    //     template: { ...obj, components },
    //   };
    //   payload.template.components = components;
    // }
    // setOpenTempleteList(false);
    setTemplateObj(template);
  };
  const setImages = async (arr) => {
    setMediaLibraryOpen(false);
    if (isArrayWithValues(arr)) setMediaLibraryImage(arr);
    if (arr?.length < 2) return;
    for await (const mediaObj of arr) {
      let mediaType = mediaObj?.mime_type?.includes("video")
        ? "video"
        : mediaObj?.mime_type?.includes("application")
        ? "document"
        : "image";
      console.log(mediaType, mediaObj?.mime_type);
      console.log(mediaType);
      setSendingMessage(true);
      let payload = {};
      if (platform === "instagram") {
        if (messageInput) payload.message = { text: messageInput };
        payload.recipient = { id: customer.id };
        // if (messageInput) payload.message = { text: messageInput };
        if (isArrayWithValues(arr)) {
          payload.message = {
            // ...payload,
            attachment: {
              type: "image",
              payload: {
                url: mediaObj.source_url,
                // is_reusable: true,
              },
            },
          };
        }
      }
      if (platform === "facebook") {
        // playload.messaging_type = "text";
        if (messageInput) {
          payload.recipient = { id: customer.id };
          payload.message = { text: messageInput };
        }
        if (isArrayWithValues(arr)) {
          payload.recipient = { id: customer.id };

          payload.message = {
            attachment: {
              type: "image",
              payload: {
                url: mediaObj.source_url,
                is_reusable: true,
              },
            },
          };

          // payload.message = {
          //   attachment: {
          //     type: "image",
          //     payload: {
          //       url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png",
          //       is_reusable: true
          //     }
          //   }
          // }
        }

        // payload.message = {
        //   attachment: {
        //     type: "image",
        //     payload: {
        //       url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png",
        //       is_reusable: true
        //     }
        //   }
        // }
      }
      if (platform === "whatsapp") {
        if (messageInput)
          payload.message = {
            ...payload,
            text: {
              body: messageInput,
            },
          };
        payload = {
          messaging_product: "whatsapp",
          recipient_type: "individual",
          to: customer.number,
          type: "text",
          name: customerName,
          // text: {
          //   body: messageInput,
          // },
        };
        if (isObjWithValues(replyMessage)) {
          console.log(replyMessage);
          payload = {
            ...payload,
            context: {
              message_id: replyMessage?.obj?.message?.id,
            },
          };
        }
        if (isArrayWithValues(arr)) {
          // console.log(mediaLibraryImage?.mime_type?.includes("video"),);
          payload = {
            ...payload,
            [mediaType]:
              mediaType !== "document"
                ? {
                    link: mediaObj.source_url,
                    // filename: mediaObj?.title?.rendered,
                  }
                : {
                    link: mediaObj.source_url,
                    filename: mediaObj?.title?.rendered,
                  },
            type: mediaType,
          };

          delete payload.text;
          // if (mediaType === "document") {
          //   payload = {
          //     ...payload,
          //     filename: mediaObj?.title?.rendered
          //   };
          // }
        }
        if (isArrayWithValues(arr) && messageInput) {
          payload = {
            ...payload,
            type: mediaType,
            [mediaType]: {
              link: mediaObj.source_url,
              caption: messageInput,
            },
          };
          if (mediaType === "document") {
            payload[mediaType].filename = mediaObj?.title?.rendered;
          }
        }
        if (isObjWithValues(templateObj)) {
          payload = { ...payload, type: "template", template: templateObj };
          delete payload.text;
        }
      }
      console.log(payload);
      await sendMessage(payload);
    }
    console.log(arr);
  };
  let broadcastListStr = `${
    customer?.broadcastList
      ?.slice(0, 2)
      ?.map((obj) => obj?.label)
      ?.join(",") || ""
  }${
    customer?.broadcastList?.slice(2, customer?.broadcastList?.length)?.length
      ? " +" +
        customer?.broadcastList?.slice(2, customer?.broadcastList?.length)
          ?.length
      : ""
  }`;
  // useEffect(() => {
  //   if (isObjWithValues(templateObj)) sendMessage();
  // }, [templateObj]);
  const getWhatsappNo = (customer) => {
    if (!customer) return "";
    let { meta_data, billing } = customer;
    let obj = {
      phone: billing?.phone,
    };
    let metaKeys = ["whatsapp", "dialcode_whatsapp", "dialcode_mobile"];
    for (let metaObj of meta_data)
      if (metaKeys.includes(metaObj.key)) obj[metaObj.key] = metaObj.value;
    if (obj.whatsapp && obj.dialcode_whatsapp)
      return `${obj.dialcode_whatsapp}${obj.whatsapp}`;
    else if (obj.phone && obj.dialcode_mobile)
      return `${obj.dialcode_mobile}${obj.phone}`;
    return "";
  };
  const sendMessage = async (newpayload) => {
    const website = getWebsite();
    const token = getToken();
    if (!website || !token) return;
    let mediaType = mediaLibraryImage[0]?.mime_type?.includes("video")
      ? "video"
      : mediaLibraryImage[0]?.mime_type?.includes("application")
      ? "document"
      : "image";
    console.log(mediaType, mediaLibraryImage[0]?.mime_type);
    console.log(mediaType);
    setSendingMessage(true);
    let payload = {};
    if (platform === "instagram") {
      if (messageInput) payload.message = { text: messageInput };
      payload.recipient = { id: customer.id };
      // if (messageInput) payload.message = { text: messageInput };
      if (isArrayWithValues(mediaLibraryImage)) {
        console.log(
          mediaLibraryImage,
          mediaLibraryImage?.mime_type?.includes("video")
        );
        payload.message = {
          // ...payload,
          attachment: {
            type: "image",
            payload: {
              url: mediaLibraryImage[0].source_url,
              // is_reusable: true,
            },
          },
        };
      }
    }
    if (platform === "facebook") {
      // playload.messaging_type = "text";
      if (messageInput) {
        payload.recipient = { id: customer.id };
        payload.message = { text: messageInput };
      }
      if (isArrayWithValues(mediaLibraryImage)) {
        payload.recipient = { id: customer.id };

        payload.message = {
          attachment: {
            type: "image",
            payload: {
              url: mediaLibraryImage[0].source_url,
              is_reusable: true,
            },
          },
        };

        // payload.message = {
        //   attachment: {
        //     type: "image",
        //     payload: {
        //       url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png",
        //       is_reusable: true
        //     }
        //   }
        // }
      }

      // payload.message = {
      //   attachment: {
      //     type: "image",
      //     payload: {
      //       url: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/b6/Image_created_with_a_mobile_phone.png/640px-Image_created_with_a_mobile_phone.png",
      //       is_reusable: true
      //     }
      //   }
      // }
    }
    if (platform === "whatsapp") {
      payload = {
        messaging_product: "whatsapp",
        recipient_type: "individual",
        to: customer.number,
        name:
          `${
            customerInfo?.first_name || customerInfo?.billing?.first_name || ""
          } ${
            customerInfo?.last_name || customerInfo?.billing?.last_name || ""
          }`?.trim() || customerName,
        type: "text",
        text: {
          body: messageInput,
        },
      };
      if (isObjWithValues(replyMessage)) {
        console.log(replyMessage);
        payload = {
          ...payload,
          context: {
            message_id: replyMessage?.obj?.message?.id,
          },
        };
      }
      if (isArrayWithValues(mediaLibraryImage)) {
        // console.log(mediaLibraryImage?.mime_type?.includes("video"),);
        payload = {
          ...payload,
          [mediaType]:
            mediaType !== "document"
              ? {
                  link: mediaLibraryImage[0].source_url,
                  // filename: mediaLibraryImage[0]?.title?.rendered,
                }
              : {
                  link: mediaLibraryImage[0].source_url,
                  filename: mediaLibraryImage[0]?.title?.rendered,
                },
          type: mediaType,
        };

        delete payload.text;
        // if (mediaType === "document") {
        //   payload = {
        //     ...payload,
        //     filename: mediaLibraryImage[0]?.title?.rendered
        //   };
        // }
      }
      if (isArrayWithValues(mediaLibraryImage) && messageInput) {
        payload = {
          ...payload,
          type: mediaType,
          [mediaType]: {
            link: mediaLibraryImage[0].source_url,
            caption: messageInput,
          },
        };
        if (mediaType === "document") {
          payload[mediaType].filename = mediaLibraryImage[0]?.title?.rendered;
        }
      }
      if (isObjWithValues(templateObj)) {
        payload = { ...payload, type: "template", template: templateObj };
        delete payload.text;
      }
    }
    // if (!payload.to) payload.to = "918097534684";
    // return;
    let whatsappNo = getWhatsappNo(customerInfo);
    if (!whatsappNo && !payload.to && platform === "whatsapp") {
      setTemplateObj({});
      setSendingMessage(false);
      return dispatch(
        setGlobalToast({
          show: true,
          message: "No Whatsapp number found!",
          severity: "error",
        })
      );
    }
    let newWhatsappCustomer = false;
    if (!payload.to && platform === "whatsapp") {
      payload.to = whatsappNo;
      newWhatsappCustomer = true;
    }
    let data = {
      platform,
      payload,
    };

    if (isObjWithValues(newpayload)) {
      data = {
        platform: platform || "whatsapp",
        payload: newpayload,
      };
    }
    try {
      // if(getIsBackendMigrated("chats"))
      const res = await axios({
        url: `${website}/wp-json/store/v1/social/message`,
        headers: {
          Authorization: `Basic ${token}`,
        },
        method: "POST",
        data: {
          ...data,
        },
      });
      if (res.data.success) {
        if (newWhatsappCustomer) {
          console.log(chats);
          let res = await getConversationById({ platform }, payload.to);
          console.log(res, "<<< RES CHAT");
          if (isObjWithValues(customerInfo)) {
            let { meta_data, first_name, last_name, id } = customerInfo;

            let conversations = await addConversation({
              id: payload.to,
              name: `${first_name || ""} ${last_name || ""}`,
              number: payload.to,
              status_updated: Date.now(),
              customer_id: id,
            });
            dispatch(
              updateCrmIdinSetting(id, [
                {
                  platform: "whatsapp",
                  id: payload.to,
                },
              ])
            );
            console.log(conversations, "<<<< convesations");
            if (isArrayWithValues(meta_data)) {
              let whatsapp_id = meta_data.find((i) => i.key === "whatsapp_id");
              if (!whatsapp_id) {
                let updatedCustomer = await updateCustomer(id, {
                  meta_data: [
                    {
                      key: "whatsapp_id",
                      value: payload.to,
                    },
                  ],
                });
                console.log(updatedCustomer, "<<<<<< updatdCustomer");
                dispatch(updateCustomerInRedux(updatedCustomer));
              }
            }
          }
        }
        console.log(res.data.data, customer.id, { platform });

        dispatch(addResponseInChat(res.data.data, customer.id, { platform }));
      }
      if (isObjWithValues(res.data?.data?.error)) {
        dispatch(
          setGlobalToast({
            show: true,
            message:
              res.data?.data?.error?.error_data?.details ||
              "Unable to send message!",
            severity: "error",
          })
        );
      }

      console.log(res?.data);
    } catch (error) {
      console.log(error);
    }
    setSendingMessage(false);
    setReplyMessage(null);
    setMessageInput("");
    setMediaLibraryImage([]);
    setTemplateObj({});
    scrollToBottom();
  };

  useMemo(() => {
    if (isObjWithValues(templateObj)) sendMessage();
  }, [templateObj]);

  useMemo(() => {
    if (!isObjWithValues(customer)) return;
    if (
      customer?.conversation &&
      !customer?.conversation[customer?.conversation?.length - 1]?.is_read
    )
      dispatch(readMessage(customer));
  }, [customer]);

  //
  const TotalConversationsCount = useMemo(
    () => (
      <>
        <Stack direction="row" alignItems={"center"} spacing={2}>
          <North
            style={{
              height: "15px",
              width: "15px",
              fill: "blue",
            }}
          />

          {customer?.conversation?.filter((o) => o?.is_sender)?.length || 0}
          <South
            style={{
              height: "15px",
              width: "15px",
              fill: "green",
            }}
          />
          {customer?.conversation?.filter((o) => !o?.is_sender)?.length || 0}
        </Stack>
      </>
    ),
    [customer?.conversation]
  );

  // const facebookThumbup = async () => {
  //   const website = getWebsite();
  //   const token = getToken();
  //   if (!website || !token) return;
  //   try {
  //     const res = await axios({
  //       url: `${website}/wp-json/store/v1/social/message`,
  //       method: "POST",
  //       data: {
  //         platform: "facebook",
  //         payload: {
  //           recipient: { id: customer.id },
  //           message: {
  //             type: "like_heart",
  //           },
  //         },
  //         // platform: "whatsapp",
  //         // payload: {
  //         //   messaging_product: "whatsapp",
  //         //   recipient_type: "individual",
  //         //   to: customer.number,
  //         //   type: "text",
  //         //   text: {
  //         //     body: messageInput,
  //         //   },
  //         // },
  //       },
  //     });
  //     console.log(res.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const FilterClose = () => {
    setAnchorEl(null);
  };
  const FilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const filterOpen = Boolean(anchorEl);
  const [loadingFilter, setLoadingFilter] = useState("");

  const onFilterAssigning = async (val) => {
    const website = getWebsite();
    const token = getToken();
    if (!website || !token) return;
    const res = await axios({
      url: `${website}/wp-json/store/v1/social/conversation/${customer.id}`,
      headers: {
        Authorization: `Basic ${token}`,
      },
      method: "PUT",
      data: {
        platform: platform,
        payload: {
          status: val,
          status_updated: Date.now(),
        },
        // "is_read": true
      },
    });
    console.log(res.data);
    await dispatch(assignedFilter(customer, val));
    dispatch(setUpConversations(allReduxConversations));
    setLoadingFilter("");
    dispatch(
      setGlobalToast({
        ...globalToast,
        show: true,
        message: `Customer status ${val}`,
        severity: "success",
      })
    );
  };

  // useEffect(() => {
  //   if (customer.conversation[conversation.length - 1].time)
  //   setSessionEnded()
  // }, [customer])
  const globalPopupStyle =
    useSelector((state) => state.helper.globalPopupStyle) || {};

  const onCannedMessageSelect = (message) => {
    setMessageInput((state) => `${state} ${message}`);
  };

  const inputRef = useRef(null);

  const onClick = (arg) => {
    setMessageInput((pre) => `${pre} ${arg?.emoji}`);
  };
  const [anchorElEmoji, setAnchorElEmoji] = React.useState(null);

  const handleClick = (event) => {
    setAnchorElEmoji(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElEmoji(null);
  };

  const open = Boolean(anchorElEmoji);
  const idEmoji = open ? "simple-popover" : undefined;

  return (
    <>
      {/* <input
        type="file"
        id="file"
        onChange={(e) => onFileDrop(e.target.files[0])}
        ref={inputFile}
        style={{ display: "none" }}
      /> */}
      {/* <Snackbar
        open={toast.show}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        TransitionComponent={Slide}
        style={{ marginRight: "2vw" }}
        onClose={() => setToast({ ...toast, show: false })}
      >
        <Alert severity={toast.severity} sx={{ width: "100%" }}>
          {toast.message}
        </Alert>
      </Snackbar> */}
      {/* <FullScreen handle={fullscreenHandle} onChange={fullscreenChange}> */}
      <Box
        className="scrollbar-hidden"
        sx={{
          position: "relative",
          width: "100%",
          backgroundImage:
            platform === "whatsapp"
              ? !showChats
                ? "#fff"
                : "url(/static/img/whatsappBackground.png)"
              : "#fff",
        }}
      >
        {ProductPopup}

        {showHeader && (
          <Card sx={{ borderRadius: "0px", position: "sticky", top: 0 }}>
            <CardContent
              sx={{
                "&:last-child": { paddingBottom: "16px" },
                paddingLeft: isIpad ? 0 : "16px",
              }}
              style={
                isIpad
                  ? { padding: "6px", paddingLeft: isIpad ? 0 : "10px" }
                  : {}
              }
            >
              <Stack
                // direction={isIpad ? "column" : "row"}
                direction={"row"}
                flexWrap={"wrap"}
                sx={{ backgroundColor: "head", px: isIpad ? 0 : 2 }}
                alignItems="center"
                justifyContent={"space-between"}
              >
                <Stack
                  direction={"row"}
                  sx={{ backgroundColor: "head", px: 0 }}
                  alignItems="center"
                >
                  {isIpad ? (
                    <IconButton
                      onClick={() => {
                        navigate(`/chats`);
                        setCustomerView(false);
                      }}
                    >
                      <ArrowBack />
                    </IconButton>
                  ) : null}
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    badgeContent={
                      <Box
                        sx={{ backgroundColor: "#fff", borderRadius: "50%" }}
                      >
                        {platform === "facebook" ? (
                          <Messenger height={18} width={18} />
                        ) : platform === "instagram" ? (
                          <InstagramSvg height={20} width={20} />
                        ) : (
                          platform === "whatsapp" && (
                            <Whatsapp height={18} width={18} />
                          )
                        )}
                      </Box>
                    }
                  >
                    <Avatar
                      alt={customerName}
                      src={customerProfile}
                      sx={
                        isIpad
                          ? { width: 40, height: 40 }
                          : { width: 50, height: 50 }
                      }
                    />
                  </Badge>
                  <Box ml={4}>
                    <Stack>
                      <Stack direction={"row"} alignItems="center" gap={"10px"}>
                        <Typography variant="h6" fontSize={"15px"}>
                          {getNameFromPlatform(customer) || ""}
                        </Typography>
                        {customer?.contact_type ? (
                          <Chip
                            size="small"
                            label={formatServerValue(customer?.contact_type)}
                          />
                        ) : null}
                        {customer?.teamMember ? (
                          <Tooltip title={customer?.teamMember || ""}>
                            <HeadsetMic
                              style={{
                                // position: "absolute",
                                // top: "5px",
                                // right: favorite ? "30px" : "5px",
                                fontSize: "13px",
                                bottom: "5px",
                                // right: "5px",
                                color: "#808080",
                              }}
                              fontSize="small"
                            />
                          </Tooltip>
                        ) : null}
                        {customer?.broadcastList ? (
                          <Tooltip title={broadcastListStr}>
                            <PeopleAltOutlined
                              style={{
                                // position: "absolute",
                                // top: "5px",
                                // right: favorite ? "30px" : "5px",
                                fontSize: "13px",
                                bottom: "5px",
                                // right: "5px",
                                color: "#808080",
                              }}
                              fontSize="small"
                            />
                          </Tooltip>
                        ) : null}
                      </Stack>

                      <Typography variant="h6" fontSize={"15px"}>
                        {customer?.names?.map((name, index) => (
                          <span>
                            {name}{" "}
                            {name && index !== customer?.names?.length - 1
                              ? ","
                              : ""}
                          </span>
                        )) || ""}
                      </Typography>
                      {lastIntreact ? (
                        <Typography>{`${
                          getRelativeTimeDifference(new Date(lastIntreact))
                            .value
                        } ${
                          getRelativeTimeDifference(new Date(lastIntreact))
                            .label
                        } ago`}</Typography>
                      ) : null}

                      {/* {customerLabel ? (
                      <Chip
                        icon={
                          <LocalOffer
                            style={{
                              height: "10px",
                              width: "10px",
                              // color: labels.find(
                              //   (i) => i.value === customerLabel
                              // ).color,
                            }}
                          />
                        }
                        onClick={() => setCustomerLabelId(customer.id)}
                        sx={{
                          ml: 3,
                          fontSize: "11px",
                          // color: labels.find((i) => i.value === customerLabel)
                          //   .color,
                        }}
                        // variant="out"
                        label={customerLabel.label}
                        size="small"
                      />
                    ) : (
                      customer.id && (
                        <IconButton
                          size="small"
                          style={{ height: 20, width: 20, marginLeft: "6px" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setCustomerLabelId(customer.id);
                          }}
                        >
                          <AddCircleOutline />
                        </IconButton>
                      )
                    )} */}
                      {/* <Stack direction={"row"} alignItems="center" ml={1}>
                      <LocalOffer style={{ height: "10px", width: "10px" }} />
                      <Typography variant="body2" fontSize={"12px"} ml={1}>
                        {customerLabel}
                      </Typography>
                    </Stack> */}
                    </Stack>
                    {TotalConversationsCount}
                    {/* <TotalConversationsCount /> */}

                    {/* {totalConversationsCount?.[customer.id] ? (
                      <Stack direction="row" alignItems={"center"} spacing={2}>
                        <North
                          style={{
                            height: "15px",
                            width: "15px",
                            fill: "blue",
                          }}
                        />
                        {totalConversationsCount?.[customer.id]?.sent}
                        <South
                          style={{
                            height: "15px",
                            width: "15px",
                            fill: "green",
                          }}
                        />
                        {totalConversationsCount?.[customer.id]?.received}
                       
                      </Stack>
                    ) : null} */}
                  </Box>
                </Stack>
                {/* <Stack direction={"row"} alignItems="center"> */}

                {/* </Stack> */}
                <Stack
                  direction={isIpad ? "row-reverse" : "row"}
                  alignItems="center"
                  justifyContent={"space-between"}
                  sx={
                    isIpad
                      ? {
                          width: "100%",
                          mt: 2,
                        }
                      : {}
                  }
                >
                  {isIpad ? (
                    <Stack direction={"row"} alignItems="center" gap={2}>
                      <IconButton
                        onClick={() =>
                          props.setShowProfile &&
                          props.setShowProfile((state) => !state)
                        }
                        sx={{
                          border: `1px solid ${
                            filterColorObj[customer?.status] || "transparent"
                          }`,
                        }}
                      >
                        <Person />{" "}
                      </IconButton>

                      <Button
                        onClick={() => setShowChats((state) => !state)}
                        sx={{ mx: 2, whiteSpace: "nowrap" }}
                        variant="outlined"
                        size="small"
                      >
                        Show{" "}
                        {showChats
                          ? `activity${
                              userIncompleteTasks?.length
                                ? ` (${userIncompleteTasks?.length})`
                                : ""
                            }`
                          : "chats"}
                      </Button>

                      <IconButton
                        onClick={FilterClick}
                        size="small"
                        sx={{}}
                        aria-controls={filterOpen ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={filterOpen ? "true" : undefined}
                      >
                        <MoreVert />
                      </IconButton>
                    </Stack>
                  ) : null}
                  {!isArrayWithValues(customer?.ids) && (
                    <Stack direction={"row"} alignItems="center">
                      {customer?.status && false ? (
                        <Tooltip
                          title={formatServerValue(customer?.status || "")}
                        >
                          <Box>{getStatusIcon(customer?.status)}</Box>
                        </Tooltip>
                      ) : null}
                      {Object.values(customer?.profiles || {})?.length > 1 && (
                        <Box sx={{ backgroundColor: "Background.paper" }}>
                          <Stack
                            direction="row"
                            sx={{ backgroundColor: "#fff", gap: "5px", mr: 2 }}
                          >
                            {channels.map((b) => {
                              if (!customer?.profiles?.[b.value]) return;
                              return (
                                <IconButton
                                  // variant={"text"}
                                  // startIcon={b.icon}
                                  sx={{
                                    border: `2px solid `,
                                    borderColor:
                                      selectedTab === b.value
                                        ? "primary.main"
                                        : "transparent",
                                    // padding: "6px 20px",

                                    // backgroundColor:
                                    //   selectedTab === b.value ? "#e6e6e6" : "transparent",
                                  }}
                                  onClick={() => {
                                    customer?.profiles?.[b.value] &&
                                      navigate(
                                        `/chats/${
                                          customer?.profiles?.[b.value]
                                        }`
                                      );
                                    setSelectedTab(b.value);
                                  }}
                                >
                                  {b.icon}
                                  {/* <Typography>{b.label}</Typography> */}
                                </IconButton>
                              );
                            })}
                          </Stack>
                        </Box>
                      )}
                      {!isIpad && (
                        <Box>
                          <IconButton
                            onClick={() =>
                              props.setShowProfile &&
                              props.setShowProfile((state) => !state)
                            }
                            sx={{
                              border: `1px solid ${
                                filterColorObj[customer?.status] ||
                                "transparent"
                              }`,
                            }}
                          >
                            <Person />{" "}
                          </IconButton>
                          <Button
                            onClick={() => setShowChats((state) => !state)}
                            sx={{ mx: 2 }}
                            variant="outlined"
                            size="small"
                          >
                            Show{" "}
                            {showChats
                              ? ` activity${
                                  userIncompleteTasks?.length
                                    ? ` (${userIncompleteTasks?.length})`
                                    : ""
                                }`
                              : "chats"}
                          </Button>
                          <IconButton
                            onClick={FilterClick}
                            size="small"
                            sx={{}}
                            aria-controls={
                              filterOpen ? "account-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={filterOpen ? "true" : undefined}
                          >
                            <MoreVert />
                          </IconButton>
                        </Box>
                      )}
                    </Stack>
                  )}
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={filterOpen}
                    onClose={FilterClose}
                    onClick={FilterClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 2px rgba(0,0,0,0.32)) ",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem
                      onClick={() => {
                        dispatch(
                          updateChatObject(
                            customer,
                            {
                              payload: {
                                contact_type: "customer",
                              },
                            },
                            (val) => {
                              // console.log(val);
                              dispatch(
                                setGlobalToast({
                                  ...globalToast,
                                  show: val,
                                  message: `Marking as Customer...`,
                                  severity: "success",
                                })
                              );
                            }
                          )
                        );
                      }}
                      // onClick={() => onFilterChange(i.value)}
                    >
                      <ListItemIcon>
                        <PersonOutlineOutlined />
                      </ListItemIcon>
                      {/* {i.icon}  */}
                      Mark As Customer
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        dispatch(
                          updateChatObject(
                            customer,
                            {
                              payload: {
                                contact_type: "lead",
                              },
                            },
                            (val) => {
                              // console.log(val);
                              dispatch(
                                setGlobalToast({
                                  ...globalToast,
                                  show: val,
                                  message: `Marking as Lead...`,
                                  severity: "success",
                                })
                              );
                            }
                          )
                        );
                      }}
                      // onClick={() => onFilterChange(i.value)}
                    >
                      <ListItemIcon style={{ opacity: "0.7" }}>
                        <LeadIcon />
                      </ListItemIcon>
                      {/* {i.icon}  */}
                      Mark As Lead
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setOpenBroadCast(true);
                      }}
                      // onClick={() => {
                      //   dispatch(
                      //     updateChatObject(customer, {
                      //       payload: {
                      //         contact_type: "lead",
                      //       },
                      //     }, (val) => {
                      //       // console.log(val);
                      //       dispatch(
                      //         setGlobalToast({
                      //           ...globalToast,
                      //           show: val,
                      //           message: `Marking as Lead...`,
                      //           severity: "success",
                      //         })
                      //       );
                      //     })
                      //   );
                      // }}
                      // onClick={() => onFilterChange(i.value)}
                    >
                      <ListItemIcon style={{ opacity: "0.7" }}>
                        <People />
                      </ListItemIcon>
                      {/* {i.icon}  */}
                      Add to Broadcast
                    </MenuItem>
                    {CRMfilters.map((i) => {
                      return (
                        <MenuItem
                          onClick={() =>
                            onFilterAssigning(
                              customer.status === i.value ? "" : i.value
                            )
                          }
                          sx={{
                            minHeight: "auto",
                            color:
                              customer.status === i.value
                                ? "danger.main"
                                : "text.primary",
                          }}
                        >
                          <ListItemIcon>{i.icon}</ListItemIcon>
                          {customer.status === i.value
                            ? i.removeLabel
                            : i.label}
                        </MenuItem>
                      );
                    })}
                    <MenuItem
                      onClick={() => {
                        setToggleDeleteDialog(true);
                      }}
                    >
                      <ListItemIcon>
                        <DeleteOutlineOutlined />
                      </ListItemIcon>
                      Delete
                    </MenuItem>
                  </Menu>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
        )}
        <Divider />
        <Dialog
          open={openBroadCast}
          onClose={() => {
            setOpenBroadCast(false);
          }}
          sx={{ ...dialogStyle }}
        >
          <BroadcastList
            selectedCustomers={[customer]}
            handleClose={() => {
              setOpenBroadCast(false);
            }}
            hideView
            hideDelete
          />
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setOpenBroadCast(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {!showChats ? (
          <UserTasks customer={customer} />
        ) : (
          <Box sx={{}}>
            {imageToUpload ? (
              <ImagePreview
                src={imageToUpload}
                caption={caption}
                setCaption={setCaption}
                // onImageSend={onImageSend}
                setImageToUpload={setImageToUpload}
                setRowImageToUpload={setRowImageToUpload}
              />
            ) : null}
            {/* <div
              ref={chatsParent}
              style={{
                width: "100%",
                overflow: "scroll",
                height: isIpad
                  ? showHeader
                    ? "calc(100vh - 180px)"
                    : "calc(100vh - 276px)"
                  : showHeader
                    ? "calc(100vh - 332px)"
                    : "calc(100vh - 332px)",
              }}
              className="scrollbar-hidden"
            >
              {customerLoading && !isArrayWithValues(chats) && (
                <Box sx={{ mt: 5 }} className="scrollbar-hidden">
                  <Stack direction="row" justifyContent={"center"}>
                    <Chip
                      icon={
                        <CircularProgress
                          style={{ height: "16px", width: "16px" }}
                        />
                      }
                      label="Loading"
                      variant="outlined"
                    />
                  </Stack>
                </Box>
              )} */}
            {isLoading ? (
              <Box
                sx={{
                  height: isIpad
                    ? showHeader
                      ? "calc(100vh - 180px)"
                      : "calc(100vh - 276px)"
                    : showHeader
                    ? "calc(100vh - 332px)"
                    : "calc(100vh - 332px)",
                }}
              >
                <Loader />
              </Box>
            ) : (
              <MappingChats
                chatsParent={chatsParent}
                customerLoading={customerLoading}
                chats={chats}
                platform={platform}
                allWhatsappTemplates={allWhatsappTemplates}
                setProductsId={setProductsId}
                setReplyMessage={setReplyMessage}
                setFormOpen={setFormOpen}
                setMessageImageView={setMessageImageView}
                showHeader={showHeader}
                isIpad={isIpad}
              />
            )}

            {/* </div> */}

            <ChatInput
              isMobile={isMobile}
              container
              alignItems="center"
              sx={{
                color: "text.secondary",
                position: "relative",
                padding: "10px",
                // position: "absolute"
                position: "sticky",
                bottom: "0px",
                // flexDirection: isObjWithValues(replyMessage) ? "column" : "row"
              }}
            >
              {isObjWithValues(replyMessage) && isIpad && (
                <Stack
                  direction={{ lg: "row", md: "column" }}
                  justifyContent="space-between"
                  alignItems={"center"}
                  flexWrap={"wrap"}
                  sx={{
                    padding: "0px 20px",
                    backgroundColor: "#f1f1f1",
                    borderRadius: "10px",
                    width: "inherit",
                    mb: 2,
                  }}
                >
                  <Stack
                    direction={"row"}
                    alignItems="center"
                    sx={{ gap: "10px", width: "-webkit-fill-available" }}
                    justifyContent="space-between"
                  >
                    <Stack>
                      {replyMessage.message && (
                        <Typography>{replyMessage.message}</Typography>
                      )}
                      {replyMessage.header && (
                        <Typography sx={{ fontWeight: "700" }}>
                          {replyMessage.header}
                        </Typography>
                      )}
                      {replyMessage.body && (
                        <Typography>{replyMessage.body}</Typography>
                      )}
                      {replyMessage.image && (
                        <Typography
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          {" "}
                          <CameraAlt
                            style={{
                              height: "15px",
                              width: "15px",
                              color: "808080",
                            }}
                          />{" "}
                          Photo
                        </Typography>
                      )}
                      {replyMessage?.document || replyMessage?.audio ? (
                        <Typography
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                          {" "}
                          <CameraAlt
                            style={{
                              height: "15px",
                              width: "15px",
                              color: "808080",
                            }}
                          />{" "}
                          Media
                        </Typography>
                      ) : null}
                    </Stack>
                    <Stack>
                      {replyMessage.image && (
                        <img
                          alt="Reply"
                          src={replyMessage.image}
                          style={{
                            height: "70px",
                            width: "70px",
                            borderRadius: "4px",
                          }}
                        />
                      )}
                      {replyMessage?.document ||
                        (replyMessage?.audio && (
                          <Stack
                            justifyContent={"center"}
                            alignItems="center"
                            sx={{
                              backgroundColor: "#ccc",
                              height: "30px",
                              width: "30px",
                            }}
                          >
                            <PermMedia />
                          </Stack>
                        ))}
                    </Stack>
                  </Stack>
                  <IconButton
                    onClick={() => {
                      setReplyMessage({});
                    }}
                  >
                    <Close />
                  </IconButton>
                </Stack>
              )}
              {integratedApps.includes(platform) || true ? (
                <>
                  {isArrayWithValues(mediaLibraryImage) && (
                    <Stack
                      direction={"row"}
                      justifyContent="space-between"
                      alignItems={"center"}
                      sx={{
                        padding: "0px 20px",
                        backgroundColor: "#f1f1f1",
                        borderRadius: "10px",
                        mb: "8px",
                        width: "100%",
                      }}
                    >
                      <Stack
                        direction={"row"}
                        alignItems="center"
                        sx={{ gap: "10px" }}
                      >
                        {mediaLibraryImage?.map((o) =>
                          o?.mime_type?.includes("image") ? (
                            <img
                              src={o?.source_url || ""}
                              style={{ height: "30px", width: "30px" }}
                            />
                          ) : (
                            <Stack
                              justifyContent={"center"}
                              alignItems="center"
                              sx={{
                                backgroundColor: "#ccc",
                                height: "30px",
                                width: "30px",
                              }}
                            >
                              <PermMedia />
                            </Stack>
                          )
                        )}
                        {/* {mediaLibraryImage[0]?.mime_type?.includes("image") ? (
                          <img
                            src={mediaLibraryImage[0]?.source_url || ""}
                            style={{ height: "30px", width: "30px" }}
                          />
                        ) : null} */}
                        {/* {!mediaLibraryImage[0]?.mime_type?.includes("image") ? (
                          <Stack
                            justifyContent={"center"}
                            alignItems="center"
                            sx={{
                              backgroundColor: "#ccc",
                              height: "30px",
                              width: "30px",
                            }}
                          >
                            <PermMedia />
                          </Stack>
                        ) : null} */}

                        <Typography>
                          {mediaLibraryImage[0]?.title?.rendered || ""}
                        </Typography>
                      </Stack>
                      <IconButton
                        onClick={() => {
                          setMediaLibraryImage([]);
                        }}
                      >
                        <Close />
                      </IconButton>
                    </Stack>
                  )}
                  <Stack
                    sx={{
                      borderRadius: "10px",
                      border: "1px solid #808080",
                      borderColor: "grey.500",
                      width: "100%",
                      padding: "3px",
                    }}
                    // direction={{ lg: "row", md: "column" }}
                    direction={
                      isObjWithValues(replyMessage) || isIpad ? "column" : "row"
                    }
                  >
                    {" "}
                    {isObjWithValues(replyMessage) && !isIpad && (
                      <Stack
                        direction={"row"}
                        justifyContent="space-between"
                        alignItems={"center"}
                        sx={{
                          padding: "0px 20px",
                          backgroundColor: "#f1f1f1",
                          borderRadius: "10px",
                        }}
                      >
                        <Stack
                          direction={"row"}
                          alignItems="center"
                          sx={{ gap: "10px", width: "-webkit-fill-available" }}
                          justifyContent="space-between"
                        >
                          <Stack>
                            {replyMessage.message && (
                              <Typography>{replyMessage.message}</Typography>
                            )}
                            {replyMessage.header && (
                              <Typography sx={{ fontWeight: "700" }}>
                                {replyMessage.header}
                              </Typography>
                            )}
                            {replyMessage.body && (
                              <Typography>{replyMessage.body}</Typography>
                            )}
                            {replyMessage.image && (
                              <Typography
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                {" "}
                                <CameraAlt
                                  style={{
                                    height: "15px",
                                    width: "15px",
                                    color: "808080",
                                  }}
                                />{" "}
                                Photo
                              </Typography>
                            )}
                          </Stack>
                          <Stack>
                            {replyMessage.image && (
                              <img
                                src={replyMessage.image}
                                style={{
                                  height: "70px",
                                  width: "70px",
                                  borderRadius: "4px",
                                }}
                              />
                            )}
                          </Stack>
                        </Stack>
                        <IconButton
                          onClick={() => {
                            setReplyMessage({});
                          }}
                        >
                          <Close />
                        </IconButton>
                      </Stack>
                    )}
                    <TextField
                      ref={inputRef}
                      onKeyDown={(e) => {
                        // console.log(
                        //   e.key === "Enter",
                        //   e.metaKey,
                        //   e.ctrlKey,
                        //   messageInput?.trim()
                        // );
                        if (
                          e.key === "Enter" &&
                          (e.ctrlKey || e.metaKey) &&
                          messageInput?.trim()
                        )
                          setMessageInput((pre) => `${pre}\n`);
                        else if (
                          e.key === "Enter" &&
                          // (e.ctrlKey || e.metaKey) &&
                          messageInput?.trim()
                        )
                          sendMessage();
                      }}
                      // onKeyDown={(e) => {
                      //   console.log(
                      //     e.key === "Enter",
                      //     e.metaKey,
                      //     e.ctrlKey,
                      //     messageInput?.trim()
                      //   );
                      //   if (
                      //     e.key === "Enter" &&
                      //     (e.ctrlKey || e.metaKey) &&
                      //     messageInput?.trim()
                      //   )
                      //     sendMessage();
                      // }}
                      disabled={
                        notOptedIn ||
                        isWindowClosed ||
                        !integratedSocials.includes(platform) ||
                        customer?.ids
                      }
                      onPaste={(event) => {
                        event.preventDefault();
                        const pastedText = event.clipboardData.getData("text");
                        const modifiedText = pastedText.replace(
                          /\r?\n/g,
                          "\r\n"
                        ); // convert all line endings to \r\n
                        const selectionStart = event.target.selectionStart;
                        const selectionEnd = event.target.selectionEnd;
                        const newValue =
                          messageInput?.substring(0, selectionStart) +
                          modifiedText +
                          messageInput?.substring(selectionEnd);
                        setMessageInput(newValue);
                        // const pastedText = event.clipboardData.getData("text");
                        // const modifiedText = pastedText.replace(/\r?\n/g, "\r\n"); // convert all line endings to \r\n
                        // console.log(modifiedText);
                        // setMessageInput(modifiedText);
                      }}
                      placeholder={"Type your message here..."}
                      autoFocus
                      fullWidth
                      multiline={!isIpad}
                      rows={isIpad ? 1 : 2}
                      maxRows={isIpad ? 1 : 2}
                      sx={{
                        cursor: "none",
                        ".MuiInputBase-input": isIpad
                          ? { padding: "10px" }
                          : {},
                        ".MuiOutlinedInput-notchedOutline": { border: "none" },
                        // color:
                        //   !isWindowClosed && !notOptedIn
                        //     ? "text.primary"
                        //     : "danger.main",
                      }}
                      // helperText=""
                      // FormHelperTextProps={{ sx: { textAlign: "right" } }}
                      onChange={(e) => setMessageInput(e.target.value)}
                      value={
                        customer?.ids
                          ? "You can only mointor chats in merged view"
                          : notOptedIn
                          ? "Customer has not yet opted-in to receive your whatsapp message from you"
                          : isWindowClosed
                          ? `As per ${platform} rules, you can only respond to a customer within 24 hours of their message`
                          : !integratedSocials.includes(platform)
                          ? `${platform || ""} is not integrated`
                          : messageInput
                      }
                    />
                    {!isArrayWithValues(customer?.ids) && (
                      <Stack
                        direction={"row"}
                        justifyContent="end"
                        gap={isIpad ? "0" : "10px"}
                        alignItems="flex-end"
                        sx={
                          isIpad
                            ? {}
                            : {
                                paddingRight: "7px",
                                gap: "10px",
                                paddingBottom: "7px",
                              }
                        }
                      >
                        {/* {platform === "whatsapp" && <IconButton onClick={() => {
                    inputFile.current.click();
                  }}>
                    <UploadFile />
                  </IconButton>} */}
                        {
                          // selectedCatalog &&
                          !isWindowClosed &&
                          !notOptedIn &&
                          platform === "whatsapp" ? (
                            <Tooltip title="Catalog">
                              <IconButton
                                onClick={() => setSelectProductOpen(true)}
                              >
                                <Inventory />
                              </IconButton>
                            </Tooltip>
                          ) : null
                        }
                        {platform === "whatsapp" && (
                          <Tooltip title="Whatsapp Template">
                            <IconButton>
                              <InsertDriveFileOutlined
                                style={{ height: "20px", width: "20px" }}
                                onClick={() => setOpenTempleteList(true)}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        {!isWindowClosed && !notOptedIn && (
                          <Tooltip title={"Attachment"}>
                            <IconButton
                              onClick={() => setMediaLibraryOpen(true)}
                            >
                              <Attachment
                                size="small"
                                style={{ height: "20px", width: "20px" }}
                                onClick={() => setopenCreateTemplete(true)}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        {/* {platform === "facebook" && false && (
                        <IconButton onClick={facebookThumbup}>
                          <ThumbUp
                            style={{
                              height: "20px",
                              width: "20px",
                              color: "#808080",
                            }}
                          />
                        </IconButton>
                      )} */}
                        {/* {platform === "instagram" && (
                        <IconButton>
                          {" "}
                          <Favorite
                            style={{
                              height: "20px",
                              width: "20px",
                              color: "#808080",
                            }}
                          />
                        </IconButton>
                      )} */}
                        {!isWindowClosed && !notOptedIn && (
                          <Tooltip title={"Quick reply"}>
                            <IconButton
                              onClick={
                                openCannedPopover
                                // addCannedMessageRef.current?.openAddCannedMessages()
                              }
                            >
                              <PostAdd
                                style={{
                                  height: "20px",
                                  width: "20px",
                                  color: "#808080",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}

                        <IconButton
                          aria-describedby={idEmoji}
                          onClick={handleClick}
                        >
                          <SentimentSatisfiedOutlined
                            color={"action"}
                            style={{
                              height: "20px",
                              width: "20px",
                              color: "#808080",
                            }}
                          />
                        </IconButton>

                        {!!messageInput ||
                        isArrayWithValues(mediaLibraryImage) ||
                        isObjWithValues(templateObj) ? (
                          <LoadingButton
                            variant={isIpad ? "text" : "outlined"}
                            endIcon={<SendIcon />}
                            loading={sendingMessage}
                            size="small"
                            onClick={() => sendMessage()}
                            sx={
                              isIpad
                                ? { minWidth: "auto" }
                                : { padding: "5px 5px" }
                            }
                          >
                            {isIpad ? "" : "Send"}
                          </LoadingButton>
                        ) : null}
                      </Stack>
                    )}
                  </Stack>
                  <Popover
                    id={idEmoji}
                    open={open}
                    anchorEl={anchorElEmoji}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    {/* <EmojiPicker
                      onEmojiClick={onClick}
                      autoFocusSearch={false}
                      // theme={Theme.AUTO}
                      // searchDisabled
                      // skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
                      // height={350}
                      // width="50%"
                      // emojiVersion="0.6"
                      // lazyLoadEmojis={true}
                      // previewConfig={{
                      //   defaultCaption: "Pick one!",
                      //   defaultEmoji: "1f92a" // 🤪
                      // }}
                      // suggestedEmojisMode={SuggestionMode.RECENT}
                      // skinTonesDisabled
                      // searchPlaceHolder="Filter"
                      // defaultSkinTone={SkinTones.MEDIUM}
                      // emojiStyle={EmojiStyle.NATIVE}
                      // categories={[
                      //   {
                      //     name: "Fun and Games",
                      //     category: Categories.ACTIVITIES
                      //   },
                      //   {
                      //     name: "Smiles & Emotions",
                      //     category: Categories.SMILEYS_PEOPLE
                      //   },
                      //   {
                      //     name: "Flags",
                      //     category: Categories.FLAGS
                      //   },
                      //   {
                      //     name: "Yum Yum",
                      //     category: Categories.FOOD_DRINK
                      //   }
                      // ]}
                    /> */}
                  </Popover>
                  <Typography
                    sx={{
                      marginLeft: "auto",
                      textAlign: "right",
                      ...secondaryLabelSmall,
                      fontSize: "10px",
                      mr: 1,
                      mt: 0.5,
                      visibility: messageInput?.trim() ? "visible" : "hidden",
                    }}
                  >
                    Enter to Send message
                  </Typography>
                </>
              ) : (
                <>
                  {sessionEnded ? (
                    <Grid item>
                      <Alert
                        variant="outlined"
                        severity="info"
                        sx={{
                          p: 0,
                          borderWidth: 0,
                          fontSize: isMobile ? "12px" : "15px",
                          alignItems: "center",
                        }}
                      >
                        As per WhatsApp rules, you can only respond to a
                        customer within 24 hours of their message.
                      </Alert>
                    </Grid>
                  ) : (
                    <>
                      {" "}
                      {isObjWithValues(customer) && (
                        <Grid item>
                          <Alert
                            variant="outlined"
                            severity="info"
                            sx={{
                              p: 0,
                              borderWidth: 0,
                              fontSize: isMobile ? "12px" : "15px",
                              alignItems: "center",
                            }}
                          >
                            {`${platform} is not integrated`}
                          </Alert>
                        </Grid>
                      )}
                    </>
                  )}
                </>
              )}

              <TempleteList
                open={openTempleteList}
                handleClose={() => setOpenTempleteList(false)}
                setParentTemplate={setTemplate}
              />
              <ProductMediaLibrary
                open={mediaLibraryOpen}
                onChange={() => {
                  setMediaLibraryOpen(false);
                }}
                setParentImages={setImages}
                multiple={true}
                enable_crop={false}
                hideUploadInfo={true}
              />
              <AddCannedMessages ref={addCannedMessageRef} />
              <Popover
                id={id}
                open={cannedOpen}
                anchorEl={anchorElCanned}
                onClose={closeCannedPopover}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                // sx={{ width: "270px" }}
              >
                <CannedMessages
                  onCreate={() =>
                    addCannedMessageRef.current?.openAddCannedMessages()
                  }
                  onClose={closeCannedPopover}
                  onSelect={onCannedMessageSelect}
                  allCannedMessages={allCannedMessages}
                />
              </Popover>
            </ChatInput>
          </Box>
        )}
      </Box>

      {/* <Dialog
        open={showNotesDialog}
        onClose={() => setShowNotesDialog(false)}
        maxWidth="sm"
        fullWidth
        sx={dialogStyle}
      >
        <DialogTitle>Add Note ({customerName})</DialogTitle>
        <DialogContent>
          <Typography variant="h6">Title</Typography>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Title"
            type="email"
            fullWidth
            variant="standard"
            value={noteForm.title}
            onChange={(e) => changeNotesForm({ title: e.target.value })}
          />
          <Typography variant="h6">Description</Typography>
          <TextField
            margin="dense"
            id="name"
            label="Description"
            type="email"
            fullWidth
            variant="standard"
            multiline
            rows={3}
            value={noteForm.note}
            onChange={(e) => changeNotesForm({ note: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowNotesDialog(false)}>Cancel</Button>
          <LoadingButton loading={noteLoading} onClick={onAddNote}>
            Add Note
          </LoadingButton>
        </DialogActions>
      </Dialog> */}

      <Dialog
        maxWidth="sm"
        fullWidth
        open={Boolean(selectProductOpen)}
        onClose={() => setSelectProductOpen(false)}
      >
        <SelectProducts
          facebookProduct={true}
          onClose={() => setSelectProductOpen(false)}
          onSelect={onProductsSelected}
          extraParams={{
            "custom_filter[0][key]": "_wc_facebook_sync_enabled",
            "custom_filter[0][value]": "yes",
          }}
        />
      </Dialog>
      {/* </FullScreen> */}
      <Dialog
        maxWidth="xs"
        fullWidth
        open={customerLabelId}
        onClose={() => setCustomerLabelId(false)}
        {...globalPopupStyle}
      >
        <CustomerLabelView
          id={customerLabelId}
          onClose={() => setCustomerLabelId(false)}
          setCustomer={props.setCrmViewCustomer}
        />
      </Dialog>
      <Dialog
        open={messageImageView.show}
        onClose={() =>
          setMessageImageView((pre) => ({ ...pre, show: false, type: "" }))
        }
        fullWidth
        maxWidth="sm"
        sx={dialogStyle}
      >
        {/* <DialogTitle>
          <IconButton>
            <a download={messageImageView.url}>
              <Download />
            </a>
          </IconButton>
        </DialogTitle> */}
        <DialogContent
          sx={{
            padding: "8px",
            "&:last-child": { paddingBottom: "3px" },
            height: "100%",
          }}
        >
          {!messageImageView.type && (
            // <StoryReply url={messageImageView.url} style={{
            //   width: "100%",
            //   maxHeight: "80vh",
            //   padding: 0,
            //   margin: 0,
            //   objectFit: "contain",
            // }} />
            <img
              src={messageImageView.url}
              // src="https://i.natgeofe.com/n/c0e0a134-3e97-4b8f-9f7b-9d11f5e1bf02/comedy-wildlife-awards-squirel-stop.jpg?w=636&h=475"
              style={{
                width: "100%",
                maxHeight: "80vh",
                padding: 0,
                margin: 0,
                objectFit: "contain",
              }}
            />
          )}
          {messageImageView.type && (
            <video
              style={{
                width: "100%",
                maxHeight: "80vh",
                padding: 0,
                margin: 0,
                objectFit: "contain",
                borderRadius: "15px",
              }}
              controls
            >
              <source src={messageImageView.url} type="video/mp4" />
              {/* <source src="movie.ogg" type="video/ogg" /> */}
              Your browser does not support the video tag.
            </video>
          )}
        </DialogContent>
      </Dialog>
      {/* <Dialog
        onClose={() => toggleProductDialog(false)}
        open={showProductDialog}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="sm"
        sx={{
          minHeight: "60vh",
          padding: "",
        }}
        fullWidth
      >
        <DialogTitle id="scroll-dialog-title">Select products</DialogTitle>
        <DialogContent dividers={"paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            sx={{
              minHeight: "60vh",
              padding: "",
            }}
            tabIndex={-1}
          >
            <TextField
              value={productSearch}
              onChange={(e) => {
                setProductSearch(e.target.value);
                if (e.target.value.length > 2)
                  return fetchProducts(
                    productSearch,
                    setProductsSearched,
                    setFetchingProducts
                  );
              }}
              sx={{ mb: 2 }}
              variant="outlined"
              fullWidth
              size="small"
              type="search"
              placeholder="Search products"
              // onSubmit={() =>
              //   fetchProducts(
              //     productSearch,
              //     setProductsSearched,
              //     setFetchingProducts
              //   )
              // }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      type="submit"
                      onClick={() =>
                        fetchProducts(
                          productSearch,
                          setProductsSearched,
                          setFetchingProducts
                        )
                      }
                    >
                      <Search />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onSubmit={() => console.log("submit")}
            />
           
            {isArrayWithValues(productsSearched)
              ? productsSearched.map((product) => {
                let { images, id } = product;
                let image = isArrayWithValues(images) && images[0].src;
                return (
                  <div
                    style={{
                      display: "flex",
                      padding: "7px 3px",
                      backgroundColor: selectedProducts.find(
                        (i) => i.id === id
                      )
                        ? "Highlight"
                        : "transparent",
                    }}
                    key={product.id}
                    onClick={() => onProductSelect(product)}
                  >
                    {image ? (
                      <img
                        src={image}
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "50%",
                        }}
                        onError={() => (image = "")}
                      />
                    ) : (
                      <img
                        src={"/static/img/woocommerce_placeholder.png"}
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "50%",
                        }}
                      />
                    )}
                    <div style={{ marginLeft: "6px" }}>{product.name}</div>
                  </div>
                );
              })
              : null}
            {fetchingProducts ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "30px",
                }}
              >
                <CircularProgress size="13px" />
                <Typography variant="body1" ml={2}>
                  Loading products.
                </Typography>
              </div>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={formOpen}
        keepMounted
        onClose={closeForm}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="sm"
        fullWidth
        sx={{ ...dialogStyle }}
      >
        <CreateUserTask customer={customer} closeForm={closeForm} />
      </Dialog>
      {/* <Dialog
        open={catalogDialog}
        onClose={() => setCatalogDialog(false)}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle id="scroll-dialog-title">Send catalog</DialogTitle>
        <DialogContent dividers={"paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}
          >
            <Grid container spacing={2} mt={1}>
            
              <Grid item xs={12} md={catalogForm.public ? 12 : 6}>
                <DropdownLabel>Catalog title</DropdownLabel>
                <TextField
                  value={catalogForm.title}
                  onChange={(e) => changeCatalogForm({ title: e.target.value })}
                  fullWidth
                  size="small"
                  id="outlined"
                  variant="outlined"
                />
              </Grid>
              {!catalogForm.public ? (
                <Grid item xs={12} md={6}>
                  <DropdownLabel>Expired in (min 1/max 48)</DropdownLabel>
                  <TextField
                    value={catalogForm.expiry}
                    onChange={(e) =>
                      changeCatalogForm({ expiry: e.target.value })
                    }
                    fullWidth
                    size="small"
                    id="outlined"
                    variant="outlined"
                    // label="Gemstone Pieces"
                    type="number"
                    inputProps={{ maxLength: 2 }}
                  />
                </Grid>
              ) : null}
              <Grid
                item
                xs={12}
              // sm={bulkEdit.stock_status === "instock" ? 4 : 6}
              >
                <DropdownLabel>Select product(s)</DropdownLabel>
                <Autocomplete
                  multiple
                  disablePortal
                  id="combo-box-demo"
                  options={catalogProducts}
                  size="small"
                  // sx={{ width: 300 }}
                  value={catalogForm.products}
                  loading={fetchingCatalogProducts}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="UP SELL"
                      onChange={(e) => onProductSearch(e.target.value)}
                    />
                  )}
                  onChange={(e, value) =>
                    changeCatalogForm({ products: value })
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
              // sm={bulkEdit.stock_status === "instock" ? 4 : 6}
              >
                <DropdownLabel>Description</DropdownLabel>
                <TextField
                  value={catalogForm.content}
                  onChange={(e) =>
                    changeCatalogForm({ content: e.target.value })
                  }
                  fullWidth
                  size="small"
                  variant="outlined"
                  // label="Gemstone Pieces"
                  // type="number"
                  id="outlined-textarea"
                  multiline
                  rows={3}
                />
              </Grid>
            </Grid>
          </DialogContentText>
          <Collapse in={catalogError ? true : false}>
            <Alert
              variant="outlined"
              severity="error"
              sx={{ marginTop: "10px" }}
            >
              {catalogError}
            </Alert>
          </Collapse>
        </DialogContent>

        <DialogActions sx={{ marginRight: "15px" }}>
          <Button onClick={() => setCatalogDialog(false)}>Cancel</Button>
          <LoadingButton
            loading={creatingCatalog}
            variant="outlined"
            onClick={() => onCreateCatalog()}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog> */}

      {/* <Dialog
        open={quotationDialog}
        maxWidth="sm"
        fullWidth
        onClose={closeQuotationDialog}
        TransitionComponent={Slide}
        sx={{ padding: "26px" }}
      >
        <DialogTitle>Create Quotation</DialogTitle>
        <DialogContent sx={{}}>
          <Collapse in={Boolean(quotationError)}>
            <Alert
              variant="outlined"
              severity="error"
              sx={{ my: 3, padding: "2px 7px" }}
            >
              {quotationError}
            </Alert>
          </Collapse>
          <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText>
          <DropdownLabel>Title</DropdownLabel>
          <TextField
            autoFocus
            // id="outlined-basic"
            size="small"
            // label=""
            variant="standard"
            fullWidth
            value={quotationForm.title}
            onChange={(e) => changeQuotationForm({ title: e.target.value })}
          />
          <Box sx={{ mt: 2.5 }} />
          <DropdownLabel>Select Customer</DropdownLabel>
          <Autocomplete
            // ref={selectQuotaionCustomerRef}
            disablePortal
            id="combo-box-demo"
            options={allQuotationCustomers}
            size="small"
            // sx={{ width: 300 }}
            value={quotationForm.customers}
            getOptionLabel={(option) => option.label || ""}
            // loading={customerSearching}
            openOnFocus
            renderInput={(params) => (
              <TextField
                {...params}
                // label="UP SELL"
                onChange={(e) =>
                  onQuotationCustomerSearch(
                    e.target.value,
                    setUpdatedQuotationCustomers
                  )
                }
              />
            )}
            onChange={(e, value) => changeQuotationForm({ customers: value })}
            ListboxProps={{
              style: {
                maxHeight: "100px",
              },
            }}
          />
          <Box sx={{ mt: 2.5 }} />
          <FormGroup sx={{ ml: 1 }}>
            <FormControlLabel
              control={
                <Switch
                  // checked={quotationForm.otp_enabled}
                  // onChange={(e) =>
                  //   changeQuotationForm({
                  //     otp_enabled: e,
                  //   })
                  // }
                  style={
                    quotationForm.otp_enabled
                      ? {
                        // margin: "4px 8px",
                        border: `1px solid ${backgroundColor}`,
                        backgroundColor,
                      }
                      : {
                        // margin: "4px 8px",
                      }
                  }
                />
              }
              label="Enable OTP"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeQuotationDialog}>Cancel</Button>
          <LoadingButton
            onClick={onQuotationCreate}
          // loading={creatingQuotation}
          >
            Create
          </LoadingButton>
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={toggleDeleteDialog}
        onClose={() => {
          setToggleDeleteDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={dialogStyle}
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure  you want to delete conversation?"}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setToggleDeleteDialog(false);
            }}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            color="error"
            loading={deletingConversation}
            onClick={async () => {
              setdeletingConversation(true);
              await dispatch(deleteConversation(customer));
              setdeletingConversation(false);
              setToggleDeleteDialog(false);
            }}
            autoFocus
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
});

const CannedMessages = ({ onCreate, onClose, onSelect, allCannedMessages }) => {
  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (!isObjWithValues(allCannedMessages)) return;
    setRows(Object.values(allCannedMessages).reverse());
  }, [allCannedMessages]);
  return (
    <Box sx={{ p: 4, pt: 2, width: "250px" }}>
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Typography sx={{ ...primaryLabel, fontSize: "16px" }}>
          Quick replies
        </Typography>
        <IconButton
          onClick={() => {
            // onClose && onClose();
            onCreate && onCreate();
          }}
          size="small"
        >
          <Add />
        </IconButton>
      </Stack>
      {rows.map((row, index) => {
        return (
          <>
            {index !== 0 ? <Divider /> : null}
            <Stack
              direction="row"
              sx={{ my: 1.5, cursor: "pointer" }}
              onClick={() => {
                onSelect(row.message);
                onClose();
              }}
            >
              <Typography sx={{ ...primaryLabel }}> {row.message}</Typography>
            </Stack>
          </>
        );
      })}
    </Box>
  );
};

const Chat = (props) => {
  let [whatsappChats, setWhatsappChats] = useState([]);
  let [propChats, setPropChats] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [customerProfile, setCustomerProfile] = useState("");
  const [customerLabel, setCustomerLabel] = useState("");
  useEffect(() => {
    let { customer } = props;
    let { profile_pic } = customer;
    if (isArrayWithValues(customer.whatsapp_chat))
      setPropChats(customer.whatsapp_chat);
    else setPropChats([]);
    let { first_name, last_name, name, username } = customer;
    // if (isArrayWithValues(meta_data)) {
    // let profileObj = meta_data.find((i) => i.key === "profile_url");
    // let labelObj = meta_data.find((i) => i.key === "label");
    // if (isObjWithValues(profileObj)) setCustomerProfile(profileObj.value);
    // else setCustomerProfile("");
    // if (isObjWithValues(labelObj)) setCustomerLabel(labelObj.value);
    // }
    setCustomerName(
      `${first_name || ""} ${last_name || ""} ${name || ""} ${username || ""} `
    );
  }, [props.customer]);
  let { customer, customerInfo, setCustomerView } = props;
  let { profile_pic } = customer;
  // const allReduxConversations = useSelector(
  //   (state) => state.chats.conversations
  // );
  // useEffect(() => {
  //   if (isArrayWithValues(propChats)) setWhatsappChats(propChats);
  //   else setWhatsappChats([]);
  // }, [propChats]);

  return (
    <React.Fragment>
      <ChatWindow
        setCustomerView={setCustomerView}
        // chats={whatsappChats}
        customerName={customerName}
        id={props.customer && props.customer.id}
        customerLoading={props.customerLoading}
        customerProfile={profile_pic}
        customer={customer}
        customerInfo={customerInfo}
        chatsTabSelected={props.chatsTabSelected}
        customerLabel={customerLabel}
        setCrmViewCustomer={props.setCrmViewCustomer}
        setShowProfile={props.setShowProfile}
      />
    </React.Fragment>
  );
};

export default memo(Chat);

const ImagePreview = (props) => {
  const {
    caption,
    setCaption,
    // onImageSend,
    src,
    setImageToUpload,
    setRowImageToUpload,
  } = props;
  const theme = useTheme();
  const isIpad = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <>
      <Grid
        sx={{
          height: "calc(61vh)",
          backgroundColor: "AppWorkspace",
          textAlign: "center",
          position: "relative",
          width: "100%",
        }}
        item
        xs={12}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
          onClick={() => {
            setImageToUpload("");
            setRowImageToUpload("");
          }}
        >
          <Close />
        </IconButton>
        <div
          style={{
            backgroundColor: "AppWorkspace",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: isIpad ? "80vw" : "40vw",
          }}
        >
          <img
            src={src}
            style={{
              width: isIpad ? "70vw" : "20vw",
              maxHeight: isIpad ? "70vw" : "20vw",
              boxShadow: "1px 1px 5px 0px rgba(0,0,0,0.54)",
            }}
          />

          <Box sx={{ height: "15px" }} />
          <ChatInput container>
            <Grid item style={{ flexGrow: 1, flex: 1 }}>
              <TextField
                variant="outlined"
                label="Type your caption"
                fullWidth
                multiline
                // sx={{ marginBottom: "30px" }}
                maxRows={4}
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
              />
            </Grid>
            <Grid item>
              <Box ml={2}>
                <Fab
                  color="primary"
                  aria-label="add"
                  size="medium"
                  // onClick={onImageSend}
                >
                  <SendIcon />
                </Fab>
              </Box>
            </Grid>
          </ChatInput>
        </div>
      </Grid>
    </>
  );
};

const getStatusIcon = (status) => {
  switch (status) {
    case "follow_up":
      return <Star style={{ color: filterColorObj?.[status] }} />;
      break;
    case "Unread":
      return <MailOutline style={{ color: filterColorObj[status] }} />;
      break;
    case "done":
      return <Done style={{ color: filterColorObj[status] }} />;
      break;

    default:
      return "";
      break;
  }
};

const shouldReverse = (chats) => {
  if (isArrayWithValues(chats)) {
    let length = chats.length;
    let startTime, endTime;
    for (let i = 0; i < length; i++) {
      let time = chats[i].time || chats[i].time;
      if (isNumber(time)) {
        if (Number(time).toString().length >= 13) time = time / 1000;
        startTime = time;
        break;
      }
    }
    for (let i = length - 1; i >= 0; i--) {
      let time = chats[i].time || chats[i].time;
      if (isNumber(time)) {
        if (time.toString().length >= 13) time = time / 1000;
        endTime = time;
        break;
      }
    }
    return startTime > endTime;
  }
};

const fetchProducts = async (search, setState, setLoading) => {
  // const controller = new AbortController();
  // const signal = controller.signal;

  const website = await getWebsite();
  const token = await getToken();
  if (setLoading) setLoading(true);
  let res = await axios({
    url: `${website}/wp-json/wc/v3/products`,
    params: {
      search,
      _fields: "id, name, sku, images,price, categories, permalink",
    },
    headers: {
      Authorization: `Basic ${token}`,
    },
  });
  if (res.data) setState && setState(res.data);
  if (setLoading) setLoading(false);
};

export const fetchChats = async (id, setState) => {
  console.log("Fetching chats", Date.now());
  if (!id) return;
  const website = await getWebsite();
  const token = await getToken();
  if (website && token)
    axios({
      url: `${website}/wp-json/wc/v3/customers/${id}?random=${Math.random()}`,
      // url: `${website}/wp-json/wc/v3/customers/${id}`,
      headers: {
        Authorization: `Basic ${token}`,
        "Content-Type": "application/x-www-form-urlencoded",

        // "Cache-Control": "no-cache",
        // Pragma: "no-cache",
        // Expires: "0",
      },
      method: "GET",
    })
      .then((res) => {
        res = res.data;
        if (res && isArrayWithValues(res.whatsapp_chat))
          setState(res.whatsapp_chat.reverse());
        // Snackbar.show({text: 'User info updated!'});
      })
      .catch((e) => {
        console.log(getAxiosError(e));
        // Snackbar.show({text: 'Info not updated!'});
      })
      .finally(() => {});
};
// {!isIpad && false && (
//   <Stack
//     direction={"row"}
//     sx={{ backgroundColor: "head", px: 2 }}
//     alignItems="center"
//     justifyContent={"space-between"}
//   >
//     {/* {customerId && (
//   <IconButton onClick={() => setShowNotesDialog(true)}>
//     <StickyNote2 />
//   </IconButton>
// )} */}

//     <LoadingButton
//       loading={loadingFilter === "follow_up"}
//       sx={{
//         "&:hover": { backgroundColor: "transparent" },
//         minWidth: "32px",
//       }}
//     >
//       <IconButton
//         sx={
//           loadingFilter === "follow_up" && { display: "none" }
//         }
//         onClick={() => {
//           // dispatch(spamCustomer(customer, customer.status === "spam" ? "" : "Spam"))
//           onFilterAssigning(
//             customer.status === "follow_up" ? "" : "follow_up"
//           );
//           setLoadingFilter("follow_up");
//         }}
//       >
//         <Tooltip title={`Mark as Follow up`}>
//           <Star
//             style={{
//               color:
//                 customer.status === "follow_up"
//                   ? filterColorObj.follow_up
//                   : null,
//             }}
//           />
//         </Tooltip>
//       </IconButton>
//     </LoadingButton>
//     {/* <IconButton onClick={() => {
//   onFilterAssigning("follow_up")
// }}>
//   <Tooltip title="Mark as Follow up">
//     <Star style={{ color: customer.status === "follow_up" ? "#FFD700" : null }} />
//   </Tooltip>
// </IconButton> */}
//     <LoadingButton
//       loading={loadingFilter === "unread"}
//       sx={{
//         "&:hover": { backgroundColor: "transparent" },
//         minWidth: "32px",
//       }}
//     >
//       <IconButton
//         sx={loadingFilter === "unread" && { display: "none" }}
//         onClick={() => {
//           onFilterAssigning(
//             customer.status === "unread" ? "" : "unread"
//           );
//           setLoadingFilter("unread");
//         }}
//       >
//         <Tooltip title={`Mark as unread`}>
//           <MarkEmailRead
//             style={{
//               color:
//                 customer.status === "unread"
//                   ? filterColorObj.unread
//                   : null,
//             }}
//           />
//         </Tooltip>
//       </IconButton>
//     </LoadingButton>

//     <LoadingButton
//       loading={loadingFilter === "done"}
//       sx={{
//         "&:hover": { backgroundColor: "transparent" },
//         minWidth: "32px",
//       }}
//     >
//       <IconButton
//         sx={loadingFilter === "done" && { display: "none" }}
//         onClick={() => {
//           onFilterAssigning(
//             customer.status === "done" ? "" : "done"
//           );
//           setLoadingFilter("done");
//         }}
//       >
//         <Tooltip title={`Mark as done`}>
//           <Done
//             style={{
//               color:
//                 customer.status === "done"
//                   ? filterColorObj.done
//                   : null,
//             }}
//           />
//         </Tooltip>
//       </IconButton>
//     </LoadingButton>
//     <LoadingButton
//       loading={loadingFilter === "delete"}
//       sx={{
//         "&:hover": { backgroundColor: "transparent" },
//         minWidth: "32px",
//       }}
//     >
//       <IconButton
//         sx={loadingFilter === "delete" && { display: "none" }}
//         onClick={() => {
//           setToggleDeleteDialog(true);
//         }}
//       >
//         <Tooltip title="Delete conversation">
//           <Delete />
//         </Tooltip>
//       </IconButton>
//     </LoadingButton>
//     <LoadingButton
//       loading={loadingFilter === "span"}
//       sx={{
//         "&:hover": { backgroundColor: "transparent" },
//         minWidth: "32px",
//       }}
//     >
//       <IconButton
//         sx={loadingFilter === "span" && { display: "none" }}
//         onClick={() => {
//           // dispatch(spamCustomer(customer, customer.status === "spam" ? "" : "Spam"))
//           onFilterAssigning(
//             customer.status === "spam" ? "" : "spam"
//           );
//           setLoadingFilter("span");
//         }}
//       >
//         <Tooltip
//           title={`Move to ${
//             customer.status === "spam" ? "UnSpam" : "Spam"
//           }`}
//         >
//           <Report
//             style={{
//               color:
//                 customer.status === "spam"
//                   ? filterColorObj.spam
//                   : null,
//             }}
//           />
//         </Tooltip>
//       </IconButton>
//     </LoadingButton>
//     {/* <IconButton onClick={() => {
//   onFilterAssigning("done")
// }}>
//   <Tooltip title="Move to Done" >
//     <Done style={{ color: customer.status === "done" ? "#0000FF" : null }} />
//   </Tooltip>
// </IconButton> */}
//     {customer && (
//       <Button
//         onClick={() =>
//           props.setShowProfile &&
//           props.setShowProfile((state) => !state)
//         }
//       >
//         Show Profile
//       </Button>
//     )}
//   </Stack>
// )}

export const deprecated_addConversation = async (obj) => {
  let website = await getWebsite();
  let token = await getToken();
  if (website && token) {
    try {
      let { data, status } = await axios({
        url: `${website}/wp-json/store/v1/settings/platform_conversations_whatsapp`,
        headers: { Authorization: `Basic ${token}` },
      });
      if (
        isArrayWithValues(data?.data) &&
        data?.data?.find((i) => i.id == obj.id)
      ) {
        return {
          error: true,
          message: "Duplicate Customer found",
          code: "duplicate_customer",
        };
      }
      if (
        status === 200
        //  && isArray(data?.data)
      ) {
        try {
          let { data: postData } = await axios({
            url: `${website}/wp-json/store/v1/settings/platform_conversations_whatsapp`,
            headers: { Authorization: `Basic ${token}` },
            method: "POST",
            data: {
              type: "platform_conversations_whatsapp",
              data: [...(data?.data || [])].concat([obj]),
            },
          });
          console.log(postData, "post data chats");
          return postData.data;
        } catch (error) {
          console.log(getAxiosError(error));
          return false;
        }
      }
    } catch (error) {
      console.log(getAxiosError(error));
    }
  }
};

export const addConversation = async (obj) => {
  if (!getIsBackendMigrated("chats")) return deprecated_addConversation(obj);
  let website = await getWebsite();
  let token = await getAuthToken();
  if (website && token) {
    try {
      let { data, status } = await axios({
        url: `${backendDoamin}/chat/users`,
        headers: { Authorization: `Bearer ${token}` },
        method: "POST",
        data: obj,
      });
      if (data.success) return [data.data];
    } catch (error) {
      console.log(getAxiosError(error));
    }
  }
};

export function generateContactString(data) {
  if (data.length === 1) {
    const contact = data[0];
    return (
      contact.name.formatted_name ||
      `${contact.name.first_name} ${contact.name.last_name}`
    );
  }

  let formattedName = "";
  let otherContactsCount = 0;

  data.forEach((contact, index) => {
    if (index === data.length - 1) {
      formattedName =
        contact.name.formatted_name ||
        `${contact.name.first_name} ${contact.name.last_name}`;
    } else {
      otherContactsCount++;
    }
  });

  return `${formattedName} and ${otherContactsCount} other contact(s)`;
}
